<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";

    import Icon from "../../../components/icons/Icon.svelte";
    import Loading from "../../../components/Elements/Loading.svelte";
    import { createEventDispatcher } from "svelte";

    export let status: string = "";
    export let loading: boolean = false;
    export let disabled: boolean = false;
    export let lists: string[] = [
        $_("statuses.unpaid"),
        $_("statuses.paid"),
        $_("statuses.overdue"),
        $_("statuses.inCollection"),
        $_("statuses.cancelled")
    ];
    export let listMapping: object = {
        NOT_PAID: $_("statuses.unpaid"),
        PAID: $_("statuses.paid"),
        OVERDUE: $_("statuses.overdue"),
        IN_COLLECTIONS: $_("statuses.inCollection"),
        CANCELLED: $_("statuses.cancelled")
    };

    $: status = mapStatus(status);

    let isOpen: boolean = false;
    let selectWrapper: HTMLDivElement;
    let direction: boolean = true;
    const dispatch = createEventDispatcher();

    function openDropdown(e) {
        e.stopPropagation();
        isOpen = !isOpen;
        if (e.clientY > window.innerHeight - 200) {
            direction = false;
        } else {
            direction = true;
        }
    }

    function scrollEvent(e) {
        if (e.clientY > window.innerHeight - 200) return (direction = false);
        return (direction = true);
    }

    function onSelect(e, list: string) {
        e.stopPropagation();
        isOpen = false;
        status = list;
        dispatch("change", { value: list });
    }

    function closeDown(event) {
        event.stopPropagation();
        isOpen = selectWrapper.contains(event.target);
    }

    function mapStatus(statusValue: string) {
        return statusValue ? listMapping[statusValue] : lists[0];
    }

    function mapStatusBack(statusValue: string) {
        const index = Object.values(listMapping).findIndex((item) => item == statusValue);
        return Object.keys(listMapping)[index];
    }

    onMount(() => {
        window.addEventListener("click", closeDown);
        window.addEventListener("scroll", scrollEvent, { passive: true });
    });

    onDestroy(() => {
        window.removeEventListener("click", closeDown);
        window.removeEventListener("scroll", scrollEvent);
    });
</script>

<div class="relative w-32 px-4 mr-2 dropdown-container" bind:this={selectWrapper}>
    <div
        class={`items-center h-full flex rounded-md cursor-pointer ${
            disabled ? "isDisabled" : "bg-white"
        }`}
        on:click={!loading && openDropdown}
        on:keyup
    >
        {#if loading}
            <Loading color="var(--gray-400)" />
        {:else}
            <div class="flex m-auto w-full h-full items-center justify-between">
                <p class="my-auto">{status}</p>
                <div class={`${isOpen && "rotate"} dropdown_icon`}>
                    <Icon name="drop-arrow-down" fill="var(--bg-active)" />
                </div>
            </div>
        {/if}
    </div>
    {#if isOpen && !disabled}
        <div class={`dropdown-list shadow-md rounded--md ${direction ? "top-down" : "bottom-up"}`}>
            {#each lists as list}
                <div
                    class="dropdown-item item flex items-center cursor-pointer pl-4"
                    on:click={(event) => onSelect(event, mapStatusBack(list))}
                    on:keyup
                >
                    <p class="py-3">{list}</p>
                </div>
            {/each}
        </div>
    {/if}
</div>

<style>
    .dropdown-container {
        width: 118px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
    }

    .dropdown-list {
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 10;
        background: white;
        border-radius: 8px;
    }

    .bottom-up {
        bottom: 2.5rem;
        box-shadow:
            0px -12px 16px -4px rgba(16, 24, 40, 0.1),
            0px -4px 6px -2px rgba(16, 24, 40, 0.05);
    }

    .top-down {
        top: 2.5rem;
        box-shadow:
            0px 12px 16px -4px rgba(16, 24, 40, 0.1),
            0px 4px 6px -2px rgba(16, 24, 40, 0.05);
    }

    .dropdown-item:hover {
        background-color: #eef4ff;
    }
    .rotate {
        transform: rotate(180deg);
    }
    .dropdown_icon {
        transition: 0.3s;
    }
    .isDisabled {
        cursor: auto;
    }
    .isDisabled .dropdown_icon {
        display: none;
    }
</style>
