<script lang="ts">
    import { _ } from "svelte-i18n";
    import { ItemWithLabel } from "@two-ui/core";
    import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
    import { faCalendarMinus } from "@fortawesome/free-regular-svg-icons";

    import { LABEL_TO_VALUE_MAP } from "./variables";
    import { type BillingAccountUI } from "./helpers";

    export let account: BillingAccountUI | null = null;

    const accountDetailsList = [
        {
            label: LABEL_TO_VALUE_MAP($_).accountantEmailAddress,
            value: account?.accountantEmails?.join(", ")
        },
        {
            label: LABEL_TO_VALUE_MAP($_).ccEmailAddress,
            value: account?.ccEmails?.join(", ") || "-"
        },
        {
            label: LABEL_TO_VALUE_MAP($_).contactNumber,
            value: account?.phone || "-"
        },
        {
            label: LABEL_TO_VALUE_MAP($_).currency,
            value: account?.currency
        },
        {
            label: LABEL_TO_VALUE_MAP($_).billingPeriod,
            value: account?.billingPeriod || "",
            secondary: account?.billingPeriodDetails,
            secondaryIcon: account?.billingPeriodDetails ? faClockRotateLeft : null
        },
        {
            label: LABEL_TO_VALUE_MAP($_).paymentTerms,
            value: account?.paymentTerms + " days",
            secondary: account?.statementDueDate,
            secondaryIcon: faCalendarMinus
        },
        {
            label: LABEL_TO_VALUE_MAP($_).lastOrderMadeOn,
            value: account?.lastOrderDate
        }
    ];
</script>

<section class="flex flex-wrap items">
    {#each accountDetailsList as item}
        <div class="w-1/2 mb-4 item-wrap">
            <ItemWithLabel
                label={item.label}
                value={item.value}
                secondary={item.secondary}
                secondaryIcon={item.secondaryIcon}
            />
        </div>
    {/each}
</section>

<style>
    .items .item-wrap:nth-child(odd) {
        padding-right: 1rem;
    }
    .items .item-wrap:nth-child(even) {
        padding-left: 1rem;
    }
</style>
