<script lang="ts">
    import { _ } from "svelte-i18n";

    import environment from "@/services/environment";
    import LoginInputEmail from "./LoginInputEmail.svelte";
    import LoginInputCode from "./LoginInputCode.svelte";
    import { loggedIn, buyerStoreMethods } from "../../../store/buyer/account.store";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";

    let buyerEmail: string;
    let step: "EMAIL" | "CODE" = "EMAIL";

    function getRedirectUrl() {
        let redirectUrl = sessionStorage.getItem("redirectUrl");
        if (redirectUrl) {
            sessionStorage.removeItem("redirectUrl");
        }
        if (!redirectUrl || !redirectUrl.startsWith("/buyer")) {
            redirectUrl = "/buyer";
        }
        return redirectUrl;
    }

    onMount(async () => {
        await buyerStoreMethods.checkLoggedIn();
        if ($loggedIn) navigate(getRedirectUrl());
    });
</script>

<body class="login">
    {#if step == "EMAIL"}
        <LoginInputEmail bind:buyerEmail bind:step />
    {:else}
        <LoginInputCode {buyerEmail} />
    {/if}
    <div class="privacy-policy">
        <a target="_blank" href={environment.branding.privacyPolicyLink}>
            {$_("pages.privacyPolicy")}
        </a>
        <p class="mt-2">&copy; {new Date().getFullYear()} {environment.branding.footerText}</p>
    </div>
</body>

<style lang="scss">
    .login {
        background-color: #f9fafb;

        .privacy-policy {
            margin: auto;
            margin-top: 2.5rem;
            font-size: 0.75rem;
            line-height: 1rem;
            color: #81809a;
            text-align: center;
        }
    }
</style>
