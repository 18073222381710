<script lang="ts">
    export let fill: string = "#d92d20";
    export let size: number = 22;
</script>

<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8 14L11 11M11 11L14 8M11 11L8 8M11 11L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke={fill}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
