<script lang="ts">
    import { navigate } from "svelte-routing";
    import { buyerData } from "../../../store/buyer/account.store";

    $: if ($buyerData) navigateToCorrectBuyerPage();

    function navigateToCorrectBuyerPage() {
        if ($buyerData.billing_account_companies?.size > 0) navigate("/buyer/statements");
        else navigate("/buyer/invoices");
    }
</script>
