import type { ComponentType } from "svelte";

import { CurrencyCodeEnum, type ICountry, LanguageCode, NotificationType } from "./types";
import CheckCircle from "../components/icons/checkCircle.svelte";
import AlertIcon from "../components/icons/alert2.svelte";
import ErrorIcon from "../components/icons/erroricon.svelte";

export const CUSTOMERS_SEARCH_PLACEHOLDER = "Search by Company name, Company ID.";
export const TWO_LEGAL_NAME = "Two B2B LTD";

// ISO 3166-2 country codes
export const GB = "GB";
export const NO = "NO";
export const SE = "SE";
export const US = "US";
export const NL = "NL";
export const ES = "ES";

export const DK = "DK";
export const FI = "FI";

export const ZZ = "ZZ";

export const BUSINESS_CUSTOMER_ONBOARDED_MERCHANTS = [];

enum paymentProductType {
    STATEMENT = "STATEMENT",
    INVOICE = "INVOICE",
    PREVIEW_STATEMENT = "PREVIEW_STATEMENT"
}

export const FILE_TYPES: string[] = ["CSV", "XLSX"];

export { paymentProductType };

// NOTE: When we implement translations,
// the language field and name could be dynamically set depending on merchant language
export const COUNTRIES: Record<string, ICountry> = {
    NO: <ICountry>{
        name: "Norway",
        country_code: NO,
        currency: CurrencyCodeEnum.NOK,
        languageCode: LanguageCode.NB,
        language: "Norwegian",
        phoneCountryCode: "+47"
    },
    GB: <ICountry>{
        name: "United Kingdom",
        country_code: GB,
        currency: CurrencyCodeEnum.GBP,
        languageCode: LanguageCode.EN,
        language: "English",
        phoneCountryCode: "+44"
    },
    SE: <ICountry>{
        name: "Sweden",
        country_code: SE,
        currency: CurrencyCodeEnum.SEK,
        locale: "sv_SE",
        languageCode: LanguageCode.SV,
        language: "Swedish",
        phoneCountryCode: "+46"
    },
    US: <ICountry>{
        name: "USA",
        country_code: US,
        currency: CurrencyCodeEnum.USD,
        languageCode: LanguageCode.US,
        language: "English",
        phoneCountryCode: "+1"
    },
    NL: <ICountry>{
        name: "Netherlands",
        country_code: NL,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.NL,
        language: "Dutch",
        phoneCountryCode: "+31"
    },
    ES: <ICountry>{
        name: "Spain",
        country_code: ES,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.EN,
        language: "Spanish",
        phoneCountryCode: "+34"
    },
    DK: <ICountry>{
        name: "Denmark",
        country_code: DK,
        currency: CurrencyCodeEnum.DKK,
        languageCode: LanguageCode.EN,
        language: "English",
        phoneCountryCode: "+45"
    },
    FI: <ICountry>{
        name: "Finland",
        country_code: FI,
        currency: CurrencyCodeEnum.EUR,
        languageCode: LanguageCode.EN,
        language: "English",
        phoneCountryCode: "+358"
    }
};

export const UNKNOWN_COUNTRY: ICountry = {
    name: "Unknown",
    country_code: ZZ,
    currency: CurrencyCodeEnum.ZZZ
};

export const LOGIN_SIGNUP_IMAGES: object[] = [
    {
        src: "/pictures/carousel/image01.png",
        testimonial:
            '"Online B2B transactions generally include long, manual processes - so much so that we often risk losing the entire purchase. Without Two, we would have had to reject 52% of our B2B customers!"',
        providerName: "Line S. Ludvigsen",
        roleCompany: "Marketing & E-Commerce Manager, Eplehuset"
    },
    {
        src: "/pictures/carousel/image02.png",
        testimonial:
            '"Introducing Two was the best decision we could have made as a business. It gives our customers the extra breathing space with the 30 day credit terms and it sets us apart from our competitors. We saw a 400% increase in AOV after using Two!"',
        providerName: "Mark Farr",
        roleCompany: "CEO & Co-Founder, Purple Planet"
    },
    {
        src: "/pictures/carousel/image03.jpg",
        testimonial:
            '"The main reason we chose Two as our B2B payment solution is because our previous methods created a lot of manual work. We no longer have that issue, which is something I\'m very happy with."',
        providerName: "Jørgen Bertelsen",
        roleCompany: "Founder, RiktigHandel"
    }
];

export const NotificationTypeIcons: Record<string, ComponentType> = {
    [NotificationType.SUCCESS]: CheckCircle,
    [NotificationType.WARNING]: AlertIcon,
    [NotificationType.ERROR]: ErrorIcon
};
