<script lang="ts">
    import { _ } from "svelte-i18n";

    import { getBaseUrl } from "../../utils/functions";
    import { ORDER_INVOICE } from "../../static/endPoints";
    import { NotificationType, type IOrderItem } from "../../static/types";
    import API from "../../services/apiService";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import Button from "../Elements/Button.svelte";
    import Icon from "../icons/Icon.svelte";

    export let invoiceID: string = "";
    export let billingAddress: {} = {};
    export let merchantOrderReference: string = "";
    export let purchaseOrderNumber: string = "";
    export let project: string = "";
    export let orderNote: string = "";
    export let lineItems: IOrderItem[] = [];

    let isEditProcessing: boolean = false;

    const handleEdit = () => {
        const baseUrl = getBaseUrl();

        const data: any = {
            buyer_reference: merchantOrderReference,
            buyer_purchase_order_number: purchaseOrderNumber,
            buyer_project: project,
            order_note: orderNote,
            line_item_updates: lineItems,
            billing_address: billingAddress
        };

        const url = `${baseUrl}${ORDER_INVOICE(invoiceID)}`;

        isEditProcessing = true;
        API.patch(url, data)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("components.invoice.orderUpdated")
                );
                modalState.actions.setModal(null, { isEdited: true });
            })
            .catch((err) => {
                let errorMessage = err.response?.data?.error_message
                    ? err.response?.data?.error_message
                    : $_("components.invoice.orderUpdateFailed");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
                modalState.actions.setModal(null, { isEdited: false });
            });
    };

    const handleCancel = () => {
        modalState.actions.setModal(null, { isEdited: false });
    };
</script>

<div class="container">
    <div class="flex title">
        <div class="title-text">{$_("components.invoice.newInvoiceText")}</div>
        <div class="close">
            <button on:click={handleCancel}>
                <Icon name="cross" size={26} />
            </button>
        </div>
    </div>
    <div class="body-text">
        {$_("components.invoice.bodyText")}
    </div>
    <div class="button-area">
        <div class="right-align button">
            <Button
                label={$_("components.goBack")}
                on:click={handleCancel}
                borderColor="var(--gray-300)"
                borderRadius="8px"
                color="var(--gray-800)"
                hoverColor="black"
                bgColor="white"
                hoverBg="var(--gray-50)"
                paddingX="12px"
                paddingY="12px"
            />
        </div>
        <div class="button">
            <Button
                label={$_("components.confirm")}
                on:click={handleEdit}
                disable={isEditProcessing}
                color="white"
                bgColor="var(--primary-600)"
                hoverBg="var(--primary-700)"
                paddingX="12px"
                paddingY="12px"
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        padding: 24px 32px 24px 32px;
        width: 544px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .close {
        margin-left: auto;
    }

    .body-text {
        color: var(--gray-400);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 24px;
    }

    .button-area {
        display: flex;
        right: 32px;
        left: 32px;
        bottom: 24px;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }

    .title-text {
        color: var(--ctnGeneralPrimary);
        font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }
</style>
