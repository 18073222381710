<script lang="ts">
    import { onMount } from "svelte";
    import Selector from "../../../components/account/Selector.svelte";
    import Search from "../../../components/search/Search.svelte";
    import StatementsTable from "./StatementsTable.svelte";
    import {
        type IStatementObject,
        filterPaidStatementsTable,
        filterUnpaidStatementsTable
    } from "./statementUtils";
    import {
        statements,
        buyerStoreMethods,
        buyerData,
        statementsSelectedCompany
    } from "../../../store/buyer/account.store";
    import { titleCase } from "../../../utilsV2/helpers";
    import { BASE_URL } from "../../../static/endPoints";
    import modalState from "../../../store/modals.store";
    import type { ISelectorInputField } from "../../../static/types";
    import StatementOfAccountModal from "../../../components/modals/StatementOfAccountModal.svelte";

    const TABS = ["All", "Unpaid", "Paid"];
    const ALL_COMPANIES = "All Companies";
    const setTab = (tab: string) => (selectedTab = tab);

    $: allRows = $statements;
    let activeRows: IStatementObject[] = [];
    let searchRows: IStatementObject[] = [];
    let selectedTab: string = "All";
    let searchValue: string = "";
    let changedValue: string = "";
    let paymentStatus: string = null;

    const buyerCompanies: Map<string, string> = new Map([[ALL_COMPANIES, ALL_COMPANIES]]);
    $: buyerCompaniesSize = buyerCompanies.size;
    let selectedCompanyValue = ALL_COMPANIES;
    const merchantCompanies: Map<string, string> = new Map();
    let selectorInput: ISelectorInputField;

    $: if ($buyerData?.billing_accounts_summary?.size > 0) {
        $buyerData.billing_accounts_summary.forEach((billingAccount) => {
            merchantCompanies.set(billingAccount.merchant_id, billingAccount.merchant_display_name);
            buyerCompanies.set(
                `${billingAccount.buyer_company_name}:${billingAccount.buyer_organization_number}:${billingAccount.buyer_company_country_code}`,
                titleCase([billingAccount.buyer_company_name])[0]
            );
            selectorInput = {
                options: buyerCompanies,
                placeholder: ALL_COMPANIES
            };
        });
    }

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }

        if (searchValue == "") {
            searchRows = activeRows;
        } else {
            searchRows = activeRows.filter(
                (row) =>
                    row["Merchant details"].tableItemBold
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                    row["Statement №"].tableItem
                        .toLowerCase()
                        .startsWith(searchValue.toLowerCase()) ||
                    row["Statement №"].subItem
                        .toLowerCase()
                        .startsWith(searchValue.toLowerCase()) ||
                    row.Sites.tableItem.toLowerCase().includes(searchValue.toLowerCase())
            );
        }
    }

    $: if (changedValue) {
        statementsSelectedCompany.set(selectedCompanyValue);

        buyerStoreMethods.getBillingAccountBuyerStatements();
    }

    onMount(() => {
        activeRows = allRows;
        searchRows = allRows;
    });

    $: {
        switch (selectedTab) {
            case "All":
                activeRows = allRows;
                paymentStatus = null;
                break;
            case "Unpaid":
                activeRows = filterUnpaidStatementsTable(allRows);
                paymentStatus = "NOT_PAID";
                break;
            case "Paid":
                activeRows = filterPaidStatementsTable(allRows);
                paymentStatus = "PAID";
                break;
        }
        searchRows = activeRows;
        setSearch(searchValue);
    }

    function showInvoiceStatementModal() {
        const accountIds = new Set();
        if (selectedCompanyValue && !selectedCompanyValue.startsWith("All")) {
            const [, companyNationalIdNumber, countryCode] = selectedCompanyValue.split(":");
            $buyerData.billing_accounts_summary.forEach((billingAccount) => {
                if (
                    billingAccount.buyer_organization_number == companyNationalIdNumber &&
                    billingAccount.buyer_company_country_code == countryCode
                ) {
                    accountIds.add(billingAccount.billing_account_id);
                }
            });
        }

        const props = {
            exportUrl: `${BASE_URL}/v1/portal/buyer/billing_account/invoice/statement`,
            additionalFilters: {
                billing_account_ids: [...accountIds.values()],
                payment_status: paymentStatus
            },
            dropDownChoices: merchantCompanies,
            isBuyerPortal: true
        };
        modalState.actions.setModal("", props, StatementOfAccountModal);
    }
</script>

<div class="orders-container">
    <div class="page-header">
        <div class="header-content">
            <div class="flex justify-between">
                <p class="text-3xl font-bold header-text">Statements</p>
            </div>
            {#if buyerCompaniesSize > 2}
                <div class="buyer-name">
                    <Selector
                        options={selectorInput.options}
                        bind:selectedKey={selectedCompanyValue}
                        bind:visibleText={changedValue}
                        placeholder={selectorInput.placeholder}
                    />
                </div>
            {/if}
        </div>

        <div class="tab-headers">
            {#each TABS as tab}
                <button
                    class="cursor-pointer px-6 mr-2 h-10 tab-header"
                    on:click={() => setTab(tab)}
                    class:selected={selectedTab === tab}
                >
                    {tab}
                </button>
            {/each}
        </div>
    </div>
    <div class="rounded-lg bg-white mx-8 mt-6 mb-8 orders-summary">
        <div class="flex items-center justify-between mx-8 pt-4 mb-3">
            <div class="flex max-w-7xl flex-col">
                <p class="text-lg font-bold">{selectedTab} statements</p>
            </div>
            <div class="search">
                <div style="width:inherit">
                    <Search
                        bind:searchValue
                        {setSearch}
                        placeholder="Search by statement № / site / payment status / merchant..."
                    />
                </div>
                {#if activeRows.length > 0}
                    <button
                        class="button-secondary-gray button-sm border"
                        style="width:100%;max-width:fit-content;"
                        on:click={showInvoiceStatementModal}>Download statement of account</button
                    >
                {/if}
            </div>
        </div>
        {#key searchRows}
            <StatementsTable {selectedTab} {searchRows} />
        {/key}
    </div>
</div>

<style>
    .page-header {
        border-bottom: 1px solid #e4e7ec;
        height: 8.5rem;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 32px;
        background-color: #ffffff;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-bottom-width: 1px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        align-items: center;
    }
    .orders-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .orders-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
    }

    .header-text {
        line-height: 42px;
    }
    .tab-headers {
        height: 2.5rem;
        display: flex;
        box-sizing: border-box;
        margin-top: 1.125rem;
    }

    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }

    .tab-header {
        line-height: 2.5rem;
        font-size: 14px;
        &.selected {
            border-bottom: 3px solid var(--primary-600);
        }
    }

    .search {
        width: 75%;
        display: flex;
        gap: 0.5rem;
        justify-content: flex-end;
        align-items: center;
    }

    .buyer-name {
        font-weight: 700;
        padding-right: 2rem;
    }
</style>
