<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onMount } from "svelte";

    import PaymentStatusSelector from "../../pages/merchant/orders/PaymentStatusSelector.svelte";

    const dispatch = createEventDispatcher();
    export let cellItem;
    let status = "PAID";
    const lists: string[] = [$_("statuses.unpaid"), $_("statuses.paid")];

    onMount(() => {
        if (cellItem.toUpperCase() !== "PAID") {
            status = "NOT_PAID";
        }
    });

    function updatePaymentStatus(event: any) {
        event.stopPropagation();
        dispatch("selectionchanged", {
            newStatus: event.detail.value
        });
    }
</script>

<div class="m-auto table-cell">
    <PaymentStatusSelector
        {status}
        on:change={(e) => updatePaymentStatus(e)}
        loading={false}
        {lists}
    />
</div>

<style>
</style>
