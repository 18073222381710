<script lang="ts">
    import { _ } from "svelte-i18n";

    import modalState from "../../../store/modals.store";
    import Button from "../../Elements/Button.svelte";
    import API from "../../../services/apiService";
    import { ACCOUNT_SETUP } from "../../../static/modals";
    import { onDestroy, onMount } from "svelte";
    import { account } from "../../../store/merchant/account.store";
    import { MERCHANT_SIGNUP } from "@/static/endPoints";
    import environment from "@/services/environment";

    export const props = {};
    export let closeModal;

    function completeSetup() {
        const application_id = $account.application_id || localStorage.getItem("application_id");
        API.get(MERCHANT_SIGNUP(application_id))
            .then((res: any) => {
                const props = {
                    data: res,
                    application_id
                };
                return modalState.actions.setModal(ACCOUNT_SETUP, props);
            })
            .catch(() => {
                localStorage.clear();
                return modalState.actions.setModal(null, {});
            });
    }

    const handleKeydown = (e) => {
        if (e.keyCode === 13 || e.code === "Enter") {
            completeSetup();
        }
    };
    onMount(() => {
        window.addEventListener("keydown", handleKeydown);
    });
    onDestroy(() => {
        window.removeEventListener("keydown", handleKeydown);
    });
</script>

<div class="px-8 py-10 max-w-xl w-full">
    <div id="hello">
        <img class="m-auto" src="/pictures/WavingHand.svg" alt="icon" />
        <p class="mt-6 text-center text-2xl font-bold">{$_("onboarding.woahThere")}</p>
        <p class="text-base ctn-general-secondary text-center mt-5">
            {@html $_("onboarding.setupIncomplete", {
                values: { brandName: environment.branding.displayName }
            })}
        </p>
        <div class="flex justify-center gap-4 m-auto mt-12 w-full">
            <div class="w-full">
                <Button
                    bgColor="white"
                    borderColor="var(--gray-300)"
                    color="var(--gray-800)"
                    hoverColor="var(--gray-800)"
                    hoverBg="var(--gray-100)"
                    size="xlarge"
                    label={$_("onboarding.keepLooking")}
                    on:click={closeModal}
                />
            </div>
            <div class="w-full">
                <Button
                    size="xlarge"
                    label={$_("onboarding.completeSetup")}
                    on:click={completeSetup}
                />
            </div>
        </div>
    </div>
</div>
