<script lang="ts">
    import { Tooltip } from "@two-ui/core";

    export let width: string = "100%";
    export let content: string = "";
    export let position: string = "top";
    export let hideTip = false;
    export let placement = "center";

    const getPosition = () => {
        switch (position) {
            case "bottom-centre":
                return "bottom";
            case "left-down":
                return "bottom";
            case "left-up":
                return "top";
            default:
                return position;
        }
    };
</script>

{#if !hideTip}
    <div style="position: relative">
        <Tooltip {width} {placement} wrapLines label={content} position={getPosition()}>
            <slot />
        </Tooltip>
    </div>
{:else}
    <slot />
{/if}
