<script lang="ts">
    export let fill: string = "#98A2B3";
    export let size: number = 12;
</script>

<svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9463 1.17697C11.2301 1.27773 11.4788 1.52644 11.9762 2.02385C12.4735 2.52125 12.7222 2.76995 12.823 3.05368C12.9255 3.3423 12.9255 3.65741 12.823 3.94604C12.7222 4.22976 12.4735 4.47846 11.9761 4.97585L11.6558 5.29614C10.1288 5.07109 8.92887 3.87105 8.70405 2.34389L9.02412 2.02382C9.52153 1.52642 9.77024 1.27772 10.054 1.17696C10.3426 1.07447 10.6577 1.07448 10.9463 1.17697ZM7.87139 3.17654L3.4272 7.62069C3.08766 7.96022 2.9179 8.12999 2.783 8.32341C2.66326 8.49509 2.56416 8.68027 2.48773 8.87513C2.40162 9.09466 2.35453 9.33008 2.26036 9.80093L1.96397 11.2829C1.90102 11.5976 1.86955 11.755 1.91599 11.8672C1.95659 11.9654 2.03455 12.0433 2.13269 12.0839C2.24493 12.1304 2.40231 12.0989 2.71706 12.036L4.19901 11.7396L4.19904 11.7396C4.66987 11.6454 4.90528 11.5983 5.12481 11.5122C5.31966 11.4358 5.50484 11.3367 5.67652 11.2169C5.86994 11.082 6.03971 10.9123 6.37924 10.5727L10.8233 6.12873C9.41653 5.68937 8.3106 4.58333 7.87139 3.17654Z"
        {fill}
    />
</svg>
