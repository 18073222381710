<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import Icon from "../icons/Icon.svelte";

    export let title: string;
    export let alwaysOpened = false;
    export let isActive = false;
    export let onToggle = () => {};
    export let onCancel = () => {};
    let isEdit = false;

    function toggleActive() {
        if (alwaysOpened) return;
        isActive = !isActive;
        onToggle();
    }
    function toggleEditMode(event) {
        event.stopPropagation();
        if (isEdit) onCancel();
        isEdit = !isEdit;
    }
</script>

<div class="collapsible-wrapper" class:alwaysOpened>
    <button
        class:active={isActive || alwaysOpened}
        class="collapsible flex justify-between"
        on:click={toggleActive}
    >
        <span class="collapsible-title">{title}</span>
        <div class="flex items-center">
            {#if isActive || (alwaysOpened && !isActive)}
                <TwoUIButton
                    on:click={(e) => toggleEditMode(e)}
                    size="md"
                    class="collapsible-edit-btn mr-8"
                    variant="tertiaryColor"
                    content={isEdit ? $_("components.cancel") : $_("components.edit")}
                />
            {/if}
            {#if !alwaysOpened}
                <span class="collapsible-icon-wrapper pr-4">
                    <Icon name="drop-arrow-down" fill="var(--user-gray)" />
                </span>
            {/if}
        </div>
    </button>
    <div class:active={isActive || alwaysOpened} class="content">
        {#if isEdit}
            <div>
                <slot name="edit" />
            </div>
        {:else}
            <div>
                <slot name="view" />
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    .collapsible-wrapper:not(.alwaysOpened) {
        border-bottom: 1px solid var(--gray-300);
    }
    .collapsible {
        align-items: center;
        cursor: pointer;
        padding: 16px 0;
        width: 100%;
    }
    .collapsible-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .collapsible.active .collapsible-icon-wrapper {
        transform: rotate(180deg);
    }

    .content {
        padding: 0;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        &:not(.active) {
            overflow: hidden;
        }
    }

    .content.active {
        padding: 0 0 18px;
        max-height: 1500px;
    }
</style>
