<script lang="ts">
    import Carousel from "../../../../components/carousel/Carousel.svelte";
    import SignupForm from "./SignupForm.svelte";
    import { LOGIN_SIGNUP_IMAGES } from "../../../../static/constant";
    import environment from "@/services/environment";
</script>

<div class="sign-up-container">
    <div class="sign-up-form-container">
        <SignupForm />
    </div>
    {#if environment.branding.displayName !== "Achteraf betalen"}
        <div class="carousel-container">
            <Carousel imageSources={LOGIN_SIGNUP_IMAGES} rotationInterval={7000} />
        </div>
    {/if}
</div>

<style lang="scss">
    :global(body) {
        background-color: var(--primary-25);
    }

    .sign-up-container {
        display: flex;
        height: 100%;
        flex: 1;
    }

    .sign-up-form-container {
        display: flex;
        margin: auto;
        min-width: 600px;
    }

    .carousel-container {
        flex: 1;
    }

    @media (max-width: 900px) {
        .carousel-container {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .sign-up-form-container {
            min-width: 100%;
        }
    }
</style>
