<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let checked: boolean = false;
    export let size: string = "large";
    let width: string = "38px";
    let slideWidth: string = "18px";
    let height: string = "22px";
    let left: string = "2px";

    const dispatch = createEventDispatcher();

    function handleChange(event) {
        dispatch("change", { checked: event.currentTarget.checked });
    }

    $: if (checked && size === "small") {
        left = "-5px";
    } else {
        left = "2px";
    }

    $: {
        switch (size) {
            case "small":
                width = "27px";
                height = "17px";
                slideWidth = "14px";
                break;
            default:
                break;
        }
    }
</script>

<label
    class="switch"
    style="--width: {width}; --slideWidth: {slideWidth}; --height: {height}; --left: {left}"
>
    <input type="checkbox" on:change|stopPropagation={handleChange} bind:checked />
    <span class="slider" />
</label>

<style>
    .switch {
        position: relative;
        display: inline-block;
        width: var(--width);
        min-width: var(--width);
        height: var(--height);
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.2s;
        transition: 0.2s;
        border-radius: 34px;
    }

    .slider::before {
        position: absolute;
        content: "";
        height: var(--slideWidth);
        width: var(--slideWidth);
        left: var(--left);
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
    }

    input:checked + .slider {
        background-color: var(--switch-checked);
    }

    input:checked + .slider {
        box-shadow: 0 0 1px var(--switch-checked);
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
    }
</style>
