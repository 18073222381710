<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Tooltip } from "@two-ui/core";

    import InputBox from "../../../components/Elements/InputBox.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import Selector from "../../../components/Elements/Selector.svelte";
    import Switch from "../../../components/Elements/Switch.svelte";
    import { emailIsValid } from "../../../utils/validation";
    import API from "../../../services/apiService";
    import type { IUpdateEmailStatementSettings } from "../../../static/types";
    import { NotificationType } from "../../../static/types";
    import notificationState from "../../../store/notifications.store";
    import modalState from "../../../store/modals.store";
    import { actions as accountActions } from "../../../store/merchant/account.store";
    import { cloneDeep } from "lodash";
    import Icon from "../../../components/icons/Icon.svelte";
    import { FILE_TYPES } from "../../../static/constant";
    import { MERCHANT_UPDATE } from "@/static/endPoints";

    export let closeModal;
    export let props: any = {};

    let emailError = "";
    let disable = false;
    let canSave = false;

    const originalDetails: any = props;
    const updateRequest = cloneDeep(props);

    $: showsSaveButton =
        originalDetails.bank_statement_email_is_enabled ||
        updateRequest.bank_statement_email_is_enabled !==
            originalDetails.bank_statement_email_is_enabled;

    $: canSave =
        updateRequest.bank_statement_email_is_enabled !==
            originalDetails.bank_statement_email_is_enabled ||
        updateRequest.bank_statement_format !== originalDetails.bank_statement_format ||
        updateRequest.bank_statement_email !== originalDetails.bank_statement_email;

    function setEmailSettings() {
        const request: IUpdateEmailStatementSettings = {
            bank_statement_email: updateRequest.bank_statement_email,
            bank_statement_email_is_enabled: updateRequest.bank_statement_email_is_enabled,
            bank_statement_format: updateRequest.bank_statement_format
        };

        API.patch(MERCHANT_UPDATE, request)
            .then(() => {
                accountActions.getAccount();
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("statement.notification.update_success")
                );
                modalState.actions.setModal(null, {}, null);
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("statement.notification.update_fail")
                );
                modalState.actions.setModal(null, {}, null);
            });
    }

    function validateEmail() {
        if (!emailIsValid(updateRequest.bank_statement_email)) {
            emailError = $_("errors.validEmail");
            disable = true;
        } else {
            emailError = "";
            disable = false;
        }
    }

    function resetEmail() {
        updateRequest.bank_statement_email = originalDetails.bank_statement_email;
        validateEmail();
    }
</script>

<div class="flex flex-col modal w-2xs">
    <p class="title">{$_("statement.title")}</p>
    <div class="flex justify-between">
        <div class="w-2/3">
            <p class="switch-label-1">{$_("statement.enable_report")}</p>
            <p class="switch-label-2">{$_("statement.email_frequency")}</p>
        </div>
        <Switch bind:checked={updateRequest.bank_statement_email_is_enabled} />
    </div>
    {#if updateRequest.bank_statement_email_is_enabled}
        <div class="details">
            <div class="flex items-center justify-between gap-2 text-sm">
                <p class="text-black-dark w-1/2">{$_("statement.statements_sent_to")}</p>
                <div class="flex items-center w-1/2 email-input">
                    <InputBox
                        bind:value={updateRequest.bank_statement_email}
                        type="email"
                        disabled={!updateRequest.bank_statement_email_is_enabled}
                        on:change={validateEmail}
                        bind:invalid={emailError}
                    />
                    <div class="email-reset relative">
                        <Tooltip label={$_("statement.reset_email_tooltip")}>
                            <button class="p-1" on:click={resetEmail}>
                                <Icon name="replay" size={16} />
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div class="flex items-center justify-between text-sm">
                <p class="text-black-dark w-3/5">{$_("statement.file_format")}</p>
                <div
                    class="w-1/4"
                    style={updateRequest.bank_statement_email_is_enabled
                        ? ""
                        : "pointer-events: none;"}
                >
                    <Selector
                        options={FILE_TYPES}
                        showArrow={true}
                        backgroundColor={updateRequest.bank_statement_email_is_enabled
                            ? "white"
                            : "#f9fafb"}
                        bind:value={updateRequest.bank_statement_format}
                    />
                </div>
            </div>
        </div>
    {/if}
    <div class="buttons">
        <div class="inline-block my-2">
            <Button
                label={$_("components.close")}
                on:click={closeModal}
                bgColor="#fff"
                borderColor="var(--primary-600)"
                hoverBg="var(--gray-50)"
                color="var(--gray-700)"
                hoverColor="var(--gray-700)"
            />
        </div>
        {#if showsSaveButton}
            <div class="inline-block my-2">
                <Button
                    label={$_("components.save")}
                    disable={disable || !canSave}
                    on:click={setEmailSettings}
                />
            </div>
        {/if}
    </div>
</div>

<style lang="scss">
    .modal {
        padding: 22px 24px;
        border-radius: 16px;
    }

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: var(--gray-800);
        margin-bottom: 34px;
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;
        margin-top: 32px;
        margin-bottom: 42px;
    }

    .switch-label-1 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-700);
    }

    .switch-label-2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-500);
    }

    .email-input {
        position: relative;
    }

    .email-reset {
        color: var(--basic);
        position: absolute;
        right: 10px;
        top: 14px;
        z-index: 1;
    }

    .buttons {
        display: flex;
        gap: 8px;
        justify-content: end;
        margin-top: 18px;
    }
</style>
