<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { InstalmentStatus, PaymentStatus } from "./types";
    export let customerPaymentStatus: PaymentStatus;
    export let instalmentStatus: InstalmentStatus;
    export let invoiceDueDate: string | null;

    let na = $_("na");

    function getCustomerPaymentName(
        customerPaymentStatus: PaymentStatus,
        instalmentStatus: InstalmentStatus
    ): string {
        if (instalmentStatus != "INVOICED" || invoiceDueDate == null) {
            return na;
        }
        try {
            var result = $_(`planDetails.paymentStatus.${customerPaymentStatus}`);
        } catch {
            var result = "-";
        }
        return result;
    }

    function getCustomerPaymentIcon(status: PaymentStatus) {
        var name: string;
        switch (status) {
            case "PAID":
                name = "paid";
                break;
            case "OVERDUE":
            case "RECOURSED":
            case "PARTIAL_REFUND":
                name = "overdue";
                break;
            case "NOT_PAID":
                name = "due";
                break;
        }
        return name;
    }
</script>

<div class="flex">
    <div class="pr-2">{getCustomerPaymentName(customerPaymentStatus, instalmentStatus)}</div>
    {#if getCustomerPaymentName(customerPaymentStatus, instalmentStatus) != na && getCustomerPaymentIcon(customerPaymentStatus)}
        <img
            class="tick"
            src="/pictures/{getCustomerPaymentIcon(customerPaymentStatus)}.svg"
            alt={customerPaymentStatus}
        />
    {/if}
</div>

<style lang="scss">
    .tick {
        width: 12px;
    }
</style>
