/* eslint-disable */
import type { IOrderItem, IInvoice, IPayee } from "@/static/types";
import { DateToUpdatedFormat, DateToUpdatedFormatWithYear } from "./functions";

export function processLineItems(items: any[]): IOrderItem[] {
    return items.map((itemData) => ({
        id: itemData.id,
        name: itemData.name,
        description: itemData.description,
        discount_amount: parseFloat(itemData.discount_amount),
        gross_amount: parseFloat(itemData.gross_amount),
        net_amount: parseFloat(itemData.net_amount),
        quantity: Number(itemData.quantity),
        unit_price: parseFloat(itemData.unit_price),
        tax_amount: parseFloat(itemData.tax_amount),
        tax_rate: parseFloat(itemData.tax_rate),
        tax_class_name: itemData.tax_class_name,
        quantity_unit: itemData.quantity_unit,
        image_url: itemData.image_url,
        product_page_url: itemData.product_page_url,
        type: itemData.type
    }));
}

function mapOrderDetails(res: any) {
    const purDate = res.date_created && new Date(res.date_created);
    const dueDate = res.date_fulfilled && new Date(res.date_fulfilled);

    const { date_fulfilled, payment_details } = res;
    if (date_fulfilled && payment_details && payment_details.due_in_days) {
        dueDate.setDate(dueDate.getDate() + res.payment_details?.due_in_days);
    }
    const fulDate = res.date_fulfilled && new Date(res.date_fulfilled);
    const updDate = res.date_updated && new Date(res.date_updated);
    const invDate = res.invoice_details?.due_date && new Date(res.invoice_details.due_date);

    return {
        orderID: res.id,
        currency: res.currency,
        download_url: res.invoice_url,
        credit_note_download_url: res.credit_note_url,
        merchantOrderID: res.merchant_order_id,
        merchantAdditionalInfo: res.merchant_additional_info,
        merchantShortName: res.merchant_short_name,
        merchantOrderReference: res.merchant_reference,
        accountType: res.payment_details?.bank_account_type || "",
        invoiceNumber: res.invoice_details?.invoice_number || "",
        payment_status: res.invoice_details?.payment_status || "",
        invoiceEmails: res.invoice_details?.invoice_emails?.join(", ") || "",
        createDate: res.date_created ? DateToUpdatedFormatWithYear(purDate) : "-",
        fulfillDate: res.date_fulfilled ? DateToUpdatedFormat(fulDate) : "-",
        status: res.status,
        origination: res?.order_origination,
        payable_amount: res.payable_amount,
        state: res.state,
        updateDate: res.date_updated ? DateToUpdatedFormat(updDate) : "-",
        lineItems: processLineItems(res.line_items),
        refunds: res.refunds,
        notes: res.order_note ? res.order_note : "",
        companyName: res.buyer?.company?.company_name || "",
        country: res.buyer?.company?.country_prefix,
        buyerName: `${res?.buyer?.representative?.first_name} ${res?.buyer?.representative?.last_name}`,
        buyerDepartment: res?.buyer_department,
        companyID: res.buyer?.company?.organization_number || "",
        phone: res.buyer?.representative?.phone_number || "",
        email: res.buyer?.representative?.email || "",
        shippingAddress: [
            res.shipping_address?.street_address,
            `${res.shipping_address?.postal_code} ${res.shipping_address?.city} ${res.shipping_address?.country}`
        ],
        originalShippingAddress: res.shipping_address,
        billingAddress: [
            res.billing_address?.street_address,
            `${res.billing_address?.postal_code} ${res.billing_address?.city} ${res.billing_address?.country}`
        ],
        shippingComp: res.shipping_details?.carrier_name,
        trackingNum: res.shipping_details?.tracking_number,
        dueDate: res.payment_details?.due_date || "",
        invoiceDueDate: dueDate ? DateToUpdatedFormatWithYear(invDate) : "",
        during: res.payment_details?.due_in_days,
        bankAccount: res.payment_details?.bank_account,
        delivery: res.shipping_address?.organization_name,
        amount: res?.gross_amount - res?.tax_amount,
        grossAmount: res?.gross_amount,
        bankAmount:
            res.state === "FULFILLED" || (res?.state === "REFUNDED" && res?.payable_amount > 0)
                ? res?.gross_amount
                : "-",
        KIDNum: res?.payment_reference,
        invoice_type: res.invoice_type,
        incurredFees: res?.total_fee ? res?.currency + " " + res?.total_fee : null,
        percentageFee: Number(res?.percentage_fee).toFixed(2),
        fixedFee: res?.currency + " " + res?.fixed_fee,
        type: res.order_type,
        purchaseOrderNumber: res?.buyer_purchase_order_number,
        project: res?.buyer_project,
        invoice_details: res?.invoice_details,
        tax_amount: res?.tax_amount,
        rootOrderID: res?.root_order_id || "",
        approvedOnRecourse: res.approved_on_recourse,
        declineReason: res?.decline_reason || null,
        features: res?.features || null,
        creditNotes: res?.credit_notes
    };
}

function mapLatentOrderDetails(res: any) {
    const purDate = res.date_created && new Date(res.date_created);
    return {
        orderID: res.id,
        currency: res?.order_json.currency,
        download_url: "",
        credit_note_download_url: "",
        merchantOrderID: res?.order_json.merchant_order_id,
        merchantAdditionalInfo: res?.order_json.merchant_additional_info,
        merchantShortName: "",
        merchantOrderReference: res?.order_json.merchant_reference,
        accountType: "",
        invoiceNumber: "",
        payment_status: "",
        createDate: res.date_created ? DateToUpdatedFormatWithYear(purDate) : "-",
        fulfillDate: "-",
        origination: "-",
        status: res.status,
        payable_amount: res?.order_json.gross_amount,
        state: res.status === "CANCELLED" ? "CANCELLED" : res?.state,
        updateDate: "-",
        lineItems: processLineItems(res?.order_json.line_items),
        refunds: res?.refunds,
        notes: res?.order_json.order_note ? res?.order_json.order_note : "",
        companyName: res?.order_json.buyer?.company?.company_name || "",
        country: res?.order_json.buyer?.company?.country_prefix,
        buyerName: `${res?.order_json?.buyer?.representative?.first_name} ${res?.order_json?.buyer?.representative?.last_name}`,
        buyerDepartment: res?.order_json?.buyer_department,
        companyID: res?.order_json.buyer?.company?.organization_number || "",
        phone: res?.order_json.buyer?.representative?.phone_number || "",
        email: res?.order_json.buyer?.representative?.email || "",
        shippingAddress: [
            res?.order_json.shipping_address?.street_address,
            `${res?.order_json.shipping_address?.postal_code} ${res?.order_json.shipping_address?.city} ${res?.order_json.shipping_address?.country}`
        ],
        originalShippingAddress: res?.order_json.shipping_address,
        billingAddress: [
            res?.order_json.billing_address?.street_address,
            `${res?.order_json.billing_address?.postal_code} ${res?.order_json.billing_address?.city} ${res?.order_json.billing_address?.country}`
        ],
        shippingComp: "",
        trackingNum: "",
        dueDate: "",
        invoiceDueDate: "",
        during: "",
        bankAccount: "",
        delivery: "",
        amount: res?.order_json?.net_amount,
        grossAmount: res?.order_json?.gross_amount,
        bankAmount: res?.order_json?.gross_amount,
        KIDNum: res?.payment_reference,
        invoice_type: res?.order_json.invoice_type,
        incurredFees: res?.total_fee ? res?.currency + " " + res?.total_fee : null,
        percentageFee: Number(res?.percentage_fee).toFixed(2),
        fixedFee: res?.order_json?.currency + " " + res?.fixed_fee,
        type: res?.order_type || "MERCHANT_INITIATED",
        purchaseOrderNumber: res?.order_json?.buyer_purchase_order_number,
        project: res?.order_json?.buyer_project,
        invoice_details: res?.order_json?.invoice_details,
        tax_amount: res?.order_json.tax_amount,
        rootOrderID: res?.root_order_id || "",
        features: res?.features || null,
        creditNotes: res?.credit_notes
    };
}

function mapOrderInvoiceDetails(res) {
    let invoices: IInvoice[] = [];

    res.forEach((item) => {
        let payee: IPayee;
        let invoice: IInvoice = {
            order_id: item.id,
            due_date: item.invoice_details.due_date,
            due_in_days: item.invoice_details.due_in_days,
            invoice_issueDate: item.invoice_details.invoice_issue_date,
            invoice_number: item.invoice_details.invoice_number,
            payment_status: item.invoice_details?.payment_status || "",
            invoice_type: item.invoice_type,
            settlement_status: "",
            value: item.gross_amount,
            invoice_url: item.invoice_url,
            invoice_addess: "",
            payee: payee,
            payment_reference: "",
            payment_reference_message: "",
            payment_reference_ocr: "",
            payment_reference_type: ""
        };
        invoices.push(invoice);
    });
    return invoices;
}

function mapFulfilledLineItems(res) {
    let lineItems: IOrderItem[] = [];

    res.forEach((item) => {
        lineItems = lineItems.concat(processLineItems(item.line_items));
    });

    const combinedData = {};
    lineItems.forEach((item) => {
        const { id, name, quantity, gross_amount, net_amount, tax_amount, ...rest } = item;

        if (!combinedData[name]) {
            combinedData[name] = {
                id,
                name,
                quantity,
                gross_amount,
                net_amount,
                tax_amount,
                ...rest
            };
        } else {
            combinedData[name].quantity += quantity;
            combinedData[name].gross_amount = Number(
                (combinedData[name].gross_amount + gross_amount).toFixed(2)
            );
            combinedData[name].net_amount = Number(
                (combinedData[name].net_amount + net_amount).toFixed(2)
            );
            combinedData[name].tax_amount = Number(
                (combinedData[name].tax_amount + tax_amount).toFixed(2)
            );
        }
    });

    const result: IOrderItem[] = Object.values(combinedData);

    return result;
}

function mapOrderCreditNotes(res) {
    let creditNotes: {}[] = [];

    if (res) {
        res.forEach((item) => {
            let creditNote: {} = {
                amount: item.amount,
                refund_no: item.refund_no.toString(),
                refund_date: item.refund_date.substring(0, 10)
            };

            creditNotes.push(creditNote);
        });
    }

    return creditNotes;
}

export {
    mapLatentOrderDetails,
    mapOrderDetails,
    mapOrderInvoiceDetails,
    mapOrderCreditNotes,
    mapFulfilledLineItems
};
