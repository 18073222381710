<script lang="ts">
    import { navigate } from "svelte-routing";
    import { getContext, onMount } from "svelte";
    import { ROUTER } from "svelte-routing/src/contexts";

    const { activeRoute } = getContext(ROUTER);
    import type { INavItem } from "../../static/types";
    import Icon from "../icons/Icon.svelte";

    export let item: INavItem;
    let active: boolean;
    let hover: boolean = false;

    $: iconColor = hover
        ? "var(--ctnInteractiveGeneralHover)"
        : active
          ? "var(--bgInteractiveSemanticHighlightPressed)"
          : "var(--ctnGeneralSecondary)";

    onMount(() => {
        activeRoute.subscribe((state) => {
            const currentUrl = `${state.uri}/${state.params["*"]}`;
            active = item.url && currentUrl.endsWith(item.url);
        });
    });
</script>

<li
    class={`flex flex-row h-12 navItem flex-shrink-0`}
    class:active
    on:click
    on:keyup
    on:mouseenter={() => (hover = true)}
    on:mouseleave={() => (hover = false)}
>
    <div
        class={`flex items-center w-full cursor-pointer`}
        on:click={() => navigate(item.url)}
        on:keyup
    >
        <Icon name={item.icon} fill={iconColor} size={20} />
        <span class={`navItemLabel text-sm`} class:active>{item.name}</span>
    </div>
</li>

<style lang="scss">
    .navItem {
        padding: 0 14px;
        border-radius: 8px;
        width: calc(100% - 16px);
        margin: 2px auto;
    }

    .navItem:hover {
        background-color: var(--bgGeneralQuarterary);

        .navItemLabel {
            color: var(--ctnInteractiveGeneralHover);
        }
    }

    .navItem.active {
        background-color: var(--primary-50);
    }

    .navItemLabel {
        padding-left: 16px;
        color: var(--ctnGeneralSecondary);
    }

    .navItemLabel.active {
        color: var(--bgInteractiveSemanticHighlightPressed);
    }
</style>
