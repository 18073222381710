<script lang="ts">
    export let color: string = "#fff";
</script>

<div class="spinner" style="--color: {color}">
    <div class="bounce1" />
    <div class="bounce2" />
    <div class="bounce3" />
</div>

<style>
    .spinner {
        width: 70px;
        margin: auto;
        text-align: center;
    }

    .spinner > div {
        width: 9px;
        height: 9px;
        background-color: var(--color);

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
</style>
