<script lang="ts">
    import { _ } from "svelte-i18n";

    import ShowVideoModal from "../modals/showVideoModal.svelte";
    import InfoModal from "../modals/InfoModal.svelte";
    import environment from "@/services/environment";

    let showHelpVideoModal = false;
    let showInfoModal = false;

    const openVideoModal = () => {
        showHelpVideoModal = true;
    };

    const openInfoModal = () => {
        showInfoModal = true;
    };

    const infoModalText = $_("statements.infoModalText", {
        values: {
            brand: environment.branding.displayName,
            supportEmail: environment.branding.supportEmail
        }
    });
    const infoModalTitle = $_("statements.infoModalTitle");
</script>

<div class="outer-container">
    <ShowVideoModal
        bind:show={showHelpVideoModal}
        videoTitle={$_("statements.videoTitle")}
        videoUrl="https://www.loom.com/embed/87077018bf9b4858b3ecf6afdf12023c?sid=b2ec7ce4-7119-49ec-a4ff-2312a18d87a4"
    />
    <InfoModal bind:show={showInfoModal} {infoModalText} {infoModalTitle} />
    <div class="statement-banner">
        <div class="statements-banner-left">
            <p>{$_("statements.combiningInvoices")}</p>
            <div class="statements-banner-buttons-container">
                <button class="get-started-button" on:click|stopPropagation={openInfoModal}>
                    {$_("statements.getStarted")}
                </button>
                <button class="watch-video-button" on:click|stopPropagation={openVideoModal}>
                    {$_("statements.watchDemoVideo")}
                </button>
            </div>
        </div>
        <div class="statements-banner-right">
            <img
                src="/pictures/statements/statements.png"
                alt="feature-banner"
                class="statement-banner-image-container"
            />
        </div>
    </div>
    <div class="statements-text-block">
        <p>
            {@html $_("statements.makeInvoicingEasier")}
        </p>
    </div>
    <div class="statements-highlights-container">
        <div class="statements-highlight">
            <div class="statements-highlight-icon">
                <img src="/pictures/shopping-cart.svg" alt="cart" class="icon" />
            </div>
            <h3>{$_("statements.encourageRepeatPurchasing")}</h3>
            <p>{$_("statements.encourageRepeatPurchasingDescription")}</p>
        </div>
        <div class="statements-highlight">
            <div class="statements-highlight-icon">
                <img src="/pictures/emoticon-happy.svg" alt="cart" class="icon" />
            </div>
            <h3>{$_("statements.seamlessRepayment")}</h3>
            <p>{$_("statements.seamlessRepaymentDescription")}</p>
        </div>
        <div class="statements-highlight">
            <div class="statements-highlight-icon">
                <img src="/pictures/clipboard-check.svg" alt="cart" class="icon" />
            </div>
            <h3>{$_("statements.easyReconciliation")}</h3>
            <p>{$_("statements.easyReconciliationDescription")}</p>
        </div>
    </div>
</div>

<style>
    .outer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .statement-banner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 90%;
        height: 309px;
        background: linear-gradient(
            50deg,
            #dfd8f8 0.21%,
            rgba(216, 229, 248, 0.41) 65.89%,
            rgba(254, 207, 54, 0) 133.55%
        );
        border-radius: 1rem;
        margin-top: 2rem;
    }

    .statements-banner-left {
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-wrap: break-word;
        height: 100%;
        padding: 48px;
        gap: 1rem;
        width: 50%;
    }

    .statements-banner-left p {
        width: 100%;
        color: #1d2939;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
    }

    .statements-banner-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        overflow: hidden;
    }

    .statement-banner-image-container {
        width: auto;
        height: 100%;
        object-fit: cover;
        padding-top: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .statements-text-block {
        display: flex;
        width: 90%;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 52px 0 52px 0;
    }

    :global(.statements-text-block span) {
        color: var(--primary-500) !important;
    }

    .get-started-button {
        cursor: pointer;
        display: flex;
        width: fit-content;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-600, var(--primary-600));
        background: var(--Primary-600, var(--primary-600));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--White, #fff);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .watch-video-button {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        border-radius: 8px;
        display: flex;
        padding: 10px 18px;
        gap: 8px;
        color: var(--Primary-700, var(--primary-700));
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .statements-banner-buttons-container {
        display: flex;
        flex-direction: row;
        width: 100;
        gap: 0.75rem;
    }

    .statements-highlights-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
    }

    .statements-highlight {
        display: flex;
        flex-direction: column;
        width: 30%;
        overflow-wrap: break-word;
        margin-bottom: 2.5rem;
    }

    .statements-highlight-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: rgba(246, 258, 254, 0.1);
        border-radius: 50%;
        margin-bottom: 1.5rem;
    }

    .icon {
        display: block;
        width: 60px;
        height: 60px;
        fill: #6172f3;
    }

    .statements-highlight h3 {
        color: #1d2939;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 1rem;
    }

    .statements-highlight p {
        align-self: stretch;
        color: var(--Text-Secondary, #344054);
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    @media (max-width: 1215px) {
        .statements-banner-left {
            width: 100%;
        }

        .statements-banner-right {
            display: none;
        }
    }
</style>
