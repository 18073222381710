export const getToken = (): any => {
    let user = localStorage.getItem("token");
    return user;
};

export const setToken = (token: string): any => {
    localStorage.setItem("token", token);
};

export const removeToken = () => {
    localStorage.removeItem("token");
};

export const setFullAccess = (value: string) => {
    localStorage.setItem("FULL_ACCESS", value);
};

export const getFullAccess = (): string => {
    return localStorage.getItem("FULL_ACCESS");
};

export const initToken = () => {
    localStorage.clear();
};

export const setOnTillit = (value: string) => {
    localStorage.setItem("ON_TILLIT", value);
};

export const getOnTillit = (): string => {
    return localStorage.getItem("ON_TILLIT");
};

export const setRole = (role: string): any => {
    localStorage.setItem("role", role);
};

export const getRole = (): string => {
    return localStorage.getItem("role");
};

export const setCountry = (country: string): void => {
    localStorage.setItem("signup_country", country);
};

export const getCountry = (): string => {
    return localStorage.getItem("signup_country");
};

export const getRedirectUrl = (): string => {
    return localStorage.getItem("redirect_url");
};

export const setRedirectUrl = (redirectUrl: string): void => {
    localStorage.setItem("redirect_url", redirectUrl);
};

export const getOrganizationId = (): string => {
    return localStorage.getItem("org_id");
};

export const setCompany = (company: string) => {
    localStorage.setItem("company", company);
};

export const getCompany = (): string => {
    return localStorage.getItem("company");
};

export const setOrganizationId = (companyId: string): void => {
    localStorage.setItem("org_id", companyId);
};

export const setAddress = (address: any) => {
    localStorage.setItem("company_info", JSON.stringify(address));
};

export const getAddress = (): any => {
    return JSON.parse(localStorage.getItem("company_info"));
};

export const setBusinessCustomerData = (data): any => {
    localStorage.setItem("businessCustomerData", JSON.stringify(data));
};

export const getBusinessCustomerData = (): any => {
    return JSON.parse(localStorage.getItem("businessCustomerData"));
};
