import { writable } from "svelte/store";
import { GET_CREDIT_LIMIT } from "../../static/endPoints";
import API from "../../services/apiService";

import type { ICreditLimit, CountryCode } from "../../static/types";

interface CreditLimitLookup {
    success: boolean;
    creditLimit: ICreditLimit;
}

function createCreditLimit() {
    const creditLimitState = writable<CreditLimitLookup>();
    const creditLimitLoading = writable(false);
    const openBankingDataRequired = writable<boolean>(false);
    const intentSoftCreditCheckApproved = writable<boolean>(null);

    return {
        creditLimitState,
        creditLimitLoading,
        openBankingDataRequired,
        intentSoftCreditCheckApproved,
        actions: {
            getCreditLimit: (
                companyNumber: string,
                countryCode: CountryCode,
                companyName: string,
                organization_type: string,
                currency: string | null = null
            ) => {
                creditLimitLoading.set(true);
                API.get(
                    `${GET_CREDIT_LIMIT}?organization_number=${companyNumber}&country_prefix=${countryCode}&organization_name=${companyName}&organization_type=${organization_type}&currency=${
                        currency ?? ""
                    }`
                )
                    .then((res: any) => {
                        let newCreditValue: ICreditLimit = {
                            buyerCompanyName: res.buyer_company_name,
                            buyerCompanyNumber: res.buyer_organization_number,
                            creditLimit: res.credit_limit,
                            availableCreditLimit: res.credit_limit_balance,
                            maxSingleOrderValue: res.max_single_order_value,
                            isBuyerInArrears: res.buyer_is_in_arrears,
                            buyerFallbackEnabled: res.buyer_fallback_enabled,
                            fallbackThreshold: res.fallback_threshold,
                            credit_approved: res.credit_approved,
                            additional_data_required: res.additional_data_required
                        };
                        creditLimitState.set({
                            success: true,
                            creditLimit: newCreditValue
                        });
                        if (res.additional_data_required == "OPEN_BANKING_DATA") {
                            openBankingDataRequired.set(true);
                        } else {
                            openBankingDataRequired.set(false);
                        }
                        creditLimitLoading.set(false);
                    })
                    .catch(() => {
                        creditLimitState.set({
                            success: false,
                            creditLimit: null
                        });
                        creditLimitLoading.set(false);
                    });
            },
            processIntentResponse(orderIntentResponse: any) {
                intentSoftCreditCheckApproved.set(orderIntentResponse?.approved ?? false);
                if (orderIntentResponse?.approved_with_conditions?.includes("OPEN_BANKING_DATA")) {
                    openBankingDataRequired.set(true);
                } else {
                    openBankingDataRequired.set(false);
                }
            },
            resetState() {
                creditLimitState.set(null);
                openBankingDataRequired.set(false);
                intentSoftCreditCheckApproved.set(null);
            }
        }
    };
}

export const {
    creditLimitState,
    creditLimitLoading,
    openBankingDataRequired,
    intentSoftCreditCheckApproved,
    actions
} = createCreditLimit();
