<script lang="ts">
    import { _ } from "svelte-i18n";

    import ShowVideoModal from "./../../../components/modals/showVideoModal.svelte";
    import InfoModal from "./../../../components/modals/InfoModal.svelte";
    import environment from "./../../../services/environment";

    let showHelpVideoModal = false;
    let showInfoModal = false;

    const openVideoModal = () => {
        showHelpVideoModal = true;
    };

    const openInfoModal = () => {
        showInfoModal = true;
    };

    const infoModalText = $_("statements.infoModalText", {
        values: {
            brand: environment.branding.displayName,
            supportEmail: environment.branding.supportEmail
        }
    });
    const infoModalTitle = $_("statements.infoModalTitle");
</script>

<div class="outer-container">
    <div class="rounded-lg bg-white mx-8 mt-6 mb-8">
        <ShowVideoModal
            bind:show={showHelpVideoModal}
            videoTitle={$_("plans.videoTitle")}
            videoUrl="https://www.loom.com/embed/4dc2c04115314cd0896a7a0cd0c53785?sid=05877253-814b-44cb-8f44-6821443f9d74"
        />
        <InfoModal bind:show={showInfoModal} {infoModalText} {infoModalTitle} />
        <div class="plan-banner">
            <div class="plans-banner-left">
                <p>{@html $_("plans.spreadPayments")}</p>
                <div class="plans-banner-buttons-container">
                    <button class="get-started-button" on:click|stopPropagation={openInfoModal}>
                        {$_("plans.getStarted")}
                    </button>
                    <button class="watch-video-button" on:click|stopPropagation={openVideoModal}>
                        {$_("plans.watchDemoVideo")}
                    </button>
                </div>
            </div>
            <div class="plans-banner-right">
                <img
                    src="/pictures/rental-plan-notification.png"
                    alt="rental-plan-notification"
                    class="plan-banner-image-container"
                />
            </div>
        </div>
        <div class="plans-text-block">
            <p>
                {@html $_("plans.financeWithoutRisk")}
            </p>
        </div>
        <div class="plans-highlights-container">
            <div class="plans-highlight">
                <div class="plans-highlight-icon">
                    <img src="/pictures/clipboard-check.svg" alt="cart" class="icon" />
                </div>
                <h3>{$_("plans.getPaidUpFront")}</h3>
                <p>{$_("plans.getPaidUpFrontDescription")}</p>
            </div>
            <div class="plans-highlight">
                <div class="plans-highlight-icon">
                    <img src="/pictures/shopping-cart.svg" alt="cart" class="icon" />
                </div>
                <h3>{$_("plans.affordableHighValue")}</h3>
                <p>{$_("plans.affordableHighValueDescription")}</p>
            </div>
            <div class="plans-highlight">
                <div class="plans-highlight-icon">
                    <img src="/pictures/emoticon-happy.svg" alt="cart" class="icon" />
                </div>
                <h3>{$_("plans.seamlessRepaymentExp")}</h3>
                <p>{$_("plans.seamlessRepaymentExpDescription")}</p>
            </div>
        </div>
    </div>
</div>

<style>
    .outer-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* margin: 15px auto;
    border-radius: 8px;
    background: white; */
    }

    .plan-banner {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 90%;
        height: 309px;
        background: linear-gradient(
            49.87deg,
            rgba(199, 215, 254, 0.8) 0.21%,
            rgba(178, 221, 255, 0.8) 65.89%,
            rgba(254, 240, 199, 0.8) 133.55%
        );
        border-radius: 1rem;
        margin: auto;
        margin-top: 2rem;
    }

    .plans-banner-left {
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-wrap: break-word;
        height: 100%;
        padding: 48px;
        gap: 1rem;
        width: 50%;
    }

    .plans-banner-left p {
        width: 100%;
        color: #1d2939;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
    }

    .plans-banner-right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
        overflow: hidden;
    }

    .plan-banner-image-container {
        width: auto;
        height: 100%;
        object-fit: cover;
        padding-top: 1rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .plans-text-block {
        display: flex;
        width: 90%;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        margin: 52px auto;
    }

    :global(.plans-text-block span) {
        color: var(--primary-500) !important;
    }

    .get-started-button {
        cursor: pointer;
        display: flex;
        width: fit-content;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-600, var(--primary-600));
        background: var(--Primary-600, var(--primary-600));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--White, #fff);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .watch-video-button {
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        border-radius: 8px;
        display: flex;
        padding: 10px 18px;
        gap: 8px;
        color: var(--Primary-700, var(--primary-700));
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .plans-banner-buttons-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 1rem;
        gap: 0.75rem;
    }

    .plans-highlights-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 90%;
        margin: auto;
    }

    .plans-highlight {
        display: flex;
        flex-direction: column;
        width: 30%;
        overflow-wrap: break-word;
        margin-bottom: 2.5rem;
    }

    .plans-highlight-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: rgba(246, 258, 254, 0.1);
        border-radius: 50%;
        margin-bottom: 1.5rem;
    }

    .icon {
        display: block;
        width: 60px;
        height: 60px;
        fill: #6172f3;
    }

    .plans-highlight h3 {
        color: #1d2939;
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 1rem;
    }

    .plans-highlight p {
        align-self: stretch;
        color: var(--Text-Secondary, #344054);
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    @media (max-width: 1215px) {
        .plans-banner-left {
            width: 100%;
        }

        .plans-banner-right {
            display: none;
        }
    }
</style>
