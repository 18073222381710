import { uniqueId } from "lodash";
import environment from "@/services/environment";

const TWO_UK = "TWO B2B LTD";
const BUYER_NO = "IKEA AS";
const TWO_SE = "TWO BCOMMERCE AB";
const SUPPORT_EMAIL = "support@two.inc";
const MI_CHECKOUT = "https://checkout.staging.two.inc/mi/order";

const MERCHANT_URLS = {
    merchant_cancel_order_url: MI_CHECKOUT,
    merchant_confirmation_url: MI_CHECKOUT,
    merchant_edit_order_url: MI_CHECKOUT,
    merchant_invoice_url: MI_CHECKOUT,
    merchant_order_verification_failed_url: MI_CHECKOUT,
    merchant_shipping_document_url: MI_CHECKOUT
};

const GlasgowAddress = {
    city: "Glasgow",
    country: "GB",
    id: "3470ee48-1ce0-413d-a17d-741256669c7d",
    organization_name: TWO_UK,
    postal_code: "G1 1DA",
    region: "Glasgow",
    street_address: "227 Ingram St"
};

const NorwayAddress = {
    city: "ASKER",
    country: "NO",
    organization_name: BUYER_NO,
    postal_code: "1396",
    region: "ASKER",
    street_address: "Nesbruveien 42"
};

// combined orders
export const orders = {
    orders: [
        {
            order_id: "39e17e40-c812-403e-8ec6-632fff20949f",
            is_latent: false,
            order_date: "2024-02-14T11:16:28.233649",
            fulfill_date: null,
            order_number: "2007",
            invoice_number: "999900004",
            invoice_due_date: null,
            amount: "12200.00",
            currency: "NOK",
            order_state: "FULFILLED",
            order_status: "APPROVED",
            buyer_company: BUYER_NO,
            buyer_country: "NO",
            buyer_first_name: "Jo",
            buyer_last_name: "Bloggs",
            buyer_email: "bot@two.inc",
            buyer_phone_number: "+4777777777",
            last_refund_date: null,
            refunded_amount: null,
            last_partial_fulfill_date: null,
            fulfilled_amount: "12200.00",
            order_type: "MERCHANT_INITIATED",
            action_required: null,
            status: null,
            payment_status: null,
            invoice_type: "FUNDED_INVOICE",
            approved_on_recourse: false
        },
        {
            order_id: "a66f2909-1d9b-4931-941f-7e60b533428b",
            is_latent: false,
            order_date: "2024-02-02T12:37:03.136009",
            fulfill_date: null,
            order_number: "2006",
            invoice_number: "10026",
            invoice_due_date: null,
            amount: "160.20",
            currency: "GBP",
            order_state: "CONFIRMED",
            order_status: "APPROVED",
            buyer_company: TWO_UK,
            buyer_country: "GB",
            buyer_first_name: "Joe",
            buyer_last_name: "blogs",
            buyer_email: SUPPORT_EMAIL,
            buyer_phone_number: "+47777777777",
            last_refund_date: null,
            refunded_amount: null,
            last_partial_fulfill_date: null,
            fulfilled_amount: null,
            order_type: "MERCHANT_INITIATED",
            action_required: "AWAITING_FULFILLMENT",
            status: null,
            payment_status: null,
            invoice_type: "FUNDED",
            approved_on_recourse: false
        }
    ],
    page: { items: 2, limit: 25, current: 1, pages: 1 }
};

// order detail map
export const orderDetailMap = {
    "39e17e40-c812-403e-8ec6-632fff20949f": {
        approved_on_recourse: false,
        billing_address: NorwayAddress,
        buyer: {
            company: {
                company_name: BUYER_NO,
                company_type: "AS",
                country_prefix: "NO",
                organization_number: "914787521",
                website: "https://shop.tillit.ai"
            },
            representative: {
                email: "bot@two.inc",
                first_name: "Jo",
                last_name: "2007",
                phone_number: "+4777777777"
            }
        },
        buyer_department: "",
        buyer_project: "",
        buyer_purchase_order_number: "2007AB123",
        credit_note_url:
            "https://api.staging.two.inc/v1/credit_note/39e17e40-c812-403e-8ec6-632fff20949f/pdf?lang=en_US",
        currency: "NOK",
        date_created: "2024-02-14T11:16:28.233649",
        date_updated: "2024-02-14T11:16:48.240732",
        date_fulfilled: "2024-02-14T11:16:48.240732",
        discount_amount: "0.00",
        discount_rate: "0.000000",
        event_log_url: "https://tillit.ai",
        fulfilled_amounts: ["12200.00"],
        gross_amount: "12200.00",
        id: "39e17e40-c812-403e-8ec6-632fff20949f",
        invoice_details: {
            due_date: "2024-03-15",
            due_in_days: 30,
            id: "fd4751ec-ddea-4d15-a019-411c3c38a9a5",
            invoice_issue_date: "2024-02-14",
            invoice_number: "999900004",
            payee: {
                bban: "15065137256",
                bic: "DNBANOKK",
                country_code: "NO",
                iban: "NO3815065137256",
                organization_name: environment.branding.displayName,
                organization_number: "925976105"
            },
            payment_reference: "1567625208217",
            payment_reference_message: "",
            payment_reference_ocr: "1567625208217",
            payment_reference_type: "assigned_by_njord"
        },
        invoice_insurance_provider: "NO_INSURANCE",
        invoice_type: "FUNDED_INVOICE",
        invoice_url: "/pictures/39e17e40-c812-403e-8ec6-632fff20949f.pdf",
        line_items: [
            {
                description: "",
                discount_amount: "0.00",
                gross_amount: "700.00",
                id: "cd7f6912-0396-480b-9a51-c1fa48550192",
                name: "Paper",
                net_amount: "625.00",
                quantity: 5.0,
                quantity_unit: "pcs",
                tax_amount: "75.00",
                tax_class_name: "",
                tax_rate: "0.120000",
                type: "DIGITAL",
                unit_price: "125.00"
            },
            {
                description: "",
                discount_amount: "0.00",
                gross_amount: "11500.00",
                id: "2875c87a-3386-46e4-a5a2-1df5eacf3ea7",
                name: "Monitor",
                net_amount: "10000.00",
                quantity: 10.0,
                quantity_unit: "pcs",
                tax_amount: "1500.00",
                tax_class_name: "",
                tax_rate: "0.150000",
                type: "DIGITAL",
                unit_price: "1000.00"
            }
        ],
        merchant_additional_info: "",
        merchant_id: uniqueId,
        merchant_order_id: "2007",
        merchant_reference: "",
        merchant_short_name: "demomerchant",
        merchant_urls: MERCHANT_URLS,
        net_amount: "10625.00",
        order_note: "",
        order_origination: "ONLINE",
        order_placed_date: "2024-02-12T11:16:27.565964",
        order_type: "MERCHANT_INITIATED",
        payable_amount: "12200.00",
        payment_url: "https://checkout.staging.two.inc/order/39e17e40-c812-403e-8ec6-632fff20949f",
        recourse_fallback_enabled: false,
        recurring: false,
        refunded_amounts: [],
        shipping_address: NorwayAddress,
        state: "FULFILLED",
        status: "APPROVED",
        tax_amount: "1575.00",
        tax_subtotals: [
            {
                tax_amount: "75.00",
                tax_rate: "0.120000",
                taxable_amount: "625.00"
            },
            {
                tax_amount: "1500.00",
                tax_rate: "0.150000",
                taxable_amount: "10000.00"
            }
        ],
        vendor_id: "e2etests"
    },
    "a66f2909-1d9b-4931-941f-7e60b533428b": {
        approved_on_recourse: false,
        billing_address: GlasgowAddress,
        buyer: {
            company: {
                company_name: TWO_UK,
                country_prefix: "GB",
                organization_number: "13078389"
            },
            representative: {
                email: SUPPORT_EMAIL,
                first_name: "Jo",
                last_name: "Bloggs",
                phone_number: "+447777777777"
            }
        },
        buyer_department: "",
        buyer_project: "",
        buyer_purchase_order_number: "",
        credit_note_url:
            "https://api.staging.two.inc/v1/credit_note/a66f2909-1d9b-4931-941f-7e60b533428b/pdf?lang=en_US",
        currency: "GBP",
        date_created: "2024-02-02T12:37:03.136009",
        date_updated: "2024-02-02T12:50:45.429720",
        discount_amount: "0.00",
        discount_rate: "0.000000",
        event_log_url: "https://tillit.ai",
        fulfilled_amounts: [],
        gross_amount: "160.20",
        id: "a66f2909-1d9b-4931-941f-7e60b533428b",
        invoice_details: {
            due_in_days: 30,
            id: "1c1021ca-6c94-413c-8dee-81cae7f686ad",
            payee: {
                branch_sort_code: "000000",
                country_code: "GB",
                description: "Default account from signup",
                local_account_number: "12345678",
                organization_name: "DEMO MERCHANT",
                organization_number: "12437391"
            },
            payment_reference: "",
            payment_reference_message: "",
            payment_reference_ocr: "",
            payment_reference_type: ""
        },
        invoice_insurance_provider: "NO_INSURANCE",
        invoice_type: "FUNDED_INVOICE",
        invoice_url:
            "https://api.staging.two.inc/v1/invoice/a66f2909-1d9b-4931-941f-7e60b533428b/pdf?lang=en&v=original",
        line_items: [
            {
                description: "",
                discount_amount: "0.00",
                gross_amount: "160.20",
                id: "95054cce-a1aa-4ce7-965a-b37ff089e4f0",
                name: "smart watch",
                net_amount: "133.5",
                quantity: 1.0,
                quantity_unit: "pcs",
                tax_amount: "26.70",
                tax_class_name: "",
                tax_rate: "0.200000",
                type: "DIGITAL",
                unit_price: "133.5"
            }
        ],
        merchant_additional_info: "",
        merchant_id: "c09e3846-f9bd-4562-bad5-acb5f1609028",
        merchant_order_id: "2006",
        merchant_short_name: "demomerchant",
        merchant_urls: MERCHANT_URLS,
        net_amount: "133.5",
        order_note: "",
        order_origination: "ONLINE",
        order_placed_date: "2024-02-02T12:37:03.067736",
        order_type: "MERCHANT_INITIATED",
        payable_amount: "0.00",
        payment_url: "https://checkout.staging.two.inc/order/a66f2909-1d9b-4931-941f-7e60b533428b",
        recourse_fallback_enabled: false,
        recurring: false,
        refunded_amounts: [],
        shipping_address: GlasgowAddress,
        state: "CONFIRMED",
        status: "APPROVED",
        tax_amount: "26.70",
        tax_subtotals: [
            {
                tax_amount: "26.70",
                tax_rate: "0.200000",
                taxable_amount: "133.50"
            }
        ],
        vendor_id: "demomerchantas"
    }
};

const addDays = (startDate: Date, daySpan: number) => {
    const beginDate = new Date(startDate);
    return new Date(beginDate.setDate(beginDate.getDate() + daySpan));
};

// Dashboard data
export const dashboardData = (startDate: Date, endDate: Date) => {
    const date1Milliseconds = startDate.getTime();
    const date2Milliseconds = endDate.getTime();
    const startingDate = startDate;

    // Calculate the difference in milliseconds
    const differenceMilliseconds = Math.round(Math.abs(date1Milliseconds - date2Milliseconds));

    // Convert milliseconds to days
    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const weekSpan = Math.round(Math.floor(differenceMilliseconds / millisecondsInDay) / 7);
    const WEEK_1_DATE = new Date(startingDate);
    const WEEK_2_DATE = addDays(WEEK_1_DATE, weekSpan);
    const WEEK_3_DATE = addDays(WEEK_2_DATE, weekSpan);
    const WEEK_4_DATE = addDays(WEEK_3_DATE, weekSpan);
    const WEEK_5_DATE = addDays(WEEK_4_DATE, weekSpan);
    const WEEK_6_DATE = addDays(WEEK_5_DATE, weekSpan);
    const WEEK_7_DATE = addDays(WEEK_6_DATE, weekSpan);

    return {
        start_date: startDate,
        end_date: endDate,
        incr_total_sales: "\u221e",
        incr_average_order_values: "\u221e",
        orders_placed: 42,
        incr_orders_placed: 0.555556,
        last_order: WEEK_1_DATE,
        credit_approval_rate: "93.330000",
        incr_credit_approval_rate: -0.0667,
        capture_rate: "100.000000",
        incr_capture_rate: 0.0,
        returning_buyers: 8,
        verification_rate: "88.100000",
        unique_buyers: 29,
        fulfilled_order_data: {
            total_value: "6669.48",
            incr_total_value: 0.432448,
            average_order_value: "180.26",
            incr_average_order_value: -0.070825,
            list_state_total: [
                {
                    start_time: WEEK_6_DATE,
                    end_time: WEEK_7_DATE,
                    amount: 544.8
                },
                {
                    start_time: WEEK_5_DATE,
                    end_time: WEEK_6_DATE,
                    amount: 1258.92
                },
                {
                    start_time: WEEK_4_DATE,
                    end_time: WEEK_5_DATE,
                    amount: 1508.4
                },
                {
                    start_time: WEEK_3_DATE,
                    end_time: WEEK_4_DATE,
                    amount: 1618.08
                },
                {
                    start_time: WEEK_2_DATE,
                    end_time: WEEK_3_DATE,
                    amount: 1739.28
                },
                {
                    start_time: WEEK_1_DATE,
                    end_time: WEEK_2_DATE,
                    amount: 0.0
                }
            ],
            list_state_volume: [
                {
                    start_time: WEEK_6_DATE,
                    end_time: WEEK_7_DATE,
                    amount: 4.0
                },
                {
                    start_time: WEEK_5_DATE,
                    end_time: WEEK_6_DATE,
                    amount: 7.0
                },
                {
                    start_time: WEEK_4_DATE,
                    end_time: WEEK_5_DATE,
                    amount: 7.0
                },
                {
                    start_time: WEEK_3_DATE,
                    end_time: WEEK_4_DATE,
                    amount: 8.0
                },
                {
                    start_time: WEEK_2_DATE,
                    end_time: WEEK_3_DATE,
                    amount: 11.0
                },
                {
                    start_time: WEEK_1_DATE,
                    end_time: WEEK_2_DATE,
                    amount: 0.0
                }
            ],
            percentage_of_approved_orders: "88.100000",
            order_count: 37,
            incr_order_count: 0.541667
        },
        refunded_order_data: {
            total_value: "299.04",
            list_state_total: [
                { start_time: WEEK_6_DATE, end_time: WEEK_7_DATE, amount: 108.0 },
                { start_time: WEEK_5_DATE, end_time: WEEK_6_DATE, amount: 0.0 },
                { start_time: WEEK_4_DATE, end_time: WEEK_5_DATE, amount: 0.0 },
                { start_time: WEEK_3_DATE, end_time: WEEK_4_DATE, amount: 0.0 },
                { start_time: WEEK_2_DATE, end_time: WEEK_3_DATE, amount: 191.04 },
                { start_time: WEEK_1_DATE, end_time: WEEK_2_DATE, amount: 0.0 }
            ]
        }
    };
};

// Customers page
export const customerData = {
    cursor_page_metadata: null,
    customers: [
        {
            billing_email_addresses: null,
            buyer_company_name: TWO_UK,
            buyer_country_code: "GB",
            buyer_organization_number: "13078389",
            credit_limit: "1000000.00",
            credit_limit_balance: "998800.00",
            current_recourse_exposure: "0.00",
            direct_invoice_fallback: false,
            due_in_days: 30,
            fallback_threshold: null,
            on_recourse: false,
            recourse_max_amount: null,
            total_gross_amount: "1200.00",
            total_order_count: 1
        },
        {
            billing_email_addresses: null,
            buyer_company_name: BUYER_NO,
            buyer_country_code: "NO",
            buyer_organization_number: "06807554",
            credit_limit: "10000000.00",
            credit_limit_balance: "9999839.80",
            current_recourse_exposure: "0.00",
            direct_invoice_fallback: false,
            due_in_days: 30,
            fallback_threshold: null,
            on_recourse: false,
            recourse_max_amount: null,
            total_gross_amount: "160.20",
            total_order_count: 1
        },
        {
            billing_email_addresses: null,
            buyer_company_name: TWO_SE,
            buyer_country_code: "SE",
            buyer_organization_number: "5593701989",
            credit_limit: "10000000.00",
            credit_limit_balance: "99990000.00",
            current_recourse_exposure: "0.00",
            direct_invoice_fallback: false,
            due_in_days: 30,
            fallback_threshold: null,
            on_recourse: false,
            recourse_max_amount: null,
            total_gross_amount: "10000.00",
            total_order_count: 1
        }
    ]
};

// fake customers

// hide recourse tab and data?

// Settlement reports - allow export of dummy data?

// Create user page

// have fake users, but include the actual onboarding user

// hide add team member buttton?

// allow dummy data download
