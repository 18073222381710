<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 28 20" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M24.0001 0H4.00008C2.15914 0 0.666748 1.49229 0.666748 3.33333V16.6667C0.666748 18.5076 2.15914 20 4.00008 20H24.0001C25.8411 20 27.3334 18.5076 27.3334 16.6667V3.33333C27.3334 1.49229 25.8411 0 24.0001 0ZM4.00008 1.66667H24.0001C24.9191 1.66667 25.6667 2.41432 25.6667 3.33333V5.20875L15.5007 12.8336C14.6153 13.4961 13.3849 13.4961 12.4995 12.8336L2.33341 5.20875V3.33333C2.33341 2.41432 3.08107 1.66667 4.00008 1.66667ZM25.6667 16.6667C25.6667 17.5857 24.9191 18.3333 24.0001 18.3333H4.00008C3.08107 18.3333 2.33341 17.5857 2.33341 16.6667V7.29135L11.5001 14.1667C12.2358 14.7192 13.1179 14.9951 14.0001 14.9951C14.8823 14.9951 15.7644 14.7192 16.5001 14.1667L25.6667 7.29135V16.6667Z"
        fill="#94A3B8"
    />
</svg>
