<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 28 24" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M24.118 12H24.1089C23.6806 12 23.3334 12.3348 23.3334 12.7478V16.5H4.6622V12.7478C4.6622 12.3348 4.31497 12 3.88671 12C3.45844 12 3.11121 12.3348 3.11121 12.7478V21.75C3.11121 22.9927 4.15587 24 5.44455 24H22.5602C23.8489 24 24.8935 22.9927 24.8935 21.75V12.7478C24.8935 12.3348 24.5463 12 24.118 12ZM23.338 21.75C23.338 22.1635 22.9891 22.5 22.5602 22.5H5.44455C5.01565 22.5 4.66677 22.1635 4.66677 21.75V18H23.338V21.75ZM26.622 4.86595L23.8353 0.615422C23.5871 0.233391 23.1443 0 22.672 0H5.32662C4.85426 0 4.41156 0.233391 4.16335 0.615422L1.37658 4.86595C-0.0617293 7.06111 1.2129 10.114 3.90168 10.4628C4.09491 10.4872 4.29212 10.5 4.48929 10.5C5.75994 10.5 6.88558 9.96661 7.65844 9.14208C8.43126 9.96661 9.55958 10.5 10.8262 10.5C12.0967 10.5 13.2225 9.96661 13.9952 9.14208C14.7681 9.96661 15.8965 10.5 17.163 10.5C18.435 10.5 19.5593 9.96661 20.3321 9.14208C21.109 9.96661 22.2346 10.5 23.5012 10.5C23.7024 10.5 23.8957 10.4872 24.0889 10.4628C26.7857 10.1179 28.0643 7.06495 26.622 4.86595ZM25.4419 7.84645C25.2623 8.21677 24.8211 8.856 23.8872 8.97548C23.7603 8.99147 23.6334 9 23.5012 9C22.7265 9 22.01 8.69222 21.4835 8.13347L20.3292 6.9082L19.1781 8.13628C18.656 8.6932 17.9404 9 17.163 9C16.3978 9 15.6638 8.68514 15.1493 8.13628L13.9951 6.90502L12.8411 8.13628C12.3192 8.6933 11.6036 9 10.8262 9C10.061 9 9.32697 8.68514 8.81247 8.13628L7.65844 6.90511L6.50437 8.13628C5.98228 8.6932 5.26668 9 4.48929 9C4.35979 9 4.23029 8.99147 4.10915 8.97619C3.17718 8.85534 2.7374 8.21639 2.55826 7.84627C2.21381 7.13489 2.26373 6.32025 2.69136 5.66747L5.42374 1.5H22.5749L25.3073 5.66775C25.7361 6.32152 25.7864 7.13606 25.4419 7.84645Z"
        fill="#94A3B8"
    />
</svg>
