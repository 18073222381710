<script lang="ts">
    import { _ } from "svelte-i18n";

    import API from "../../services/apiService";
    import { REFUND_ORDER } from "../../static/endPoints";
    import { type CurrencyCode, type IOrderItem, NotificationType } from "../../static/types";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import { getBaseUrl } from "../../utils/functions";
    import utils from "../../utilsV2/currency";
    import Button from "../Elements/Button.svelte";

    export let orderID: string = "";
    export let merchantOrderID: string = "";
    export let refundAmount: number = 0;
    export let reason: string = "";
    export let currency: CurrencyCode;
    export let lang: string = "";
    export let lineItems: IOrderItem[] = [];
    export let taxSubtotals: [] = [];

    let isRefundProcessing: boolean = false;

    function formatAmount(amount: number | string) {
        const locale = lang;
        return utils.formatCurrency(currency)(amount, { locale });
    }

    interface IRefundParams {
        amount: number;
        currency: string;
        initiate_payment_to_buyer: boolean;
        reason: string;
        line_items?: IOrderItem[];
        tax_subtotals?: [];
    }

    const handleRefund = () => {
        const baseUrl = getBaseUrl();
        const url = `${baseUrl}${REFUND_ORDER(orderID, lang)}`;
        isRefundProcessing = true;
        const params: IRefundParams = {
            amount: refundAmount,
            currency,
            initiate_payment_to_buyer: false,
            reason
        };
        if (lineItems) {
            params.line_items = lineItems;
        }
        if (taxSubtotals) {
            params.tax_subtotals = taxSubtotals;
        }

        API.post(url, params)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("refund.refundSuccess")
                );
                modalState.actions.setModal(null, { isRefunded: true });
                setTimeout(() => window.location.reload(), 2000); // to refresh UI
            })
            .catch((err) => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    err?.response?.data?.error_message || $_("refund.refundFailed")
                );
                modalState.actions.setModal(null, { isRefunded: false });
            });
    };

    const handleCancelModal = () => {
        modalState.actions.setModal(null, {});
    };
</script>

<div class="container">
    <div class="flex title">
        {#if merchantOrderID}
            <div class="ctn-general-primary">
                {$_("refund.confirmRefundWithId", {
                    values: {
                        merchantOrderID:
                            merchantOrderID.substring(0, 45) +
                            (merchantOrderID.length > 45 ? " ..." : "")
                    }
                })}
            </div>
        {:else}
            <div class="ctn-general-primary">{$_("refund.confirmRefundWithoutId")}</div>
        {/if}
    </div>
    <div class="body-text">
        {$_("refund.refundAmountNotice", { values: { refundAmount: formatAmount(refundAmount) } })}
    </div>
    <div class="body-text">{$_("refund.actionCannotBeUndone")}</div>
    <div class="button-area">
        <div class="right-align">
            <Button
                label={$_("refund.cancel")}
                on:click={handleCancelModal}
                borderColor="var(--gray-300)"
                borderRadius="8px"
                color="#1D2939"
                hoverColor="black"
                bgColor="white"
                hoverBg="#F9FAFB"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
        <div>
            <Button
                label={$_("refund.confirmRefund")}
                on:click={handleRefund}
                disable={isRefundProcessing}
                color="white"
                bgColor="var(--primary-600)"
                hoverBg="var(--primary-700)"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        padding: 24px 32px 24px 32px;
        height: 296px;
        width: 544px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .body-text {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }

    .button-area {
        display: flex;
        position: absolute;
        right: 32px;
        left: 32px;
        bottom: 24px;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }
</style>
