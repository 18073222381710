<script lang="ts">
    import { _ } from "svelte-i18n";

    import Icon from "../../icons/Icon.svelte";
    import FiltersSidebar from "../FiltersSidebar.svelte";
    import CheckboxItem from "../../../components/checkboxitem/CheckboxItem.svelte";
    import Radio from "../../Elements/Radio.svelte";

    export let updateTable;

    let filterCounter: number = 0;

    let showFilters: boolean = false;
    let filterParams: string = "";

    let limitSetBy: string[] = ["ALL"];
    let totalSpend: string[] = ["ALL"];

    const creditProviders = {
        KOMPLETT: "KOMPLETT",
        TWO: "TWO"
    };


    const creditUsageOptions = [
        {
            value: "all",
            label: $_("riskManagement.filters.all")
        },
        {
            value: "95",
            label: $_("riskManagement.filters.over95")
        },
        {
            value: "90",
            label: $_("riskManagement.filters.over90")
        },
        {
            value: "80",
            label: $_("riskManagement.filters.over80")
        }
    ];

    const limitSetByOptions = [
        {
            name: "all",
            value: $_("riskManagement.filters.all")
        },
        {
            name: "Komplett",
            value: $_("riskManagement.filters.komplett")
        },
        {
            name: "Two",
            value: $_("riskManagement.filters.two")
        }
    ];

    const totalSpendOptions = [
        {
            name: "all",
            value: $_("riskManagement.filters.all")
        },
        {
            name: "noSpend",
            value: $_("riskManagement.filters.customersWithNoSpend")
        },
        {
            name: "anySpend",
            value: $_("riskManagement.filters.customersWithAnySpend")
        }
    ];

    let usageTypeSelected = creditUsageOptions[0];

    function clearFilters() {
        limitSetBy = ["ALL"];
        totalSpend = ["ALL"];
        usageTypeSelected = creditUsageOptions[0];
        filterCounter = 0;

        showFilters = false;
    }

    function toggleFilters() {
        showFilters = !showFilters;
    }

    function filterLimits(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                limitSetBy = ["ALL"];
            } else {
                limitSetBy.push(event.detail.checkedItem.toUpperCase());
                if (
                    limitSetBy.includes(creditProviders.KOMPLETT) &&
                    limitSetBy.includes(creditProviders.TWO)
                ) {
                    limitSetBy = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItem.toUpperCase() === "ALL") {
                limitSetBy = [];
            } else if (limitSetBy.includes("ALL")) {
                limitSetBy = [creditProviders.KOMPLETT, creditProviders.TWO];
            }

            const index = limitSetBy.indexOf(event.detail.checkedItem.toUpperCase());
            if (index !== -1) {
                limitSetBy.splice(index, 1);
            }
        }
    }

    function filterTotalSpend(event) {
        if (event.detail.checked) {
            if (event.detail.checkedItemKey.toUpperCase() === "ALL") {
                totalSpend = ["ALL"];
            } else {
                totalSpend.push(event.detail.checkedItemKey.toUpperCase());
                if (
                    totalSpend.includes("NOSPEND") &&
                    totalSpend.includes("ANYSPEND")
                ) {
                    totalSpend = ["ALL"];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItemKey.toUpperCase() === "ALL") {
                totalSpend = [];
            } else if (totalSpend.includes("ALL")) {
                totalSpend = ["NOSPEND", "ANYSPEND"];
            }

            const index = totalSpend.indexOf(event.detail.checkedItemKey.toUpperCase());
            if (index !== -1) {
                totalSpend.splice(index, 1);
            }
        }
    }

    function applyFilters() {
        filterCounter = 0;
        filterParams = "";

        if (!limitSetBy.includes("ALL") && limitSetBy.length > 0) {
            if (limitSetBy.includes(creditProviders.KOMPLETT)) {
                filterParams += "&credit_limit_provider=Komplett";
            } else {
                filterParams += "&credit_limit_provider=Two";
            }
            filterCounter++;
        } 

        if (!totalSpend.includes("ALL") && totalSpend.length > 0) {
            if (totalSpend.includes("NOSPEND")) {
                filterParams += "&has_spend_in_past_30_days=false";
            } else {
                filterParams += "&has_spend_in_past_30_days=true";
            }
            filterCounter++;
        }

        if (usageTypeSelected.value !== "all") {
            filterParams += `&usage_percentage_above=${usageTypeSelected.value}`;
            filterCounter++;
        }
        updateTable(filterParams);

        showFilters = false;
    }
</script>

<div class="filter-button" class:filters-active={filterCounter > 0} on:click={toggleFilters} on:keyup>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("riskManagement.creditInsights")}</span>
            </div>
            <div class="close">
                <button on:click={toggleFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.filters.creditUsage")}</span
                >
                <div class="filter-items-radio">
                    {#each creditUsageOptions as usageItem}
                        <Radio
                            bind:group={usageTypeSelected}
                            option={usageItem}
                            id={usageItem.label}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.filters.limitSetBy")}</span
                >
                <div class="filter-items-options">
                    {#each limitSetByOptions as limitItem}
                        <CheckboxItem
                            statusItem={limitItem}
                            isChecked={limitSetBy.includes(limitItem.name.toUpperCase()) ||
                                    limitSetBy.includes("ALL")}
                            on:checkedItem={filterLimits}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text"
                    >{$_("riskManagement.creditInsightsTable.total_spend_last_30_days")}</span
                >
                <div class="filter-items-options">
                    {#each totalSpendOptions as totalSpendItem}
                        <CheckboxItem
                            statusItem={totalSpendItem}
                            isChecked={totalSpend.includes(totalSpendItem.name.toUpperCase()) ||
                                    totalSpend.includes("ALL")}
                            on:checkedItem={filterTotalSpend}
                        />
                    {/each}
                </div>
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="apply-button" on:click={applyFilters} on:keyup>{$_("filters.applyFilters")}</div>
            <div class="clear-button" on:click={clearFilters} on:keyup>{$_("filters.clearAll")}</div>
        </div>
    </FiltersSidebar>
{/if}

<style>
    .filter-header {
        display: flex;
        justify-content: space-between;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--ctnGeneralPrimary);
    }

    .filter-footer {
        display: flex;
        gap: 0.5rem;
    }

    .filter-button {
        position: relative;
        margin: auto 0;
        width: 6.125rem;
        height: 2.25rem;
        line-height: 2.25rem;
        background: #ffffff;
        border: 1px solid var(--borderGeneralSecondary);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        cursor: pointer;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: var(--ctnInteractiveGeneralDefault);
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .filter-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .filters-active {
        border: 2px solid var(--ctnInteractiveGeneralDefault);
    }

    .filter-counter {
        position: absolute;
        width: 20px;
        height: 20px;
        background: var(--ctnInteractiveGeneralDefault);
        border-radius: 16px;
        color: white;
        top: -10px;
        right: -10px;
        line-height: 20px;
    }

    .apply-button {
        color: white;
        width: 126px;
        height: 3rem;
        line-height: 3rem;
        background: var(--primary-600);
        border: 1px solid var(--primary-600);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.5px;
        cursor: pointer;
    }

    .clear-button {
        width: 6.125rem;
        height: 3rem;
        line-height: 3rem;
        background: #ffffff;
        border: 1px solid var(--borderGeneralSecondary);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: var(--ctnInteractiveGeneralDefault);
        cursor: pointer;
    }

    .clear-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .header-subtext {
        font-weight: 400;
    }

    .filter-item-header-text {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--ctnGeneralPrimary);
    }
    .filter-item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0.25rem 0 2rem 0;
        margin: 0 0 1rem 0;
        gap: 1rem;
        border-bottom: 1px solid #e4e7ec;
    }

    .filter-items-options {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 0.5rem;
    }

    .filter-items-radio {
        display: grid;
        gap: 0.5rem;
    }

    * :global(.purdateWrapper) {
        top: 130px !important;
        left: 0px !important;
        z-index: 1000;
    }
</style>
