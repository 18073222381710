<script lang="ts">
    import { _, number } from "svelte-i18n";
  
    import type { CurrencyCode } from "@/static/types";
    import utils from "../../../../utilsV2/currency";
    import { type PlanLineItems } from "./types";

    export let planLineItems: PlanLineItems;
    export let currency: CurrencyCode;

    function getTotalTaxAmount(planLineItems: PlanLineItems): number {
        return planLineItems.total_amount_including_tax - planLineItems.total_amount_excluding_tax;
    }

    function formatCurrency(amount: number): string {
        return utils.formatCurrency(currency)(amount);
    }
</script>

<div class="main-bar">
    <p class="main-bar-title">{$_("planDetails.productsCard.title")}</p>
</div>
<table class="products">
    <thead>
        <th>{$_("planDetails.productsCard.table.headers.number")}</th>
        <th>{$_("planDetails.productsCard.table.headers.item")}</th>
        <th>{$_("planDetails.productsCard.table.headers.unitPrice")}</th>
        <th>{$_("planDetails.productsCard.table.headers.discount")}</th>
        <th>{$_("planDetails.productsCard.table.headers.units")}</th>
        <th>{$_("planDetails.productsCard.table.headers.priceExclTax")}</th>
        <th>{$_("planDetails.productsCard.table.headers.tax")}</th>
        <th>{$_("planDetails.productsCard.table.headers.priceInclTax")}</th>
    </thead>
    <tbody class="products">
        {#each planLineItems?.line_items as lineItem, i}
            <tr>
                <td>{i + 1}</td>
                <td class="truncate emphasise">{lineItem.name}</td>
                <td>{formatCurrency(lineItem.unit_price_amount)}</td>
                <td>{formatCurrency(lineItem.unit_discount_amount)}</td>
                <td>{$number(lineItem.quantity)}</td>
                <td>{formatCurrency(lineItem.total_amount_excluding_tax)}</td>
                <td>{$number(lineItem.tax_rate, { style: "percent" })}</td>
                <td class="emphasise">{formatCurrency(lineItem.total_amount_including_tax)}</td>
            </tr>
        {/each}
    </tbody>
    <tbody class="products-summary">
        <tr>
            <td colspan="6"></td>
            <td>{$_("planDetails.productsCard.summary.subtotal")}:</td>
            <td class="emphasise"
                >{formatCurrency(planLineItems.total_amount_excluding_tax)}</td
            >
        </tr>
        <tr>
            <td colspan="6"></td>
            <td>{$_("planDetails.productsCard.summary.tax")}:</td>
            <td>{formatCurrency(getTotalTaxAmount(planLineItems))}</td>
        </tr>
        <tr>
            <td colspan="6"></td>
            <td class="emphasise">{$_("planDetails.productsCard.summary.total")}:</td>
            <td class="emphasise"
                >{formatCurrency(planLineItems.total_amount_including_tax)}</td
            >
        </tr>
    </tbody>
</table>

<style lang="scss">
    tr {
        height: unset;
    }

    table.products {
        width: 100%;
        th,
        td {
            cursor: default;
            text-align: left;
            color: var(--gray-700);
            font-size: 0.875rem;
            font-weight: 400;
            padding: 1.25rem 0.5rem;
        }
        th:first-child,
        td:first-child {
            padding-left: 1.5rem;
            padding-right: 0.5rem;
            max-width: 50px;
        }
        th:last-child,
        td:last-child {
            padding-left: 0.5rem;
            padding-right: 1.5rem;
            text-align: right;
        }
        thead {
            background-color: var(--bgGeneralQuarterary);
            color: var(--ctnGeneralSecondary);
        }
        tbody.products td {
            border-bottom: 1px solid var(--gray-200);
        }
        .emphasise {
            font-weight: 700;
        }
        .truncate {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    tbody.products-summary {
        td {
            text-align: right;
            padding: 1rem 0;
        }
        tr:not(:last-child) td:not(:first-child) {
            border-bottom: 1px solid var(--gray-200);
        }
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
        .main-bar-title {
            font-weight: 700;
            padding: 1.5rem;
        }
    }
</style>
