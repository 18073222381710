<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
    import { onMount } from "svelte";

    import type { INotification } from "../../static/types";
    import notificationState from "../../store/notifications.store";
    import Icon from "../icons/Icon.svelte";
    import modalState from "@/store/modals.store";
    import { NotificationTypeIcons as icons } from "../../static/constant";

    export let notification: INotification;
    let theme: string;
    let mainColor: string;

    onMount(() => {
        theme = notification.type.toLowerCase();
        mainColor = `var(--${theme}-700)`;
        setTimeout(() => {
            notificationState.actions.delete(notification.id);
        }, 6000);
    });

    function dismiss() {
        notificationState.actions.delete(notification.id);
    }
</script>

<div class="notification notification--{theme}">
    <section class="main">
        <div class="icon">
            <svelte:component this={icons[notification.type]} {...{ size: 20, fill: mainColor }} />
        </div>
        <div class="message-container">
            <span class="message">{notification.message}</span>
            {#if notification.extraInfo}
                <span class="extra">
                    {notification.extraInfo}
                </span>
            {/if}
            {#if notification.tryAgainActionModal}
                <button
                    class="try-again"
                    on:click={() => {
                        modalState.actions.setModal(
                            notification.tryAgainActionModal,
                            notification.tryAgainActionProps,
                            null
                        );
                        dismiss();
                    }}
                    style="color:{mainColor}"
                >
                    {$_("components.tryAgain")}
                    <Fa icon={faArrowRight} color={mainColor} />
                </button>
            {/if}
        </div>
        <button class="dismiss" on:click={dismiss}>
            <Icon name="cross" size={20} fill="#667085" />
        </button>
    </section>
</div>

<style lang="scss">
    .notification {
        display: flex;
        flex-direction: row;
        background-color: white;
        gap: 5px;
        position: relative;
        font-size: 14px;
        padding: 16px;
        border-radius: 8px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);

        &--success {
            color: var(--success-700);
            background-color: var(--success-50);
            border: 2px solid var(--success-200);
        }

        &--warning {
            color: var(--warning-700);
            background-color: var(--warning-50);
            border: 2px solid var(--warning-200);
        }
        &--error {
            color: var(--error-700);
            background-color: var(--error-50);
            border: 2px solid var(--error-200);
        }
    }

    .message-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .message {
        text-align: left;
        width: 100%;
        margin: auto;
    }

    .main {
        display: flex;
        gap: 14px;
        font-weight: 500;
        padding-right: 34px;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .extra {
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
    }

    .dismiss {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .try-again {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
    }
</style>
