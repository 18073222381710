import CustomerSignup from "../pages/onboarding/customer/CustomerSignup.svelte";
import B2BCustomerSignup from "../pages/onboarding/customer/B2BCustomerSignup.svelte";
// import SettingUpUser from "../pages/onboarding/customer/user/SettingUpUser.svelte";
import SettingUpUserSuccess from "../pages/onboarding/customer/user/SettingUpUserSuccess.svelte";
import SettingUpUserFailed from "../pages/onboarding/customer/user/SettingUpUserFailed.svelte";
import UserOnboardingComplete from "../pages/onboarding/customer/user/UserOnboardingComplete.svelte";
import TokenExpired from "../pages/onboarding/customer/user/TokenExpired.svelte";

const routes = [
    {
        path: "/customer",
        component: CustomerSignup
    },
    {
        path: "/b2bcustomer",
        component: B2BCustomerSignup
    },
    // {
    //   path: "/customer/create-user",
    //   component: SettingUpUser,
    // },
    {
        path: "/customer/create-user-success",
        component: SettingUpUserSuccess
    },
    {
        path: "/customer/create-user-failed",
        component: SettingUpUserFailed
    },
    {
        path: "/customer/user/onboarding-complete",
        component: UserOnboardingComplete
    },
    {
        path: "/customer/token-expired",
        component: TokenExpired
    }
];

export default routes;
