<script lang="ts">
    import { _ } from "svelte-i18n";

    import Button from "../Elements/Button.svelte";
    import modalState from "../../store/modals.store";
    import Checkbox from "../Elements/Checkbox.svelte";
    import environment from "@/services/environment";

    export let newOrderIntent;
    export let order_intent_data;
    export let data;
    export let disableOrderBtn;
    export let buyerName;

    let merchantAcceptsFallback = false;

    const handleCancelModal = () => {
        modalState.actions.setModal(null, {});
        disableOrderBtn();
    };
</script>

<div class="container">
    <div class="flex title">
        {$_("buyer.noPayout", { values: { brand: environment.branding.displayName } })}
    </div>
    <div class="body-text">
        {$_("buyer.creditLimit", { values: { buyerName } })}
        {$_("buyer.guaranteedCheckout", {
            values: { buyerName, brand: environment.branding.displayName }
        })}
    </div>
    <div class="confirmFallbackCheckbox text-sm flex flex-row items-center">
        <Checkbox bind:checked={merchantAcceptsFallback} borderColor="red" showCircle={false} />
        <p class="fallbackConfirmation">
            {$_("buyer.fallbackConfirmation", {
                values: { brand: environment.branding.displayName }
            })}
        </p>
    </div>
    <div class="button-area">
        <div class="right-align">
            <Button
                label={$_("components.goBack")}
                on:click={handleCancelModal}
                borderColor="var(--gray-300)"
                borderRadius="8px"
                color="#1D2939"
                hoverColor="black"
                bgColor="white"
                hoverBg="#F9FAFB"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
        <div>
            <Button
                label={$_("buyer.proceedOrder")}
                on:click|once={() => newOrderIntent(order_intent_data, data)}
                disable={!merchantAcceptsFallback}
                color="white"
                bgColor="var(--primary-600)"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        padding: 24px 32px 24px 32px;
        height: 400px;
        width: 584px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .body-text {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }

    .button-area {
        display: flex;
        position: absolute;
        right: 32px;
        left: 32px;
        bottom: 24px;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }

    .confirmFallbackCheckbox {
        background-color: #fffaeb;
        padding: 12px;
        border-radius: 8px;
    }

    .fallbackConfirmation {
        color: rgba(147, 55, 13, 1);
    }
</style>
