<script lang="ts">
    import { _ } from "svelte-i18n";

    import { account } from "../../store/merchant/account.store";
    import API from "../../services/apiService";
    import { API_KEY, SECONDARY_API_KEY } from "../../static/endPoints";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";
    import Input from "../Elements/InputBox.svelte";
    import notificationState from "../../store/notifications.store";
    import LoadingWrapper from "../Elements/LoadingWrapper.svelte";
    import { downloadJsonAsCsv } from "../../utils/csv";
    import { onMount } from "svelte";
    import environment from "@/services/environment";
    import { NotificationType } from "@/static/types";

    export let sandbox: boolean = false;

    interface IMerchantAPIKey {
        id?: string;
        api_key?: string;
        merchant_id?: string;
        hint: string;
        label: string;
    }

    let existingKeys: IMerchantAPIKey[] = [];
    let createdKey: IMerchantAPIKey;
    let invalidID: string = "";
    let invalidKey: string = "";
    let loading: boolean = false;
    let confirm: boolean = false;
    const apiKeyLabel: string = sandbox ? $_("apiKey.sandboxLabel") : $_("apiKey.productionLabel");

    const handleDownload = () => {
        const data = {
            "Merchant ID": createdKey.merchant_id,
            "X-API-Key": createdKey.api_key
        };
        downloadJsonAsCsv(
            [data],
            sandbox ? "sandbox_merchant_id_and_api_key" : "merchant_id_and_api_key"
        );
        // === do sth for posting api key ===
        modalState.actions.setModal(null, {});
    };

    const handleCancel = () => {
        modalState.actions.setModal(null, {});
    };

    const handleCreateAPIKey = () => {
        loading = true;
        confirm = true;
        API.post(sandbox ? SECONDARY_API_KEY : API_KEY)
            .then((res: IMerchantAPIKey) => {
                createdKey = res;
                loading = false;
            })
            .catch((err) => {
                loading = false;
                notificationState.actions.create(
                    NotificationType.ERROR,
                    err?.response?.data?.error_message || $_("apiKey.failed")
                );
            });
    };

    const retrieveAPIKeys = () => {
        loading = true;
        confirm = false;
        API.get(sandbox ? SECONDARY_API_KEY : API_KEY)
            .then((res: IMerchantAPIKey[]) => {
                existingKeys = res;
                loading = false;
            })
            .catch((err) => {
                loading = false;
                notificationState.actions.create(
                    NotificationType.ERROR,
                    err?.response?.data?.error_message || $_("apiKey.failedToRetrieve")
                );
            });
    };

    onMount(() => retrieveAPIKeys());
</script>

<div class="min-w-400 flex flex-col items-center px-4 py-12 sm:px-12">
    <p class="text-center text-black-dark font-semibold text-xl">
        {apiKeyLabel}
    </p>
    <LoadingWrapper {loading} />
    {#if confirm && createdKey}
        <p class="my-4 text-center">
            {$_("apiKey.configureIntegration", {
                values: { brandName: environment.branding.displayName }
            })}
        </p>
        <div class="w-full my-2">
            <Input
                label={$_("apiKey.merchantIdLabel")}
                required={true}
                requiredStar={false}
                placeholder="e.g. 1111-2222-3333-4444-5555"
                disabled={true}
                bind:value={createdKey.merchant_id}
                bind:invalid={invalidID}
                type="text"
                id="merchantID"
            />
        </div>
        <div class="w-full my-2">
            <Input
                label={apiKeyLabel}
                required={true}
                requiredStar={false}
                placeholder="secret_env_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                disabled={true}
                bind:value={createdKey.api_key}
                bind:invalid={invalidKey}
                type="text"
                id="apiKey"
            />
        </div>
        <div class="w-72 my-2">
            <Button label={$_("apiKey.downloadButton")} on:click={handleDownload} />
        </div>
    {:else}
        <div class="w-72 my-4">
            <Button
                label={$_("apiKey.createNewKey", { values: { apiKeyLabel } })}
                on:click={handleCreateAPIKey}
            />
        </div>
        {#if existingKeys.length > 0}
            <p class="my-2 text-center">
                {$_("apiKey.existingKeysInfo", {
                    values: {
                        keyLabel:
                            existingKeys.length > 1
                                ? `${existingKeys.length} ${$_("apiKey.keys")}`
                                : $_("apiKey.key")
                    }
                })}
            </p>
            <div class="flex flex-wrap justify-center my-2">
                {#each existingKeys as key}
                    <div class="text-white mx-2 my-1 rounded-lg px-4 py-2 w-content bg-gray-400">
                        ...{key.hint}
                    </div>
                {/each}
            </div>
        {/if}
    {/if}
    <div class="w-72 my-2">
        <Button
            label={$_("components.cancel")}
            bgColor="white"
            color="#3E16A2"
            hoverBg="#3E16A2aa"
            on:click={handleCancel}
        />
    </div>
</div>
