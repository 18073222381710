<script lang="ts">
    export let fill = "white";
    export let size = 20;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04199 9.99984C1.04199 5.05229 5.05277 1.0415 10.0003 1.0415C14.9479 1.0415 18.9587 5.05229 18.9587 9.99984C18.9587 14.9474 14.9479 18.9582 10.0003 18.9582C5.05277 18.9582 1.04199 14.9474 1.04199 9.99984ZM13.864 8.03017C14.1569 7.73727 14.1569 7.2624 13.864 6.96951C13.5711 6.67661 13.0962 6.67661 12.8033 6.96951L9.16699 10.6058L8.03066 9.46951C7.73776 9.17661 7.26289 9.17661 6.97 9.46951C6.6771 9.7624 6.6771 10.2373 6.97 10.5302L8.63666 12.1968C8.77731 12.3375 8.96808 12.4165 9.16699 12.4165C9.3659 12.4165 9.55667 12.3375 9.69732 12.1968L13.864 8.03017Z"
        {fill}
    />
</svg>
