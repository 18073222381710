/* eslint-disable @typescript-eslint/no-unsafe-return */
import { type IAllOrders, OrderState } from "../../../static/types";
import { DateToString } from "../../../utils/functions";
import utils from "../../../utilsV2/currency";
import { recourseLimitConfig } from "../../../store/merchant/account.store";

const timeZone = "GMT";
const riskBearerIconMap = {
    "Two": "logo",
    "Your company": "envelope",
    "Not decided yet": "time-alt"
};

function getStatusClass(statusItem: string, display_status: string) {
    if (
        statusItem === OrderState.FULFILLED ||
        statusItem === OrderState.DELIVERED ||
        display_status === OrderState.FULFILLED ||
        display_status === OrderState.PART_FULFILLED
    ) {
        return "item-status accepted";
    } else if (
        statusItem === OrderState.CANCELLED ||
        statusItem === OrderState.REFUNDED ||
        display_status === OrderState.REFUNDED ||
        display_status === OrderState.CANCELLED
    ) {
        return "item-status cancelled";
    } else if (statusItem === OrderState.VERIFIED || statusItem === OrderState.CONFIRMED) {
        return "item-status ready-to-fulfill";
    }
    return "item-status pending";
}

function getStatusText(order, $_) {
    if (
        order.order_state === OrderState.FULFILLED ||
        order.order_state === OrderState.DELIVERED ||
        order.display_status === OrderState.FULFILLED
    ) {
        return $_("status.fulfilled");
    } else if (
        order.order_state === OrderState.CANCELLED ||
        order.order_state === OrderState.REFUNDED ||
        order.display_status === OrderState.REFUNDED ||
        order.display_status === OrderState.CANCELLED
    ) {
        const statusText =
            order.display_status === OrderState.CANCELLED ||
            order.display_status === OrderState.REFUNDED
                ? order.display_status
                : order.order_state;
        return statusText
            .toLowerCase()
            .split(" ")
            .map(function (word) {
                const status = word.charAt(0) + word.slice(1);
                return $_(`status.${status}`);
            })
            .join(" ");
    } else if (order.display_status === OrderState.PART_FULFILLED) {
        return $_("status.part_fulfilled");
    } else if (
        order.order_state === OrderState.VERIFIED ||
        order.order_state === OrderState.CONFIRMED
    ) {
        return $_("status.readyToFulfill");
    }

    return $_("status.pending");
}

function getOrderAction(item: IAllOrders) {
    if (item.action_required) {
        const action_required = item.action_required.toLowerCase();
        return (action_required[0].toUpperCase() + action_required.slice(1).toLowerCase()).replace(
            /_/g,
            " "
        );
    } else {
        return "Not set";
    }
}

function DateToTime(item: Date) {
    return `${item.getHours() < 10 ? 0 : ""}${item.getHours()}:${
        item.getMinutes() < 10 ? 0 : ""
    }${item.getMinutes()}`;
}

function getInvoiceNumber(item: IAllOrders) {
    if (
        (item.display_status === OrderState.PART_FULFILLED ||
            item.display_status === OrderState.FULFILLED) &&
        item.invoice_number === "None"
    ) {
        return "Multiple invoices";
    }
    return item.invoice_number === "None" || item.is_latent === true
        ? "Not issued"
        : item.invoice_number;
}

function buildAllOrdersTable(orders: IAllOrders[], $_) {
    const tableRows = [];
    let showRecourseFallback = false;

    const unsubscribe = recourseLimitConfig.subscribe((value) => {
        showRecourseFallback = value?.merchant_recourse_limits?.[0]?.recourse_fallback_enabled;
    });

    unsubscribe();

    for (const order of orders) {
        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Invoice №": {
                tableItem: getInvoiceNumber(order)
            },
            "Order status": {
                tableItemBold: getStatusText(order, $_),
                cellType: getStatusClass(order.order_state, order.display_status)
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Amount": {
                tableItemBold: utils.formatCurrency(order.currency)(order.amount),
                subItem:
                    order.fulfilled_amount && order.display_status !== OrderState.REFUNDED
                        ? `${utils.formatCurrency(order.currency)(
                              order.fulfilled_amount
                          )} Fulfilled`
                        : ""
            }
        };
        if (showRecourseFallback) {
            rowItem["Risk bearer"] = {
                tableItem: getRiskBearer(order),
                iconName: riskBearerIconMap[getRiskBearer(order)],
                tooltip: getRiskBearerTooltip(order)
            };
        }
        tableRows.push(rowItem);
    }
    return tableRows;
}

function getRiskBearer(order: IAllOrders): string {
    let riskBearer = "Not decided yet";
    if (order.invoice_type == "FUNDED_INVOICE") {
        if (order.approved_on_recourse) {
            riskBearer = "Your company";
        } else {
            riskBearer = "Two";
        }
    } else if (order.invoice_type == "DIRECT_INVOICE") {
        riskBearer = "Your company";
    }
    return riskBearer;
}

function getRiskBearerTooltip(order: IAllOrders): string {
    let text = "Risk bearer not decided yet";
    if (order.invoice_type == "FUNDED_INVOICE") {
        if (order.approved_on_recourse) {
            text = "Funded invoice on recourse";
        } else {
            text = "Funded invoice";
        }
    } else if (order.invoice_type == "DIRECT_INVOICE") {
        text = "Direct invoice";
    }
    return text;
}

function buildPendingOrdersTable(orders: IAllOrders[]) {
    const tableRows = [];

    for (const order of orders) {
        const orderAction = getOrderAction(order);

        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Action required": {
                tableItem: orderAction,
                cellType: orderAction === "Awaiting fulfillment" ? "blue-highlight" : null
            },
            "Amount": {
                tableItemBold: utils.formatCurrency(order.currency)(order.amount)
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

function buildReadyToFulfilTable(orders: IAllOrders[]) {
    const tableRows = [];

    for (const order of orders) {
        const orderAction = getOrderAction(order);

        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Amount": {
                tableItemBold: utils.formatCurrency(order.currency)(order.amount)
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

function buildFulfilledOrdersTable(orders: IAllOrders[]) {
    const tableRows = [];
    let showRecourseFallback = false;

    const unsubscribe = recourseLimitConfig.subscribe((value) => {
        showRecourseFallback = value?.merchant_recourse_limits?.[0]?.recourse_fallback_enabled;
    });

    unsubscribe();

    for (const order of orders) {
        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Invoice №": {
                tableItem: getInvoiceNumber(order)
            },
            "Payment due date": {
                tableItem:
                    order.invoice_type === "DIRECT_INVOICE"
                        ? order.payment_status
                        : order.invoice_due_date
                          ? order.invoice_due_date
                          : "Multiple due dates",
                subItem: order.invoice_type === "DIRECT_INVOICE" ? "" : order.payment_status,
                cellType: order.invoice_type === "DIRECT_INVOICE" ? "dropdown" : null
            },
            "Amount fulfilled": {
                tableItemBold: utils.formatCurrency(order.currency)(
                    Number(order.fulfilled_amount) > 0 ? order.fulfilled_amount : order.amount
                ),
                subItem: `of ${utils.formatCurrency(order.currency)(order.amount)}`
            }
        };
        if (showRecourseFallback) {
            rowItem["Risk bearer"] = {
                tableItem: getRiskBearer(order),
                iconName: getRiskBearer(order) == "Two" ? "logo" : "envelope",
                tooltip: getRiskBearerTooltip(order)
            };
        }
        tableRows.push(rowItem);
    }
    return tableRows;
}

function buildRefundedOrdersTable(orders: IAllOrders[]) {
    const tableRows = [];
    let showRecourseFallback = false;

    const unsubscribe = recourseLimitConfig.subscribe((value) => {
        showRecourseFallback = value?.merchant_recourse_limits?.[0]?.recourse_fallback_enabled;
    });

    unsubscribe();

    for (const order of orders) {
        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Latest refund date": {
                tableItem: order.last_refund_date ? DateToString(order.last_refund_date) : "NA",
                ...(order.last_refund_date && {
                    subItem: `${DateToTime(order.last_refund_date)} ${timeZone}`
                })
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Amount": {
                tableItemBold: utils.formatCurrency(order.currency)(order.amount)
            },
            "Refunded": {
                tableItemBold: utils.formatCurrency(order.currency)(order.refunded_amount),
                cellType: "negative-amount"
            }
        };
        if (showRecourseFallback) {
            rowItem["Risk bearer"] = {
                tableItem: getRiskBearer(order),
                iconName: getRiskBearer(order) == "Two" ? "logo" : "envelope",
                tooltip: getRiskBearerTooltip(order)
            };
        }
        tableRows.push(rowItem);
    }
    return tableRows;
}

function buildCancelledOrdersTable(orders: IAllOrders[]) {
    const tableRows = [];

    for (const order of orders) {
        const rowItem = {
            "ID": order.id,
            "isLatent": order.is_latent,
            "Order №": {
                ...(order.order_number && { tableItemBold: order.order_number }),
                ...(!order.order_number && { tableItem: "Not set" })
            },
            "Purchase date": {
                tableItem: DateToString(order.order_date),
                subItem: `${DateToTime(order.order_date)} ${timeZone}`
            },
            "Customer details": {
                tableItemBold: order.buyer_company,
                tableItem: order.buyer_country,
                subItem: order.buyer_representative
            },
            "Contact details": {
                tableItem: order.buyer_email,
                subItem: order.buyer_phone_number
            },
            "Amount": {
                tableItemBold: utils.formatCurrency(order.currency)(order.amount),
                cellType: "negative-amount",
                ...(order.order_status === "REJECTED" && { subItem: "Credit rejected" })
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

export {
    buildAllOrdersTable,
    buildPendingOrdersTable,
    buildReadyToFulfilTable,
    buildFulfilledOrdersTable,
    buildRefundedOrdersTable,
    buildCancelledOrdersTable
};
