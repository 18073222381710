<script lang="ts">
    import { Router, Route } from "svelte-routing";
    export let routes: any[] = [];
    export let url: string = "";
</script>

<Router {url}>
    {#each routes as route}
        <Route path={route.path} component={route.component} {url} />
    {/each}
</Router>
