import { enableMocking } from "./services/http/msw";
import App from "./App.svelte";
import "./sentry";
import "./main.css";

const themes: { [key: string]: any } = {
    default: {
        cssFiles: ["/default-colors.css"]
    },
    choco: {
        cssFiles: ["/choco-colors.css"]
    },
    achterafbetalen: {
        cssFiles: ["/abn-colors.css"]
    }
};

const domain = window.location.hostname.split(".");
const themeName = Object.keys(themes).find((key) => domain.includes(key));
const theme = themes[themeName] || themes.default;

// Apply CSS files
theme.cssFiles.forEach((cssFile: string) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = cssFile;
    link.id = "theme-stylesheet";
    document.head.appendChild(link);
});

enableMocking().then(() => {
    new App({
        target: document.body
    });
}).catch(e => console.error("enableMocking failed with ", e));
