<script lang="ts">
    import { onDestroy, onMount } from "svelte";
    import type { Unsubscriber } from "svelte/store";

    import { invoiceDataLoading } from "../../../store/buyer/account.store";
    import Table from "../../../components/table/Table.svelte";
    import type { IInvoiceObject } from "./invoiceUtils";

    export let selectedTab;
    export let searchRows: IInvoiceObject[];

    let noInvoicesShownMessage = "No invoices match this filter.";
    const MAIL_BOX_SVG = "/pictures/mailboxstate.svg";
    let imgSrc = MAIL_BOX_SVG;

    $: if (selectedTab == "Unpaid") {
        noInvoicesShownMessage = "All invoices associated with your email address are paid!";
        imgSrc = "/pictures/Checked-box-green.svg";
    } else if (selectedTab == "Paid") {
        noInvoicesShownMessage = "You've not paid any invoices yet.";
        imgSrc = MAIL_BOX_SVG;
    } else {
        noInvoicesShownMessage = "No invoices match this filter.";
        imgSrc = MAIL_BOX_SVG;
    }

    const INVOICE_DATE = "Invoice date";
    const INVOICE_DUE_DATE = "Due date";

    const tableHeaders = [
      { title: "Merchant details", clickable: false, type: "merchant_details" },
      { title: "Purchased by", clickable: false, type: "purchased_by" },
      { title: "Invoice №", clickable: false, type: "invoice_number" },
      { title: INVOICE_DATE, clickable: true, type: "invoice_date" },
      { title: INVOICE_DUE_DATE, clickable: true, type: "due_date" },
      { title: "Invoice amount", clickable: false, type: "invoice_amount" },
      { title: "Actions", clickable: false, type: "actions" }
    ];

    let current_sort_column = INVOICE_DUE_DATE;
    let ascending: boolean = true;

    let allInvoices: IInvoiceObject[] = [];
    let numAllRecords: number = 0;
    let unsubscribeInvoiceDataLoading: Unsubscriber;

    onMount(() => {
      unsubscribeInvoiceDataLoading = invoiceDataLoading.subscribe((loading: Boolean) => {
         if (!loading) {
          sortInvoices(searchRows);
         }
      });
    });

    onDestroy(()=> unsubscribeInvoiceDataLoading());

    function sortInvoices(invoices: any[]) {
      allInvoices = invoices;
      if (invoices && invoices.length) {
          allInvoices = sort(allInvoices, current_sort_column, ascending);
          numAllRecords = allInvoices.length;
      }
   }

    const sort = (
      data: any[],
      property: any,
      ascending: boolean,
    ) => {
      data.sort(function (first: any, second: any) {
        return ascending ?
            new Date(first[property].tableItem).getTime() - new Date(second[property].tableItem).getTime()
            : new Date(second[property].tableItem).getTime() - new Date(first[property].tableItem).getTime();
      });
      return data;
    };

    function handleSort(event) {
      const sortItem = event.detail.sortItem;
      // only toggle the value if we are clicking same value again
      ascending = current_sort_column == sortItem ? !ascending : true;

      switch (sortItem) {
        case "Invoice date":
          current_sort_column = INVOICE_DATE;
          sortInvoices(searchRows);
          break;
        case "Due date":
          current_sort_column = INVOICE_DUE_DATE;
          sortInvoices(searchRows);
      }
    }

</script>

<div class="invoices-list-container">
    {#if !$invoiceDataLoading && allInvoices.length === 0}
        <div class="w-full h-48 flex flex-col items-center pt-8">
            <img src={imgSrc} alt="mailbox" />
            <p class="my-4 font-semibold text-black-dark text-center">{noInvoicesShownMessage}</p>
        </div>
    {:else}
        <Table
            loading={$invoiceDataLoading}
            {tableHeaders}
            bind:tableRows={allInvoices}
            bind:numAllRecords={numAllRecords}
            numRows={25}
            on:sorting={handleSort}
            --cursor="auto"
        />
    {/if}
</div>

<style lang="scss">
    div {
        img {
            width: 10%;
        }
    }
    .invoices-list-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 80vh;
    }
</style>
