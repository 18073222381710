<script lang="ts">
    import { _ } from "svelte-i18n";

    import Button from "../../../../components/Elements/Button.svelte";
    import b2bonboardingState from "../../../../store/b2bonboarding.store";

    let customer: any = null;
    let user: any = null;

    $: b2bonboardingState.customerData.subscribe((res: any) => {
        if (res?.id) {
            customer = res;
        }
    });
</script>

<div class="flex flex-col justify-center flex-wrap py-10 px-4 mainContainer">
    <h3 class="text-black mb-6 font-bold">{$_("onboarding.demoComplete")}</h3>
    {#if customer}
        <p>{$_("onboarding.enrolledCustomer")}</p>
        <pre>{JSON.stringify(customer, undefined, 2)}</pre>
    {/if}

    {#if user}
        <p>{$_("onboarding.enrolledUser")}</p>
        <pre>{JSON.stringify(user, undefined, 2)}</pre>
    {/if}

    <div class="w-full">
        <Button
            size="large"
            label={$_("components.done")}
            borderRadius="20px"
            on:click={() => {
                window.close();
            }}
        />
    </div>
    <style>
        #hs-eu-cookie-confirmation {
            display: none !important;
        }
    </style>
</div>

<style>
    .mainContainer {
        overflow: auto;
        border: 5px green solid;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 99999;
        background: #fff;
    }
</style>
