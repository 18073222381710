<script lang="ts">
    export let fill: string = "#1D2939";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.4999 0.916672C12.0857 0.916672 11.7499 1.25246 11.7499 1.66667C11.7499 2.08089 12.0857 2.41667 12.4999 2.41667C13.9267 2.41667 15.0833 3.57327 15.0833 5.00001C15.0833 6.42674 13.9267 7.58334 12.4999 7.58334C12.0857 7.58334 11.7499 7.91913 11.7499 8.33334C11.7499 8.74755 12.0857 9.08334 12.4999 9.08334C14.7551 9.08334 16.5833 7.25517 16.5833 5.00001C16.5833 2.74484 14.7551 0.916672 12.4999 0.916672ZM14.1666 10.9167C13.7524 10.9167 13.4166 11.2525 13.4166 11.6667C13.4166 12.0809 13.7524 12.4167 14.1666 12.4167H15.1666C16.9615 12.4167 18.4166 13.8717 18.4166 15.6667C18.4166 16.7252 17.5585 17.5833 16.4999 17.5833H14.1666C13.7524 17.5833 13.4166 17.9191 13.4166 18.3333C13.4166 18.7476 13.7524 19.0833 14.1666 19.0833H16.4999C18.3869 19.0833 19.9166 17.5536 19.9166 15.6667C19.9166 13.0433 17.7899 10.9167 15.1666 10.9167H14.1666ZM6.66659 1.04167C4.48046 1.04167 2.70825 2.81388 2.70825 5.00001C2.70825 7.18613 4.48046 8.95834 6.66659 8.95834C8.85271 8.95834 10.6249 7.18613 10.6249 5.00001C10.6249 2.81388 8.85271 1.04167 6.66659 1.04167ZM0.208252 15.7917C0.208252 13.1683 2.3349 11.0417 4.95825 11.0417H8.37491C10.9983 11.0417 13.1249 13.1683 13.1249 15.7917C13.1249 17.5406 11.7072 18.9583 9.95825 18.9583H3.37492C1.62602 18.9583 0.208252 17.5406 0.208252 15.7917Z"
        {fill}
    />
</svg>
