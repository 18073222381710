<script>
    import { getDateRows, uuid, noop } from "../../utils/date-time";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    // props
    export let date;
    export let month;
    export let year;
    export let isAllowed;
    export let minDate;

    // local vars to help in render
    const weekdays = ["S", "M", "T", "W", "T", "F", "S"];
    let cells;

    // function helpers
    const onChange = (date) => {
        dispatch("datechange", new Date(year, month, date));
    };

    const allow = (year, month, date) => {
        if (!date) return true;
        return isAllowed(new Date(year, month, date));
    };

    $: cells = getDateRows(month, year).map((c) => ({
        value: c,
        allowed: allow(year, month, c)
    }));

    const isDateUnderMinDate = (value) => {
        return new Date(minDate).getTime() >= new Date(year, month, value).getTime();
    };
</script>

<div class="container">
    <div class="flex row flex-wrap">
        {#each weekdays as day}
            <div class="font-bold cell ctn-semantic-highlight-primary">{day}</div>
        {/each}
    </div>

    <div class="flex row flex-wrap">
        {#each cells as { value } (uuid())}
            <div
                on:click|stopPropagation={value < 1000 && value && !isDateUnderMinDate(value)
                    ? onChange.bind(this, value)
                    : noop}
                on:keyup
                class:cell={true}
                class:highlight={value < 1000 && value}
                class:disabled={value > 1000 || isDateUnderMinDate(value)}
                class:selected={new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    date.getDate()
                ).getTime() === new Date(year, month, value).getTime()}
            >
                {value > 1000 ? value - 1000 : value || ""}
            </div>
        {/each}
    </div>
</div>

<style>
    .container {
        padding: 6px;
        min-width: 322px;
        max-width: 322px;
    }

    .cell {
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 8px;
        border-radius: 100%;
        margin: 1px;
    }

    .selected {
        color: var(--ctnInteractiveGeneralInverse);
        background: var(--bgInteractiveSemanticHighlightDefault);
    }

    .disabled {
        cursor: not-allowed;
        color: #bfbfbf;
    }

    .highlight:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
        cursor: pointer;
        transition: 0.1s;
    }
    .row {
        margin: 6px 2px;
    }

    .selected.highlight:hover {
        background: var(--bgInteractiveSemanticHighlightHoverPrimary);
    }
    @media only screen and (max-width: 600px) {
        .container {
            min-width: 250px;
            max-width: 250px;
        }
        .cell {
            display: inline-block;
            width: 30px;
            height: 30px;
            padding: 4px;
        }
    }
</style>
