<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faXmark } from "@fortawesome/free-solid-svg-icons";
    import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
    import { Button as TwoUIButton } from "@two-ui/core";

    import Input from "../../../components/Elements/InputBox.svelte";
    import { LABEL_TO_VALUE_MAP } from "./variables";

    export let config: any = {};
    export let accountDetailsEditable: any = {};
    export let invalid: Array<string> = [];

    const onAdd = () => {
        accountDetailsEditable[config.dataKey] = [...accountDetailsEditable[config.dataKey], ""];
    };
    const onRemove = (index) => {
        accountDetailsEditable[config.dataKey] = accountDetailsEditable[config.dataKey].filter(
            (_, i) => i !== index
        );
    };
</script>

<div>
    {#if !accountDetailsEditable[config.dataKey]?.length}
        <label class="label text-sm text-gray-700" for={config.dataKey}>
            {LABEL_TO_VALUE_MAP($_)[config.key]}
        </label>
    {/if}
    {#each accountDetailsEditable[config.dataKey] as email, index}
        <div class="relative mb-2" class:mb-8={invalid[index]}>
            <Input
                label={index === 0 ? LABEL_TO_VALUE_MAP($_)[config.key] : ""}
                id={config.dataKey.toLowerCase().replace(" ", "-") + `-editable-${index}`}
                type="email"
                placeholder={$_("components.enterYour", {
                    values: { field: LABEL_TO_VALUE_MAP($_)[config.key].toLowerCase() }
                })}
                bind:value={email}
                required={config.required}
                disabled={config.disabled}
                invalid={invalid[index]}
                showInvalidError={Boolean(invalid[index])}
            />
            <button on:click={() => onRemove(index)} class="remove-email-btn">
                <Fa icon={faXmark} size="lg" color="var(--gray-500)" class="cursor-pointer" />
            </button>
        </div>
    {/each}
    <div class="flex">
        <TwoUIButton
            on:click={onAdd}
            size="md"
            class="add-email-btn mt-2"
            variant="tertiaryColor"
            content={$_("customer.addEmail")}
        >
            <Fa icon={faPlusSquare} color="var(--primary-600)" slot="leftIcon" />
        </TwoUIButton>
    </div>
</div>

<style lang="scss">
    .remove-email-btn {
        position: absolute;
        bottom: 0;
        right: -30px;
        transform: translateY(-50%);
    }
</style>
