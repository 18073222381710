import { writable } from "svelte/store";
import API from "../../services/apiService";
import { GET_GROUPED_ORDERS } from "../../static/endPoints";
import type { IBillingStatement, IBillingStatements, ICursorPage } from "../../static/types";

function createCustomerBillingStatements() {
    const billingStatements = writable<IBillingStatement[]>([]);
    const cursorPage = writable<ICursorPage>();
    const loading = writable(false);
    const searchFilter = writable("");
    const cursorLimit = writable(100);

    return {
        loading,
        billingStatements,
        cursorPage,
        searchFilter,
        cursorLimit,
        statements: {
            actions: {
                getStatements: (
                    searchFilterQuery: string = null,
                    pageCursor: string = null,
                    billingAccountId: string = null,
                    filterQueryString = ""
                ) => {
                    // API endpoint here
                    loading.set(true);
                    searchFilter.set(searchFilterQuery);
                    let limit: number | null = null;
                    cursorLimit.subscribe((value) => (limit = value));
                    const searchParams = new URLSearchParams();
                    if (searchFilterQuery) {
                        searchParams.append("search_filter", searchFilterQuery);
                    }
                    if (pageCursor) {
                        searchParams.append("page_cursor", pageCursor);
                    }
                    if (billingAccountId) {
                        searchParams.append("billing_account_id", billingAccountId);
                    }

                    API.get(
                        GET_GROUPED_ORDERS +
                            `?limit=${limit}&` +
                            searchParams.toString() +
                            "&" +
                            filterQueryString
                    )
                        .then((res: IBillingStatements) => {
                            if (res?.statements) {
                                billingStatements.set(res.statements || []);
                                cursorPage.set(res.cursor_page_metadata);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                        .finally(() => loading.set(false));
                }
            }
        }
    };
}

export const { billingStatements, cursorPage, loading, statements, searchFilter, cursorLimit } =
    createCustomerBillingStatements();
