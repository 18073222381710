<script lang="ts">
    import { _ } from "svelte-i18n";

    import { NotificationType } from "../../static/types";
    import { account } from "../../store/merchant/account.store";
    import {
        customersLoading,
        actions as customerActions
    } from "../../store/merchant/customers.store";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";
    import InputBox from "../Elements/InputBox.svelte";
    import { emailIsValid } from "@/utils/validation";
    import API from "../../services/apiService";
    import { SEND_BUSINESS_REGISTRATION_INVITE } from "../../static/endPoints";
    import notificationState from "@/store/notifications.store";

    let countryCode: string = "";
    let email: string = "";
    let invalidEmail: string = "";

    $: if ($account?.countryCode) {
        countryCode = $account.countryCode;
    }

    const handleClose = () => {
        modalState.actions.setModal(null, {});
    };

    function sendBusinessRegistrationInvite() {
        if (!emailIsValid(email)) {
            notificationState.actions.create(
                NotificationType.WARNING,
                $_("invite.invalidEmailWarning")
            );
            return;
        } else {
            const data: { email: string } = { email };
            API.post(SEND_BUSINESS_REGISTRATION_INVITE, data)
                .then(() => {
                    notificationState.actions.create(
                        NotificationType.SUCCESS,
                        $_("invite.inviteSentSuccess", { values: { email } })
                    );
                    handleClose();
                })
                .catch(() => {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        $_("invite.inviteFailedError", { values: { email } })
                    );
                });
        }
    }
</script>

<div class="relative w-full p-8 sm:w-xxs">
    <div class="w-full text-2xl font-bold flex justify-between text-center items-center">
        <p class="w-full">{$_("invite.title")}</p>
        <div
            class="rounded-full hover:bg-gray-200 cursor-pointer flex justify-center items-center w-7 h-7 flex-shrink-0"
        >
            <img
                src="/pictures/close.svg"
                alt="close"
                on:keydown={handleClose}
                on:click={handleClose}
            />
        </div>
    </div>
    <div class="mt-9"></div>
    <InputBox
        label={$_("invite.emailLabel")}
        required
        requiredStar={true}
        type="email"
        placeholder={$_("invite.emailPlaceholder")}
        bind:invalid={invalidEmail}
        bind:value={email}
    />
    <div class="mt-12 b">
        <Button
            label={$_("invite.sendButton")}
            on:click={sendBusinessRegistrationInvite}
            loading={$customersLoading}
        />
    </div>
</div>
