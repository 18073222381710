export enum DATA_TYPE {
    TEXT,
    NUMBER,
    AMOUNT,
    PERCENT,
    DATE
}

interface ITableColumns {
    label: string;
    propertyName: string | null;
    type: DATA_TYPE;
    alignment: "left" | "right";
    width: string;
    bold?: boolean;
    redbold?: boolean;
}

export function getTableColumns(taxNaming): ITableColumns[] {
    return [
        {
            label: "№",
            propertyName: null,
            type: DATA_TYPE.NUMBER,
            alignment: "left",
            width: "50px"
        },
        {
            label: "Item",
            propertyName: "name",
            type: DATA_TYPE.TEXT,
            alignment: "left",
            width: "35%",
            bold: true
        },
        {
            label: "Unit price",
            propertyName: "unit_price",
            type: DATA_TYPE.AMOUNT,
            alignment: "left",
            width: "auto"
        },
        {
            label: "Units",
            propertyName: "quantity",
            type: DATA_TYPE.NUMBER,
            alignment: "left",
            width: "100px"
        },
        {
            label: "Discounts",
            propertyName: "discount_amount",
            type: DATA_TYPE.AMOUNT,
            alignment: "left",
            width: "auto"
        },
        {
            label: `Price <span class='nobr'>excl. ${taxNaming}</span>`,
            propertyName: "net_amount",
            type: DATA_TYPE.AMOUNT,
            alignment: "left",
            width: "auto"
        },
        {
            label: `${taxNaming}`,
            propertyName: "tax_rate",
            type: DATA_TYPE.PERCENT,
            alignment: "left",
            width: "100px"
        },
        {
            label: `Price <span class='nobr'>incl. ${taxNaming}</span>`,
            propertyName: "gross_amount",
            type: DATA_TYPE.AMOUNT,
            alignment: "right",
            width: "auto",
            bold: true,
            redbold: true
        }
    ];
}

export const invoiceColumns: {
    label: string;
    propertyName: string | null;
    type: DATA_TYPE;
    alignment: "left" | "right";
    width: string;
    bold?: boolean;
    redbold?: boolean;
}[] = [
    {
        label: "Invoice number",
        propertyName: "number",
        type: DATA_TYPE.NUMBER,
        alignment: "left",
        width: "200px"
    },
    {
        label: "Date issued",
        propertyName: "date_issued",
        type: DATA_TYPE.DATE,
        alignment: "left",
        width: "200px",
        bold: true
    },
    {
        label: "Due date",
        propertyName: "due_date",
        type: DATA_TYPE.DATE,
        alignment: "left",
        width: "200px"
    },
    {
        label: "Value",
        propertyName: "value",
        type: DATA_TYPE.NUMBER,
        alignment: "left",
        width: "150px"
    },
    {
        label: "Customer payment",
        propertyName: "customer_payment",
        type: DATA_TYPE.TEXT,
        alignment: "left",
        width: "auto"
    },
    {
        label: "",
        propertyName: "empty",
        type: DATA_TYPE.TEXT,
        alignment: "left",
        width: "100px"
    }
];

export const creditNoteColumns: {
    label: string;
    propertyName: string | null;
    type: DATA_TYPE;
    alignment: "left" | "right";
    width: string;
    bold?: boolean;
    redbold?: boolean;
}[] = [
    {
        label: "Credit note number",
        propertyName: "number",
        type: DATA_TYPE.NUMBER,
        alignment: "left",
        width: "200px"
    },
    {
        label: "Date refunded",
        propertyName: "date_issued",
        type: DATA_TYPE.DATE,
        alignment: "left",
        width: "200px",
        bold: true
    },
    {
        label: "Value",
        propertyName: "value",
        type: DATA_TYPE.NUMBER,
        alignment: "left",
        width: "auto"
    },
    {
        label: "",
        propertyName: "empty",
        type: DATA_TYPE.TEXT,
        alignment: "left",
        width: "100px"
    }
];

export interface ILineItemColumnProperties {
    name: string;
    unit_price: number;
    quantity: number;
    discount_amount: number;
    net_amount: number;
    tax_rate: number;
    gross_amount: number;
}
