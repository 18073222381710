<script lang="ts">
    export let fill: string = "#4CFAFF";
</script>

<svg width="20" height="20" viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
    <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="5"
        width="14"
        height="16"
    >
        <path
            d="M8.5 12C10.4344 12 12 10.4344 12 8.5C12 6.56562 10.4344 5 8.5 5C6.56562 5 5 6.56562 5 8.5C5 10.4344 6.56562 12 8.5 12ZM8.5 7C9.32812 7 10 7.67188 10 8.5C10 9.32812 9.32812 10 8.5 10C7.67188 10 7 9.32812 7 8.5C7 7.67188 7.67188 7 8.5 7ZM15.5 14C13.5656 14 12 15.5656 12 17.5C12 19.4344 13.5656 21 15.5 21C17.4344 21 19 19.4344 19 17.5C19 15.5656 17.4344 14 15.5 14ZM15.5 19C14.6719 19 14 18.3281 14 17.5C14 16.6719 14.6719 16 15.5 16C16.3281 16 17 16.6719 17 17.5C17 18.3281 16.3281 19 15.5 19ZM17.2594 5.00625L18.2469 5.00313C18.8531 5 19.2125 5.68438 18.8625 6.18438L7.41875 20.675C7.34975 20.7734 7.25807 20.8537 7.15145 20.9092C7.04484 20.9647 6.92644 20.9937 6.80625 20.9937L5.7625 20.9969C5.15312 20.9969 4.79688 20.3125 5.14687 19.8156L16.6469 5.325C16.7875 5.125 17.0156 5.00625 17.2594 5.00625Z"
            fill="black"
        />
    </mask>
    <g mask="url(#mask0)">
        <rect width="24" height="24" {fill} />
    </g>
</svg>
