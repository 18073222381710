<script lang="ts">
    export let fill: string = "#98A2B3";
    export let size: number = 12;
</script>

<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.0001 3.33325C10.0001 4.80601 11.194 5.99992 12.6667 5.99992M2.66675 13.3333L2.95338 11.9001C3.09464 11.1938 3.16527 10.8407 3.29443 10.5114C3.40908 10.2191 3.55774 9.94131 3.73734 9.68378C3.93969 9.39365 4.19434 9.13901 4.70364 8.62971L10.6668 2.66657C11.4032 1.9302 12.5971 1.93022 13.3335 2.6666V2.6666C14.0698 3.40298 14.0698 4.59685 13.3335 5.33321L7.37029 11.2964C6.86099 11.8057 6.60634 12.0603 6.31621 12.2627C6.0587 12.4423 5.78093 12.5909 5.48864 12.7056C5.15935 12.8347 4.80621 12.9054 4.09995 13.0466L2.66675 13.3333Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
