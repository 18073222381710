<script lang="ts">
    import { _ } from "svelte-i18n";

    export let Legal_name: string = "Amann OS";
</script>

<div
    class="py-6 px-9 text-black z-30 bg-white max-w-lg w-full top-5 left-1/2 transform -translate-x-1/2 absolute rounded-lg flex shadow-md"
>
    <img class="w-8 h-8 mr-4" alt="check" src="./pictures/Checked-box-green.svg" />
    <div class="w-full h-full">
        <p class="text-sm font-bold">{$_("components.emailSent")}</p>
        <p class="text-sm">
            {@html $_("components.emailSentDetails", { values: { legalName: Legal_name } })}
        </p>
    </div>
</div>
