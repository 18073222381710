<script>
    export let fill = "#4F25E4";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1294 0.469053L8.54488 0.401367L10.2905 2.07661L5.21231 6.95004L6.37606 8.06687L11.4543 3.19344L13.1999 4.86868L13.1294 0.469053ZM10.3572 11.5523V6.502L11.8676 5.05247V12.0018C11.8676 12.5541 11.4199 13.0018 10.8676 13.0018H0.999999C0.447714 13.0018 0 12.5541 0 12.0018V2.61269C0 2.06041 0.447715 1.61269 1 1.61269H8.10294L6.59252 3.06221H1.51043V11.5523H10.3572Z"
        {fill}
    />
</svg>

<style>
</style>
