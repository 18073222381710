<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { CurrencyCodeEnum } from "@/static/types";
    import { DateToString } from "../../../utils/functions";
    import utils from "../../../utilsV2/currency";

    export let showKredinorView = false;
    export let fundingProviderName: string;
    export let periodStartDate: string;
    export let currency: CurrencyCodeEnum;
    export let grossBalance: number;
    export let estimatedFees: number;
    export let netBalance: number;

    const formatMoney = utils.formatCurrency(currency);
</script>

<div>
    <h2>{fundingProviderName || $_("payouts.noFundingProviderName")} ({currency}):</h2>
    <div class="provider-balance">
        {#if !showKredinorView}
            <div class="row">
                <div>{$_("payouts.current_period_start")}</div>
                <div>
                    {#if periodStartDate}
                        {DateToString(new Date(periodStartDate))}
                    {:else}
                        N/A
                    {/if}
                </div>
            </div>
        {/if}
        <div class="row">
            <div>{$_("payouts.gross_balance")}</div>
            <div>{formatMoney(grossBalance)}</div>
        </div>
        <div class="row">
            <div>{$_("payouts.estimated_fees")}</div>
            <div>{formatMoney(estimatedFees)}</div>
        </div>
        <div class="row">
            <div>{$_("payouts.net_balance")}</div>
            <div>{formatMoney(netBalance)}</div>
        </div>
    </div>
</div>

<style lang="scss">
    * {
        box-sizing: border-box;
    }
    h2 {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5;
        margin-block-end: 0.25rem;
    }
    .provider-balance {
        font-size: 0.875rem;
        line-height: 1.25;

        .row:nth-child(even) {
            background-color: var(--bgSemanticHighlightTertiary);
        }
        .row:last-child {
            border-bottom: 1px solid var(--gray-300);
        }
    }
    .row {
        display: flex;
        justify-content: space-between;
        line-height: 1.45;
        padding-inline: 9px;
        padding-block: 4px;
    }
</style>
