<script lang="ts" xmlns="http://www.w3.org/1999/html">
    import { _ } from "svelte-i18n";

    import Button from "../../../components/Elements/Button.svelte";
    import DatePicker from "../../../components/Elements/DatePicker.svelte";
    import { GET_STATEMENT_DOWNLOAD } from "../../../static/endPoints";
    import { DateToFormat, getBaseUrl } from "../../../utils/functions";
    import Selector from "../../../components/Elements/Selector.svelte";
    import modalState from "../../../store/modals.store";
    import { FILE_TYPES } from "../../../static/constant";

    export let closeModal;

    const today = new Date();
    let startDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    let endDate = today;
    let format = "CSV";
    let errorMsg = "";

    function onEndDateChange(d) {
        endDate = d.detail;
        validateDates();
    }

    function onStartDateChange(d) {
        startDate = d.detail;
        validateDates();
    }

    function validateDates() {
        if (startDate > endDate) {
            errorMsg = $_("date.startDateIsGreater");
        } else if (startDate > today || endDate > today) {
            errorMsg = $_("date.datesCantBeInFuture");
        } else {
            errorMsg = "";
        }
    }

    function downloadStatement() {
        window.location.href = `${getBaseUrl()}${GET_STATEMENT_DOWNLOAD}?start_date=${DateToFormat(
            startDate
        )}&end_date=${DateToFormat(endDate)}&filetype=${format}&legacy=true`;
        modalState.actions.setModal(null, {}, null);
    }
</script>

<div class="flex flex-col py-6 px-6 modal w-2xs">
    <p class="title">{$_("statement.downloadCustomSettlementReport")}</p>
    <div class="flex flex-col justify-between gap-4 mb-6">
        <div class="flex items-center justify-between text-sm">
            <p class="text-black-dark">{$_("date.title")}</p>
            <div class="range-select">
                <DatePicker on:datechange={onStartDateChange} selectedDate={startDate} />
                <span> - </span>
                <DatePicker on:datechange={onEndDateChange} selectedDate={endDate} mode="end" />
            </div>
        </div>
        {#if errorMsg}
            <div class="text-red-500 text-sm mt-2">{errorMsg}</div>
        {/if}
        <div class="flex items-center justify-between text-sm">
            <p class="text-black-dark w-3/5">{$_("statement.file_format")}</p>
            <div class="file-select">
                <Selector
                    options={FILE_TYPES}
                    backgroundColor="white"
                    showArrow={true}
                    size="44px"
                    bind:value={format}
                />
            </div>
        </div>
        <div class="buttons">
            <Button
                label={$_("components.cancel")}
                bgColor="#fff"
                borderColor="#d0d5dd"
                hoverBg="var(--gray-50)"
                color="var(--gray-700)"
                hoverColor="var(--gray-700)"
                on:click={closeModal}
            />
            <Button
                label={$_("components.download")}
                disable={errorMsg.length > 0}
                on:click={downloadStatement}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;

        margin-bottom: 56px;
    }

    .range-select {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .file-select {
        width: 140px;
    }

    .buttons {
        display: flex;
        gap: 8px;
        justify-content: end;
        margin-top: 18px;
    }
</style>
