<script lang="ts">
    import { _ } from "svelte-i18n";
    import Icon from "../icons/Icon.svelte";

    export let setPage = (pageCursor: string) => {
        console.info(`pageCursor=${pageCursor}`);
    };
    export let rowCount: number = 0;
    export let pageLimit: number = 25;
    export let cursorLimit: number = 100;
    export let nextPage: string = "";
    export let prevPage: string = "";
    export let loading: boolean = false;
    export let rowsDescription: string = "";

    export let pageNum = 1;
    const numAllRecords = rowCount;
    const numPages = Math.ceil(rowCount / pageLimit);

    function changeToPrevPage() {
        if (pageNum === 1 && prevPage) {
            setPage(prevPage);
            // go to last page of returned rows
            pageNum = numPages;
        } else if (pageNum > 1) {
            pageNum--;
        }
    }
    function changeToNextPage() {
        if (pageNum === numPages && nextPage) {
            setPage(nextPage);
            pageNum = 1;
        } else if (pageNum < numPages) {
            pageNum++;
        }
    }
</script>

{#if !loading && rowCount}
    <div class="py-4 pr-20 flex justify-end page-counter" style="gap:1rem">
        <span
            >{prevPage && !nextPage && pageNum === numPages
                ? $_("components.lastPageOf", { values: { amount: cursorLimit } })
                : numAllRecords}{nextPage || prevPage ? "+" : ""}
            {rowsDescription}</span
        >
        <button
            class="cursor-pointer"
            disabled={pageNum === 1 && !prevPage}
            on:click={() => changeToPrevPage()}
        >
            <Icon
                name="arrow-left"
                size={16}
                fill={pageNum === 1 && !prevPage ? "#D0D5DD" : "#667085"}
            />
        </button>
        <button
            class="cursor-pointer flip-icon"
            disabled={pageNum === numPages && !nextPage}
            on:click={() => changeToNextPage()}
        >
            <Icon
                name="arrow-left"
                size={16}
                fill={pageNum === numPages && !nextPage ? "#D0D5DD" : "#667085"}
            />
        </button>
    </div>
{/if}

<style lang="scss">
    .flip-icon {
        transform: scaleX(-1);
    }

    .page-counter {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #344054;
        user-select: none;
        box-shadow: 0px -1px 5px #eee;
        background-color: white;
        z-index: 10;
    }
</style>
