<script lang="ts">
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.5 3.70263C7.24915 3.47351 8.08248 3.33325 9 3.33325C13.1421 3.33325 16.5 6.69112 16.5 10.8333C16.5 14.9754 13.1421 18.3333 9 18.3333C4.85786 18.3333 1.5 14.9754 1.5 10.8333C1.5 9.29146 1.96523 7.85833 2.76296 6.66659M6.5 3.70263L9 0.833252M6.5 3.70263L9.41667 5.83325"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
