<script lang="ts">
    export let fill: string = "#26E6A6";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.06774 0.167969C3.35265 0.167969 3.58362 0.391826 3.58362 0.667969V1.77972C5.52862 1.61184 7.48517 1.61184 9.43018 1.77972V0.667969C9.43018 0.391826 9.66114 0.167969 9.94605 0.167969C10.231 0.167969 10.4619 0.391826 10.4619 0.667969V1.87893C11.4889 2.01966 12.3002 2.80695 12.4375 3.81119L12.4971 4.24649C12.7475 6.07738 12.7258 7.9336 12.4329 9.75854C12.2877 10.6626 11.53 11.3569 10.5906 11.4467L9.76997 11.5252C7.59984 11.7326 5.41391 11.7326 3.24378 11.5252L2.42319 11.4467C1.48379 11.3569 0.726038 10.6626 0.580899 9.75854C0.287912 7.9336 0.266286 6.07738 0.516681 4.2465L0.576213 3.81119C0.713557 2.80694 1.52489 2.01964 2.55187 1.87892V0.667969C2.55187 0.391826 2.78283 0.167969 3.06774 0.167969ZM3.37394 2.80317C5.45751 2.60402 7.55624 2.60402 9.63981 2.80317L10.2626 2.8627C10.8588 2.91968 11.336 3.367 11.4147 3.9426L11.4743 4.3779C11.4951 4.52997 11.5139 4.68223 11.5307 4.83464H1.48302C1.49986 4.68223 1.51868 4.52997 1.53948 4.3779L1.59901 3.9426C1.67773 3.367 2.15497 2.91968 2.75114 2.8627L3.37394 2.80317ZM1.40101 5.83464C1.33348 7.09335 1.40001 8.35678 1.60038 9.60478C1.67318 10.0582 2.05326 10.4065 2.52445 10.4516L3.34503 10.53C5.44783 10.731 7.56592 10.731 9.66872 10.53L10.4893 10.4516C10.9605 10.4065 11.3406 10.0582 11.4134 9.60478C11.6137 8.35678 11.6803 7.09335 11.6127 5.83464H1.40101Z"
        {fill}
    />
</svg>
