import Statements from "../pages/merchant/customers/billing/statements/Statements.svelte";
import Account from "../pages/merchant/Account.svelte";
import Dashboard from "../pages/merchant/Dashboard/Dashboard.svelte";
import Customers from "../pages/merchant/customers/Customers.svelte";
import Plans from "../pages/merchant/plans/Plans.svelte";
import CustomerDetails from "../pages/merchant/customer-details/CustomerDetails.svelte";
import Integration from "../pages/merchant/integration/Integration.svelte";
import OrderDetail from "../pages/merchant/orders/OrderDetail.svelte";
import CombinedOrders from "../pages/merchant/combinedOrders/CombinedOrders.svelte";
import CreateOrder from "../pages/merchant/orders/merchantInitiated/CreateOrder.svelte";
import Settlement from "../pages/merchant/statement/Settlement.svelte";
import PeriodDetail from "../pages/merchant/customers/billing/statements/periodDetail/PeriodDetail.svelte";
import Payouts from "../pages/merchant/payouts/Payouts.svelte";
import PlanDetail from "../pages/merchant/plans/planDetail/PlanDetail.svelte";
import RiskManagement from "../pages/merchant/risk-management/RiskManagement.svelte";

const routes = [
    { path: "", component: Dashboard },
    { path: "orders", component: CombinedOrders },
    { path: "orders/:id", component: OrderDetail },
    { path: "createorder", component: CreateOrder },
    { path: "plans", component: Plans },
    { path: "plans/:id", component: PlanDetail },
    { path: "customers", component: Customers },
    { path: "customers/:id", component: CustomerDetails },
    { path: "customers/billing/:billingaccountid/period/:id", component: PeriodDetail },
    { path: "customers/billing/statements", component: Statements },
    { path: "settlement", component: Settlement },
    { path: "payouts", component: Payouts },
    { path: "risk-management", component: RiskManagement },
    { path: "account", component: Account },
    { path: "integration", component: Integration }
];

export default routes;
