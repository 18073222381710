<script lang="ts">
    import { _ } from "svelte-i18n";

    import ButtonRekki from "../../../components/Elements/Rekki/ButtonRekki.svelte";
    import b2bonboardingState from "../../../store/b2bonboarding.store";
    import type { MerchantB2bOnboardingData } from "../../../static/types";

    let merchantDetails: MerchantB2bOnboardingData = {};

    $: b2bonboardingState.merchantOnboardingData.subscribe(
        (data: MerchantB2bOnboardingData) => (merchantDetails = data)
    );

    function handleSkipVerification(): any {
        b2bonboardingState.customerData.actions.skipEmailVerification();
    }
</script>

<div class="confirmationBoxContainer">
    <div class="confirmPopup">
        <div class="w-full mb-3 bg-white rounded-xl">
            <p class="text-lg p-4 sm:text-xs">
                {$_("b2bOnboarding.skipEmailVerification.content")}
            </p>
            <ButtonRekki
                size="xlarge"
                label={$_("b2bOnboarding.skipEmailVerification.buttonText")}
                color="#FF453A"
                hoverColor="#FF453A"
                bgColor="transparent"
                hoverBg="transparent"
                hBg="transparent"
                on:click={handleSkipVerification}
            />
        </div>

        <div class="w-full mb-5">
            <ButtonRekki
                size="xlarge"
                label={$_("components.cancel")}
                color="#0A84FF"
                hoverColor="#0A84FF"
                bgColor="#ffffff"
                hoverBg="#ffffff"
                hBg="#ffffff"
                borderRadius={"13px"}
                on:click={() => {
                    b2bonboardingState.customerData.actions.setTriggerSkipVerifyEmailConfirmation(
                        false
                    );
                }}
            />
        </div>
    </div>
</div>

<style>
    .confirmationBoxContainer {
        display: flex;
        align-content: end;
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .confirmPopup {
        width: 95%;
        margin: 0 auto;
        text-align: center;
        box-sizing: border-box;
        font-family: var(--font-family-rekki-diatype) !important;
    }
    .confirmPopup p {
        color: rgba(60, 60, 67, 0.6);
        border-bottom: 0.5px solid rgba(60, 60, 67, 0.36);
    }

    @media (max-width: 640px) {
        .sm\:text-xs {
            font-size: 0.75rem;
            line-height: 1rem;
        }
    }
</style>
