<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M22.7169 15.498C22.7162 15.498 22.7155 15.498 22.7147 15.4965L17.9452 13.4509C17.078 13.0905 16.0606 13.3395 15.4725 14.0639L13.919 15.9609C11.4464 14.6147 9.39042 12.5588 8.04497 10.0868L9.94416 8.53116C10.6788 7.92549 10.9205 6.9301 10.5462 6.0541L8.502 1.28311C8.0933 0.341204 7.0672 -0.175171 6.07327 0.0540949L1.64869 1.08094C0.678234 1.30214 0 2.15175 0 3.14639C0 14.6455 9.35456 24 20.8537 24C21.849 24 22.7008 23.3232 22.9242 22.3535L23.946 17.9253C24.1752 16.9262 23.6581 15.9053 22.7169 15.498ZM21.4623 22.0166C21.3978 22.2964 21.1415 22.5 20.8537 22.5C10.1822 22.5 1.5 13.8178 1.5 3.14639C1.5 2.85633 1.69852 2.60808 1.98488 2.54288L6.4102 1.51603C6.45708 1.50502 6.50395 1.49991 6.55008 1.49991C6.79547 1.49991 7.02398 1.64494 7.1243 1.87636L9.16706 6.64374C9.27764 6.90225 9.20587 7.19522 8.99128 7.3725L6.62405 9.31195C6.35452 9.53316 6.27248 9.91036 6.42633 10.2231C7.99078 13.4018 10.6034 16.0136 13.7828 17.5781C14.0941 17.7334 14.4735 17.6499 14.694 17.3804L16.6342 15.0131C16.8077 14.7993 17.1102 14.729 17.3607 14.833L22.1222 16.875C22.3991 16.9951 22.5514 17.2954 22.484 17.5883L21.4623 22.0166Z"
        {fill}
    />
</svg>
