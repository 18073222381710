<script lang="ts">
    import { CardWidths } from "../../../static/types";

    export let width: keyof typeof CardWidths = "md";
</script>

<main class="card" style="--width: {CardWidths[width]};">
    <section class="container">
        <slot />
    </section>
</main>

<style lang="scss">
    .card {
        width: var(--width);
        min-height: 388px;
        margin: 2.5rem auto;
        border-radius: 16px;
        background-color: white;
        box-shadow: 0px 0px 6px rgba(208, 213, 221, 0.25);

        .container {
            height: 100%;
            padding: 40px 64px 32px 64px;
        }
    }

    @media only screen and (max-width: 480px) {
        .card {
            max-width: 95%;
            margin: 1rem auto;

            .container {
                height: 100%;
                padding: 24px 16px 24px 16px;
            }
        }
    }
</style>
