<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";

    import b2bonboardingState from "../../../store/b2bonboarding.store";
    import CompanySelector from "../../../components/companySelector/Rekki/CompanySelectorRekki.svelte";
    import InputBoxRekki from "../../../components/Elements/Rekki/InputBoxRekki.svelte";
    import ConfirmationBoxRekki from "../../../components/Elements/Rekki/ConfirmationBoxRekki.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import VerifyCode from "../../../components/Elements/VerifyCode.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import { setBusinessCustomerData, getBusinessCustomerData } from "../../../utils/auth";
    import type { MerchantB2bOnboardingData } from "../../../static/types";
    import { emailIsValid } from "../../../utils/validation";
    import { GB, NO } from "../../../static/constant";

    let merchantDetails: MerchantB2bOnboardingData = {};
    let loading = false;
    let step: number = 1;
    let company: string = "";
    let companyID: string = "";
    let address: any = {};
    let invalid: string = "";
    let onboarding_email_address: string = "";
    let mid: string = "";
    let cid: string = "";
    let verifyCode: string[] = [];
    let isBillingEmailVerified: boolean = false;
    let enableEmailVerification: boolean = true;
    let tooManyVerificationAttempts: boolean = false;
    let triggerSkipVerifyEmailConfirmation: boolean = false;
    let skipVerificationCode: boolean = false;

    const backButton = document.querySelector("#back-button-on-step-2");
    const skipVerificationButton = document.querySelector("#skip-verification-button");

    let customerData: any = {};

    let country: any = {
        flag: "/pictures/flag/uk.svg",
        name: "UK",
        country_code: GB
    };
    let error: any = {
        company: "",
        address: "",
        companyID: ""
    };
    let companyChecked: boolean = false;

    $: isCodeComplete = verifyCode.filter((digit) => digit !== null).length === 6;

    onMount(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (params?.mid) {
            // set data to localstorage
            setBusinessCustomerData(params);

            window.location.href = location.protocol + "//" + location.host + location.pathname;
        }

        let userInfo = getBusinessCustomerData();

        mid = userInfo?.mid;
        cid = userInfo?.cid;

        if (mid && cid) {
            // fetch the customer profile
            b2bonboardingState.customerData.actions.getCustomer(mid, cid);
        }

        backButton.addEventListener("click", handleBackButton);
        skipVerificationButton.addEventListener("click", handleSkipVerificationButton);
    });

    onDestroy(() => {
        backButton.removeEventListener("click", handleBackButton);
        skipVerificationButton.removeEventListener("click", handleSkipVerificationButton);
    });

    $: b2bonboardingState.customerData.subscribe((res: any) => {
        if (res?.id) {
            customerData = res;
        }
    });

    $: b2bonboardingState.enableEmailVerification.subscribe(
        (value: any) => (enableEmailVerification = value)
    );

    $: b2bonboardingState.triggerSkipVerifyEmailConfirmation.subscribe(
        (value: any) => (triggerSkipVerifyEmailConfirmation = value)
    );

    $: b2bonboardingState.skipVerificationCode.subscribe(
        (value: any) => (skipVerificationCode = value)
    );

    $: b2bonboardingState.customerBillingEmailVerified.subscribe(
        (value: boolean) => (isBillingEmailVerified = value)
    );

    $: {
        if (isBillingEmailVerified && enableEmailVerification) {
            handleSubmit();
        }
    }

    $: b2bonboardingState.updateCustomerLoading.subscribe((value: boolean) => (loading = value));

    $: b2bonboardingState.verifyEmailLoading.subscribe((value: boolean) => (loading = value));

    $: b2bonboardingState.tooManyVerificationAttempts.subscribe(
        (value: boolean) => (tooManyVerificationAttempts = value)
    );

    $: tooManyVerificationAttempts, tooManyVerificationAttempts && resetEmailPage();

    function resetEmailPage() {
        verifyCode = [];
        step = 2;
        error.verifyCode = "";
        error.onboarding_email_address = $_("customerSignup.checkEmail");
    }

    $: b2bonboardingState.merchantOnboardingData.subscribe(
        (data: MerchantB2bOnboardingData) => (merchantDetails = data)
    );

    $: {
        if (step == 2 || step == 3) {
            document.getElementById("back-button-to-close").style.display = "none";
            document.getElementById("back-button-on-step-2").style.display = "inherit";
        } else {
            document.getElementById("back-button-on-step-2").style.display = "none";
            document.getElementById("back-button-to-close").style.display = "inherit";
        }
        if (step == 3) {
            document.getElementById("powered-logo").style.display = "none";
            document.getElementById("skip-verification-button").style.display = "inherit";
        } else {
            document.getElementById("skip-verification-button").style.display = "none";
            document.getElementById("powered-logo").style.display = "inherit";
        }
    }

    const handleBackButton = (event: any) => {
        if (step >= 2) {
            error.onboarding_email_address = "";
            if (enableEmailVerification) {
                step = step - 1;
            } else {
                step = 1;
            }
        } else {
            window?.close();
        }
    };

    const handleSkipVerificationButton = (event: any) => {
        b2bonboardingState.customerData.actions.setTriggerSkipVerifyEmailConfirmation(true);
    };

    const handleSubmit = () => {
        if (!company) return (error.company = $_("b2bSignup.inputCompanyName"));

        if (!address.streetAddress) return (error.address = $_("customerSignup.addressRequired"));

        if (!companyID) return (error.companyID = $_("customerSignup.companyIDRequired"));

        // when company required details are provided then ask for customer onboarding email address for billing
        if (step == 1) {
            step = 2;
            return;
        }

        if (!onboarding_email_address)
            return (error.onboarding_email_address = $_("customerSignup.emailRequiredMessage"));
        if (!emailIsValid(onboarding_email_address))
            return (error.onboarding_email_address = $_("customerSignup.emailInvalidMessage"));

        if (enableEmailVerification) {
            if (step == 2) {
                b2bonboardingState.customerData.actions.sendVerificationCode(mid, cid, {
                    billing_email_address: onboarding_email_address
                });
                step = 3;
                return;
            }

            if (step == 3) {
                // if user is on last screen for verifying the code
                if (!isCodeComplete && !skipVerificationCode)
                    return (error.verifyCode = $_("customerSignup.enterCodeLabel"));
                else {
                    error.verifyCode = "";
                    if (!isBillingEmailVerified) {
                        b2bonboardingState.customerData.actions.verifyEmailCode({
                            email: onboarding_email_address,
                            code: verifyCode.join("")
                        });
                        if (!isBillingEmailVerified) {
                            return (error.verifyCode = $_("customerSignup.invalidCodeMessage"));
                        }
                        return;
                    }
                }
            }
        }

        var addressData = {
            organization_name: company,
            street_address: address?.streetAddress,
            city: address?.city,
            region: country.country_code == GB ? GB : NO,
            country: country.country_code == GB ? GB : NO,
            postal_code: address?.postalCode
        };

        var shipping_addresses = customerData?.shipping_addresses || [];

        if (shipping_addresses && shipping_addresses.length) {
            shipping_addresses = shipping_addresses.map((item) => {
                delete item.preferred;
                delete item.id;
                return item;
            });
        }

        shipping_addresses.push(addressData);

        let data: any = {
            country_prefix: NO,
            organization_id: companyID,
            legal_name: company,
            trade_name: customerData?.trade_name,
            website: customerData?.website,
            merchant_customer_id: customerData?.merchant_customer_id,
            insights_webhook: customerData?.insights_webhook,
            shipping_addresses: shipping_addresses,
            official_address: addressData,
            billing_email_address: onboarding_email_address
        };

        if (country.country_code === GB) {
            data.country_prefix = GB;
        } else {
            data.country_prefix = NO;
        }

        if (isBillingEmailVerified && enableEmailVerification) {
            data.billing_email_address_verified = true;
        }

        // update the business customer profile
        b2bonboardingState.customerData.actions.updateCustomer(mid, cid, data);
    };

    function onSubmit(e) {
        e.preventDefault();
    }
</script>

<div class="flex relative justify-center flex-wrap rekki-font h-full">
    <form
        on:submit={onSubmit}
        class="px-4 card container"
        class:step-one={step >= 2 ? true : false}
    >
        <p class="text-2xl text-black text-spacing">
            {$_("b2bOnboarding.companyScreen.heading")}
        </p>
        <p class="text-2xl text-subtitle text-spacing">
            {$_("b2bOnboarding.companyScreen.subheading")}
        </p>

        <div class="mt-10">
            <CompanySelector
                bind:address
                {country}
                bind:company
                bind:companyID
                required={false}
                invalid={error.company}
                labelFont="--font-family-rekki-ocd"
            />
        </div>

        {#if companyID && address.streetAddress}
            <div class="mt-10">
                <div class="item-label">{$_("customerSignup.companyNumber")}</div>
                <div class="company-details">{companyID}</div>
            </div>

            <div class="mt-10">
                <div class="item-label">{$_("customerSignup.companyAddress")}</div>
                <div class="company-details">{`${address.streetAddress}, ${address.city},`}</div>
                <div class="company-details">{`${address.postalCode}`}</div>
            </div>
        {/if}
    </form>

    {#if step == 2}
        <div class="p-4 card container">
            <p class="text-2xl text-black text-spacing">
                {$_("b2bOnboarding.companyScreen.heading")}
            </p>
            <p class="text-2xl text-subtitle text-spacing">
                {$_("b2bOnboarding.companyScreen.subheading")}
            </p>

            <div class="mt-5">
                <InputBoxRekki
                    label={$_("customerSignup.emailAddressLabel")}
                    type="email"
                    bind:invalid={error.onboarding_email_address}
                    bind:value={onboarding_email_address}
                    labelFont="--font-family-rekki-ocd"
                    placeholder="charlie@example.com"
                />
            </div>
        </div>
    {/if}

    {#if step == 3 && enableEmailVerification}
        <div class="p-4 card container">
            <p class="text-2xl text-black text-spacing">
                {$_("b2bOnboarding.verifyEmailScreen.heading")}
            </p>
            <p class="text-2xl text-subtitle text-spacing">
                {$_("b2bOnboarding.verifyEmailScreen.subheading")}
            </p>

            <div class="mt-5 relative">
                <VerifyCode
                    isValidEmail={onboarding_email_address ? true : false}
                    verifyMethod="email"
                    bind:code={verifyCode}
                    bind:error={error.verifyCode}
                    label={$_("customerSignup.enterCodeLabel")}
                    labelFont="--font-family-rekki-ocd"
                />
            </div>
            {#if onboarding_email_address}
                <p class="rekki-font-ocd text-sm m-auto mt-2 uppercase submit-text">
                    {$_("customerSignup.codeSentTo")}
                    {onboarding_email_address}
                </p>
            {/if}
        </div>
    {/if}

    <div class="mt-10 px-4 flex w-full flex-wrap justify-evenly" style="align-content: end;">
        {#if step >= 2}
            <div class="flex my-4 w-full items-center">
                <div class="icon-container">
                    <Icon name="rekkiinfo" size={24} fill={"#fff"} />
                </div>
                <div class="rekki-font-ocd text-sm m-auto ml-2 submit-text">
                    {step === 2
                        ? $_("b2bOnboarding.customerScreen.submissionText")
                        : $_("b2bOnboarding.verifyEmailScreen.submissionText")}
                </div>
            </div>
        {/if}

        <div class="w-full mb-6">
            <Button
                size="huge"
                label={step === (enableEmailVerification ? 3 : 2)
                    ? $_("components.submit")
                    : $_("components.continue")}
                bgColor="#0063E1"
                hoverBg="#095ac2"
                on:click={handleSubmit}
                {loading}
                disable={companyID == "" ||
                    !address?.streetAddress ||
                    (step === 2 && onboarding_email_address == "") ||
                    (step == 3 && !isCodeComplete)}
            />
        </div>

        {#if step == 3 && triggerSkipVerifyEmailConfirmation}
            <ConfirmationBoxRekki />
        {/if}
    </div>

    <style>
        #hubspot-messages-iframe-container {
            display: none !important;
        }
        #hs-eu-cookie-confirmation {
            display: none !important;
        }
    </style>
</div>

{#if loading}
    <div class="flex flex-col justify-center flex-wrap items-center absolute mainContainer">
        <div class="loader" />
        <p class="font-normal text-lg text-user-gray mb-6 rekki-font-ocd">LOADING...</p>
    </div>
{/if}

<style>
    * :global(.userBtn) {
        font-family: var(--font-family-rekki-ocd);
        font-size: 1.25rem;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }
    * :global(.userBtn:disabled) {
        color: rgba(0, 0, 0, 0.5);
        background-color: #cacaca;
        border: none;
        opacity: 1;
    }

    * :global(.userBtn:hover) {
        background-color: #0162e1 !important;
    }

    * :global(.userBtn:disabled:hover) {
        background-color: #cacaca !important;
    }

    * :global(.input-label) {
        font-size: 1rem;
        color: #150e0b !important;
    }

    * :global(input) {
        font-size: 1.5rem !important;
    }

    * :global(input::placeholder) {
        color: rgba(0, 0, 0, 0.5);
        opacity: 1;
    }

    * :global(.error) {
        font-family: var(--font-family-rekki-ocd);
        text-transform: uppercase;
    }
    * :global(.nav-back) {
        display: none !important;
    }
    * :global(.select-warning) {
        font-family: var(--font-family-rekki-ocd);
        text-transform: uppercase;
    }
    @media (max-height: 667px) {
        * :global(input) {
            font-size: 1.18rem !important;
        }

        * :global(.error) {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }

        * :global(.select-warning) {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }
    }
    @media (max-width: 430px) {
        * :global(input),
        * :global(textarea) {
            font-size: 1.1rem !important;
        }
        * :global(.error) {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }
        * :global(.select-warning) {
            font-size: 15px !important;
            height: 35px !important;
            line-height: 35px !important;
        }
    }

    .mainContainer {
        background: white;
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .icon-container {
        width: 24px;
    }

    .loader {
        border: 5px solid #f3f3f3;
        border-radius: 50%;
        border-top: 5px solid #3498db;
        width: 70vw;
        height: 70vw;
        max-width: 200px;
        max-height: 200px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        margin-bottom: 50px;
    }
    .rekki-font {
        font-family: var(--font-family-rekki-diatype);
    }
    .rekki-font-ocd {
        font-family: var(--font-family-rekki-ocd);
    }

    .submit-text {
        color: black;
        opacity: 0.5;
        line-height: 15px;
    }

    .text-subtitle {
        color: rgba(0, 0, 0, 0.5);
    }
    .text-spacing {
        letter-spacing: -0.6px;
    }

    .step-one {
        display: none;
    }

    .item-label {
        font-family: var(--font-family-rekki-ocd);
        font-size: 1rem;
        color: #150e0b !important;
    }

    .company-details {
        font-family: var(--font-family-rekki-diatype);
        font-size: 1.5rem;
        color: #150e0b !important;
    }
</style>
