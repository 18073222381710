import { monthNames } from "../static/monthName";

const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const isLeapYear = (year) => year % 4 === 0;
const getEmptyRows = () => {
    return Array.from({ length: 42 }).map(() => []);
};
const getMonthDays = (index, year) => {
    return index !== 1 ? monthDays[index] : isLeapYear(year) ? 29 : 28;
};

const getMonthStats = (monthIndex, year) => {
    const today = new Date(year, monthIndex, 1);
    const index = today.getMonth();
    return {
        name: index[index],
        days: getMonthDays(index, year)
    };
};

export const getMonthName = (index) => monthNames[index];

export const getDateRows = (monthIndex, year) => {
    const { days } = getMonthStats(monthIndex, year);
    const lastMonthDay = getMonthStats(monthIndex === 0 ? 11 : monthIndex - 1, year);
    const rows: any = getEmptyRows();
    const startIndex = new Date(year, monthIndex, 1).getDay();

    Array.from({ length: startIndex }).forEach((_, i) => {
        rows[startIndex - i - 1] = lastMonthDay.days - i + 1000;
    });

    Array.from({ length: days }).forEach((_, i) => {
        const index = startIndex + i;
        rows[index] = i + 1;
    });
    const next = (rows.length - startIndex - days) % 7;
    Array.from({ length: next }).forEach((_, i) => {
        rows[days + startIndex + i] = i + 1 + 1000;
    });
    const filled = rows.map((i) => (Array.isArray(i) ? undefined : i));

    return filled[35] ? filled : filled.slice(0, -7);
};

export const noop = () => {};

export const uuid = (() => {
    let id = 1;
    return () => {
        return ++id;
    };
})();

export const isAfter2019 = (value: string) => new Date(value).getFullYear() >= 2019;
