<script lang="ts">
    export let size: number = 12;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.33333 2.50008L8.75002 7.91677M17.5 16.6667L12.0833 11.2501M12.0833 11.2501L8.8702 14.4632C8.3609 14.9725 8.10626 15.2272 7.81613 15.4295C7.55861 15.6091 7.28084 15.7578 6.98856 15.8724C6.65926 16.0016 6.30613 16.0722 5.59986 16.2134L3.33333 16.6667L3.78663 14.4002C3.92789 13.694 3.99851 13.3408 4.12768 13.0115C4.24232 12.7192 4.39098 12.4415 4.57059 12.1839C4.77293 11.8938 5.02758 11.6392 5.53689 11.1299L8.75002 7.91677M12.0833 11.2501L8.75002 7.91677M10.8333 5.83348L13.3334 3.3334C13.5679 3.09892 13.6852 2.98168 13.7974 2.89724C14.5096 2.36117 15.4906 2.36118 16.2028 2.89726C16.315 2.98171 16.4323 3.09895 16.6667 3.33344V3.33344C16.9012 3.56792 17.0185 3.68516 17.1029 3.79735C17.6389 4.50957 17.6389 5.49058 17.1029 6.20279C17.0184 6.31498 16.9012 6.43222 16.6667 6.6667L14.1667 9.16675M12.5 4.16675C12.5 6.0077 13.9924 7.50008 15.8333 7.50008"
        stroke="#98A2B3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
