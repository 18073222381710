<script lang="ts">
    import { _ } from "svelte-i18n";

    export let loading: boolean = false;
    export let background: string = "none";
</script>

<div class:is-loading={loading} style="--background: {background}">
    {#if loading}
        <div class="lds-wrapper">
            <div class="loader">{$_("components.loading")}</div>
        </div>
    {/if}
    <slot />
</div>

<style>
    .is-loading {
        overflow: hidden;
    }
    .is-loading::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        z-index: 90;
        background-color: var(--background);
    }
    .lds-wrapper {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 25px);
        z-index: 95;
        width: 50px;
        height: 24px;
    }

    .loader,
    .loader:before,
    .loader:after {
        background: #ebeaef;
        -webkit-animation: load1 1s infinite ease-in-out;
        animation: load1 1s infinite ease-in-out;
        width: 5px;
        height: 24px;
    }
    .loader {
        color: #ebeaef;
        text-indent: -9999em;
        margin: 0 auto;
        position: relative;
        font-size: 11px;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
    .loader:before,
    .loader:after {
        position: absolute;
        top: 0;
        content: "";
    }
    .loader:before {
        left: -10px;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    .loader:after {
        left: 10px;
    }
    @-webkit-keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 24px;
        }
        40% {
            box-shadow: 0 -2em;
            height: 34px;
        }
    }
    @keyframes load1 {
        0%,
        80%,
        100% {
            box-shadow: 0 0;
            height: 24px;
        }
        40% {
            box-shadow: 0 -2em;
            height: 34px;
        }
    }
</style>
