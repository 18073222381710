export function clickOutside(node: Node, { enabled }: { enabled: boolean }) {
    function handleClick(event: MouseEvent) {
        const { target } = event;
        if (!node.contains(target as Node)) {
            node.dispatchEvent(
                // NOTE named same as clickOutside.js to reuse existing type declaration
                new CustomEvent("click_outside")
            );
        }
    }

    function update({ enabled }: { enabled: boolean }) {
        if (enabled) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }
    }

    update({ enabled });
    return {
        update,
        destroy() {
            document.removeEventListener("click", handleClick);
        }
    };
}
