<script lang="ts">
    import Loading from "./../Loading.svelte";
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();
    export let label: string = "";
    export let size: string = "large";
    export let color: string = "white";
    export let hBg: string = "#4F25E4EE";
    export let hoverBg: string = "#4F25E4";
    export let hoverColor: string = "white";
    export let loading: boolean = false;
    export let disable: boolean = false;
    export let bgColor: string = "#4F25E4";
    export let borderRadius: string = "10px";
    export let borderColor: string = bgColor;
    export let boxShadow: string = "";
    export let paddingX: string = "0";
    export let paddingY: string = "0";
    export let autoHeight: boolean = false;
    let height: string;
    $: {
        switch (size) {
            case "small":
                height = "34px";
                break;
            case "large":
                height = "42px";
                break;
            case "xlarge":
                height = "60px";
                break;
            case "huge":
                height = "78px";
                break;
        }
    }
    function onClick() {
        if (!loading) {
            dispatch("click");
        }
    }
</script>

<div
    class="relative"
    style="--bg-color: {bgColor}; --color:{color}; --hBg:{hBg}; --height:{height}; --hoverBg:{hoverBg}; --hoverColor:{hoverColor}; --borderRadius: {borderRadius}; --borderColor: {borderColor}; --boxShadow: {boxShadow}; --paddingY:{paddingY}; --paddingX: {paddingX}"
>
    <button
        class="relative text-white userBtn focus:outline-none"
        class:disable
        class:autoHeight
        class:fixedHeight={!autoHeight}
        on:click={onClick}
        class:cursor-not-allowed={disable}
        class:btn-huge={size == "huge"}
        disabled={disable}
    >
        {#if loading}
            <Loading />
        {:else}
            {@html label}
            <slot />
        {/if}
    </button>
</div>

<style>
    .userBtn {
        width: 100%;
        border-radius: var(--borderRadius);
        border: 1px solid var(--borderColor);
        background-color: var(--bg-color);
        color: var(--color);
        box-shadow: var(--boxShadow);
        padding: var(--paddingY) var(--paddingX);
        text-transform: inherit;
        font-family: inherit;
    }
    .userBtn.fixedHeight {
        height: var(--height);
    }
    .userBtn.autoHeight {
        min-height: var(--height);
    }
    .userBtn:active {
        background-color: var(--hBg) !important;
        color: var(--hoverColor) !important;
    }
    .userBtn:focus {
        background-color: var(--hBg) !important;
        color: var(--hoverColor) !important;
    }
    .userBtn:hover {
        background-color: var(--hoverBg) !important;
        color: var(--hoverColor) !important;
    }
    .disable {
        opacity: 0.5;
    }
    .btn-huge {
        font-size: 1.2rem;
        letter-spacing: 3px;
    }
</style>
