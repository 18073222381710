<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import CountrySelector from "../../../../components/countrySelector/CountrySelector.svelte";
    import CompanySelector from "../../../../components/companySelector/CompanySelector.svelte";
    import Button from "../../../../components/Elements/Button.svelte";
    import Checkbox from "../../../../components/Elements/Checkbox.svelte";
    import Input from "../../../../components/Elements/InputBox.svelte";
    import PhoneInput from "../../../../components/authentication/PhoneInput.svelte";
    import Icon from "../../../../components/icons/Icon.svelte";
    import { COUNTRIES, NO, SE } from "../../../../static/constant";
    import { emailIsValid, validatePhoneNumber } from "@/utils/validation";
    import authenticationService from "../../../../services/authenticationService";
    import { setOrganizationId, setCountry, setAddress, setCompany } from "../../../../utils/auth";
    import notificationState from "../../../../store/notifications.store";
    import API from "../../../../services/apiService";
    import { NotificationType } from "../../../../static/types";
    import { M_ONBOARDING } from "../../../../static/endPoints";
    import environment from "@/services/environment";
    import { isCompanyEmail } from "../../../../utils/functions";

    const SIGNUP_ERROR = $_("login.signupError");

    let company: string = "";
    let organizationNumber: string = "";
    let country: any = {};
    let address: any = {};
    let isLoading: boolean = false;
    let terms_condition: boolean = false;

    let firstName: string = "";
    let lastName: string = "";
    let invalidFirstName: string = "";
    let invalidLastName: string = "";
    let invalidEmail: string = "";
    let invalidPhone: string = "";
    let invalidCompany: string = "";

    let userEmail: string = "";
    const phoneDisable: boolean = false;
    let phoneNumberWithCode: string = "+44";
    let phoneNumber: string = "";
    let countryCode: string = "";
    let phoneCountryCode: string = "";

    let prevCountry: any = {};
    $: if (country && country !== prevCountry) {
        countryCode = country.country_code;
        phoneCountryCode = COUNTRIES[countryCode]?.phoneCountryCode ?? "";
        prevCountry = country;
    }

    function handleSubmit() {
        if (!country) {
            navigate("https://www.two.inc/book-a-demo");
        }
        invalidFirstName = !firstName ? $_("login.invalidFirstName") : "";
        invalidLastName = !lastName ? $_("login.invalidLastName") : "";
        invalidEmail = !userEmail
            ? $_("login.enterEmail")
            : !emailIsValid(userEmail)
              ? $_("login.invalidEmail")
              : "";

        if (!invalidEmail) {
            invalidEmail = isCompanyEmail(userEmail) ? $_("errors.useCompanyEmail") : "";
        }

        invalidPhone = validatePhoneNumber($_, phoneCountryCode, phoneNumber);
        invalidCompany = organizationNumber?.length ? "" : $_("login.chooseCompany");

        if (invalidFirstName || invalidLastName || invalidEmail || invalidPhone) {
            return;
        }

        const form = {
            first_name: firstName,
            last_name: lastName,
            email: userEmail,
            phone_number: phoneNumberWithCode,
            country_code: country.country_code,
            organization_number: organizationNumber,
            company_name: company
        };

        if (address.streetAddress) {
            form.street_address = address.streetAddress;
        }
        if (address.postalCode) {
            form.postal_code = address.postalCode;
        }
        if (address.city) {
            form.city = address.city;
        }

        API.post(M_ONBOARDING, form)
            .then((res: any) => {
                localStorage.setItem("application_id", res.id);
                localStorage.setItem("user_email", userEmail);
                setOrganizationId(organizationNumber);
                setAddress(address);
                setCompany(company);
                setCountry(country.country_code);
                const formValue = {
                    verification_type: "EMAIL",
                    verification_context: "MERCHANT_ONBOARDING",
                    email: userEmail,
                    signup_id: res.id
                };
                authenticationService
                    .sendEmailCode(formValue)
                    .then((res: any) => {
                        localStorage.setItem("verification_id", res.verification_id);
                        localStorage.setItem("redirect_url", res.redirect_url);
                        navigate("/auth/merchant/signup/verification");
                    })
                    .catch(() => {
                        notificationState.actions.create(NotificationType.ERROR, SIGNUP_ERROR);
                    });
            })
            .catch((error: any) => {
                isLoading = false;
                if (error.response.status == 409) {
                    notificationState.actions.create(
                        NotificationType.ERROR,
                        $_("login.accountExists")
                    );
                    return;
                }
                notificationState.actions.create(NotificationType.ERROR, SIGNUP_ERROR);
            });
    }

    function login() {
        navigate("/auth/merchant/login");
    }
</script>

<svelte:head>
    <title>{$_("login.twoSignUp")}</title>
</svelte:head>

<div class="signup-form-main">
    <div class="brand-logo"><img id="theme-logo" src={environment.branding.logo} alt="logo" /></div>
    <h2 class="ctn-general-primary">{$_("login.createAccount")}</h2>
    <div class="features">
        <div class="feature">
            <Icon name="check" /><span class="ml-1">{$_("login.getPaidUpfront")}</span>
        </div>
        <div class="feature">
            <Icon name="check" /><span class="ml-1">{$_("login.boostB2BSales")}</span>
        </div>
        <div class="feature">
            <Icon name="check" /><span class="ml-1">{$_("login.eliminateAdmin")}</span>
        </div>
    </div>
    <div class="user-name">
        <div>
            <p class="text-sm">{$_("login.firstName")}</p>
            <Input
                type="text"
                bind:value={firstName}
                placeholder={$_("login.enterFirstName")}
                bind:invalid={invalidFirstName}
            />
        </div>
        <div>
            <p class="text-sm">{$_("login.lastName")}</p>
            <Input
                type="text"
                bind:value={lastName}
                placeholder={$_("login.enterLastName")}
                bind:invalid={invalidLastName}
            />
        </div>
        {#if invalidFirstName || invalidLastName}
            <p class="mt-2"></p>
        {/if}
    </div>

    <div>
        <CountrySelector bind:country required={false} showNotOnList={true} />
    </div>
    {#if country}
        <div class="company-selector">
            <CompanySelector
                bind:address
                countryCode={country?.country_code}
                bind:company
                bind:companyID={organizationNumber}
                required={false}
                bind:invalid={invalidCompany}
                addressRequired={true}
            />
            {#if invalidCompany}
                <p class="mb-10"></p>
            {/if}
        </div>
        <p class="mt-4 text-sm">{$_("login.email")}</p>
        <Input
            type="text"
            bind:value={userEmail}
            placeholder="olivia@example.com"
            bind:invalid={invalidEmail}
        />
        {#if invalidEmail}
            <p class="mb-10"></p>
        {/if}

        <p class="mt-4 text-sm">{$_("login.phoneNumber")}</p>
        <PhoneInput
            disable={phoneDisable}
            bind:phoneNumberWithCode
            bind:phoneNumber
            bind:countryCode={phoneCountryCode}
            bind:invalid={invalidPhone}
        />
        {#if invalidPhone.length}
            <p class="error">{invalidPhone}</p>
        {/if}

        <p class="mt-4"></p>
        <div class="mt-6 flex items-center">
            <Checkbox
                bind:checked={terms_condition}
                checkboxLabel={$_("login.acceptTerms", {
                    values: { brand: environment.branding.displayName }
                })}
            />
            <p class="ml-1 text-slight-dark">
                {#if country.country_code === NO}
                    <a
                        class="cursor-pointer text-main"
                        target="_blank"
                        rel="noopener"
                        href="https://www.two.inc/no-merchant-terms"
                    >
                        {$_("login.termsConditions")}
                    </a>
                {:else if country.country_code === SE}
                    <a
                        class="cursor-pointer text-main"
                        target="_blank"
                        rel="noopener"
                        href="https://www.two.inc/se-merchant-terms"
                    >
                        {$_("login.termsConditions")}
                    </a>
                {:else}
                    <a
                        class="cursor-pointer text-main"
                        target="_blank"
                        rel="noopener"
                        href="https://www.two.inc/uk-merchant-terms"
                    >
                        {$_("login.termsConditions")}
                    </a>
                {/if}
            </p>
        </div>
    {/if}
    <div class="mt-6">
        <Button
            size="large"
            bgColor="var(--primary-600)"
            label={country ? $_("components.continue") : $_("login.learnMore")}
            loading={isLoading}
            on:click={handleSubmit}
            disable={!terms_condition && country}
        />
    </div>

    <p class="text-sm text-center mt-6">
        {$_("login.alreadyHaveAccount")}
        <button class="font-bold text-main login-btn" on:click={login}>
            {$_("login.login")}
        </button>
    </p>
</div>

<style lang="scss">
    .signup-form-main {
        max-width: 500px;
        padding: 1rem 2rem;
        margin: auto;
    }

    .brand-logo {
        margin-bottom: 3rem;
    }

    h2 {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 1.5rem;
    }

    .features {
        display: flex;
        width: 420px;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        color: var(--Gray-500, #667085);
        font-size: 14px;

        .feature {
            display: flex;
            align-items: center;
        }
    }

    .user-name {
        display: grid;
        margin: 1.5rem 0 1rem 0;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    .error {
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .login-btn {
        color: var(--primary-100);
    }

    * :global(.company-selector > div) {
        margin-top: 1rem;
    }
</style>
