<script lang="ts">
    export let fill: string = "#938DA9";
    export let size: number = 16;
</script>

<svg
    width={size}
    height={size}
    enable-background="new 0 0 32 32"
    id="Editable-line"
    version="1.1"
    viewBox="0 0 32 32"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ><circle
        cx="14"
        cy="14"
        fill="none"
        id="XMLID_42_"
        r="9"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
    /><line
        fill="none"
        id="XMLID_44_"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="2"
        x1="27"
        x2="20.366"
        y1="27"
        y2="20.366"
    /></svg
>
