<script lang="ts">
    import { _ } from "svelte-i18n";

    import { statementDataLoading } from "../../../store/buyer/account.store";
    import Table from "../../../components/table/Table.svelte";
    import type { IStatementObject } from "./statementUtils";
    import { navigate } from "svelte-routing";

    export let selectedTab: string;
    export let searchRows: IStatementObject[];
    const mailboxStateImgSrc = "/pictures/mailboxstate.svg";
    let noStatementsShownMessage = "No statements match this filter.";
    let imgSrc = mailboxStateImgSrc;
    const SITES_TOOLTIP_TEXT = "The locations covered by the statement.";

    $: if (selectedTab == "Unpaid") {
        noStatementsShownMessage = "All statements associated with your email address are paid!";
        imgSrc = "/pictures/Checked-box-green.svg";
    } else if (selectedTab == "Paid") {
        noStatementsShownMessage = "You've not paid any statements yet.";
        imgSrc = mailboxStateImgSrc;
    } else {
        noStatementsShownMessage = "No statements match this filter.";
        imgSrc = mailboxStateImgSrc;
    }

    function itemSelected(event, tableRow) {
        const url = `/buyer/billing/${tableRow.billingAccountId}/period/${tableRow.periodId}`;
        if (event.metaKey) {
            window.open(url, "_blank");
        } else {
            navigate(url);
        }
    }

    const tableHeaders = [
        { title: "Merchant details", clickable: false, type: "merchant_details" },
        { title: "Sites", clickable: false, type: "trade_names", tooltip: SITES_TOOLTIP_TEXT },
        { title: "Statement №", clickable: false, type: "statement_number" },
        { title: "Issue date", clickable: false, type: "statement_date" },
        {
            title: "Statement total",
            clickable: false,
            type: "gross_amount",
            tooltip: $_("statements.totalAmountTooltip")
        },
        {
            title: "Remaining to pay",
            clickable: false,
            type: "unreconciled_amount",
            tooltip: $_("statements.remainingToPayTooltip")
        },
        { title: "View and pay", clickable: false, type: "actions" }
    ];
</script>

<div class="statements-list-container">
    {#if !$statementDataLoading && searchRows.length == 0}
        <div class="w-full h-48 flex flex-col items-center pt-8">
            <img src={imgSrc} alt="mailbox" />
            <p class="my-4 font-semibold text-black-dark text-center">{noStatementsShownMessage}</p>
        </div>
    {:else}
        <Table
            {tableHeaders}
            bind:tableRows={searchRows}
            bind:numAllRecords={searchRows.length}
            numRows={25}
            {itemSelected}
            loading={$statementDataLoading}
        />
    {/if}
</div>

<style lang="scss">
    div {
        img {
            width: 10%;
        }
    }
    .statements-list-container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        height: 80vh;
    }
</style>
