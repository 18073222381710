<script lang="ts">
    import { _ } from "svelte-i18n";

    import { getBaseUrl } from "../../utils/functions";
    import { CANCEL_REMAINING_ORDER } from "../../static/endPoints";
    import API from "../../services/apiService";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import { isCancelledRemainingOrder } from "../../store/merchant/allOrders.store";
    import Button from "../Elements/Button.svelte";
    import { NotificationType } from "../../static/types";


    export let orderID: string = "";
    export let merchantOrderID: string = "";
    export let buyerCompanyName: string = "";
    let isCancelProcessing: boolean = false;
    
    const handleCancel = () => {
        const baseUrl = getBaseUrl();
        const url = `${baseUrl}${CANCEL_REMAINING_ORDER(orderID)}`;
        isCancelProcessing = true;
        API.post(url, orderID)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.cancel.remainingSuccess")
                );
                modalState.actions.setModal(null, { isCancelled: true });
                isCancelledRemainingOrder.set(true);
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("order.cancel.errorMessage")
                );
                modalState.actions.setModal(null, { isCancelled: false });
            });
    };
    const handleCancelModal = () => {
        modalState.actions.setModal(null, {});
    };
</script>

<div class="container">
    <div class="flex title">
        <div class="grey-text">
            {$_("order.cancel.titleRemaining", { values: { merchantOrderID } })}
        </div>
    </div>
    <div class="body-text">
        {$_("order.cancel.remainingText", { values: { buyerCompanyName } })}
    </div>
    <div class="button-area">
        <div class="right-align">
            <Button
                label={$_("components.goBack")}
                on:click={handleCancelModal}
                borderColor="var(--gray-300)"
                borderRadius="8px"
                color="#1D2939"
                hoverColor="black"
                bgColor="white"
                hoverBg="#F9FAFB"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
        <div>
            <Button
                label={$_("components.cancel")}
                on:click={handleCancel}
                disable={isCancelProcessing}
                color="white"
                bgColor="var(--error-600)"
                hoverBg="var(--error-700)"
                paddingX="12px"
                paddingY="12px"
                autoHeight={true}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        padding: 24px 32px 24px 32px;
        height: 368px;
        width: 544px;
    }
    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }
    .body-text {
        color: #667085;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }
    .button-area {
        display: flex;
        position: absolute;
        right: 32px;
        left: 32px;
        bottom: 24px;
    }
    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }
    .grey-text {
        color: #1d2939;
    }
</style>
