export function get_statement_type_from_creation_reason(reason: string): string {
    switch (reason) {
        case "FINALISED":
            return "Original";
        case "AMENDED":
            return "Amendment";
        case "PREVIEW":
            return "Preview";
    }
}
