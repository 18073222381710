<svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
        <path
            opacity="0.4"
            d="M79.6911 13.5917C78.9275 12.5801 77.7634 12 76.4959 12H23.7446L29.8396 44H69.6404C71.4196 44 72.9997 42.8047 73.4861 41.0976L80.3435 17.0996C80.6911 15.8809 80.4529 14.6035 79.6911 13.5917ZM3.5 26H13.5C15.157 26 16.5 24.6567 16.5 23C16.5 21.343 15.157 20 13.5 20H3.5C1.84325 20 0.5 21.343 0.5 23C0.5 24.6567 1.84325 26 3.5 26ZM3.5 36H15.5C17.157 36 18.5 34.6567 18.5 33C18.5 31.343 17.157 30 15.5 30H3.5C1.84325 30 0.5 31.343 0.5 33C0.5 34.6567 1.84325 36 3.5 36ZM17.5 40H3.5C1.84325 40 0.5 41.343 0.5 43C0.5 44.6567 1.84325 46 3.5 46H17.5C19.157 46 20.5 44.6567 20.5 43C20.5 41.343 19.157 40 17.5 40Z"
            fill="#B3AEFF"
        />
        <path
            d="M66.5 60C63.1862 60 60.5 62.6862 60.5 66C60.5 69.3137 63.1862 72 66.5 72C69.8137 72 72.5 69.3137 72.5 66C72.5 62.6862 69.8137 60 66.5 60ZM30.5 60C27.1862 60 24.5 62.6862 24.5 66C24.5 69.3137 27.1862 72 30.5 72C33.8137 72 36.5 69.3137 36.5 66C36.5 62.6862 33.8137 60 30.5 60ZM69.5 50H30.9824L23.4472 10.4387C23.1777 9.02375 21.9406 8 20.5002 8H11.5C9.84312 8 8.5 9.34312 8.5 11C8.5 12.6569 9.84312 14 11.5 14H18.0176L25.553 53.5614C25.8225 54.9764 27.0596 56 28.5 56H69.5C71.1569 56 72.5 54.6569 72.5 53C72.5 51.3431 71.1569 50 69.5 50Z"
            fill="#B3AEFF"
        />
    </g>
    <defs>
        <clipPath id="clip0">
            <rect width="80" height="80" fill="white" transform="translate(0.5)" />
        </clipPath>
    </defs>
</svg>
