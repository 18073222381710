<script lang="ts">
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="var(--bgSemanticHighlightTertiary)" />
    <path
        d="M16.8601 12.2999C15.2214 12.2999 14.402 12.2999 13.7451 12.5364C12.6235 12.9402 11.7404 13.8233 11.3366 14.945C11.1001 15.6018 11.1001 16.4212 11.1001 18.0599C11.1001 18.4696 11.1001 18.6745 11.1592 18.8387C11.2602 19.1191 11.4809 19.3399 11.7614 19.4408C11.9256 19.4999 12.1304 19.4999 12.5401 19.4999H35.4601C35.8698 19.4999 36.0746 19.4999 36.2388 19.4408C36.5193 19.3399 36.74 19.1191 36.841 18.8387C36.9001 18.6745 36.9001 18.4696 36.9001 18.0599C36.9001 16.4212 36.9001 15.6018 36.6636 14.945C36.2598 13.8233 35.3767 12.9402 34.255 12.5364C33.5982 12.2999 32.7788 12.2999 31.1401 12.2999H16.8601Z"
        fill={"var(--bgSemanticHighlightSecondary)"}
    />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.1001 22.8999C11.1001 22.3399 11.1001 22.0598 11.2091 21.8459C11.305 21.6578 11.4579 21.5048 11.6461 21.4089C11.86 21.2999 12.14 21.2999 12.7001 21.2999H35.3001C35.8602 21.2999 36.1402 21.2999 36.3541 21.4089C36.5423 21.5048 36.6952 21.6578 36.7911 21.8459C36.9001 22.0598 36.9001 22.3399 36.9001 22.8999V29.2999C36.9001 31.5401 36.9001 32.6602 36.4641 33.5159C36.0806 34.2685 35.4687 34.8805 34.7161 35.264C33.8604 35.6999 32.7403 35.6999 30.5001 35.6999H17.5001C15.2599 35.6999 14.1398 35.6999 13.2841 35.264C12.5315 34.8805 11.9196 34.2685 11.5361 33.5159C11.1001 32.6602 11.1001 31.5401 11.1001 29.2999V22.8999ZM16.8 24.2999C16.3029 24.2999 15.9 24.7029 15.9 25.1999C15.9 25.697 16.3029 26.0999 16.8 26.0999H21.6C22.097 26.0999 22.5 25.697 22.5 25.1999C22.5 24.7029 22.097 24.2999 21.6 24.2999H16.8Z"
        fill="var(--bgSemanticHighlightSecondary)"
    />
</svg>
