<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";

    import { Button as TwoUIButton } from "@two-ui/core";
    import DatePicker from "../Elements/DatePicker.svelte";
    import Icon from "../icons/Icon.svelte";

    let showDatePicker: boolean = false;
    let picker: any;
    let calendar: any;
    let dateRange: string = "";
    export let startDate: Date = new Date();
    export let endDate: Date = new Date();
    export let label: string = $_("date.dateRange");
    export let size: string = "48px";
    export let placeholder: string = $_("date.selectDate");

    const dispatch = createEventDispatcher();
    function onEndDateChange(d) {
        endDate = d.detail;
    }

    function onStartDateChange(d) {
        startDate = d.detail;
    }

    function setLastWeek() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
    }

    function setLastMonth() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    }

    function setLastQuarter() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 90 * 24 * 60 * 60 * 1000);
    }

    function setLastYear() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 365 * 24 * 60 * 60 * 1000);
    }

    const closeDown = (event) => {
        if (event.path && event.path[0] == picker) return;
        if (!calendar?.contains(event.target)) {
            showDatePicker = false;
        }
    };

    function onApply() {
        if (startDate > endDate) {
            dispatch("error", $_("statements.errorMessageStartDate"));
            return;
        }
        if (startDate > new Date() || endDate > new Date()) {
            dispatch("error", $_("statements.errorMessageFutureDate"));
            return;
        }
        dispatch("changeDate", { startDate, endDate });
        showDatePicker = false;
        dateRange = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();
    }

    onMount(() => {
        document.addEventListener("mousedown", closeDown);
        dateRange = startDate.toLocaleDateString() + " - " + endDate.toLocaleDateString();
    });

    onDestroy(() => {
        document.removeEventListener("mousedown", closeDown);
    });
</script>

<div class="text-xs" style="--height: {size}">
    {#if label !== ""}
        <label class="cursor-pointer text-gray-700 text-sm" for="date-selector">
            {label}
        </label>
    {/if}
    <div class="relative text-sm">
        <div
            class="selector flex items-center justify-between mt-1"
            id="date-selector"
            on:click={() => (showDatePicker = !showDatePicker)}
            on:keyup
        >
            <p class="text-ls pl-4" class:text-gray-100={dateRange === ""}>
                {dateRange || placeholder}
            </p>
            <div class="mr-4">
                <div class={`${showDatePicker && "rotate"} dropdown_icon`}>
                    <Icon name="drop-arrow-down" fill={"#81809A"} />
                </div>
            </div>
        </div>

        <div class="relative w-full">
            {#if showDatePicker}
                <div class="absolute datepicker" bind:this={calendar}>
                    <div class="flex mb-3 items-center">
                        <div class="mr-2">
                            <DatePicker
                                on:datechange={onStartDateChange}
                                selectedDate={startDate}
                            />
                        </div>
                        <div>-</div>
                        <div class="ml-2">
                            <DatePicker
                                on:datechange={onEndDateChange}
                                selectedDate={endDate}
                                mode="end"
                            />
                        </div>
                    </div>
                    <div class="mt-4 flex justify-between text-sm font-bold">
                        {$_("date.last")}
                        <p class="w-fit text-primary" on:click={() => setLastWeek()} on:keyup>
                            {$_("date.week")}
                        </p>
                        <p class="w-fit text-primary" on:click={() => setLastMonth()} on:keyup>
                            {$_("date.month")}
                        </p>
                        <p class="w-fit text-primary" on:click={() => setLastQuarter()} on:keyup>
                            {$_("date.quarter")}
                        </p>
                        <p class="w-fit text-primary" on:click={() => setLastYear()} on:keyup>
                            {$_("date.year")}
                        </p>
                    </div>
                    <div class="flex mt-4">
                        <div class="w-full mr-2">
                            <TwoUIButton
                                on:click={(showDatePicker = !showDatePicker)}
                                size="md"
                                variant="secondaryGray"
                                content={$_("components.cancel")}
                                fullSize={true}
                            />
                        </div>
                        <div class="w-full ml-2">
                            <TwoUIButton
                                on:click={() => onApply()}
                                size="md"
                                variant="primary"
                                content={$_("components.apply")}
                                fullSize={true}
                            />
                        </div>
                    </div>
                    {#if startDate > endDate}
                        <div class="text-red-500 text-sm mt-2">
                            {$_("date.startDateIsGreater")}
                        </div>
                    {/if}
                    {#if startDate > new Date() || endDate > new Date()}
                        <div class="text-red-500 text-sm mt-2">
                            {$_("date.datesCantBeInFuture")}
                        </div>
                    {/if}
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    .selector {
        background-color: #f9fafb;
        box-sizing: border-box;
        border-radius: 8px;
        height: var(--height);
        width: 100%;
        cursor: pointer;
        border: 1px solid var(--gray-100);
    }
    .datepicker {
        position: absolute;
        background-color: white;
        z-index: 20;
        border: 1px solid var(--gray-100);
        border-radius: 8px;
        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
        padding: 24px 24px;
    }
    .text-primary {
        color: var(--primary-700);
        cursor: pointer;
    }
    .text-primary:hover {
        opacity: 0.8;
    }
    .rotate {
        transform: rotate(180deg);
    }
</style>
