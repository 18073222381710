<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { Button } from "@two-ui/core";

    import { client } from "../../../services/http/client";
    import { GET_PROVIDER_BALANCES } from "../../../static/endPoints";
    import { NotificationType } from "../../../static/types";
    import { account as merchantAccount } from "../../../store/merchant/account.store";
    import notificationState from "../../../store/notifications.store";
    import PayoutsProviderBalance from "./PayoutsProviderBalance.svelte";
    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import PendingPayoutsReportModal from "../../../components/modals/PendingPayoutsReportModal.svelte";
    import { getFingerprintHeaders } from "@/services/fingerprint";

    export let showKredinorView = false;
    let loadingPendingBalances = false;
    let balances = [];
    let showPendingPayoutsReportModal = false;
    $: merchantName = $merchantAccount.legalName;
    $: defaultCurrency = $merchantAccount.feeCurrency;

    onMount(getProviderBalances);

    async function getProviderBalances() {
        loadingPendingBalances = true;
        const { data, response } = await getFingerprintHeaders().then((headers) =>
            client.get(GET_PROVIDER_BALANCES, { headers })
        );
        loadingPendingBalances = false;
        if (response.ok) {
            balances = data.balances;
        }
        if (!balances.length) {
            notificationState.actions.create(
                NotificationType.ERROR,
                $_("payouts.error_pending_payouts"),
                null,
                $_("payouts.reload_page")
            );
        }
    }

    function openPendingPayoutsReportModal() {
        showPendingPayoutsReportModal = true;
    }
</script>

{#if loadingPendingBalances || merchantName == undefined}
    <div class="gcard relative h-28">
        <LoadingWrapper loading={loadingPendingBalances} />
    </div>
{:else if balances.length}
    <div class="root gcard">
        <div class="header">
            <div class="left">
                <h1 class="page-title">
                    {$_("payouts.merchant_balance", { values: { merchantName } })}
                </h1>
                <p class="description">
                    {$_("payouts.description")}
                </p>
            </div>
            <div class="right" on:click|stopPropagation on:keyup>
                <Button
                    on:click={openPendingPayoutsReportModal}
                    size="sm"
                    variant="secondaryGray"
                    content={$_("payouts.download_balance_report")}
                    fullSize={true}
                />
            </div>
        </div>
        <div class="content">
            {#each balances as balance}
                <PayoutsProviderBalance
                    {showKredinorView}
                    fundingProviderName={balance.funding_provider_prettified}
                    periodStartDate={balance.period_start_date}
                    currency={balance.currency}
                    grossBalance={balance.gross_amount}
                    estimatedFees={balance.fee_amount}
                    netBalance={balance.net_amount}
                />
            {/each}
        </div>
    </div>
{/if}
<PendingPayoutsReportModal bind:show={showPendingPayoutsReportModal} {balances} {defaultCurrency} />

<style lang="scss">
    .root {
        padding: 20px 24px;
        .content {
            > :global(:not(:first-child)) {
                margin-block-start: 1rem;
            }
        }
    }
    .right {
        margin-inline-start: 100px;
    }

    :global(.right button) {
        white-space: nowrap;
    }

    .description {
        font-size: 0.875rem;
        line-height: 1.25;
    }
    .header {
        display: flex;
        justify-content: space-between;
        margin-block-end: 1.5rem;
    }
</style>
