<script lang="ts">
    import { createEventDispatcher } from "svelte";

    export let requiredStar: boolean = true;
    export let value: any = "";
    export let label: string = "";
    export let type: string = "";
    export let disabled: boolean = false;
    export let required: boolean = false;
    export let name: string = "";
    export let labelFont: string = "";

    let isFocus: boolean = false;
    let innerType: string = "password";

    export let id: string = "userinput";
    export let invalid: string = "";
    export let placeholder: string = "";

    const dispatch = createEventDispatcher();

    function handleChange(): any {
        if (value) {
            invalid = "";
        }
        dispatch("change", { value: value });
    }
    function topLabel(): any {
        isFocus = true;
    }
    function loseFocus(): any {
        // if (!value) {
        isFocus = false;
        // }
    }
    function changeType(): any {
        if (innerType === "text") {
            innerType = "password";
        } else if (innerType === "password") {
            innerType = "text";
        }
    }

    function handleKeyPress(event): any {
        if (event.key === "e") {
            event.preventDefault();
        }
    }
</script>

<label
    class="label text-sm text-gray-700 input-label"
    class:focus={isFocus}
    class:left-12={type === "email" || type === "password"}
    for={id}
    style="font-family: var({labelFont})"
>
    {label}
    {#if required && requiredStar}
        <span class="text-main">*</span>
    {/if}
</label>

<div
    class="relative flex w-full input_wrapper mt-1 rounded"
    class:border_focus={isFocus}
    class:incorrect={invalid ? true : false}
>
    {#if type === "email"}
        <!-- <img
      class="absolute ml-4 top-4 front-icon"
      src={`/pictures/envelope${isFocus ? "-color" : ""}.svg`}
      alt="email"
    /> -->
        <input
            type="email"
            bind:value
            {name}
            {placeholder}
            {id}
            class="w-full pl-4 border-none outline-none"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
        />
    {:else if type === "number"}
        <input
            type="number"
            bind:value
            {name}
            {placeholder}
            {id}
            class="w-full pl-4 border-none outline-none"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
        />
    {:else if type === "password"}
        <img
            class="absolute ml-4 top-4 front-icon"
            src={`/pictures/password${invalid ? "-incorrect" : isFocus ? "-color" : ""}.svg`}
            alt="password"
        />
        <!-- I found Great and bad and fucking solution -->
        {#if innerType === "text"}
            <input
                bind:value
                type="text"
                {name}
                {id}
                class="w-full pl-12 bg-white border-none outline-none"
                on:focus={topLabel}
                on:focusout={loseFocus}
                on:input={handleChange}
            />
        {:else if innerType === "password"}
            <input
                bind:value
                {name}
                type="password"
                {id}
                class="w-full pl-12 bg-white border-none outline-none"
                on:focus={topLabel}
                on:focusout={loseFocus}
                on:input={handleChange}
            />
        {/if}
        {#if value !== ""}
            <img
                class="mr-3 cursor-pointer front-icon"
                on:click={changeType}
                on:keyup
                src={innerType === "text" ? `/pictures/eye.svg` : `/pictures/eyeclose.svg`}
                alt="eye"
            />
        {/if}
    {:else if type === "text"}
        <input
            bind:value
            type="text"
            {placeholder}
            {name}
            {disabled}
            {id}
            class="w-full pl-4 bg-white border-none outline-none sm:pl-4"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:click
            autocomplete="off"
        />
    {:else if type === "number"}
        <input
            bind:value
            type="number"
            {name}
            {placeholder}
            {id}
            {disabled}
            class="w-full pl-4 bg-white border-none outline-none"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:keypress={handleKeyPress}
            autocomplete="off"
        />
    {:else if type === "textarea"}
        <textarea
            value={value?.streetAddress && value?.city && value?.postalCode
                ? `${value?.streetAddress}, ${value?.city}, ${value?.postalCode}`
                : ""}
            {placeholder}
            {name}
            {disabled}
            {id}
            rows="3"
            class="w-full p-4 bg-white border-none outline-none sm:pl-4"
            on:focus={topLabel}
            on:focusout={loseFocus}
            on:input={handleChange}
            on:click
            autocomplete="off"
        />
    {/if}
    {#if invalid}
        <p class="absolute text-sm font-medium error">
            {invalid}
        </p>
    {/if}
</div>

<style>
    .input_wrapper {
        border: 1px solid transparent;
        border-radius: 4px;
    }
    /* .focus {
    color: var(--bg-btn-primary);
  } */
    .error {
        top: calc(100% - 4px);
        font-size: 18px;
        background-color: #d11514;
        width: 100%;
        height: 40px;
        line-height: 40px;
        z-index: 10;
        color: white;
        margin: auto;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-left: 15px;
    }
    input,
    textarea {
        z-index: 1;
        border-radius: 4px;
        background-color: #cacaca;
        font-size: 1.5rem;
        line-height: 1.8rem;
        padding-right: 3.5rem;
        color: #150e0b;
    }
    input:disabled:not(#company),
    textarea:disabled {
        background: none;
        border: 1px solid #bcbbbb;
    }
    @media (max-height: 667px) {
        input,
        textarea {
            font-size: 1.18rem;
        }
    }
    input,
    input:placeholder-shown {
        height: 64px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .incorrect {
        border: none;
        margin-bottom: 40px;
    }
    .incorrect > input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .left-12 {
        left: 3rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
