<script lang="ts">
    import { _ } from "svelte-i18n";

    import Button from "../../Elements/Button.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import { actions as accountActions } from "@/store/merchant/account.store";
    import modalState from "@/store/modals.store";
    import { CREATE_UPDATE_USER_MERCHANT } from "@/static/modals";
    import {
        CREATE_USER_MERCHANT_SUCCESS,
        UPDATE_USER_MERCHANT_SUCCESS,
        DELETE_USER_MERCHANT_SUCCESS
    } from "../../../static/modals";

    export let closeModal;
    export let props: any = {};
    export let modalStatus;
</script>

<div
    class="h-full w-full flex flex-col justify-center items-center px-6 text-sm pt-10 pb-2"
    style="width:544px;"
>
    <Icon name="check-circle" fill="#039855" size={100} />
    {#if modalStatus === CREATE_USER_MERCHANT_SUCCESS}
        <h1 class="text-xl sm:text-2xl text-user-gray-800 font-bold mb-4">
            {$_("components.user.createSuccessTitle")}
        </h1>
        <p class="mb-12 px-6 text-gray-500 text-center">
            {@html $_("components.user.createSuccessMessage", {
                values: {
                    role: props.role,
                    first_name: props.first_name,
                    last_name: props.last_name,
                    phone_number: props.phone_number
                }
            })}
        </p>
    {:else if modalStatus === UPDATE_USER_MERCHANT_SUCCESS}
        <h1 class="text-xl sm:text-2xl text-user-gray-800 font-bold mb-4">
            {$_("components.user.updateSuccessTitle")}
        </h1>
        <p class="mb-12 px-6 text-gray-500 text-center">
            {@html $_("components.user.updateSuccessMessage", {
                values: {
                    role: props.role,
                    first_name: props.first_name,
                    last_name: props.last_name
                }
            })}
        </p>
    {:else if modalStatus === DELETE_USER_MERCHANT_SUCCESS}
        <h1 class="text-xl sm:text-2xl text-user-gray-800 font-bold mb-4">
            {$_("components.user.deleteSuccessTitle")}
        </h1>
        <p class="mb-12 px-6 text-gray-500 text-center">
            {@html $_("components.user.deleteSuccessMessage", {
                values: {
                    role: props.role,
                    first_name: props.first_name,
                    last_name: props.last_name
                }
            })}
        </p>
    {/if}

    <Button
        bgColor="var(--primary-700)"
        paddingX="60px"
        label={$_("components.close")}
        on:click={() => {
            closeModal();
            accountActions.getTeam();
        }}
    />
    {#if modalStatus === CREATE_USER_MERCHANT_SUCCESS}
        <div class="mt-2 mb-5">
            <Button
                color="#667085"
                bgColor="#fff"
                hoverBg="#fff"
                label={$_("components.user.addAnotherUserButton")}
                on:click={() => {
                    closeModal();
                    modalState.actions.setModal(CREATE_UPDATE_USER_MERCHANT, {});
                }}
            />
        </div>
    {/if}
</div>
