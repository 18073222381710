<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";
    import type { Unsubscriber } from "svelte/store";

    import { objectSort, SORT_TYPE } from "../../../utils/functions";
    import TeamTable from "../../../components/table/Table.svelte";
    import type { IAccount, ITeamUser } from "../../../static/types";
    import {
        actions as accountActions,
        account,
        teamMembers,
        loggedInAsMerchant
    } from "../../../store/merchant/account.store";
    import { CREATE_UPDATE_USER_MERCHANT, DELETE_USER_MERCHANT } from "../../../static/modals";

    const numRowsPerPage = 100;
    const NAME = "Name";
    const EMAIL_ADDRESS = "Email address";
    const PHONE_NUMBER = "Phone number";
    const ROLE = "Role";
    const NAME_SORT = "firstName|lastName";
    const EMAIL_ADDRESS_SORT = "email";
    const PHONE_NUMBER_SORT = "phoneNumber";
    const ROLE_SORT = "role";
    const WIDE_CUSTOMER_DETAILS = "wide-customer-details";
    const tableHeaders = [
        {
            title: NAME,
            translation: $_("account.team.name"),
            type: WIDE_CUSTOMER_DETAILS,
            clickable: true
        },
        //{ title: TEAM, type: WIDE_CUSTOMER_DETAILS, clickable: true },  DL: To be reinstated once we agree on layout for this new column. Also see line 111.
        {
            title: EMAIL_ADDRESS,
            translation: $_("account.team.emailAddress"),
            type: WIDE_CUSTOMER_DETAILS,
            clickable: true
        },
        {
            title: PHONE_NUMBER,
            translation: $_("account.team.phoneNumber"),
            type: WIDE_CUSTOMER_DETAILS,
            clickable: true
        },
        {
            title: ROLE,
            translation: $_("account.team.role"),
            type: WIDE_CUSTOMER_DETAILS,
            clickable: true
        },
        { title: "Edit", translation: $_("account.team.edit"), clickable: false, notext: true },
        { title: "Delete", translation: $_("account.team.delete"), clickable: false, notext: true }
    ];
    let teamRows = [];
    let rawRows = [];
    let teamUnsubscribe: Unsubscriber;
    let userId = null;
    let userRole = null;
    const loading = false;
    const currentSortMap = {};
    const INITIAL_SORT_COLUMN_NAME = NAME;
    const roleMap = {
        ADMIN: {
            name: $_("account.team.admin"),
            textColor: "var(--primary-600, var(--primary-600))",
            bgColor: "var(--primary-100, #C7D7FE)"
        },
        DEVELOPER: {
            name: $_("account.team.developer"),
            textColor: "var(--blue-gray-600, #3E4784)",
            bgColor: "var(--blue-gray-100, #EAECF5)"
        },
        ACCOUNTANT: {
            name: $_("account.team.accountant"),
            textColor: "var(--secondary-600, #0086C9)",
            bgColor: "var(--secondary-50, #F0F9FF)"
        },
        USER: {
            name: $_("account.team.user"),
            textColor: "var(--primary-700, var(--primary-700))",
            bgColor: "var(--primary-200, #A4BCFD)"
        }
    };

    $: if ($loggedInAsMerchant) accountActions.getTeam();

    onMount(() => {
        currentSortMap[NAME_SORT] = "down";
        currentSortMap[EMAIL_ADDRESS_SORT] = "down";
        currentSortMap[PHONE_NUMBER_SORT] = "down";
        currentSortMap[ROLE_SORT] = "down";
        teamUnsubscribe = teamMembers.subscribe((value: ITeamUser[]) => {
            if (value) {
                // initial sort name
                currentSortMap[NAME_SORT] = "down";
                setInitialData(value, NAME_SORT);
            }
        });
        account.subscribe((value: IAccount) => {
            userId = value.merchant_user?.id;
            userRole = value.admin_user ? "ADMIN" : value.merchant_user?.role;
        });
    });

    onDestroy(() => {
        teamUnsubscribe();
    });

    function constructTeamRows(data: ITeamUser[]) {
        const row_list = [];
        for (const member of data) {
            const currentUserData = {
                id: member.id || $account.application_id,
                first_name: member.firstName,
                last_name: member.lastName,
                email: member.email,
                phone_number_with_code: member.phoneNumber,
                role: member.role,
                team: member.team
            };
            const displayName = [member.firstName, member.lastName]
                .filter((el) => {
                    return el != null;
                })
                .join(" ")
                .trim();
            const row = {
                ID: member.id,
                Name: {
                    tableItemBold: displayName || "-"
                },
                // Team: {  DL: To be reinstated once we agree on layout for this new column. Also see line 27.
                //   tableItemBold: member.team || "-"
                // },
                "Email address": {
                    tableItemBold: member.email || "-"
                },
                "Phone number": {
                    tableItemBold: member.phoneNumber || "-"
                },
                Role: {
                    tableItemBold: member.role ? roleMap[member.role].name : "-"
                },
                Edit: {
                    tableItem: $_("components.edit"),
                    actionModal: CREATE_UPDATE_USER_MERCHANT,
                    actionProps: Object.assign({}, currentUserData, {
                        submit_user: accountActions.updateUser
                    }),
                    itemTextColor: "var(--primary-700)"
                },
                Delete: {
                    tableItem: $_("components.remove"),
                    actionModal: DELETE_USER_MERCHANT,
                    actionProps: Object.assign({}, currentUserData, {
                        submit_user: accountActions.deleteUser
                    }),
                    itemTextColor: "#B42318"
                }
            };
            if (userId == member.id) {
                row.Delete.hidden = true;
            }
            if (userRole != "ADMIN") {
                row.Delete.hidden = true;
                if (!userId || userId != member.id) {
                    row.Edit.hidden = true;
                }
            } else {
                if (!displayName) {
                    row.Name = {
                        tableItem: "+ " + $_("account.team.addName"),
                        actionModal: CREATE_UPDATE_USER_MERCHANT,
                        actionProps: Object.assign({}, currentUserData, {
                            submit_user: accountActions.updateUser
                        }),
                        itemTextColor: "var(--primary-700)"
                    };
                }
                if (!member.email) {
                    row["Email address"] = {
                        tableItem: "+ " + $_("account.team.addEmail"),
                        actionModal: CREATE_UPDATE_USER_MERCHANT,
                        actionProps: Object.assign({}, currentUserData, {
                            submit_user: accountActions.updateUser
                        }),
                        itemTextColor: "var(--primary-700)"
                    };
                }
            }
            row_list.push(row);
        }
        return row_list;
    }

    function putCurrentUserFirst() {
        if (userId) {
            const currentUserRow = rawRows.find((item) => item.id === userId);
            if (currentUserRow) {
                rawRows = rawRows.filter((item) => item.id !== userId);
                rawRows.unshift(currentUserRow);
            }
        }
        return rawRows;
    }

    function setInitialData(rows: any[], sortProperties) {
        if (rows && rows.length) {
            const sortType = SORT_TYPE.string;
            const ascending: boolean = currentSortMap[sortProperties] === "up";
            rawRows = rows;
            for (const sortProperty of sortProperties.split("|").reverse()) {
                rawRows = objectSort(rawRows, sortProperty, ascending, sortType);
            }
            putCurrentUserFirst();
            teamRows = constructTeamRows(rawRows);
        }
    }

    function handleSortToggle(sortProperties: string) {
        const sortType = SORT_TYPE.string;
        currentSortMap[sortProperties] = currentSortMap[sortProperties] === "down" ? "up" : "down";
        const ascending: boolean = currentSortMap[sortProperties] === "up";

        for (const sortProperty of sortProperties.split("|").reverse()) {
            rawRows = objectSort(rawRows, sortProperty, ascending, sortType);
        }
        putCurrentUserFirst();
        teamRows = constructTeamRows(rawRows);
    }

    function handleSort(event) {
        const sortItem = event.detail.sortItem;
        let sortProperties: string;
        switch (sortItem) {
            case NAME:
                sortProperties = NAME_SORT;
                break;
            case EMAIL_ADDRESS:
                sortProperties = EMAIL_ADDRESS_SORT;
                break;
            case PHONE_NUMBER:
                sortProperties = PHONE_NUMBER_SORT;
                break;
            case ROLE:
                sortProperties = ROLE_SORT;
                break;
            default:
                ascending = false;
        }

        handleSortToggle(sortProperties);
    }
</script>

<div class="rounded-lg bg-white mx-8 mt-6 mb-8 team-summary">
    <div class="flex items-center justify-between mx-8 pt-4">
        <div class="flex max-w-7xl team-page-title">
            <p class="text-lg font-bold">{$_("account.team.title")}</p>
        </div>
    </div>
    <div class="team-members-container">
        <TeamTable
            numRows={numRowsPerPage}
            numAllRecords={teamRows.length}
            {tableHeaders}
            tableRows={teamRows}
            useBackendPagination={false}
            {loading}
            currentSort={INITIAL_SORT_COLUMN_NAME}
            on:sorting={handleSort}
        />

        {#if !loading && !teamRows.length}
            <div class="w-full h-48 flex flex-col items-center pt-8">
                <img src="/pictures/mailboxstate.svg" alt="mailbox" />
                <p class="my-4 font-semibold text-black-dark text-center">
                    {$_("account.team.noTeamMembers")}
                </p>
            </div>
        {/if}
    </div>
</div>

<style>
    .team-page-title {
        margin: 0 0 8px 0;
    }
    .team-members-container {
        display: flex;
        flex-direction: column;
    }
    .team-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
    }
</style>
