import { titleCase } from "../../../../../utilsV2/helpers";
import { BASE_URL, DOWNLOAD_GROUPED_STATEMENT_PDF } from "../../../../../static/endPoints";
import type {
    CurrencyCodeEnum,
    IBillingStatement,
    IPeriodStatement
} from "../../../../../static/types";
import utils from "../../../../../utilsV2/currency";
import {
    IIconLink,
    ITag,
    paymentStatusTextColors,
    determinePaymentStatusNoPartialPayment,
    paymentStatusTagColors
} from "../../../../../utilsV2/tableHelpers";
import { get_statement_type_from_creation_reason } from "../../../../../static/billing";

export interface IStatementObject {
    "Issue date": {
        tableItem: string;
        subItem: string;
        subItemTextColor: string;
    };
    "Statement №": {
        tableItem: number;
        subItem: string;
        subItemTextColor: string;
        itemBgColor: string;
    };
    "Customer details": {
        tableItemBold: string;
        subItem: string;
    };
    Amount: {
        tableItemBold: string;
        subItem: string;
    };
    Tags: {
        tags: ITag[];
    };
    Download: {
        icons: IIconLink[];
    };
    billingAccountId: string;
    periodId: string;
}

const isStatementOverDue = (dueDate: string | number | Date) => {
    return new Date(dueDate) < new Date();
};

export function buildStatementsRows(statements: IBillingStatement[]): IStatementObject[] {
    const tableRows: IStatementObject[] = [];
    for (const statement of statements) {
        const paymentStatus = determinePaymentStatusNoPartialPayment(statement.unreconciled_amount);
        const rowItem: IStatementObject = {
            billingAccountId: statement.billing_account_id,
            periodId: statement.billing_period_id,
            "Customer details": {
                tableItemBold: statement.buyer_company_name,
                subItem: titleCase(statement.trade_names || []).join(", ")
            },
            "Statement №": {
                tableItem: statement.statement_number,
                subItem: paymentStatus,
                subItemTextColor: paymentStatusTextColors[paymentStatus],
                itemBgColor: paymentStatusTagColors[paymentStatus]
            },
            "Issue date": {
                tableItem: new Date(statement.issue_date).toLocaleDateString(),
                subItem: `Due: ${new Date(statement.due_date).toLocaleDateString()}`,
                subItemTextColor:
                    Number(statement.unreconciled_amount) > 0 &&
                    isStatementOverDue(statement.due_date)
                        ? "var(--error-600)"
                        : ""
            },
            Amount: {
                tableItemBold: `${utils.formatCurrency(statement.currency)(
                    statement.total_amount
                )}`,
                subItem: `${utils.formatCurrency(statement.currency)(
                    statement.unreconciled_amount
                )} remaining`
            },
            Tags: {
                tags: [getDownloadedTag(statement.last_downloaded)]
            },
            Download: {
                icons: [
                    {
                        description: "downloadStatement",
                        iconSource: "/pictures/download-statement.svg",
                        link: `${BASE_URL}${DOWNLOAD_GROUPED_STATEMENT_PDF}?statement_id=${statement.statement_id}&statement_type=${statement.statement_type}`
                    }
                ]
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

export function buildStatementSummaryRows(
    statements: IPeriodStatement[],
    currency: CurrencyCodeEnum
) {
    const tableRows: object[] = [];
    for (const statement of statements) {
        const rowItem = {
            Number: {
                tableItem: statement.statement_reference
            },
            "Date issued": {
                tableItem: new Date(statement.issue_date).toLocaleDateString()
            },
            Type: {
                tableItem: get_statement_type_from_creation_reason(
                    statement.statement_creation_reason
                )
            },
            "Statement total": {
                tableItem: `${utils.formatCurrency(currency)(statement.total_amount)}`
            },
            "Downloaded?": {
                tableItem: "",
                iconSrc: statement.last_downloaded
                    ? "/pictures/check_circle.svg"
                    : "/pictures/clock-warning.svg"
            },
            Download: {
                textLink: {
                    text: "Download",
                    link: `${BASE_URL}${DOWNLOAD_GROUPED_STATEMENT_PDF}?statement_id=${statement.statement_id}`
                }
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

function getDownloadedTag(downloadedDate: Date | null) {
    if (downloadedDate) {
        return <ITag>{
            text: "Downloaded",
            textColor: "var(--success-700)",
            bgColor: "var(--success-50)"
        };
    }
    return <ITag>{
        text: "Not Downloaded",
        textColor: "var(--warning-700)",
        bgColor: "var(--warning-50)"
    };
}
