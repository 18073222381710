<script lang="ts">
    import { _ } from "svelte-i18n";
    import { afterUpdate } from "svelte";
    import Chart from "chart.js/auto";
    import { type ChartTypeRegistry } from "chart.js";

    import { htmlLegendPlugin } from "./custom-legend";

    export let title = "";
    export let hoverStrings = [""];
    export let label = [];
    export let lineData = [];
    export let yAxisTitle = "";
    export let colours = [];
    export let id = "myChart";
    export let type: keyof ChartTypeRegistry = "bar";
    export let showCustomLegend = true;
    export let showDefaultLegend = false;
    export let yTickModifier = (value) => Number(value).toLocaleString();

    afterUpdate(async () => {
        const ctxDom = document.getElementById(id) as HTMLCanvasElement;
        if (ctxDom) {
            const ctx = ctxDom.getContext("2d");
            new Chart(ctx, {
                type,
                data: {
                    datasets: lineData?.map((dataList, index) => {
                        return {
                            label: hoverStrings[index],
                            data: dataList,
                            type,
                            fill: false,
                            backgroundColor: colours[index],
                            pointBackgroundColor: colours[index],
                            borderColor: colours[index],
                            pointBorderColor: colours[index],
                            borderRadius: 4
                        };
                    }),
                    labels: label
                },
                plugins: showCustomLegend ? [htmlLegendPlugin] : [],
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    elements: {
                        line: {
                            tension: 0.000001
                        }
                    },
                    plugins: {
                        // @ts-ignore
                        htmlLegend: {
                            // ID of the container to put the legend in
                            containerID: `${id}-legend-container`
                        },
                        legend: {
                            display: showDefaultLegend,
                            align: "end",
                            labels: {
                                usePointStyle: true,
                                pointStyle: "circle",
                                padding: 10,
                                boxWidth: 12,
                                boxHeight: 12
                            }
                        },
                        tooltip: {
                            callbacks: {
                                afterLabel({ dataIndex }) {
                                    if (!lineData || lineData.length < 2) return null;
                                    const totalValue = lineData.reduce(
                                        (acc, item) => (acc += item[dataIndex]),
                                        0
                                    );
                                    return `${$_("lineItem.totalLabel")} ${new Intl.NumberFormat(
                                        "en-US"
                                    ).format(totalValue)}`;
                                }
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        y: {
                            title: {
                                display: true,
                                text: yAxisTitle
                            },
                            display: true,
                            stacked: true,
                            ticks: {
                                callback(value) {
                                    return yTickModifier(value);
                                }
                            },
                            grid: {
                                color: "#eef4ff"
                            }
                        },
                        x: {
                            display: true,
                            stacked: true,
                            grid: {
                                display: false
                            }
                        }
                    }
                }
            });
        }
    });
</script>

<div class="chartWrapper">
    <div id={`${id}-legend-container`}></div>
    <canvas class={`${title} h-72`} {id} />
</div>

<style>
    .chartWrapper {
        width: calc(100% - 24px);
        height: 400px;
    }
</style>
