<script lang="ts">
    import { _ } from "svelte-i18n";

    import InputBox from "../Elements/InputBox.svelte";
    import type { ICustomer } from "../../static/types";
    import { toLongNumberString } from "../../utils/functions";
    import Button from "../Elements/Button.svelte";
    import modalState from "../../store/modals.store";
    import CloseButton from "./CloseButton.svelte";
    import { CONFIRM_EDIT_CREDIT } from "../../static/modals";

    interface Props extends ICustomer {
        newValue: string;
    }
    export let props: Props;

    let newLimit: string = props.newValue;
    let invalid: string = "";

    function handleClose() {
        modalState.actions.setModal(null, {});
    }

    function onSubmit(e) {
        e.preventDefault();
        if (!newLimit) invalid = $_("modals.invalidNewLimitMessage");
        modalState.actions.setModal(CONFIRM_EDIT_CREDIT, {
            ...props,
            newValue: newLimit
        });
    }
</script>

<form on:submit={onSubmit} class="p-10 relative w-full max-w-md sm:w-xsm">
    <p class="text-2xl font-bold">
        {$_("modals.editCreditTitle", { values: { buyerCompanyName: props.buyer_company_name } })}
    </p>
    <div class="absolute top-6 right-6">
        <CloseButton on:click={handleClose} />
    </div>

    <div class="mt-9">
        <InputBox
            label={$_("modals.currentCreditLimitLabel")}
            type="text"
            disabled={true}
            value={(props.unit || "") + " " + (toLongNumberString(props.limit) === "NaN" || "")}
        />
    </div>
    <div class="mt-6">
        <InputBox
            type="number"
            label={$_("modals.newCreditLimitLabel")}
            bind:value={newLimit}
            bind:invalid
        />
    </div>
    <div class="mt-12 w-full">
        <Button
            label={$_("modals.updateCreditLimitButton")}
            size="large"
            bgColor={!newLimit ? "#EDEDF3" : "var(--primary-dark)"}
            hoverBg={!newLimit ? "#EDEDF3" : "var(--primary)"}
            color={!newLimit ? "var(--gray-400)" : "white"}
            hoverColor={!newLimit ? "var(--gray-400)" : "white"}
            disable={!newLimit ? true : false}
            on:click
        />
    </div>
</form>
