<script lang="ts">
    import { _ } from "svelte-i18n";

    import DatePicker from "../Elements/DatePicker.svelte";
    import Selector from "../../components/Elements/Selector.svelte";
    import Button from "../Elements/Button.svelte";
    import modalState from "../../store/modals.store";
    import { DateToFormat, getBaseUrl } from "../../utils/functions";
    import { account, signupComplete } from "../../store/merchant/account.store";
    import { GET_STATEMENT_DOWNLOAD } from "../../static/endPoints";
    import SignupIncompleteModal from "../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import Modal from "../Elements/Modal.svelte";

    export let show: boolean;
    const today = new Date();
    const fileFormats: string[] = ["csv", "xlsx"];
    let fileFormatSelected: string = fileFormats[0];
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let startDate = firstDayOfMonth;
    let endDate = today;
    let errorMsg: string;
    let isLegacyUI: boolean = false;
    // BE has "NO" as fallback if no argument is passed for country_code param
    // Setting it to "ALL" ensures that merchant gets correct data regardless of country
    // provided account.CountryCode is not undefined
    let countryCode = "ALL";

    $: if ($account.countryCode) {
        isLegacyUI = $account.flags.legacy_settlement_ui_portal;
        countryCode = $account.countryCode.toUpperCase();
    }

    function handleClose() {
        show = false;
        resetInputs();
    }

    function resetInputs() {
        startDate = firstDayOfMonth;
        endDate = today;
        errorMsg = "";
        fileFormatSelected = fileFormats[0];
    }

    function handleStartDateChange(d: CustomEvent) {
        startDate = d.detail;
        validateDates();
    }
    function handleEndDateChange(d: CustomEvent) {
        endDate = d.detail;
        validateDates();
    }

    function validateDates() {
        if (startDate > endDate) {
            errorMsg = $_("settlements.errorFromAfterTo");
        } else if (startDate > today || endDate > today) {
            errorMsg = $_("settlements.errorFutureDates");
        } else {
            errorMsg = "";
        }
    }

    function generateStatement() {
        const query =
            "?" +
            new URLSearchParams({
                country_code: countryCode,
                start_date: DateToFormat(startDate),
                end_date: DateToFormat(endDate),
                filetype: fileFormatSelected,
                legacy: isLegacyUI.toString()
            }).toString();
        window.location.href = `${getBaseUrl()}${GET_STATEMENT_DOWNLOAD}${query}`;
    }

    function downloadReport() {
        if ($signupComplete) {
            generateStatement();
            handleClose();
        } else {
            handleClose();
            modalState.actions.setModal("", {}, SignupIncompleteModal);
        }
    }
</script>

<Modal title={$_("settlements.modalTitle")} bind:show on:close={resetInputs}>
    <p class="description">
        {$_("settlements.description")}
    </p>
    <div class="date-input-wrapper">
        <DatePicker
            label="From"
            on:datechange={handleStartDateChange}
            selectedDate={startDate}
            helperText={$_("settlements.dateHelperText")}
        />
        <DatePicker
            label="To"
            on:datechange={handleEndDateChange}
            selectedDate={endDate}
            helperText={$_("settlements.dateHelperText")}
        />
    </div>
    {#if errorMsg}
        <div class="error">
            {errorMsg}
        </div>
    {/if}
    <Selector
        label={$_("settlements.fileFormatLabel")}
        backgroundColor="white"
        options={fileFormats}
        showArrow={true}
        bind:value={fileFormatSelected}
    />
    <div class="buttons" slot="footer">
        <button class="button-secondary-gray button-sm cancel" on:click={handleClose}>
            {$_("components.close")}
        </button>
        <Button
            label={$_("settlements.downloadButton")}
            paddingX="16px"
            size="medium"
            on:click={downloadReport}
            disable={!!errorMsg}
        />
    </div>
</Modal>

<style lang="scss">
    * {
        box-sizing: border-box;
    }

    .description {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        color: var(--gray-500);
        margin-block-end: 1.7rem;
    }

    .date-input-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 8px;
        margin-block-end: 1.5rem;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        margin-block-start: 3rem;
    }

    .cancel {
        margin-inline-end: 8px;
    }

    .error {
        font-size: 0.875rem;
        line-height: 1.25;
        color: #ef4444;
        margin-block-end: 1rem;
        text-align: center;
    }
</style>
