<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.99996 6.66699V10.0003M9.99996 13.3337H10.0083M18.3333 10.0003C18.3333 14.6027 14.6023 18.3337 9.99996 18.3337C5.39759 18.3337 1.66663 14.6027 1.66663 10.0003C1.66663 5.39795 5.39759 1.66699 9.99996 1.66699C14.6023 1.66699 18.3333 5.39795 18.3333 10.0003Z"
        stroke={fill}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
