<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import notificationState from "../../store/notifications.store";
    import { NotificationType } from "../../static/types";
    const dispatch = createEventDispatcher();

    export let logoPath: string = "";
    export let label: string = "";
    export let name: string = "";
    export let successUpload: boolean = false;
    export let isEditing: boolean = false;
    export let editOngoing: boolean = false;

    let imageName: string = "";
    let base64image: any;
    let uploadImageInput: HTMLInputElement = null;
    let newLogoPath: string = "";

    function getBase64(file: any) {
        return new Promise((resolve) => {
            let baseURL: any = "";
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    }

    function uploadImage() {
        uploadImageInput.click();
        isEditing = true;
    }

    function onImageChange(e: any) {
        const file = e.target.files[0];
        imageName = file.name;

        if (file.size / 1024 / 1024 > 4) {
            notificationState.actions.create(
                NotificationType.ERROR,
                $_("errors.imageLessThan4MB"),
                name
            );
            isEditing = false;
            return;
        }
        if (!["png", "svg"].some((file_type) => file.type.toLowerCase().includes(file_type))) {
            notificationState.actions.create(
                NotificationType.ERROR,
                $_("errors.imageSvgOrPng"),
                name
            );
            isEditing = false;
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(file); // read file as data url

        reader.onload = (event) => {
            // called once readAsDataURL is completed
            newLogoPath = event.target.result;
        };

        getBase64(file).then((result) => {
            base64image = result;
            file.base64 = result;
            const data = {
                logo_file_name: imageName,
                logo_base64: base64image.split("base64,")[1]
            };
            isEditing = false;
            dispatch("imageChanged", data);
        });
        isEditing = false;
    }

    function handleEditOngoing(isEditing: boolean) {
        editOngoing = isEditing;
        dispatch("edit", { isEditing });
    }

    $: handleEditOngoing(isEditing);

    $: if (successUpload) {
        logoPath = newLogoPath;
        successUpload = false;
    }

    function onClose() {
        isEditing = false;
    }
</script>

<div style="display:flex;justify-content:space-between;gap:1rem">
    <div class:disable={editOngoing}>
        <p class="label">{label}</p>
        <div
            class="h-16 rounded-lg bg-white flex items-center justify-center border border-user-gray-200 relative"
        >
            {#if logoPath}
                <button
                    class="h-full w-full flex items-center justify-center rounded-lg overflow-hidden company-logo"
                    on:click={() => uploadImage()}><img src={`${logoPath}`} alt="logo" /></button
                >
            {:else}
                <button class="flex" on:click={uploadImage}>
                    <img src="/pictures/upload.svg" alt="upload" />
                </button>
            {/if}
            <input
                bind:this={uploadImageInput}
                on:change={onImageChange}
                on:abort={onClose}
                on:close={onClose}
                on:cancel={onClose}
                class="hidden"
                type="file"
                accept="image/*"
            />
        </div>
    </div>
    <button
        class="button-lg button-tertiary"
        disabled={isEditing || editOngoing}
        on:click={uploadImage}
    >
        {logoPath ? $_("components.update") : $_("components.add")}
        {#if !isEditing}
            <span class="tooltip-white">{$_("components.logoImageInstruction")}</span>
        {/if}
    </button>
</div>

<style lang="scss">
    .company-logo {
        height: 62px;
        width: 104px;
        min-height: 60px;
        min-width: 60px;
    }

    button {
        position: relative;

        &:hover {
            .tooltip-white {
                visibility: visible;
            }
        }

        .tooltip-white {
            visibility: hidden;
            min-width: 175px;
            top: 50px;
            left: -90px;
        }
    }
    div.disable {
        color: var(--gray-400);
    }
</style>
