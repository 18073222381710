<script lang="ts">
    let codeLength: number = 6;
    let arr: number[] = new Array(codeLength).fill(1);
    let inputArr: HTMLInputElement[] = new Array(codeLength);
    export let isValidPhone: boolean = false;
    export let isValidEmail: boolean = false;
    export let label: string = "";
    export let labelFont: string = "";
    export let id: string = "userinput";

    export let verifyMethod = "email";

    $: isValidContact =
        (verifyMethod === "email" && isValidEmail) || (verifyMethod === "sms" && isValidPhone);

    export let code: string[] = new Array(6).fill("");
    export let error: string = "";

    function handleKeyDown(event, i) {
        if (i === 0) return inputArr[0].focus();
        if (!event.target.value && event.key === "Backspace") {
            inputArr[i - 1].focus();
        }
    }

    let focusStop: boolean = false;
    $: if (isValidContact) {
        if (!focusStop) {
            setTimeout(() => {
                inputArr[0].focus();
                focusStop = true;
            }, 200);
        }
    }

    function handleInput(event, i) {
        let value = event.target.value;
        if (event.inputType === "deleteContentBackward") {
            return;
        }
        if (value.length >= codeLength) {
            for (let i = 0; i < codeLength; i++) {
                inputArr[i].value = value[i];
            }
            inputArr[codeLength - 1].focus();
            for (let i = 0; i < codeLength; i++) {
                code[i] = value[i];
            }
            code[codeLength] = "";
            return;
        } else {
            inputArr[i].value = value[value.length - 1];
            code[i] = value[value.length - 1];
        }
        if (i + 1 < codeLength) inputArr[i + 1].focus();
        return;
    }
</script>

{#if label}
    <label
        style="font-family: var({labelFont})"
        class="label text-sm text-gray-700 input-label"
        for={id}
    >
        {label}
    </label>
{/if}

<div class="field-container">
    <div class="flex justify-between w-full pincode my-2">
        {#each arr as _, i}
            <div
                class="relative text-2xl w-16 h-16 p-1 rounded itemCode"
                class:pl-0={i == 0}
                class:pr-0={i == arr.length - 1}
            >
                <input
                    type="number"
                    disabled={!isValidContact}
                    class="text-center"
                    placeholder=""
                    pattern="[0-9]*"
                    bind:this={inputArr[i]}
                    bind:value={code[i]}
                    on:input={(e) => handleInput(e, i)}
                    on:keydown={(e) => handleKeyDown(e, i)}
                    autocomplete="one-time-code"
                    {id}
                />
            </div>
        {/each}
    </div>
    {#if error.length}
        <div class="absolute text-sm font-medium error">
            {error}
        </div>
    {/if}
</div>

<style>
    .error {
        font-size: 18px;
        background-color: #d11514;
        width: 100%;
        max-width: 400px;
        height: 40px;
        line-height: 40px;
        z-index: 10;
        color: white;
        margin: auto;
        border-radius: 4px;
        padding-left: 15px;
    }
    /* .activator {
    height: 2px;
  }
  .valid {
    background-color: var(--auth-primary);
  }
  .invalid {
    background-color: red;
  } */
    /* .itemCode {
    border: solid 1px #938da9 !important;
  } */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .field-container {
        width: 100%;
        max-width: 400px;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
        background-color: #cacaca;
        font-size: 1.5rem !important;
    }
</style>
