import { writable } from "svelte/store";
import { type INotification, NotificationType } from "../static/types";
import { v4 as uuidv4 } from "uuid";

function createNotification() {
    const notification = writable<INotification[]>([]);

    return {
        notification,
        actions: {
            create: (
                type: NotificationType,
                message: string,
                displayWithItem: string = null,
                extraInfo: string = null,
                tryAgainActionModal: string = null,
                tryAgainActionProps: object = null
            ) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                const id = uuidv4() as string;
                notification.update((notifications) => [
                    ...notifications,
                    {
                        id,
                        type,
                        message,
                        displayWithItem,
                        extraInfo,
                        tryAgainActionModal,
                        tryAgainActionProps
                    }
                ]);
            },

            delete: (id: string) => {
                notification.update((notifications) => {
                    return notifications.filter((item: INotification) => {
                        if (item.id !== id) {
                            return item;
                        }
                    });
                });
            }
        }
    };
}

const notificationState = createNotification();
export default notificationState;
