export const TWO_STATUS = "https://status.two.inc";
export const MERCHANT_FAQ =
    "https://support.two.inc/hc/en-us/categories/18513411706013-I-m-a-Seller";
export const BUYER_FAQ = "https://support.two.inc/hc/en-us/articles/19682750316189-What-is-Two";
export const PORTAL_TOUR =
    "https://support.two.inc/hc/en-us/articles/18461183525277-Merchant-Portal-Tour";
export const PAYMENT_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461412689821-How-and-when-do-I-get-paid";
export const ORDER_CREATE_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461014765597-Order-Creator";
export const ORDER_STATUS_FAQ = "https://support.two.inc/hc/en-us/articles/18461080480413-Orders";
export const CREDIT_RISK_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461365745309-Credit-risk";
export const PAYMENT_TERMS_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461446064285-Payment-terms";
export const CUSTOMER_RECOURSE_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461646914717-Recourse";
export const CUSTOMER_TAB_FAQ =
    "https://support.two.inc/hc/en-us/articles/18460978647069-Learn-how-the-Customer-Tab-works";
export const CREDIT_CHECK_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461177111453-Credit-Checks";
export const DUNNING_COLLECTION_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461324016797-Dunnings-Collections";
export const ACCOUNT_SETTING_FAQ =
    "https://support.two.inc/hc/en-us/articles/18460876010653-Account-Settings";
export const FRAUD_CHECK_FAQ =
    "https://support.two.inc/hc/en-us/articles/18461500967581-Fraud-checks";
export const SETTLEMENT_FAQ =
    "https://support.two.inc/hc/en-us/articles/18460924131485-Settlement-reports";
export const BUYER_INVOICE_FAQ =
    "https://support.two.inc/hc/en-us/articles/18445353631773-How-do-I-pay-my-invoice";
export const BUYER_REFUND_FAQ =
    "https://support.two.inc/hc/en-us/articles/19131510535325-I-need-my-order-refunded-what-can-I-do";
