<script lang="ts">
    export let fill: string = "#26E6A6";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00014 12.8002C10.6512 12.8002 12.8002 10.6515 12.8002 8.0002C12.8002 5.34893 10.6512 3.2002 8.00014 3.2002C5.34913 3.2002 3.2002 5.34893 3.2002 8.0002C3.2002 10.6515 5.34913 12.8002 8.00014 12.8002ZM10.4013 6.78589L7.26513 9.9227L5.59927 8.25683L6.27035 7.58619L7.26513 8.57605L9.73037 6.11525L10.4013 6.78589Z"
        {fill}
    />
</svg>
