<script lang="ts">
    import { _ } from "svelte-i18n";

    import onboardingRoutes from "../../routes/onboarding.routes";
    import Router from "../../routes/CustomRouter.svelte";
    import b2bonboardingState from "../../store/b2bonboarding.store";
    import type { MerchantB2bOnboardingData } from "../../static/types";

    let title = "Business customer onboarding";

    let merchantDetails: MerchantB2bOnboardingData = {};

    $: b2bonboardingState.merchantOnboardingData.subscribe(
        (data: MerchantB2bOnboardingData) => (merchantDetails = data)
    );
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<div class="h-screen bg-white">
    <div class="z-10 flex flex-row flex-wrap justify-between items-center py-5 px-5 header">
        <div class="flex text-right flex-col pt-3">
            <h1 class="text-2xl yellow">{@html $_("b2bSignup.layoutTitle")}</h1>
        </div>
        <div class="flex flex-row items-end">
            <div class="pr-2 m-auto">{$_("b2bSignup.tradeAccountsPoweredBy")}</div>
            <img
                class="cursor-pointer mb-2"
                src="/pictures/logos/logo-darkgray.png"
                alt="logo"
                width="60"
            />
        </div>
    </div>
    <div class="flex flex-col justify-center w-full pt-0 m-5">
        <Router routes={onboardingRoutes} />
    </div>
</div>

<style>
    .header {
        width: 90%;
        margin: auto;
        border-bottom: 1px solid lightgray;
    }

    .yellow {
        color: #ffc107;
    }
</style>
