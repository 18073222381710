<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";

    import { searchApi } from "../../services/apiService";
    import Input from "../Elements/InputBoxDark.svelte";
    import Icon from "../icons/Icon.svelte";
    import { getCompanySearchUrl } from "@/utils/functions";
    import helpers from "../../utilsV2/helpers";

    export let country: any = {};
    export let error: string = "";
    export let isChecked: boolean = false;
    export let invalid: string = "";
    export let currentOrg: any = {};
    export let companyID: string = "";
    export let address: any = {};
    export let showId: boolean = true;
    export let required: boolean = true;
    export let company: string = "";
    export let isGettingAddress: boolean = true;
    export let labelFont: string = "";

    let isShow: boolean = false;
    let orgs: any[] = [];

    // Html element handling
    let inputCom: any = null;
    let dropDown: any = null;

    let selectedOrgIdx = -1;

    function getAddress(org: any, condition: boolean) {
        if (!isGettingAddress) return;
        isShow = false;
        isChecked = false;
        error = "";
        if (!condition) return;
        if (company === "") return;
        currentOrg = org;
        company = org.name;
        companyID = org.id;
        // //for mock testing
        // currentOrg.id = "01111111";
        searchApi
            .get(`/v1/${country.country_code.toLowerCase()}/company/${currentOrg.id}/address`)
            .then((res) => {
                error = "";
                isChecked = true;
                address = res.data.address;
            })
            .catch((err) => {
                error = `There is no ${company} in ${country.name}`;
                isChecked = false;
            });
    }

    const search = (company: string) => {
        if (!isGettingAddress) return;
        let baseUrl = getCompanySearchUrl(country.country_code);
        let searchable = orgs.filter((org) => org.name === company).length > 0 ? false : true;
        if (searchable) {
            isShow = true;
            if (company.length > 2) {
                searchApi
                    .get(`${baseUrl}/search?q=${encodeURIComponent(company)}&offset=0&limit=10`)
                    .then((res) => {
                        orgs = res.data.data.items;
                        selectedOrgIdx = 0;
                        dropDown.scrollTop = 0;
                    })
                    .catch((err) => console.error(err));
            } else {
                orgs = [];
            }
        }
    };

    const handleCompanyChange = helpers.debounce((e) => {
        company = e.detail.value;
        search(company);
    }, 400);

    $: if (country.name && !isShow) getAddress(currentOrg, true);

    const closeDown = (event) => {
        if (!inputCom.contains(event.target) && !dropDown.contains(event.target)) isShow = false;
    };

    const selectOrg = (event) => {
        if (isShow) {
            if (event.key === "ArrowDown" && selectedOrgIdx < orgs.length - 1) {
                selectedOrgIdx++;
                if (dropDown.scrollTop < 60 * (selectedOrgIdx - 3)) {
                    dropDown.scrollTop += 100;
                }
            }
            if (event.key === "ArrowUp" && selectedOrgIdx > 0) {
                selectedOrgIdx--;
                if (dropDown.scrollTop > 60 * selectedOrgIdx) {
                    dropDown.scrollTop -= 100;
                }
            }
            if (event.key === "Enter" && selectedOrgIdx > -1) {
                getAddress(orgs[selectedOrgIdx], orgs[selectedOrgIdx].id !== currentOrg.id);
                isShow = false;
            }
        }
    };
    onMount(() => {
        document.addEventListener("click", closeDown);
        document.addEventListener("keydown", selectOrg);
    });

    onDestroy(() => {
        document.removeEventListener("click", closeDown);
        document.removeEventListener("keydown", selectOrg);
    });
</script>

<div class="relative mt-6 text-sm">
    {#if error && company && !isShow}
        <p class="text-center text-semantic-error">{error}</p>
    {/if}
    <div class="relative mt-2" bind:this={inputCom}>
        <div class="mt-1">
            <Input
                label={$_("components.input.companyLabel")}
                {required}
                placeholder={$_("components.input.companyPlaceholder")}
                value={company}
                on:change={handleCompanyChange}
                bind:invalid
                type="text"
                id="company"
                {labelFont}
            />

            {#if orgs && orgs.length}
                <div
                    class="absolute w-7 h-7 top-10 right-6 z-10 cursor-pointer reset-selection"
                    on:click={() => {
                        company = "";
                        companyID = "";
                        address = {};
                    }}
                    on:keyup
                >
                    <Icon size={32} name="close" fill="#8C8C8C" />
                </div>
            {/if}

            {#if showId && isChecked}
                <p class="absolute w-7 h-7 top-9 right-14 z-10 gray hidden">
                    {companyID}
                </p>
            {/if}
        </div>
    </div>
    <div
        class="absolute z-10 w-full shadow-md dropdown-list dropdown-list-dark"
        class:hidden={!isShow}
        bind:this={dropDown}
    >
        {#each orgs as org, key}
            <div
                class="flex items-center py-4 px-5 cursor-pointer dropdown-item"
                class:bg-gray-200={selectedOrgIdx === key}
                on:click={() => {
                    getAddress(org, currentOrg.id !== org.id);
                }}
                on:keyup
            >
                <div>
                    <p>{org.name}</p>
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    .dropdown-list.dropdown-list-dark {
        background: #cacaca;
        max-height: 264px;
    }
    .dropdown-list.dropdown-list-dark .dropdown-item {
        border-color: #bcbbbb;
        font-size: 1rem;
    }
    .dropdown-list.dropdown-list-dark .dropdown-item:hover {
        background-color: inherit;
    }
    .reset-selection {
        background-color: white;
        border-radius: 16px;
        top: 44px !important;
    }
    * :global(#Layer_1) {
        bottom: -2px;
        position: absolute;
        right: -2px;
    }
</style>
