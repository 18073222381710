<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg
    height={size}
    id="Layer_1"
    style="enable-background:new 0 0 128 128;"
    version="1.1"
    viewBox="0 0 128 128"
    width={size}
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ><g
        ><g
            ><line
                style="fill:none;stroke:{fill};stroke-width:12;stroke-linecap:square;stroke-miterlimit:10;"
                x1="57.12"
                x2="17.787"
                y1="103.334"
                y2="64"
            /><line
                style="fill:none;stroke:{fill};stroke-width:12;stroke-linecap:square;stroke-miterlimit:10;"
                x1="17.787"
                x2="57.12"
                y1="64"
                y2="24.666"
                {fill}
            /></g
        ><line
            style="fill:none;stroke:{fill};stroke-width:12;stroke-miterlimit:10;"
            x1="17.787"
            x2="118.213"
            y1="64"
            y2="64"
            {fill}
        /></g
    ></svg
>
