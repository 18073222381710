<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";

    import { searchApi } from "../../../services/apiService";
    import Input from "../../Elements/Rekki/InputBoxRekki.svelte";
    import Icon from "../../icons/Icon.svelte";
    import { clickOutside } from "../../../utils/clickOutside.js";
    import { getCompanySearchUrl } from "@/utils/functions";
    import helpers from "../../../utilsV2/helpers";

    export let country: any = {};
    export let error: string = "";
    export let isChecked: boolean = false;
    export let invalid: string = "";
    export let currentOrg: any = {};
    export let companyID: string = "";
    export let address: any = {};
    export let showId: boolean = true;
    export let required: boolean = true;
    export let company: string = "";
    export let isGettingAddress: boolean = true;
    export let labelFont: string = "";

    let isShow: boolean = false;
    let orgs: any[] = [];
    let showWarning = false;

    // Html element handling
    let inputCom: any = null;
    let dropDown: any = null;

    let selectedOrgIdx = -1;

    function handleClickOutside(event) {
        if (company !== "" && companyID === "") {
            showWarning = true;
            document.getElementById("name-container").style.marginBottom = "40px";
        }
        document.getElementById("company").style.borderBottomLeftRadius = "4px";
        document.getElementById("company").style.borderBottomRightRadius = "4px";
    }

    function getAddress(org: any, condition: boolean) {
        if (!isGettingAddress) return;

        document.getElementById("company").disabled = true;
        isShow = false;
        isChecked = false;
        error = "";
        if (!condition) return;
        if (company === "") return;
        currentOrg = org;
        company = org.name;
        companyID = org.id;
        searchApi
            .get(`/v1/${country.country_code.toLowerCase()}/company/${currentOrg.id}/address`)
            .then((res) => {
                error = "";
                isChecked = true;
                address = company === "" ? "" : res.data.address;
            })
            .catch((err) => {
                error = $_("rekki.input.noCompanyFound", {
                    values: { company, country: country.name }
                });
                isChecked = false;
            });
    }

    const search = (company: string) => {
        if (!isGettingAddress) return;
        showWarning = false;
        const nameContainer = document.getElementById("name-container");
        if (nameContainer) nameContainer.style.marginBottom = "0px";
        const companyElem = document.getElementById("company");
        if (companyElem) {
            companyElem.style.borderBottomLeftRadius = "0px";
            companyElem.style.borderBottomRightRadius = "0px";
        }

        let baseUrl = getCompanySearchUrl(country.country_code);
        let searchable = orgs.filter((org) => org.name === company).length > 0 ? false : true;
        if (searchable) {
            isShow = true;
            if (company.length > 2) {
                searchApi
                    .get(`${baseUrl}/search?q=${encodeURIComponent(company)}&offset=0&limit=10`)
                    .then((res) => {
                        orgs = res.data.data.items;
                        selectedOrgIdx = 0;
                        dropDown.scrollTop = 0;

                        // if there's suggestions found
                        showWarning = orgs.length ? false : true;
                    })
                    .catch((err) => console.error(err));
            } else {
                orgs = [];
            }
        }
    };

    const handleCompanyChange = helpers.debounce((e) => {
        company = e.detail.value;
        search(company);
    }, 400);

    $: if (country.name && !isShow && companyID !== "") getAddress(currentOrg, true);

    const closeDown = (event) => {
        document.getElementById("company").style.borderBottomLeftRadius = "4px";
        document.getElementById("company").style.borderBottomRightRadius = "4px";
        if (!inputCom.contains(event.target) && !dropDown.contains(event.target)) {
            isShow = false;
        }
    };

    const selectOrg = (event) => {
        const companyElem = document.getElementById("company");
        if (companyElem) {
            companyElem.style.borderBottomLeftRadius = "4px";
            companyElem.style.borderBottomRightRadius = "4px";
        }

        if (isShow) {
            if (event.key === "ArrowDown" && selectedOrgIdx < orgs.length - 1) {
                selectedOrgIdx++;
                if (dropDown.scrollTop < 60 * (selectedOrgIdx - 3)) {
                    dropDown.scrollTop += 100;
                }
            }
            if (event.key === "ArrowUp" && selectedOrgIdx > 0) {
                selectedOrgIdx--;
                if (dropDown.scrollTop > 60 * selectedOrgIdx) {
                    dropDown.scrollTop -= 100;
                }
            }
            if (event.key === "Enter" && selectedOrgIdx > -1) {
                getAddress(orgs[selectedOrgIdx], orgs[selectedOrgIdx].id !== currentOrg.id);
                isShow = false;
            }
        }
    };
    onMount(() => {
        document.addEventListener("click", closeDown);
        document.addEventListener("keydown", selectOrg);
    });

    onDestroy(() => {
        document.removeEventListener("click", closeDown);
        document.removeEventListener("keydown", selectOrg);
    });
</script>

<div class="relative mt-6 text-sm">
    <div class="relative mt-2" bind:this={inputCom}>
        <div id="name-container" class="mt-1">
            <Input
                label={$_("rekki.input.companyLabel")}
                {required}
                placeholder={$_("rekki.input.companyPlaceholder")}
                value={company}
                on:change={handleCompanyChange}
                bind:invalid
                type="text"
                id="company"
                {labelFont}
            />
            {#if showWarning}
                <div class="absolute select-warning">
                    {$_("rekki.input.selectFromSuggestions")}
                </div>
            {/if}
            {#if error && company && !isShow}
                <div class="absolute select-warning">
                    {error}
                </div>
            {/if}

            {#if orgs && orgs.length}
                <div
                    class="absolute w-7 h-7 top-10 right-6 z-10 cursor-pointer reset-selection"
                    on:keyup
                    on:click={() => {
                        company = "";
                        companyID = "";
                        address = {};
                        currentOrg = {};
                        orgs = [];
                        var div = document.getElementById("company");
                        div.style.border = "none";
                        setTimeout(
                            (function (div) {
                                return function () {
                                    div.removeAttribute("disabled");
                                };
                            })(div),
                            500
                        );
                    }}
                >
                    <Icon size={34} name="rekkiclose" fill="#8C8C8C" />
                </div>
            {/if}

            {#if showId && isChecked}
                <p class="absolute w-7 h-7 top-9 right-14 z-10 gray hidden">
                    {companyID}
                </p>
            {/if}
        </div>
    </div>
    <div
        class="absolute z-10 w-full dropdown-list dropdown-list-dark"
        class:hidden={!isShow}
        bind:this={dropDown}
        use:clickOutside
        on:click_outside={handleClickOutside}
    >
        {#each orgs as org, key}
            <div
                class="flex items-center py-4 px-5 cursor-pointer dropdown-item"
                on:keyup
                on:click={() => {
                    getAddress(org, currentOrg.id !== org.id);
                }}
            >
                <div>
                    <p>{org.name}</p>
                </div>
            </div>
        {/each}
    </div>
</div>

<style>
    .dropdown-list.dropdown-list-dark {
        background: #cacaca;
        max-height: 264px;
        border-top: 1px solid #bcbbbb;
        margin-top: -1px;
        width: calc(100% - 2px);
        margin-left: 1px;
    }
    .dropdown-list.dropdown-list-dark .dropdown-item {
        border-color: #bcbbbb;
        font-size: 1rem;
    }
    .dropdown-list.dropdown-list-dark .dropdown-item:hover {
        background-color: inherit;
    }
    .reset-selection {
        top: 42px !important;
    }
    .select-warning {
        top: calc(100% - 4px);
        font-size: 18px;
        background-color: #ffb200;
        width: 100%;
        height: 40px;
        line-height: 40px;
        z-index: 10;
        color: black;
        margin: auto;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-left: 15px;
    }

    * :global(input::placeholder) {
        color: rgba(0, 0, 0, 0.5);
        opacity: 1;
    }

    * :global(#Layer_1) {
        bottom: -2px;
        position: absolute;
        right: -2px;
    }
</style>
