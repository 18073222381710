<script lang="ts">
    import type { IAuth } from "../../static/types";
    import authState from "../../store/auth.store";

    let arr: number[] = new Array(6).fill(1);
    let inputArr: HTMLInputElement[] = new Array(6);
    let codeLength: number = 6;

    export let isValidPhone: boolean = false;
    export let code: string[] = new Array(6).fill("");
    export let invalid: boolean[] = new Array(6).fill(true);
    export let codeError: boolean = false;

    authState.auth.subscribe((value: IAuth): boolean => (isValidPhone = value.isValidPhone));
    function handleKeyDown(event, i) {
        if (i === 0) return inputArr[0].focus();
        if (!event.target.value && event.key === "Backspace") {
            inputArr[i - 1].focus();
            checkValid(code);
        }
    }

    let focusStop: boolean = false;
    $: if (isValidPhone) {
        if (!focusStop) {
            setTimeout(() => {
                inputArr[0].focus();
                focusStop = true;
            }, 200);
        }
    }

    const checkValid = (arr: string[]): void => {
        arr.map((item, index) => {
            item !== "" ? (invalid[index] = false) : (invalid[index] = true);
        });
    };

    function handleInput(event, i) {
        codeError = false;
        let value = event.target.value;
        if (event.inputType === "deleteContentBackward") {
            code[i] = "";
            checkValid(code);
            return;
        }
        if (value.length >= codeLength) {
            for (let i = 0; i < codeLength; i++) {
                inputArr[i].value = value[i];
            }

            inputArr[codeLength - 1].focus();
            for (let i = 0; i < codeLength; i++) {
                code[i] = value[i];
            }
            checkValid(code);
            return;
        } else {
            inputArr[i].value = value[value.length - 1];
            code[i] = value[value.length - 1];
            checkValid(code);
        }
        if (i + 1 < codeLength) inputArr[i + 1].focus();
        return;
    }
</script>

<div class="flex justify-center w-full pincode">
    {#each arr as _, i}
        <div
            class="relative text-2xl w-12 h-16 p-1 mx-1 rounded"
            class:itemCode={!codeError}
            class:error-item={codeError}
            class:input-added={!codeError && code[i] && code[i] != ""}
        >
            <input
                type="number"
                class="text-center"
                placeholder=""
                pattern="[0-9]*"
                bind:this={inputArr[i]}
                bind:value={code[i]}
                on:input={(e) => handleInput(e, i)}
                on:keydown={(e) => handleKeyDown(e, i)}
                autocomplete="one-time-code"
            />
            {#if codeError || !code[i] || code[i] === ""}
                <div class="absolute w-8 bottom-2 left-2 activator" class:error-code={codeError} />
            {/if}
        </div>
    {/each}
</div>

<style>
    .activator {
        height: 2px;
        background-color: #344054;
    }

    .error-code {
        background-color: #d92d20;
    }

    .error-item {
        border: 1px #fef3f2 solid !important;
        background-color: #fef3f2 !important;
    }

    .error-item > input {
        background: #fef3f2;
    }

    .itemCode > input:focus {
        background: #f5f8ff;
        caret-color: transparent;
    }

    .itemCode:focus-within {
        border: 1px #f5f8ff solid !important;
        background-color: #f5f8ff !important;
    }

    .input-added {
        border-radius: 8px;
        border: 1px solid #98a2b3 !important;
        background-color: #f9fafb !important;
    }

    .input-added > input {
        background-color: #f9fafb !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
