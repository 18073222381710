<script lang="ts">
    import { slide } from "svelte/transition";
</script>

<div class="filter-sidebar-container">
    <div transition:slide={{ delay: 300, duration: 250, axis: "x" }} class="filter-sidebar">
        <div class="filter-sidebar-header">
            <slot name="header" />
        </div>
        <div class="filter-sidebar-main">
            <slot name="main" />
        </div>
        <div class="filter-sidebar-footer">
            <slot name="footer" />
        </div>
    </div>
</div>

<style>
    .filter-sidebar-container {
        position: fixed;
        height: 100%;
        width: 100%;
        right: 0;
        background: rgba(0, 0, 0, 0.1);
        top: 0;
        z-index: 110;
        display: flex;
        justify-content: flex-end;
    }

    .filter-sidebar {
        height: 100%;
        width: 42rem;
        right: 0;
        background: white;
        display: flex;
        flex-direction: column;
    }

    .filter-sidebar-main {
        flex-grow: 1;
        padding: 1.5rem 3.5rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .filter-sidebar-header {
        height: 5rem;
        border-bottom: 1px solid #e4e7ec;
        padding: 1.5rem 3.5rem;
    }

    .filter-sidebar-footer {
        height: 6rem;
        border-top: 1px solid #e4e7ec;
        padding: 1.5rem 3.5rem;
    }
</style>
