<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onDestroy, onMount } from "svelte";
    import type { Unsubscriber } from "svelte/store";

    import type { IStatement } from "../../../static/types";
    import type { CurrencyCodeEnum } from "../../../static/types";
    import { COUNTRIES } from "@/static/constant";
    import statementState from "../../../store/merchant/statement.store";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import modalState from "../../../store/modals.store";
    import EmailStatementModal from "./EmailStatementModal.svelte";
    import { account } from "../../../store/merchant/account.store";
    import GenerateStatementModal from "./GenerateStatementModal.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import StatementTable from "./StatementTable.svelte";

    const rowsPerPage = 10;

    let allStatements = [];
    let loading = true;
    let currencyCode = CurrencyCodeEnum.GBP;
    let bankStatementSettings: {
        bank_statement_email: string;
        bank_statement_email_is_enabled: boolean;
        bank_statement_format: string;
    };

    let statementUnsubscribe: Unsubscriber;

    onMount(() => {
        statementState.loading((value) => (loading = value));
        statementUnsubscribe = statementState.subscribe((statements: IStatement[]) => {
            allStatements = statements;
        });
        statementState.actions.getStatement();
    });

    $: if ($account.countryCode) {
        const { bank_statement_email, bank_statement_format, countryCode } = $account;
        const bank_statement_email_is_enabled = $account.flags.bank_statement_email_is_enabled;
        bankStatementSettings = {
            bank_statement_email,
            bank_statement_email_is_enabled,
            bank_statement_format
        };
        currencyCode = resolveCurrency(countryCode);
    }

    onDestroy(() => {
        statementUnsubscribe();
    });

    function resolveCurrency(countryCode: string): CurrencyCodeEnum {
        return COUNTRIES[countryCode]?.currency || CurrencyCodeEnum.GBP;
    }

    function generateStatement() {
        modalState.actions.setModal("", bankStatementSettings, GenerateStatementModal);
    }

    function emailStatement() {
        modalState.actions.setModal("", bankStatementSettings, EmailStatementModal);
    }
</script>

<MetaWrapper title={$_("statement.page_title")}>
    <div class="page-container">
        <PageHeader
            title={$_("statement.settlement.reports")}
            newUser={!$account?.flags?.has_made_order}
        />
        <div class="page-wrap">
            <article class="page-content">
                <section class="header">
                    <Button
                        label={$_("statement.settlement.receive_via_email")}
                        color="#344054"
                        size="large"
                        bgColor="#FFFFFF"
                        hoverBg="#E8E8E9"
                        borderColor="#D0D5DD"
                        autoHeight={true}
                        paddingX="14px"
                        on:click={emailStatement}
                    />
                    <Button
                        label={$_("statement.settlement.download_report")}
                        color="var(--primary-700)"
                        size="large"
                        bgColor="#EEF4FF"
                        hoverBg="#E8E8E9"
                        autoHeight={true}
                        paddingX="14px"
                        on:click={generateStatement}
                    />
                </section>
                <StatementTable {allStatements} {currencyCode} {rowsPerPage} {loading} />
            </article>
        </div>
    </div>
</MetaWrapper>

<style lang="scss">
    .header {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 18px 16px;
    }
</style>
