<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import API from "@/services/apiService";
    import { type IMorderSummary, NotificationType } from "../../static/types";
    import { SyncLoader } from "svelte-loading-spinners";
    import notificationState from "@/store/notifications.store";
    import ToolTip from "../../components/tooltip/ToolTip.svelte";
    import { signupComplete } from "../../store/merchant/account.store";
    import environment from "@/services/environment";
    import Fa from "svelte-fa";
    import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";

    let pollForInvoice: boolean = $signupComplete ? true : false;
    let invoiceAvailableInterval: NodeJS.Timer;
    let invoiceAvailablePollingCount: number = 1;
    let needToValidate: boolean = $signupComplete ? true : false;
    let buttonTooltipContent: string = "";
    const failedValidateAttemptsAllowed: number = 5;

    export let order: IMorderSummary;
    export let isRefundProcess: boolean = false;
    export let oldOrdersPage: boolean = false;
    export let isInstalmentOrder: boolean = false;

    $: buttonDisabled = isRefundProcess || pollForInvoice || isInstalmentOrder;
    $: validateUrl = "/v1/invoice/" + order?.orderID + "/pdf/validate";
    $: showButton = order?.fulfillDate?.length > 1 || order?.payment_status === "PARTIAL_REFUND";
    $: buttonTooltipContent = isInstalmentOrder
        ? $_("components.invoice.instalmentInvoiceTooltip", {
              values: { displayName: environment.branding.displayName }
          })
        : $_("components.invoice.downloadInvoice");

    onMount(() => {
        if ($signupComplete) isInvoiceAvailable();
    });

    function isInvoiceAvailable() {
        if (isInstalmentOrder) {
            // if it's instalment invoice then we don't need to start polling
            pollForInvoice = false;
        } else {
            checkIfInvoiceIsAvailable();
            startPollingForInvoiceAvailable();
        }
    }

    function checkIfInvoiceIsAvailable() {
        if (pollForInvoice) {
            API.get(validateUrl)
                .then((data) => {
                    if (data.valid) {
                        handleInvoiceIsAvailable();
                    } else {
                        handleInvoiceIsNotAvailable();
                    }
                })
                .catch(() => {
                    handleInvoiceIsNotAvailable();
                });
        }
    }

    function handleInvoiceIsAvailable() {
        needToValidate = false;
        stopPollingForInvoiceAvailable();
    }

    function handleInvoiceIsNotAvailable() {
        pollForInvoice = true;
        if (invoiceAvailablePollingCount >= failedValidateAttemptsAllowed) {
            stopPollingForInvoiceAvailable();
        } else {
            invoiceAvailablePollingCount++;
        }
    }

    function startPollingForInvoiceAvailable() {
        if (pollForInvoice) {
            invoiceAvailableInterval = setInterval(checkIfInvoiceIsAvailable, 3000);
        }
    }

    function stopPollingForInvoiceAvailable() {
        if (invoiceAvailableInterval) {
            clearInterval(invoiceAvailableInterval);
        }
        invoiceAvailablePollingCount = 0;
        pollForInvoice = false;
    }

    function showInvoiceNotReadyError() {
        const errorMessage = $_("components.invoice.invoiceNotReadyError", {
            values: { supportEmail: environment.branding.supportEmail }
        });
        notificationState.actions.create(NotificationType.ERROR, errorMessage);
    }

    function downloadInvoice() {
        const downloadLink = order.download_url;
        if (needToValidate) {
            API.get(validateUrl)
                .then((data) => {
                    if (data.valid) {
                        window.open(downloadLink, "_blank").focus();
                    } else {
                        showInvoiceNotReadyError();
                    }
                })
                .catch(() => {
                    showInvoiceNotReadyError();
                });
            return;
        }
        window.open(downloadLink, "_blank").focus();
    }
</script>

{#if showButton}
    <ToolTip position="bottom-centre" content={buttonTooltipContent} hideTip={!isInstalmentOrder}>
        <button
            on:click={downloadInvoice}
            disabled={buttonDisabled}
            class="{oldOrdersPage ? "smaller" : "standard"}
            download-invoice-button download-invoice-button--download{buttonDisabled
                ? "-disabled"
                : ""} "
        >
            {#if pollForInvoice}
                <SyncLoader size="20" color="rgb(255, 255, 255)" />
            {:else}
                <Fa icon={faArrowCircleDown} size="lg" color="white" />
                <span>{$_("components.invoice.downloadInvoice")}</span>
            {/if}
        </button>
    </ToolTip>
{/if}

<style lang="scss">
    .smaller {
        padding: 6px 16px;
    }

    .standard {
        padding: 10px 20px;
    }

    .download-invoice-button {
        display: flex;
        gap: 10px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        height: 40px;
        min-width: 184px;
        justify-content: center;

        &--download {
            color: var(--basic);
            background-color: var(--primary-500);
            display: flex;
        }

        &--download:hover {
            background-color: var(--primary-600);
        }

        &--download-disabled {
            color: var(--basic);
            background-color: var(--primary-200);
            display: flex;
            cursor: default;
        }

        span {
            white-space: nowrap;
        }
    }
</style>
