import type {
    IBillingAccountBuyerStatementResponse,
    IBuyerBillingAccount,
    IBuyerInvoiceResponse,
    IBuyerMerchantRelationship
} from "../../static/types";
import {
    IStatementObject,
    buildAllStatementsTable
} from "../../pages/buyer/billingPeriods/statementUtils";
import { BASE_URL } from "../../static/endPoints";
import { navigate } from "svelte-routing";
import { Writable, writable } from "svelte/store";
import { type IInvoiceObject, buildAllInvoicesTable } from "@/pages/buyer/invoices/invoiceUtils";

const ALL_COMPANIES = "All Companies";

export interface BuyerData {
    account_email: string;
    billing_accounts_summary: Map<string, IBuyerBillingAccount>;
    invoice_accounts_summary: [IBuyerMerchantRelationship];
    invoice_companies: Map<string, string>;
    billing_account_companies: Map<string, string>;
}

interface StatementRequest {
    company_name?: string;
    organization_number?: string;
}

interface BuyerAccountResponse {
    account_email: string;
    billing_accounts_summary: [IBuyerBillingAccount];
    invoice_accounts_summary: [IBuyerMerchantRelationship];
}

function createBuyerAccount() {
    const loggedIn = writable(false);
    const buyerData: Writable<BuyerData> = writable();
    const statementsSelectedCompany: Writable<string> = writable(ALL_COMPANIES);
    const statements: Writable<IStatementObject[]> = writable(new Array<IStatementObject>());
    const statementDataLoading = writable(true);
    const invoices: Writable<IInvoiceObject[]> = writable(new Array<IInvoiceObject>());
    const invoicesSelectedCompany: Writable<string> = writable(ALL_COMPANIES);
    const invoiceDataLoading = writable(true);

    return {
        loggedIn,
        buyerData,
        statements,
        statementsSelectedCompany,
        statementDataLoading,
        invoices,
        invoicesSelectedCompany,
        invoiceDataLoading,
        buyerStoreMethods: {
            checkLoggedIn: async () => {
                const response = await fetch(`${BASE_URL}/v1/portal/buyer`, {
                    credentials: "include"
                });
                try {
                    if (response.ok) {
                        loggedIn.set(true);
                        const responseData: BuyerAccountResponse =
                            (await response.json()) as BuyerAccountResponse;
                        buyerData.set({
                            account_email: responseData.account_email,
                            // This will allow for the merchant relationship to be retrieved by the billing account id
                            billing_accounts_summary: new Map<string, IBuyerBillingAccount>(
                                responseData.billing_accounts_summary.map(
                                    (summary: IBuyerBillingAccount) => [
                                        summary.billing_account_id,
                                        summary
                                    ]
                                )
                            ),
                            invoice_accounts_summary: responseData.invoice_accounts_summary,
                            billing_account_companies: get_companies(
                                responseData.billing_accounts_summary
                            ),
                            invoice_companies: get_companies(responseData.invoice_accounts_summary)
                        });
                    } else {
                        loggedIn.set(false);
                        sessionStorage.setItem("redirectUrl", window.location.pathname);
                        navigate("/auth/buyer/login");
                    }
                } catch {
                    loggedIn.set(false);
                    sessionStorage.setItem("redirectUrl", window.location.pathname);
                    navigate("/auth/buyer/login");
                }
            },
            getBillingAccountBuyerStatements: async () => {
                statementDataLoading.set(true);
                let body: StatementRequest;
                let selectedCompanyValue: string;
                const unsubscribeCompany = statementsSelectedCompany.subscribe(
                    (value) => (selectedCompanyValue = value)
                );

                if (selectedCompanyValue && selectedCompanyValue !== ALL_COMPANIES) {
                    const [companyName, orgNumber, _] = selectedCompanyValue.split(":");
                    body = <StatementRequest>{
                        company_name: companyName,
                        organization_number: orgNumber
                    };
                }
                const response = await fetch(`${BASE_URL}/v1/portal/buyer/statements`, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-type": "application/json"
                    }
                });

                const json: IBillingAccountBuyerStatementResponse[] =
                    (await response.json()) as IBillingAccountBuyerStatementResponse[];
                const rows = buildAllStatementsTable(json);
                statements.set(rows);
                statementDataLoading.set(false);
                unsubscribeCompany();
            },
            getBuyerInvoices: async () => {
                invoiceDataLoading.set(true);
                let url = `${BASE_URL}/v1/portal/buyer/invoices`;
                let selectedCompanyValue: string;
                const unsubscribeCompany = invoicesSelectedCompany.subscribe(
                    (value: string) => (selectedCompanyValue = value)
                );

                if (selectedCompanyValue && selectedCompanyValue !== ALL_COMPANIES) {
                    const [companyName, orgNumber, _] = selectedCompanyValue.split(":");
                    url = `${url}?company_name=${companyName}&organization_number=${orgNumber}`;
                }

                const response = await fetch(url, {
                    credentials: "include"
                });

                const json: IBuyerInvoiceResponse[] =
                    (await response.json()) as IBuyerInvoiceResponse[];
                const rows = buildAllInvoicesTable(json);
                invoices.set(rows);
                invoiceDataLoading.set(false);
                unsubscribeCompany();
            }
        }
    };
}

const get_companies = (buyerMerchantRelationships: [IBuyerMerchantRelationship]) =>
    new Map<string, string>(
        buyerMerchantRelationships.map((summary: IBuyerMerchantRelationship) => [
            summary.buyer_company_name,
            summary.buyer_organization_number
        ])
    );

export const {
    loggedIn,
    buyerData,
    statements,
    statementsSelectedCompany,
    statementDataLoading,
    invoices,
    invoicesSelectedCompany,
    invoiceDataLoading,
    buyerStoreMethods
} = createBuyerAccount();
