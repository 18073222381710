<script lang="ts">
    import { _ } from "svelte-i18n";
    import { cloneDeep } from "lodash";
    import { Tooltip, Button as TwoUIButton } from "@two-ui/core";

    import API from "../../../services/apiService";
    import { type BillingAccountUI, adaptBillingAccountToBackend } from "./helpers";
    import { BILLING_ACCOUNT_CONFIG, LABEL_TO_VALUE_MAP } from "./variables";
    import MultiEmails from "./MultiEmails.svelte";
    import Input from "../../../components/Elements/InputBox.svelte";
    import PhoneInput from "../../../components/authentication/PhoneInput.svelte";
    import { getBaseUrl } from "../../../utils/functions";
    import notificationState from "../../../store/notifications.store";
    import { NotificationType } from "../../../static/types";
    import { emailExp } from "../../../static/regExp";
    import { account as storeAccount } from "@/store/merchant/account.store";
    import { validatePhoneNumber } from "@/utils/validation";

    export let account: BillingAccountUI | null = null;
    export let fetchBillingAccounts = () => undefined;
    let phoneCountryCode: string = $storeAccount.countryCode;

    const accountDetailsEditable = { ...account };
    const accountDetailsInitialValues = cloneDeep(account);

    const onFocusHandler = (config) => {
        const elem = document.getElementById(
            config.dataKey.toLowerCase().replace(" ", "-") + "-editable"
        );

        if (elem) {
            // not working without async
            setTimeout(() => {
                elem.focus();
            }, 100);
        }
    };

    const onBlurHandler = (config) => {
        account[config.dataKey] = accountDetailsEditable[config.dataKey];
        isFormValid();
    };

    const isElemEditable = (elem) => {
        const currentConfig = BILLING_ACCOUNT_CONFIG.find((item) => item.dataKey === elem);
        return currentConfig && !currentConfig.disabled;
    };

    const isElemRequired = (elem) => {
        const currentConfig = BILLING_ACCOUNT_CONFIG.find((item) => item.dataKey === elem);
        return currentConfig && currentConfig.required;
    };

    const invalidObj = Object.fromEntries(
        Object.keys(accountDetailsEditable).map((dataKey) => [dataKey, ""])
    );

    const invalidObjForMultiEmails = {
        accountantEmails: [],
        ccEmails: []
    };

    const isFormValid = () => {
        let isValid = true;

        // check required fields
        Object.keys(accountDetailsEditable)
            .filter(isElemEditable)
            .filter(isElemRequired)
            .forEach((elem) => {
                if (isElemEditable(elem) && !accountDetailsEditable[elem]) {
                    invalidObj[elem] = $_("errors.fieldRequired");
                    isValid = false;
                } else {
                    invalidObj[elem] = "";
                }
            });
        // check payment terms
        if (+accountDetailsEditable.paymentTerms < 5 || +accountDetailsEditable.paymentTerms > 60) {
            invalidObj.paymentTerms = $_("customer.paymentTermsRangeError");
            isValid = false;
        } else {
            invalidObj.paymentTerms = "";
        }

        // phone
        const phoneValidationError = validatePhoneNumber(
            $_,
            phoneCountryCode,
            accountDetailsEditable.phone,
            false
        );

        const isPhoneChanged = accountDetailsEditable.phone !== phoneCountryCode;

        if (isPhoneChanged && phoneValidationError) {
            invalidObj.phone = phoneValidationError;
            isValid = false;
        }

        // multi-emails
        Object.keys(invalidObjForMultiEmails).forEach((key) => {
            invalidObjForMultiEmails[key] = [];
            accountDetailsEditable[key].forEach((email, index) => {
                if (!emailExp.test(email)) {
                    invalidObjForMultiEmails[key][index] = $_("errors.validEmail");
                    isValid = false;
                } else {
                    invalidObjForMultiEmails[key][index] = "";
                }
            });
        });

        return isValid;
    };

    const getUpdatedElements = () => {
        const updatedElements: Partial<BillingAccountUI> = {};
        // send just updated values
        Object.entries(accountDetailsEditable).forEach(([key, value]) => {
            if (key === "phone") {
                if (value && value !== phoneCountryCode) {
                    updatedElements.phone = value as string;
                }
            } else if (accountDetailsInitialValues[key] !== value) {
                updatedElements[key] = value;
            }
        });

        return updatedElements;
    };

    const onSave = () => {
        if (isFormValid()) {
            const updatedElements = getUpdatedElements();
            if (Object.keys(updatedElements).length > 0) {
                updateAccount(updatedElements);
            }
        }
    };

    const updateAccount = (updatedElements) => {
        const url = `${getBaseUrl()}/billing/v1/account/${accountDetailsEditable.id}`;
        const data = adaptBillingAccountToBackend(updatedElements);

        API.patch(url, data)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("customer.accountUpdated")
                );
                fetchBillingAccounts();
            })
            .catch((err) => {
                const errorMessage = err.response?.data?.error_message
                    ? err.response?.data?.error_message
                    : $_("customer.accountFailedToBeUpdated");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            });
    };
</script>

<section class="flex flex-wrap items">
    {#each BILLING_ACCOUNT_CONFIG as config}
        <div class={`w-1/2 mb-4 item-wrap billing-${config.dataKey}`}>
            <div class="w-2/3">
                {#if config.key}
                    {#if config.inputType === "phone"}
                        <label
                            class="label text-sm text-gray-700"
                            class:label-invalid={invalidObj[config.dataKey]}
                            for={config.dataKey}
                        >
                            {$_("components.inputType.phone")}
                        </label>
                        <PhoneInput
                            id={config.dataKey}
                            useCustomInput={true}
                            disable={false}
                            bind:countryCode={phoneCountryCode}
                            bind:phoneNumberWithCode={accountDetailsEditable[config.dataKey]}
                            bind:invalid={invalidObj[config.dataKey]}
                        />
                    {:else if config.inputType === "multi-emails"}
                        <MultiEmails
                            {config}
                            {accountDetailsEditable}
                            invalid={invalidObjForMultiEmails[config.dataKey]}
                        />
                    {:else}
                        <!-- isFocused -->
                        <div style={`display: ${config.isFocused ? "block" : "none"}`}>
                            <Input
                                label={LABEL_TO_VALUE_MAP($_)[config.key]}
                                id={config.dataKey.toLowerCase().replace(" ", "-") + "-editable"}
                                type={config.inputType}
                                placeholder={$_("components.enterYour", {
                                    values: {
                                        field: LABEL_TO_VALUE_MAP($_)[config.key].toLowerCase()
                                    }
                                })}
                                bind:value={accountDetailsEditable[config.dataKey]}
                                required={config.required}
                                disabled={config.disabled}
                                onBlur={() => {
                                    onBlurHandler(config);
                                    config.isFocused = false;
                                }}
                                helperText={config.helperText ? config.helperText($_) : ""}
                            />
                        </div>
                        <!-- not isFocused -->
                        <div
                            style={`display: ${!config.isFocused ? "block" : "none"}`}
                            class:mb-4={invalidObj[config.dataKey]}
                        >
                            <Tooltip
                                style="width: 100%"
                                wrapLines
                                width={276}
                                position="bottom"
                                placement="start"
                                {...config.tooltipContent ? {} : { opened: false }}
                                label={config.tooltipContent ? config.tooltipContent($_) : ""}
                            >
                                <Input
                                    label={LABEL_TO_VALUE_MAP($_)[config.key]}
                                    id={config.dataKey.toLowerCase().replace(" ", "-")}
                                    type="text"
                                    placeholder={$_("components.enterYour", {
                                        values: {
                                            field: LABEL_TO_VALUE_MAP($_)[config.key].toLowerCase()
                                        }
                                    })}
                                    value={config.formatValue
                                        ? config.formatValue(account, $_)
                                        : account[config.dataKey]}
                                    required={config.required}
                                    disabled={config.disabled}
                                    invalid={invalidObj[config.dataKey]}
                                    onFocus={() => {
                                        onFocusHandler(config);
                                        config.isFocused = true;
                                    }}
                                    helperText={config.helperText ? config.helperText($_) : ""}
                                />
                            </Tooltip>
                        </div>
                    {/if}
                {/if}
            </div>
        </div>
    {/each}

    <div class="mt-4 mb-8">
        <TwoUIButton
            on:click={onSave}
            size="md"
            variant="primary"
            content={$_("components.saveChanges")}
        />
    </div>
</section>

<style>
    * :global(.iti__flag-container) {
        height: 50px;
    }
    .items .item-wrap:nth-child(odd) {
        padding-right: 1rem;
    }
    .items .item-wrap:nth-child(even) {
        padding-left: 1rem;
    }
</style>
