<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 24 28" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M20.0953 3.99935H18.4763V1.49935C18.4763 1.03914 18.1138 0.666016 17.6667 0.666016C17.2197 0.666016 16.8572 1.03914 16.8572 1.49935V3.99935H7.14294V1.49935C7.14294 1.03914 6.78047 0.666016 6.33342 0.666016C5.88636 0.666016 5.52389 1.03914 5.52389 1.49935V3.99935H3.90484C2.1165 3.99935 0.666748 5.49174 0.666748 7.33268V23.9993C0.666748 25.8403 2.1165 27.3327 3.90484 27.3327H20.0953C21.8837 27.3327 23.3334 25.8403 23.3334 23.9993V7.33268C23.3334 5.49174 21.8837 3.99935 20.0953 3.99935ZM21.7144 23.9993C21.7144 24.9199 20.9895 25.666 20.0953 25.666H3.90484C3.01062 25.666 2.2858 24.9199 2.2858 23.9993V10.666H21.7144V23.9993ZM21.7144 8.99935H2.2858V7.33268C2.2858 6.41216 3.01062 5.66602 3.90484 5.66602H20.0953C20.9895 5.66602 21.7144 6.41216 21.7144 7.33268V8.99935ZM6.33342 16.4993H17.6667C18.1142 16.4993 18.4763 16.1266 18.4763 15.666C18.4763 15.2054 18.1142 14.8327 17.6667 14.8327H6.33342C5.88595 14.8327 5.52389 15.2054 5.52389 15.666C5.52389 16.1266 5.88595 16.4993 6.33342 16.4993ZM6.33342 21.4993H12.8096C13.2571 21.4993 13.6191 21.1266 13.6191 20.666C13.6191 20.2054 13.2571 19.8327 12.8096 19.8327H6.33342C5.88595 19.8327 5.52389 20.2054 5.52389 20.666C5.52389 21.1266 5.88595 21.4993 6.33342 21.4993Z"
        {fill}
    />
</svg>
