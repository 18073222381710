import MerchantLogin from "../pages/authentication/merchant/Login.svelte";
import LoginMode from "../pages/authentication/merchant/LoginOption.svelte";
import MerchantSignup from "../pages/authentication/merchant/SignUp/SignUp.svelte";
import VerifyLoginCode from "../pages/authentication/merchant/SignUp/VerifyLoginCode.svelte";
import Login from "../pages/authentication/buyer/Login.svelte";
import SelectMerchant from "../pages/authentication/merchant/User/SelectMerchant.svelte";

const routes = [
    {
        path: "/merchant/login",
        component: MerchantLogin
    },
    {
        path: "/merchant/login/select",
        component: LoginMode
    },
    {
        path: "/merchant/signup",
        component: MerchantSignup
    },
    {
        path: "/merchant/signup/verification",
        component: VerifyLoginCode
    },
    {
        path: "/merchant/user/select",
        component: SelectMerchant
    },
    {
        path: "/buyer/login",
        component: Login
    }
];

export default routes;
