<script lang="ts">
    import { _ } from "svelte-i18n";
    import Modal from "../Elements/Modal.svelte";
    export let show: boolean;
    export let infoModalText: string;
    export let infoModalTitle: string;

    function closeModal() {
        show = false;
    }
</script>

<Modal title={infoModalTitle} bind:show>
    <div class="selector-wrapper">
        <p>
            {infoModalText}
        </p>
    </div>

    <div slot="footer">
        <button class="close-button" on:click={closeModal}>{$_("components.close")}</button>
    </div>
</Modal>

<style>
    p {
        color: var(--Text-Tertiary, #98a2b3);
        font-feature-settings:
            "clig" off,
            "liga" off;
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    .close-button {
        display: flex;
        width: fit-content;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-600, var(--primary-600));
        background: var(--Primary-600, var(--primary-600));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--White, #fff);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 3rem;
    }
</style>
