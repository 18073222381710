<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 28" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10 0.666016C4.47755 0.666016 0 5.14357 0 10.666C0 14.6978 1.40464 15.8241 8.97297 26.795C9.2212 27.1535 9.61057 27.3327 10 27.3327C10.3894 27.3327 10.7788 27.1535 11.027 26.795C18.5954 15.8241 20 14.6978 20 10.666C20 5.14357 15.5224 0.666016 10 0.666016ZM10 25.3492C2.70479 14.8003 1.66667 13.9977 1.66667 10.666C1.66667 6.07107 5.40495 2.33268 10 2.33268C14.5951 2.33268 18.3333 6.07107 18.3333 10.666C18.3333 13.993 17.3211 14.7636 10 25.3492ZM10 6.49935C7.69885 6.49935 5.83333 8.36477 5.83333 10.666C5.83333 12.9673 7.69885 14.8327 10 14.8327C12.3012 14.8327 14.1667 12.9673 14.1667 10.666C14.1667 8.36477 12.3012 6.49935 10 6.49935ZM10 13.166C8.62151 13.166 7.5 12.0445 7.5 10.666C7.5 9.28753 8.62151 8.16602 10 8.16602C11.3785 8.16602 12.5 9.28753 12.5 10.666C12.5 12.0445 11.3785 13.166 10 13.166Z"
        {fill}
    />
</svg>
