<script lang="ts">
    import { onMount, onDestroy } from "svelte";
    import Icon from "../icons/Icon.svelte";
    export let placeholder: string = "Search for personal or business bank";
    export let ifMobile: boolean = false;
    export let label: string = "";

    export let value: string = "";
    export let itemList: string[] = [];
    export let required: boolean = false;

    let filteredList: string[] = [];
    let isFocus: boolean = false;

    let selectedItemIdx: any = -1;
    let isDropdownShow: boolean = false;

    // Html element handling
    let inputCom: any = null;
    let dropDown: any = null;

    onMount(() => {
        isDropdownShow = false;
        filteredList = itemList;
    });

    $: filterList(value);

    function filterList(searchValue: string) {
        isDropdownShow = true;
        if (searchValue) {
            filteredList = itemList.filter((item) =>
                item.toLowerCase().includes(searchValue.toLowerCase())
            );
        } else {
            filteredList = itemList;
        }
    }

    const handleDropdownSelect = (event) => {
        if (isDropdownShow) {
            if (event.key === "ArrowDown" && selectedItemIdx < filteredList.length - 1) {
                selectedItemIdx++;
                if (dropDown.scrollTop < 60 * (selectedItemIdx - 3)) {
                    dropDown.scrollTop += 100;
                }
            }
            if (event.key === "ArrowUp" && selectedItemIdx > 0) {
                selectedItemIdx--;
                if (dropDown.scrollTop > 60 * selectedItemIdx) {
                    dropDown.scrollTop -= 100;
                }
            }
            if (event.key === "Enter" && selectedItemIdx > -1) {
                selectItem();
            }
        }
    };

    const selectItem = () => {
        value = filteredList[selectedItemIdx];
        setTimeout(() => {
            isDropdownShow = false;
        }, 10);
    };

    function topLabel(): any {
        isFocus = true;
    }
    function loseFocus(): any {
        isFocus = false;
    }

    onMount(() => {
        document.addEventListener("click", closeDown);
        document.addEventListener("keydown", handleDropdownSelect);
    });

    onDestroy(() => {
        document.removeEventListener("click", closeDown);
        document.removeEventListener("keydown", handleDropdownSelect);
    });

    const closeDown = (event) => {
        if (!inputCom?.contains(event.target) && !dropDown?.contains(event.target))
            isDropdownShow = false;
    };
</script>

<div style="display: flex;flex-direction: column;}">
    <p class="label text-sm">
        {label}
        {#if required}
            <span class="text-main">*</span>
        {/if}
    </p>
    <p></p>
    <div class="dropdown-container" bind:this={inputCom}>
        <div
            class="relative flex w-full input_wrapper bg-white mt-1 rounded-lg"
            class:border_focus={isFocus}
        >
            <input
                bind:value
                type="text"
                {placeholder}
                class="w-full pl-12 pr-8 border-none outline-none"
                on:focus={topLabel}
                on:focusout={loseFocus}
                on:click
                autocomplete="on"
            />
            <button
                class="z-10 arrow"
                style="margin-right:1rem"
                class:rotate-arrow={isDropdownShow}
                on:click={() => {
                    isDropdownShow = !isDropdownShow;
                }}
            >
                <Icon name="drop-arrow-down" size={14} makeSquare={true} fill="#667085" />
            </button>
        </div>
        {#if isDropdownShow}
            <div
                class="z-10 bg-white shadow-md dropdown-list"
                class:small={ifMobile}
                bind:this={dropDown}
            >
                {#each filteredList as item, index}
                    <button
                        class="flex items-center text-left py-3 pl-5 cursor-pointer dropdown-item"
                        class:bg-gray-200={selectedItemIdx === index}
                        on:click={() => {
                            value = item;
                            setTimeout(() => {
                                isDropdownShow = false;
                            }, 10);
                        }}
                    >
                        <p>{item}</p>
                    </button>
                {/each}
            </div>
        {/if}
    </div>
</div>

<style>
    .bg-gray-200 {
        background-color: var(--gray-200);
    }

    .dropdown-list {
        position: absolute;
        top: 100%; /* Position it below the input */
        left: 0;
        width: 100%; /* Makes the dropdown the same width as the container */
        box-sizing: border-box; /* Ensures padding/border is included in the width */
    }

    .dropdown-list.small {
        max-height: 150px;
    }
    .dropdown-list::-webkit-scrollbar {
        width: 8px;
        background-color: white;
    }
    .dropdown-list::-webkit-scrollbar-thumb {
        background: #e4e7ec;
        border-radius: 5px;
    }
    .dropdown-item {
        border: none;
        color: #1d2939;
        width: 100%;
    }
    .dropdown-item:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }
    .arrow {
        transition: all 0.25s ease-in-out;
        -webkit-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        -moz-transition: all 0.25s ease-in-out;
        top: 2.6rem;
    }
    .rotate-arrow {
        transform: rotate(-180deg);
        -webkit-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
    }
    input {
        z-index: 1;
        height: 42px;
        border-radius: 8px;
        color: var(--ctnGeneralPrimary);
        padding-left: 14px;
    }
    .dropdown-container {
        position: relative;
        display: inline-block;
    }
</style>
