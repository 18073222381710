<script lang="ts">
    import { _ } from "svelte-i18n";

    import { titleCase } from "../../../utilsV2/helpers";
    import type { IDocument } from "../../../static/types";
    import { getBaseUrl } from "../../../utils/functions";
    import environment from "@/services/environment";

    const statusColorMap = {
        "AWAITING SIGNATURE": "warning",
        "SIGNED": "success"
    };

    export let documents: IDocument[] = [];
    export let editOngoing: boolean;

    const onDownload = () => {
        window.open(`${getBaseUrl()}/v1/merchant/terms_and_conditions/pdf`, "_blank");
    };
</script>

<table id="Documents" class:disabled={editOngoing}>
    <caption class="text-base font-bold w-full pb-2" style="text-align:left;font-size:18px">
        {$_("account.document.title")}
    </caption>
    <thead>
        <th>{$_("account.document.name")}</th>
        <th>{$_("account.document.dateIssued")}</th>
        <th>{$_("account.document.status")}</th>
        <th />
    </thead>
    <tbody>
        {#each documents as document}
            {@const status = document.status}
            <tr>
                <td><p class="bold-text">{document.name}</p></td>
                <td><p>{document.date_issued || "N/A"}</p></td>
                <td><p class="status {statusColorMap[status]}">{titleCase([status])[0]}</p></td>
                <td>
                    <button
                        class="button-sm button-tertiary"
                        on:click={onDownload}
                        disabled={document.document_type !== "STANDARD"}
                    >
                        {#if !document.download_link && document.document_type !== "STANDARD"}
                            <span class="tooltip-white"
                                >{@html $_("account.document.tooltipDownloadContact", {
                                    values: { supportEmail: environment.branding.supportEmail }
                                })}</span
                            >
                        {/if}
                        {$_("account.document.download")}
                    </button>
                </td>
            </tr>
        {/each}
        {#if documents.length == 0}
            <tr>
                <td colspan="4">
                    <p style="display: flex;justify-content: center;">
                        {$_("account.document.noDocuments")} &#128195;
                    </p>
                </td>
            </tr>
        {/if}
    </tbody>
</table>

<style lang="scss">
    table {
        width: 100%;
        table-layout: auto;
        text-align: left;
    }

    .disabled {
        td,
        th,
        p,
        caption {
            color: var(--gray-400) !important;
        }
    }

    thead {
        background-color: var(--gray-200);
        color: var(--gray-600);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    td:first-child,
    th:first-child {
        padding-left: 1rem;
    }

    th {
        height: 40px !important;
    }

    th,
    tr {
        cursor: unset;
    }

    td:last-child {
        width: 65px;
    }

    td p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    tr {
        padding-top: 0.5rem;
    }

    .bold-text {
        color: var(--gray-600);
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
    }

    .status {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        border-radius: 2px;
        padding: 2px 4px;
        width: fit-content;

        &.warning {
            color: var(--warning-900);
            background-color: var(--warning-100);
        }

        &.success {
            color: var(--success-900);
            background-color: var(--success-100);
        }
    }

    button {
        position: relative;

        &:hover {
            .tooltip-white {
                visibility: visible;
            }
        }

        .tooltip-white {
            min-width: 250px;
            z-index: 10;
            top: -60px;
            left: -150px;
            visibility: hidden;
            position: absolute;
            background-color: white;
            color: black;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid var(--gray-300);
        }
    }

    button:disabled {
        cursor: not-allowed;
    }
</style>
