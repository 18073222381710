import { writable } from "svelte/store";
import { NotificationType, type IAuth } from "../static/types";
import API from "../services/apiService";
import { BUYER_LOG_OUT, MERCHANT_LOG_OUT } from "../static/endPoints";
import notificationState from "./notifications.store";
import { navigate } from "svelte-routing";

function createUser() {
    const user = writable({
        email: "",
        confirmEmail: "",
        isExistEmail: ""
    });

    const auth = writable({
        data: {},
        phoneNumber: "",
        isValidPhone: false
    });

    const buyerOrg = writable([
        "Amann",
        "Nordmann AS",
        "Norske AS",
        "NoNname Company AS",
        "NoName #2 AS",
        "Asike Sa",
        "Babnine",
        "Cobanke",
        "Motizier"
    ]);
    return {
        user: user,
        orgs: buyerOrg,
        auth: auth,
        action: {
            logout: (loginUser: string) => {
                let url: string = loginUser === "merchant" ? MERCHANT_LOG_OUT : BUYER_LOG_OUT;
                API.post(url, {})
                    .then(() => {
                        user.set({
                            email: "",
                            confirmEmail: "",
                            isExistEmail: ""
                        });
                        // push(`/auth/${loginUser}/login`);
                        navigate(`/auth/${loginUser}/login`);
                    })
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            `${loginUser} Logout Failed`
                        );
                    });
            },
            updateData: (data: object): void => {
                auth.update((value: IAuth) => ({ ...value, data: data }));
            },

            updatePhoneNumber: (phoneNumber: string): void => {
                auth.update((value: IAuth) => ({ ...value, phoneNumber }));
            },

            updateIsValidPhone: (isValidPhone: boolean): void => {
                auth.update((value: IAuth) => ({ ...value, isValidPhone }));
            }
        }
    };
}

const authState: any = createUser();
export default authState;
