<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg
    height={size}
    id="Layer_1"
    style="enable-background:new 0 0 512 512;"
    version="1.1"
    viewBox="0 0 512 512"
    width={size}
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ><g
        ><path
            {fill}
            d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M384,265H128v-17h256V265z"
        /></g
    ></svg
>
