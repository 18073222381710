import { register, init, getLocaleFromNavigator } from "svelte-i18n";

async function setupI18n() {
    register("en", () => import("./languages/en.json"));
    register("nl", () => import("./languages/nl.json"));
    register("no", () => import("./languages/no.json"));
    register("sv", () => import("./languages/sv.json"));
    register("da", () => import("./languages/da.json"));

    // this will be extended to no and sv after https://linear.app/tillit/issue/KNA-939/default-for-se-and-no-merchants
    const isNlLang = getLocaleFromNavigator()?.startsWith("nl");
    await init({
        fallbackLocale: "en",
        initialLocale: localStorage.getItem("lang") || (isNlLang ? "nl" : "en")
    });
}

setupI18n().catch((error) => {
    console.error("Error setting up i18n:", error);
});
