/* eslint-disable
 @typescript-eslint/no-explicit-any
 */

import {
    UPDATE_PASSWORD,
    SEND_CONFIRM_EMAIL,
    CREATE_PASSWORD,
    AUTH_LOGIN,
    TRIGGER_VERIFY,
    EMAIL_VERIFICATION
} from "./../static/endPoints";
import API from "./apiService";

const callApi = (url: string, body: any) => {
    return new Promise((resolve, reject) => {
        API.post(url, body)
            .then((res: any) => {
                resolve(res);
            })
            .catch((err) => {
                return Promise.reject(err.response);
            });
    });
};

const sendSMSCode = (value: any): any => {
    const url: string = TRIGGER_VERIFY;
    return callApi(url, value);
};

const sendEmailCode = (value: object): any => {
    const url: string = TRIGGER_VERIFY;
    return callApi(url, value);
};

const triggerVerification = (value: object): any => {
    return API.post(TRIGGER_VERIFY, value);
};

const authLogin = (formValue: object): any => {
    const url: string = AUTH_LOGIN;
    return callApi(url, formValue);
};

const updatePassword = (password: string): any => {
    const url: string = UPDATE_PASSWORD;
    return callApi(url, password);
};

const sendConfirmEmail = (formValue: { email: string }): any => {
    const url: string = SEND_CONFIRM_EMAIL;
    return callApi(url, formValue);
};

const createPassword = (body: { token: string; password: string }): any => {
    const url: string = CREATE_PASSWORD;
    return callApi(url, body);
};

const completeVerification = (formValue: any): any => {
    return API.post(EMAIL_VERIFICATION, formValue);
};

export default {
    authLogin,
    updatePassword,
    sendConfirmEmail,
    createPassword,
    sendSMSCode,
    sendEmailCode,
    completeVerification,
    triggerVerification
};
