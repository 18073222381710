<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12 12V8M12.5 15.5C12.5 15.7761 12.2761 16 12 16C11.7239 16 11.5 15.7761 11.5 15.5M12.5 15.5C12.5 15.2239 12.2761 15 12 15C11.7239 15 11.5 15.2239 11.5 15.5M12.5 15.5H11.5M19.3311 9.09116L18.98 8.46437C16.6988 4.39063 15.5581 2.35377 14.0576 1.67625C12.7495 1.08558 11.2505 1.08558 9.94239 1.67625C8.44189 2.35377 7.30124 4.39064 5.01995 8.46438L4.66894 9.09117C2.47606 13.007 1.37961 14.965 1.56302 16.5683C1.72303 17.967 2.46536 19.2335 3.60763 20.0566C4.91688 21 7.16092 21 11.649 21H12.351C16.8391 21 19.0831 21 20.3924 20.0566C21.5346 19.2335 22.277 17.967 22.437 16.5683C22.6204 14.965 21.5239 13.007 19.3311 9.09116Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
