<script lang="ts">
    export let size = 20;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="4" fill="#1D2939" />
    <path
        d="M4 6.08439V2H7.01196V6.08439H9.47039V8.88192H7.01196V13.3729C7.01196 14.574 7.49032 15.0738 8.63326 15.0738H10V18H8.07289C5.18394 18 4 16.5714 4 13.8485V6.08439Z"
        fill="white"
    />
    <circle cx="14" cy="16" r="2" fill="white" />
</svg>
