<script lang="ts">
    // Icon name overwrites fill so leaving this off for now...
    //   export let fill = "#98A2B3";
</script>

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04166 10.0001C1.04166 5.05253 5.05244 1.04175 9.99999 1.04175C14.9475 1.04175 18.9583 5.05253 18.9583 10.0001C18.9583 14.9476 14.9475 18.9584 9.99999 18.9584C5.05244 18.9584 1.04166 14.9476 1.04166 10.0001ZM10.75 6.66675C10.75 6.25253 10.4142 5.91675 9.99999 5.91675C9.58578 5.91675 9.24999 6.25253 9.24999 6.66675V10.0001C9.24999 10.2508 9.37532 10.485 9.58396 10.6241L12.084 12.2908C12.4286 12.5206 12.8943 12.4274 13.124 12.0828C13.3538 11.7381 13.2607 11.2725 12.916 11.0427L10.75 9.59869V6.66675Z"
        fill="#98A2B3"
    />
</svg>
