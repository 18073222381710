<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import PageHeader from "../../../../../../components/pageHeader/PageHeader.svelte";
    import { showAccountAlert } from "../../../../../../store/merchant/account.store";
    import API from "../../../../../../services/apiService";
    import { onMount } from "svelte";
    import utils from "../../../../../../utilsV2/currency";
    import StatementTable from "../../../../../../components/table/Table.svelte";
    import { buildStatementSummaryRows } from "../statementUtils";
    import { account as merchantAccount } from "../../../../../../store/merchant/account.store";
    import type { IPeriodDetailResponse, IPeriodStatement } from "../../../../../../static/types";
    import ToolTip from "../../../../../../components/tooltip/ToolTip.svelte";
    import environment from "@/services/environment";

    let periodData: IPeriodDetailResponse = null;
    const tableHeaders = [
        {
            title: $_("period.table.number"),
            clickable: false,
            type: "statement_number",
            tooltip: $_("period.table.numberTooltip")
        },
        { title: $_("period.table.dateIssued"), clickable: false, type: "statement_number" },
        { title: $_("period.table.type"), clickable: false, type: "string" },
        { title: $_("period.table.statementTotal"), clickable: false, type: "gross_amount" },
        { title: $_("period.table.downloaded"), clickable: false, type: "boolean" },
        { title: $_("period.table.download"), clickable: false, notext: true, type: "actions" }
    ];
    let tableRows = [];
    let latestStatement: IPeriodStatement;
    let outstandingAmount: number;
    let statementTotal: number;

    onMount(() => {
        const billing_account_id: string = window.location.pathname.split("/")[4];

        const period_id: string = window.location.pathname.split("/")[6];

        API.get(
            `/billing/v1/account/${billing_account_id}/period/${period_id}?preview_statements=true`
        ).then((response: IPeriodDetailResponse) => {
            periodData = response;
            tableRows = buildStatementSummaryRows(response?.statements, response.currency);
            latestStatement = response.statements.sort((a, b) => {
                // order by issue date and use suffix as tiebreak.
                // This isn't perfect since if we go into double digits it will not work between Z and AA
                if (a.issue_date < b.issue_date) return 1;
                if (a.issue_date > b.issue_date) return -1;
                if (a.statement_suffix > b.statement_suffix) return -1;
                if (a.statement_suffix < b.statement_suffix) return 1;

                return 0;
            })[0];
            if (latestStatement.statement_creation_reason === "PREVIEW") {
                // none live data
                outstandingAmount = latestStatement.outstanding_amount;
                statementTotal = latestStatement.total_amount;
            } else {
                // live data
                outstandingAmount = periodData.totals.outstanding_amount;
                statementTotal =
                    Number(periodData.totals.invoiced_amount) +
                    Number(periodData.totals.credited_amount);
            }
        });
    });
</script>

<div class="page-container">
    <PageHeader
        backLink="/merchant/customers/billing/statements"
        title={periodData
            ? $_("period.statement.titleWithNumber", {
                  values: { statementNumber: periodData.statements[0].statement_number }
              })
            : $_("period.statement.title")}
        showAccountAlert={$showAccountAlert}
    />
    <div class="content-container">
        {#if periodData}
            <div class="detail-cards">
                <div class="card">
                    <h3 style="padding: 1.5rem 1.5rem 0 1.5rem;">
                        {$_("period.statement.paymentDetails")}
                    </h3>
                    <div class="payment-figures">
                        <div>
                            <div class="payment-title">
                                <p>{$_("period.statement.remainingToPay")}</p>
                                <ToolTip
                                    width="250px"
                                    position="right"
                                    placement="start"
                                    content={$_("statements.remainingToPayTooltip")}
                                >
                                    <Fa
                                        size="xs"
                                        icon={faCircleQuestion}
                                        color="var(--primary-500)"
                                    />
                                </ToolTip>
                            </div>
                            <p style="font-weight:700">
                                {utils.formatCurrency(periodData.currency)(outstandingAmount, null)}
                            </p>
                        </div>
                        <div>
                            <div class="payment-title">
                                <p>{$_("period.statement.statementTotal")}</p>
                                <ToolTip
                                    width="250px"
                                    position="right"
                                    placement="start"
                                    content={$_("statements.totalAmountTooltip")}
                                >
                                    <Fa
                                        size="xs"
                                        icon={faCircleQuestion}
                                        color="var(--primary-500)"
                                    />
                                </ToolTip>
                            </div>
                            <p>{utils.formatCurrency(periodData.currency)(statementTotal, null)}</p>
                        </div>
                        <div>
                            <p>{$_("period.statement.lastReconciliationDate")}</p>
                            <p>{new Date(periodData.totals.last_updated).toLocaleDateString()}</p>
                        </div>
                    </div>
                    <div class="payment-details">
                        <div>
                            <p>{$_("period.statement.accountName")}</p>
                            <p>{periodData.bank_account.account_name}</p>
                        </div>
                        <div>
                            <p>{$_("period.statement.accountNumber")}</p>
                            <p>{periodData.bank_account.account_number}</p>
                        </div>
                        <div>
                            <p>{$_("period.statement.sortCode")}</p>
                            <p>{periodData.bank_account.sort_code}</p>
                        </div>
                        <div class="address-details">
                            <p>{$_("period.statement.merchantAddress")}</p>
                            {#each ($merchantAccount.formattedRegisterdAddress ?? "").split(",") || [] as line}
                                <p>{line}</p>
                            {/each}
                        </div>
                        <div>
                            <p>{$_("period.statement.paymentReference")}</p>
                            <p>{periodData.bank_account.payment_reference}</p>
                        </div>
                    </div>
                </div>
                <div class="card" style="padding:1.5rem">
                    <h3 style="padding-bottom:1.5rem">
                        {$_("period.statement.aboutYourStatement")}
                    </h3>
                    <p>
                        {$_("period.statement.aboutDescription", {
                            values: { brandName: environment.branding.displayName }
                        })}
                        <br /><br />
                        {$_("period.statement.aboutDetails")}
                        <br /><br />
                        {$_("period.statement.amendmentInfo")}
                    </p>
                </div>
            </div>
            <div class="table-card">
                <div class="flex items-center justify-between p-6">
                    <p class="text-lg font-bold">{$_("period.statement.documentsTitle")}</p>
                </div>
                <StatementTable
                    {tableHeaders}
                    {tableRows}
                    numAllRecords={tableRows?.length ?? 0}
                    numRows={5}
                    --cursor="auto"
                    loading={false}
                    tableContainerNoOverflow={true}
                />
            </div>
        {/if}
    </div>
</div>

<style>
    .card {
        background: #ffffff;
        border: 1px solid #f2f4f7;
        box-shadow: 0px 2px 6px rgba(208, 213, 221, 0.25);
        border-radius: 8px 8px 0px 0px;
        min-height: 300px;
    }

    .card p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .payment-figures {
        display: grid;
        flex-direction: row;
        gap: 1rem;
        padding: 1.5rem 1.5rem 1rem 1.5rem;
        grid-column: 0.5fr 0.5fr 0.5fr;
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
    }

    .payment-figures div > p:first-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .payment-figures div > p:nth-child(2) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    .payment-details {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        justify-content: space-between;
        gap: 1rem;
        border-top: 1px solid var(--gray-200);
        padding: 1.5rem;
    }

    .payment-details div > p:first-child {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }
    .payment-title {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    .address-details {
        grid-row: 1/3;
        grid-column: 3/4;
    }
    @media (min-width: 950px) {
        .detail-cards {
            grid-template-columns: 0.5fr 0.5fr;
        }
    }
    @media (max-width: 1200px) {
        .payment-details {
            grid-template-columns: 0.5fr 0.5fr;
        }
        .address-details {
            grid-row: 0/1;
            grid-column: 2/3;
        }
    }
    .detail-cards {
        padding: 1.5rem 1.5rem 0rem 1.5rem;
        display: grid;
        gap: 1rem;
    }

    .table-card {
        background: var(--basic);
        border: 1px solid #f2f4f7;
        box-shadow: 0px 2px 6px rgba(208, 213, 221, 0.25);
        border-radius: 8px 8px 0px 0px;
        margin: 1.5rem;
        display: flex;
        overflow: auto;
        flex-direction: column;
    }
</style>
