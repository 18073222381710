<script lang="ts">
    import { _ } from "svelte-i18n";

    import Selector from "../Elements/Selector.svelte";
    import Button from "../Elements/Button.svelte";
    import Switch from "../Elements/Switch.svelte";
    import Checkbox from "../Elements/Checkbox.svelte";
    import { actions as customerActions } from "../../store/merchant/customers.store";
    import { account } from "@/store/merchant/account.store";
    import Input from "../../components/Elements/InputBox.svelte";
    import environment from "@/services/environment";

    export let closeModal;
    export let props: any = {};

    let guaranteedCheckoutEnabled = props.direct_invoice_fallback;
    let guaranteedCheckoutLimit = props.fallback_threshold;
    let guaranteedCheckoutAcceptRisk = false;
    let guaranteedCheckoutAcceptRiskBorderColor = "#D0D5DD";

    let paymentTerm: string = props.due_in_days ? props.due_in_days + " days" : "30 days";
    const options = ["14 days", "21 days", "30 days", "45 days", "60 days", "90 days"];

    const invalid = {
        selectCompany: "",
        paymentTerm: ""
    };

    let buyerFallbackEnabledOnMerchant = false;
    let merchantCurrencyCode = "GBP";
    let invalidLimitInput = false;

    $: {
        buyerFallbackEnabledOnMerchant = $account?.flags?.buyer_fallback_enabled;
        merchantCurrencyCode = $account.feeCurrency;
    }

    function validateEditCustomerInput() {
        let valid = true;
        if (guaranteedCheckoutLimit !== null) {
            guaranteedCheckoutLimit = guaranteedCheckoutLimit.replace(/,/g, "");
        }
        if (guaranteedCheckoutEnabled && buyerFallbackEnabledOnMerchant) {
            if (isNaN(parseInt(guaranteedCheckoutLimit))) {
                invalidLimitInput = true;
                valid = false;
            } else {
                invalidLimitInput = true;
            }

            if (!guaranteedCheckoutAcceptRisk) {
                guaranteedCheckoutAcceptRiskBorderColor = "red";
                valid = false;
            } else {
                guaranteedCheckoutAcceptRiskBorderColor = "#D0D5DD";
            }
        }

        if (valid) {
            customerActions.updateCustomerSettings({
                due_in_days: Number(paymentTerm.split(" ")[0]),
                buyer_company_name: props.buyer_company_name,
                buyer_organization_number: props.buyer_organization_number,
                buyer_country_code: props.buyer_country_code,
                direct_invoice_fallback: guaranteedCheckoutEnabled,
                fallback_threshold:
                    guaranteedCheckoutLimit === "" || guaranteedCheckoutLimit === null
                        ? null
                        : parseInt(guaranteedCheckoutLimit).toString()
            });
        }
    }
</script>

<div class="flex flex-col py-10 px-10 pb-5 modal">
    <p class="text-black-dark font-semibold mb-8 text-2xl">{$_("customer.title")}</p>
    <div class="flex flex-row justify-between gap-4 mb-6">
        <div class="w-full">
            <p class="text-l text-gray-700 font-medium mb-1">{$_("customer.companyNameLabel")}</p>
            <div class="customerInformation">
                {#if props.buyer_company_name.length > 20}
                    {props.buyer_company_name.slice(0, 20)}...
                {:else}
                    {props.buyer_company_name}
                {/if}
            </div>
        </div>
        <div class="w-full">
            <p class="text-l text-gray-700 font-medium mb-1">
                {$_("customer.organisationNumberLabel")}
            </p>
            <div class="customerInformation">
                {props.buyer_organization_number}
            </div>
        </div>
    </div>
    <div class="mb-6">
        <div class="flex flex-row justify-between">
            <div>
                <p class="text-l text-gray-700 font-medium">
                    {$_("customer.invoicePaymentTermsLabel")}
                </p>
                <p class="text-gray-500 break-words max-w-xs">
                    {$_("customer.invoicePaymentTermsDescription")}
                </p>
            </div>
            <div class="dueInDaysSelector">
                <Selector
                    invalid={invalid.paymentTerm}
                    bind:value={paymentTerm}
                    {options}
                    backgroundColor="white"
                />
            </div>
        </div>
    </div>
    {#if buyerFallbackEnabledOnMerchant}
        <div>
            <p class="text-l text-gray-700 font-medium">{$_("customer.guaranteedCheckoutLabel")}</p>
            <div class="flex flex-row justify-between">
                <p class="text-gray-500 break-words max-w-xs">
                    {$_("customer.guaranteedCheckoutDescription", {
                        values: { brandName: environment.branding.displayName }
                    })}
                </p>
                <div>
                    <Switch bind:checked={guaranteedCheckoutEnabled} />
                </div>
            </div>
        </div>
        {#if guaranteedCheckoutEnabled}
            <div class="mb-6 mt-6">
                <p class="text-l text-gray-700 font-medium">
                    {$_("customer.guaranteedCheckoutLimitLabel")}
                </p>
                <div class="flex flex-row justify-between">
                    <p class="text-gray-500 break-words max-w-xs">
                        {$_("customer.guaranteedCheckoutLimitDescription", {
                            values: { currencyCode: merchantCurrencyCode }
                        })}
                    </p>
                    <div class="relative with-type w-1/3">
                        <Input
                            type="text"
                            bind:value={guaranteedCheckoutLimit}
                            placeholder="1000"
                            invalid={invalidLimitInput}
                            showInvalidError={false}
                        />
                        <span class="item-type">{merchantCurrencyCode}</span>
                    </div>
                </div>
            </div>
            <!-- </div> -->
            <div class="guaranteedCheckoutCheckbox text-sm flex flex-row items-center">
                <Checkbox
                    bind:checked={guaranteedCheckoutAcceptRisk}
                    borderColor={guaranteedCheckoutAcceptRiskBorderColor}
                    showCircle={false}
                />
                <p class="guaranteedCheckoutConfirmation">
                    {$_("customer.guaranteedCheckoutCheckboxLabel")}
                </p>
            </div>
        {/if}
    {/if}
    <div class="w-full mt-8 text-right">
        <div class="inline-block w-44 my-2">
            <Button
                label={$_("components.close")}
                on:click={closeModal}
                bgColor="white"
                color="#3E16A2"
                borderColor="#3E16A2"
            />
        </div>
        <div class="inline-block w-44 my-2 mr-2">
            <Button
                label={$_("components.saveChanges")}
                on:click={() => validateEditCustomerInput()}
            />
        </div>
    </div>
</div>

<style>
    .modal {
        min-width: 584px;
        max-width: 584px;
    }
    .customerInformation {
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        padding: 10px 14px;
        background-color: #f9fafb;
        color: #667085;
    }

    .dueInDaysSelector {
        min-width: 25%;
        margin-top: 8px;
    }

    .guaranteedCheckoutCheckbox {
        background-color: #fffaeb;
        padding: 12px;
        border-radius: 8px;
    }

    .guaranteedCheckoutConfirmation {
        color: #93370d;
    }
</style>
