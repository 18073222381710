import type { MerchantB2bOnboardingData } from "./types";

export const merchantData: MerchantB2bOnboardingData = {
    logo_url: "/pictures/logos/rekki-logo.svg",
    two_logo_label: "Trade credit",
    description:
        "Get approved instantly for 30 day invoice terms when you purchase at any supplier through Rekki, at no extra cost. Just enter your company name.",
    billing_address_guidelines:
        "This is your billing address. Supplier orders will be sent to your delivery address.",
    company_screen: {
        heading: "company information",
        subheading: "enter your company details"
    },
    customer_screen: {
        heading: "enter your email address",
        subheading: "your statements will be sent to this address",
        submissionText:
            "BY SUBMITTING YOU GRANT TWO B2B LTD PERMISSION TO CHARGE YOUR ACCOUNT FOR ALL ORDERS PLACED WITH PRO SUPPLIERS.",
        termsLink: "GENERAL T&CS APPLY."
    },
    verify_email_screen: {
        heading: "verify email address",
        subheading: "check your email for the code",
        submissionText:
            "BY SUBMITTING YOU GRANT TWO B2B LTD PERMISSION TO CHARGE YOUR ACCOUNT FOR ALL ORDERS PLACED WITH PRO SUPPLIERS."
    },
    success: {
        icon: "alert",
        heading: "Thank you!",
        details:
            "You can now order with 30 day payment terms when you buy from any PRO supplier on REKKI.",
        success_url: "/buyer/onboarding/customer/user/onboarding-complete"
    },
    failure: {
        icon: "error",
        heading: "Application is received and registered.",
        details:
            "Unfortunately we cannot enroll your company to trade credit at the moment. However, we will on a regular basis evaluate if we can enroll you. If your company become eligible it will be displayed at checkout automatically.",
        failure_url: ""
    },
    tokenExpired: {
        icon: "error",
        heading: "Token Expired"
    },
    skipEmailVerification: {
        content:
            "We recommend verifying your email address to ensure you receive your statements. Are you sure you want to skip?",
        buttonText: "Skip email verification"
    }
};
