<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import DatePicker from "../../Elements/DatePicker.svelte";
    import ApplyBtnGroup from "./ApplyBtnGroup.svelte";

    const dispatch = createEventDispatcher();

    let errorMsg: string = "";

    export let startDate: Date;
    export let endDate: Date;

    export let top: string = "0px";

    const onStartDateChange = (d) => {
        errorMsg = "";
        startDate = d.detail;
    };
    const onEndDateChange = (d) => {
        errorMsg = "";
        endDate = d.detail;
    };

    function setLastWeek() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 6 * 24 * 60 * 60 * 1000);
    }

    function setLastMonth() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    }

    function setLastQuarter() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 90 * 24 * 60 * 60 * 1000);
    }

    function setLastYear() {
        endDate = new Date();
        startDate = new Date(endDate.getTime() - 365 * 24 * 60 * 60 * 1000);
    }

    setLastQuarter();

    const handleApply = () => {
        if (endDate >= startDate) {
            dispatch("setDate", { startDate, endDate });
            dispatch("close");
        } else {
            errorMsg = "invalid duration!";
        }
    };
    const handleCancel = () => {
        dispatch("close");
    };
</script>

<div
    data-testid="dateSelector"
    class="z-50 bg-white rounded-lg purdateWrapper user-shadow"
    style="--top: {top}"
>
    <p class="mb-2 font-bold">{$_("components.datePicker.dateRange")}</p>
    <div class="flex mb-3">
        <div class="mr-2">
            <DatePicker on:datechange={onStartDateChange} selectedDate={startDate} />
        </div>
        <div class="ml-2">
            <DatePicker on:datechange={onEndDateChange} selectedDate={endDate} mode="end" />
        </div>
    </div>
    <div class="mt-4 flex justify-between text-sm font-bold">
        {$_("components.datePicker.last")}
        <p class="w-fit text-primary" on:click={() => setLastWeek()} on:keyup>
            {$_("components.datePicker.week")}
        </p>
        <p class="w-fit text-primary" on:click={() => setLastMonth()} on:keyup>
            {$_("components.datePicker.month")}
        </p>
        <p class="w-fit text-primary" on:click={() => setLastQuarter()} on:keyup>
            {$_("components.datePicker.quarter")}
        </p>
        <p class="w-fit text-primary" on:click={() => setLastYear()} on:keyup>
            {$_("components.datePicker.year")}
        </p>
    </div>
    <p class="text-sm text-red-500">{errorMsg}</p>
    <ApplyBtnGroup on:apply={handleApply} on:cancel={handleCancel} />
</div>

<style>
    .purdateWrapper {
        position: absolute;
        left: 153px;
        top: var(--top);
        padding: 16px;
    }
    @media only screen and (max-width: 600px) {
        .purdateWrapper {
            left: 0px;
            top: calc(var(--top) + 40px);
            width: 280px;
            z-index: 2000;
        }
    }

    .text-primary {
        color: var(--primary-700);
        cursor: pointer;
    }
    .text-primary:hover {
        opacity: 0.8;
    }
</style>
