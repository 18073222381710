<script lang="ts">
    import AuthWrapper from "./AuthWrapper.svelte";
    import authRoutes from "../../routes/auth.routes";
    import Router from "../../routes/CustomRouter.svelte";

    export let url;
</script>

<svelte:head>
    <meta name="viewport" content="width=device-width,initial-scale=1" />
</svelte:head>

<div class="layout">
    <AuthWrapper>
        <Router routes={authRoutes} {url} />
    </AuthWrapper>
</div>

<style lang="scss">
    .layout {
        position: relative;
        background-color: var(--primary-25);
        min-height: 100vh;
        color: var(--ctnGeneralPrimary);
        display: flex;
        flex-direction: column;
    }
</style>
