<script lang="ts">
    import { _ } from "svelte-i18n";
    import { isDate } from "lodash";

    import { DateToFormat, DateToString } from "../../../utils/functions";
    import DateSelector from "./DateSelector.svelte";
    import { clickOutside } from "../../../utils/clickOutside.js";
    import Icon from "../../icons/Icon.svelte";
    import FiltersSidebar from "../../filters/FiltersSidebar.svelte";
    import type { IBillingStatementFilters } from "../../../static/types";
    import CheckboxItem from "../../../components/checkboxitem/CheckboxItem.svelte";

    export let updateTable;

    const ALL = "ALL";
    const NOT_PAID = "NOT_PAID";
    const PARTIAL_PAYMENT = "PARTIAL_PAYMENT";
    const PAID = "PAID";

    let filterCounter: number = 0;
    const filterTags = [
        { value: "All", name: ALL },
        { value: "Downloaded", name: "DOWNLOADED" },
        { value: "Not downloaded", name: "NOT_DOWNLOADED" }
    ];
    const filterPaymentStatuses = [
        { name: ALL, value: "All" },
        { name: PAID, value: "Paid" },
        { name: NOT_PAID, value: "Unpaid" }
        // Don't include partial payment can be confusing
    ];

    const DUE_DATE: string = "Due date";

    let showFilters: boolean = false;
    let filterItem: string = "";

    let startDate: Date;
    let endDate: Date;
    let tags: string[] = [ALL];
    let paymentStatuses: string[] = [ALL];
    let statementFilters: IBillingStatementFilters = {
        downloaded: null,
        payment_statuses: [],
        due_date_from: null,
        due_date_to: null
    };

    const updateFilterCount = function () {
        filterCounter = 0;
        if (startDate || endDate) {
            filterCounter++;
        }
        if (!tags?.includes(ALL) && tags.length > 0) {
            filterCounter++;
        }
        if (!paymentStatuses?.includes(ALL) && paymentStatuses?.length > 0) {
            filterCounter++;
        }
    };

    function clearFilters() {
        Object.entries(statementFilters).map(([key, value]) => {
            if (typeof value == "string") {
                statementFilters[key] = "";
            } else if (value != null && statementFilters[key].length > 0) {
                statementFilters[key].length = 0;
            } else if (!Array.isArray(value)) {
                statementFilters[key] = null;
            }
        });
        tags = [ALL];
        paymentStatuses = [ALL];

        updateFilterCount();
        applyFilters();
        showFilters = false;
    }

    function handleClick(item) {
        filterItem = item;
    }

    function handleClose() {
        filterItem = "";
    }

    function openFilters() {
        showFilters = !showFilters;
    }

    function getFiltersAsQueryParams(filters: IBillingStatementFilters) {
        let queryParams = new URLSearchParams();
        if (filters == null) {
            return queryParams;
        }
        for (let [key, value] of Object.entries(filters)) {
            if (value != null) {
                if (Array.isArray(value) && value.includes(ALL)) {
                    //don't bother filtering if array contains ALL
                    continue;
                }
                //We no longer want not paid and partial payment to be seperated, so add partial to API request if not paid selected
                if (
                    Array.isArray(value) &&
                    value.includes(NOT_PAID) &&
                    !value.includes(PARTIAL_PAYMENT)
                ) {
                    value.push(PARTIAL_PAYMENT);
                }
                if (isDate(value)) {
                    value = DateToFormat(value);
                } else if (typeof value == "string") {
                    value = value.trim();
                } else {
                    value = JSON.stringify(value);
                }
                queryParams.append(key, value);
            }
        }
        return queryParams;
    }

    function checkTags(event) {
        const allValues = filterTags.filter((item) => item.name != ALL).map((item) => item.name);
        tags = filterArray(event, tags, allValues);
    }

    function checkPayments(event) {
        const allValues = filterPaymentStatuses
            .filter((item) => item.name != ALL)
            .map((item) => item.name);
        paymentStatuses = filterArray(event, paymentStatuses, allValues);
    }

    const filterArray = (event, checkedArray: string[], allValues: string[]) => {
        if (event.detail.checked) {
            if (event.detail.checkedItemKey === ALL) {
                checkedArray = [ALL];
            } else {
                checkedArray.push(event.detail.checkedItemKey);

                if (allValues.every((item) => checkedArray.includes(item))) {
                    checkedArray = [ALL];
                }
            }
        }

        if (!event.detail.checked) {
            if (event.detail.checkedItemKey === ALL) {
                checkedArray = [];
            } else if (checkedArray.includes(ALL)) {
                checkedArray = allValues;
            }

            const index = checkedArray.indexOf(event.detail.checkedItemKey);
            if (index !== -1) {
                checkedArray.splice(index, 1);
            }
        }
        return checkedArray;
    };

    function applyFilters() {
        // only add downloaded filter, of either tag included not all of the download options
        if (
            (tags.includes("DOWNLOADED") || tags.includes("NOT_DOWNLOADED")) &&
            !["DOWNLOADED", "NOT_DOWNLOADED"].every((tag) => tags.includes(tag))
        ) {
            //DOWNLOADED and NOT_DOWNLOADED cancel eachother out,
            // should not occur atm, but will happen when more tags are added
            statementFilters.downloaded = tags.includes("DOWNLOADED");
        }

        statementFilters.payment_statuses.push(...paymentStatuses);

        const queryParams = getFiltersAsQueryParams(statementFilters);
        updateTable(queryParams.toString());

        // reset the const arrays
        statementFilters.downloaded = null;
        statementFilters.payment_statuses.length = 0;
        updateFilterCount();

        // close filter bar
        showFilters = false;
    }
</script>

<div class="filter-button" class:filters-active={filterCounter > 0} on:click={openFilters} on:keyup>
    <Icon name="sliders" size={16} />
    <span>{$_("filters.filters")}</span>
    {#if filterCounter > 0}
        <div class="filter-counter">{filterCounter}</div>
    {/if}
</div>

{#if showFilters}
    <FiltersSidebar>
        <div slot="header" class="filter-header">
            <div>
                <span>{$_("filters.filtersFor")}</span>
                <span class="header-subtext">{$_("filters.statements")}</span>
            </div>
            <div class="close">
                <button on:click={openFilters}>
                    <Icon name="cross" size={32} />
                </button>
            </div>
        </div>
        <div slot="main">
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("filters.paymentStatus")}</span>
                <div class="order-status-items">
                    {#each filterPaymentStatuses as paymentStatus}
                        <CheckboxItem
                            statusItem={paymentStatus}
                            isChecked={paymentStatuses.includes(paymentStatus.name) ||
                                paymentStatuses.includes(ALL)}
                            on:checkedItem={checkPayments}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text">{$_("filters.tags")}</span>
                <div class="order-status-items">
                    {#each filterTags as filterTag}
                        <CheckboxItem
                            statusItem={filterTag}
                            isChecked={tags.includes(filterTag.name) || tags.includes(ALL)}
                            on:checkedItem={checkTags}
                        />
                    {/each}
                </div>
            </div>
            <div class="filter-item">
                <span class="filter-item-header-text">{DUE_DATE}</span>
                <span>{$_("filters.chooseRange")}</span>
                <div on:click={() => handleClick(DUE_DATE)} on:keyup class="data-range date-range">
                    {`${DateToString(statementFilters.due_date_from, true)} - ${DateToString(
                        statementFilters.due_date_to,
                        true
                    )}`}
                </div>
                {#if filterItem === DUE_DATE}
                    <div use:clickOutside on:click_outside={handleClose}>
                        <DateSelector
                            on:close={handleClose}
                            bind:startDate={statementFilters.due_date_from}
                            bind:endDate={statementFilters.due_date_to}
                            top="155px"
                        />
                    </div>
                {/if}
            </div>
        </div>
        <div slot="footer" class="filter-footer">
            <div class="apply-button" on:click={applyFilters} on:keyup>{$_("filters.applyFilters")}</div>
            <div class="clear-button" on:click={clearFilters} on:keyup>{$_("filters.clearAll")}</div>
        </div>
    </FiltersSidebar>
{/if}

<style>
    .filter-header {
        display: flex;
        justify-content: space-between;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--ctnGeneralPrimary);
    }

    .filter-footer {
        display: flex;
        gap: 0.5rem;
    }

    .filter-button {
        position: relative;
        margin: auto 0;
        width: 6.125rem;
        height: 2.25rem;
        line-height: 2.25rem;
        background: #ffffff;
        border: 1px solid var(--borderGeneralSecondary);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        cursor: pointer;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: var(--ctnInteractiveGeneralDefault);
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
    }

    .filter-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .filters-active {
        border: 2px solid var(--ctnInteractiveGeneralDefault);
    }

    .filter-counter {
        position: absolute;
        width: 20px;
        height: 20px;
        background: var(--ctnInteractiveGeneralDefault);
        border-radius: 16px;
        color: white;
        top: -10px;
        right: -10px;
        line-height: 20px;
    }

    .apply-button {
        color: white;
        width: 126px;
        height: 3rem;
        line-height: 3rem;
        background: var(--primary-600);
        border: 1px solid var(--primary-600);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.5px;
        cursor: pointer;
    }

    .clear-button {
        width: 6.125rem;
        height: 3rem;
        line-height: 3rem;
        background: #ffffff;
        border: 1px solid var(--borderGeneralSecondary);
        box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
        border-radius: 0.5rem;
        text-align: center;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: var(--ctnInteractiveGeneralDefault);
        cursor: pointer;
    }

    .clear-button:hover {
        background: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .header-subtext {
        font-weight: 400;
    }

    .filter-item-header-text {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: var(--ctnGeneralPrimary);
    }
    .filter-item {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0.25rem 0 2rem 0;
        margin: 0 0 1rem 0;
        gap: 1rem;
        border-bottom: 1px solid #e4e7ec;
    }

    .date-range {
        border-radius: 0.5rem;
        max-width: 15rem;
    }

    .order-status-items {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 0.5rem;
    }

    .data-range {
        display: flex;
        border: 1px solid #e4e7ec;
        padding: 0 1rem;
        height: 2.5rem;
        line-height: 2.5rem;
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: var(--ctnGeneralPrimary);
    }

    * :global(.purdateWrapper) {
        top: 130px !important;
        left: 0px !important;
        z-index: 1000;
    }
</style>
