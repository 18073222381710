<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 12;
</script>

<svg width={size} height={size} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M7.1885 5.0875L6.6935 5.5935C6.2975 5.9895 6.05 6.325 6.05 7.15H4.95V6.875C4.95 6.2645 5.1975 5.7145 5.5935 5.3185L6.2755 4.6255C6.479 4.4275 6.6 4.1525 6.6 3.85C6.6 3.2395 6.105 2.75 5.5 2.75C5.20826 2.75 4.92847 2.86589 4.72218 3.07218C4.51589 3.27847 4.4 3.55826 4.4 3.85H3.3C3.3 3.26652 3.53179 2.70694 3.94437 2.29436C4.35695 1.88178 4.91652 1.65 5.5 1.65C6.08348 1.65 6.64305 1.88178 7.05563 2.29436C7.46821 2.70694 7.7 3.26652 7.7 3.85C7.7 4.334 7.502 4.7685 7.1885 5.0875ZM6.05 9.35H4.95V8.25H6.05V9.35ZM5.5 0C4.77773 0 4.06253 0.142262 3.39524 0.418663C2.72795 0.695063 2.12163 1.10019 1.61091 1.61091C0.579463 2.64236 0 4.04131 0 5.5C0 6.95869 0.579463 8.35764 1.61091 9.38909C2.12163 9.89981 2.72795 10.3049 3.39524 10.5813C4.06253 10.8577 4.77773 11 5.5 11C6.95869 11 8.35764 10.4205 9.38909 9.38909C10.4205 8.35764 11 6.95869 11 5.5C11 2.4585 8.525 0 5.5 0Z"
        {fill}
    />
</svg>
