<script lang="ts">
    import { _ } from "svelte-i18n";

    import environment from "@/services/environment";
    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import { FILE_TYPES } from "../../../static/constant";
    import type { CurrencyCodeEnum, IStatement } from "../../../static/types";
    import { clickOutside } from "../../../utils/clickOutside.js";
    import {
        DateToFormatOnlyDate,
        DateToFormatOnlyMonth,
        DateToFormatOnlyYear,
        objectSort,
        SORT_TYPE
    } from "../../../utils/functions";
    import utils from "../../../utilsV2/currency";

    export let allStatements: IStatement[] = [];
    export let rowsPerPage = 10;
    export let loading: boolean;
    export let currencyCode: CurrencyCodeEnum;

    enum DATA_TYPE {
        DEFAULT,
        DATE,
        AMOUNT
    }

    const order = {
        columnIndex: 0,
        ascending: false
    };
    let currentlyVisibleStatements: IStatement[];
    let pageCount = 1;
    let currentPage = 1;
    let downloadSelectionIndex: boolean[];

    $: allStatements, currentPage, updateTable();

    $: currentRange = `${rowsPerPage * currentPage - rowsPerPage + 1}-${
        rowsPerPage * currentPage > allStatements.length
            ? allStatements.length
            : rowsPerPage * currentPage
    }`;

    function updateTable() {
        const sortingColumn = tableColumns[order.columnIndex];

        currentlyVisibleStatements = objectSort(
            allStatements,
            sortingColumn.propertyName,
            order.ascending,
            sortingColumn.sortType
        ).slice(rowsPerPage * (currentPage - 1), rowsPerPage * currentPage);

        pageCount = Math.ceil(allStatements.length / rowsPerPage);

        downloadSelectionIndex = new Array(currentlyVisibleStatements.length);
    }

    function getHeaders(): string[] {
        return tableColumns.map(({ label }) => label);
    }

    function onSortChange(columnIndex: number) {
        if (order.columnIndex === columnIndex) {
            order.ascending = !order.ascending;
        } else {
            order.columnIndex = columnIndex;
            order.ascending = false; // descending sort by default on all columns
        }
        currentPage = 1;
        updateTable();
    }

    function formatValue(statement: IStatement, column): string {
        const rawValue = statement[column.propertyName];
        switch (column.type) {
            case DATA_TYPE.AMOUNT:
                return utils.formatCurrency(currencyCode)(rawValue);
            case DATA_TYPE.DATE:
                const date = new Date(rawValue);
                return (
                    `${DateToFormatOnlyDate(date)} ${DateToFormatOnlyMonth(date)}, ` +
                    `${DateToFormatOnlyYear(date)}`
                );
            default:
                return rawValue;
        }
    }

    function goToNextPage() {
        if (currentPage < allStatements.length / rowsPerPage) {
            currentPage++;
        }
    }

    function goToPreviousPage() {
        if (currentPage > 1) {
            currentPage--;
        }
    }

    function dismissDownloadBox(index: number) {
        downloadSelectionIndex[index] = false;
    }

    function toggleDownloadBox(index: number) {
        downloadSelectionIndex[index] = !downloadSelectionIndex[index];
    }

    function downloadSelectedStatement(option: string, index: number) {
        window.location.href =
            currentlyVisibleStatements[index].download_url_xlsx + `&filetype=${option}&legacy=true`;
        downloadSelectionIndex[index] = false;
    }

    const tableColumns: {
        label: string;
        propertyName: string;
        type: DATA_TYPE;
        sortType: SORT_TYPE;
    }[] = [
        {
            label: $_("statement.table.settlement_period"),
            propertyName: "date_payout",
            type: DATA_TYPE.DATE,
            sortType: SORT_TYPE.date
        },
        {
            label: $_("statement.table.orders"),
            propertyName: "number_of_entries",
            type: DATA_TYPE.DEFAULT,
            sortType: SORT_TYPE.number
        },
        {
            label: $_("statement.table.fulfilled"),
            propertyName: "fulfilled_amount",
            type: DATA_TYPE.AMOUNT,
            sortType: SORT_TYPE.number
        },
        {
            label: $_("statement.table.fee"),
            propertyName: "fee",
            type: DATA_TYPE.AMOUNT,
            sortType: SORT_TYPE.number
        },
        {
            label: $_("statement.table.paid_out"),
            propertyName: "net_payout",
            type: DATA_TYPE.AMOUNT,
            sortType: SORT_TYPE.number
        },
        {
            label: $_("statement.table.reversals"),
            propertyName: "refunded_amount",
            type: DATA_TYPE.AMOUNT,
            sortType: SORT_TYPE.number
        }
    ];
</script>

<table class="statements-table">
    <thead class="statements-table__head">
        {#each getHeaders() as header, index}
            <th class="statements-table__header" on:click={() => onSortChange(index)}>
                <div class="statements-table__header-content">
                    {header}
                    {#if order.columnIndex === index}
                        <div class="icon-sort" class:icon-rotate={!order.ascending}>
                            <Icon name="arrow-up" size={14} fill="#55536A" />
                        </div>
                    {/if}
                </div>
            </th>
        {/each}
        <th class="statements-table__header"></th>
    </thead>

    <tbody>
        {#if !loading && currentlyVisibleStatements.length}
            {#each currentlyVisibleStatements as statement, index}
                <tr class="statements-table__row">
                    {#each tableColumns as column}
                        <td class="statements-table__row-content">
                            {formatValue(statement, column)}
                        </td>
                    {/each}
                    <td
                        class="statements-table__row-download"
                        use:clickOutside
                        on:click_outside={() => dismissDownloadBox(index)}
                    >
                        <button
                            class="button-download"
                            on:click={() => {
                                toggleDownloadBox(index);
                            }}
                        >
                            <Icon name="file-download" fill="var(--primary-700)" size={24} />
                        </button>
                        {#if downloadSelectionIndex[index] === true}
                            <div class="download-box">
                                {#each FILE_TYPES as option, i}
                                    <div
                                        class="download-box__option"
                                        on:click={() => downloadSelectedStatement(option, index)}
                                        on:keyup
                                        class:border-none={FILE_TYPES.length === i + 1}
                                    >
                                        {$_("statement.downloadAs")} .{option}
                                    </div>
                                {/each}
                            </div>
                        {/if}
                    </td>
                </tr>
            {/each}
        {:else if loading}
            <tr>
                <td colspan={tableColumns.length + 1}>
                    <div class="loader-wrap">
                        <LoadingWrapper {loading} />
                    </div>
                </td>
            </tr>
        {:else}
            <tr>
                <td colspan={tableColumns.length + 1}>
                    <div class="empty">
                        <Icon name="file-invoice" fill="#2F384C" size={50} />
                        <p class="empty__header">{$_("statement.noPayouts")}</p>
                        <p class="empty__body">
                            {$_("statement.no_payout_message", {
                                values: { brand: environment.branding.displayName }
                            })}
                            <a href="/merchant/account" class="link">
                                {$_("statement.account_page")}</a
                            >.
                        </p>
                    </div>
                </td>
            </tr>
        {/if}
    </tbody>
</table>
{#if allStatements.length}
    <section class="pagination">
        <span>{`${currentRange} of ${allStatements.length}`}</span>
        <button disabled={currentPage === 1} on:click={() => goToPreviousPage()}>
            <Icon name="arrow-left" size={16} fill={currentPage === 1 ? "#D0D5DD" : "#667085"} />
        </button>
        <span
            >{$_("components.table.pageNumOfPages", {
                values: { pageNum: currentPage, amount: pageCount }
            })}</span
        >
        <button
            class="icon-flip"
            disabled={currentPage === pageCount}
            on:click={() => goToNextPage()}
        >
            <Icon
                name="arrow-left"
                size={16}
                fill={currentPage === pageCount ? "#D0D5DD" : "#667085"}
            />
        </button>
    </section>
{/if}

<style lang="scss">
    /* global styles reset TODO: review when global cleared */
    th {
        height: unset;
    }

    tr {
        cursor: unset;
    }
    /* /global styles reset */

    .statements-table {
        width: 100%;
        table-layout: fixed;
        font-size: 14px;
    }

    .statements-table__head {
        background-color: #ebeaef;
        color: var(--main-color);
    }

    .statements-table__header {
        width: 100%;

        &:first-child {
            padding-left: 24px;
            width: 190px;
        }

        &:last-child {
            width: 80px;
            cursor: default;
        }
    }

    .statements-table__header-content {
        display: flex;
        flex-direction: row;
        width: 100%;

        text-align: left;
        align-items: center;
        gap: 10px;
        padding: 12px 0;
    }

    .icon-sort {
        padding: 0 8px;
    }

    .statements-table__row {
        height: 72px;
        color: var(--gray-700);
        border-bottom: 1px solid var(--gray-200);
    }

    .statements-table__row-content {
        &:first-child {
            padding-left: 24px;
        }
    }

    .statements-table__row-download {
        position: relative;
    }

    .statements-table__row:hover {
        box-shadow: 0 4px 20px rgba(24, 13, 49, 0.1);
        border-radius: 8px;
    }

    .button-download {
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 18px;
        border-radius: 8px;
        background-color: var(--primary-50);
    }

    .download-box {
        position: absolute;
        width: 240px;
        left: -150px;

        background-color: white;
        z-index: 20;
        border-radius: 8px;
        box-shadow: 0 5px 30px rgba(64, 58, 85, 0.1);
    }

    .download-box__option {
        padding: 10px 16px;
        cursor: pointer;

        &:hover {
            background-color: var(--gray-50);
        }
    }

    .loader-wrap {
        padding: 80px;
        position: relative;
    }

    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px;
        gap: 26px;
    }

    .empty__header {
        font-size: 24px;
        font-weight: 700;
    }

    .empty__body {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--gray-500);
        max-width: 725px;
        text-align: center;
    }

    .link {
        color: var(--primary-600);
    }

    .pagination {
        display: flex;
        justify-content: end;
        gap: 16px;
        padding: 18px 44px;

        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--gray-700);
    }

    button:disabled {
        cursor: default;
    }
</style>
