<script lang="ts">
    export let size = 20;
    export let fill: string = "var(--primary-500)";
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="plus-rec">
        <path
            id="Vector"
            d="M6.6665 10.0001H9.99984M9.99984 10.0001H13.3332M9.99984 10.0001V6.66675M9.99984 10.0001V13.3334M8.06651 18.3334H11.9332C14.1734 18.3334 15.2935 18.3334 16.1491 17.8974C16.9018 17.5139 17.5137 16.902 17.8972 16.1494C18.3332 15.2937 18.3332 14.1736 18.3332 11.9334V8.06675C18.3332 5.82654 18.3332 4.70643 17.8972 3.85079C17.5137 3.09814 16.9018 2.48622 16.1491 2.10272C15.2935 1.66675 14.1734 1.66675 11.9332 1.66675H8.0665C5.82629 1.66675 4.70619 1.66675 3.85054 2.10272C3.09789 2.48622 2.48597 3.09814 2.10248 3.85079C1.6665 4.70643 1.6665 5.82654 1.6665 8.06675V11.9334C1.6665 14.1736 1.6665 15.2937 2.10248 16.1494C2.48597 16.902 3.09789 17.5139 3.85054 17.8974C4.70619 18.3334 5.82629 18.3334 8.06651 18.3334Z"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </g>
</svg>
