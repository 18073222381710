import { writable } from "svelte/store";

let initModal = { type: null, props: null, component: null };

function createModal() {
    const modal = writable(initModal);
    return {
        modals: modal.subscribe,
        actions: {
            setModal: (type: string, props: any, component: any = null) => {
                modal.set({
                    type: type,
                    props: props,
                    component: component
                });
            }
        }
    };
}

const modalState = createModal();
export default modalState;
