<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";
    import { Button as TwoUIButton } from "@two-ui/core";

    import modalState from "../../store/modals.store";
    import Icon from "../icons/Icon.svelte";
    import { account } from "@/store/merchant/account.store";

    export let fName: string = "";
    export let companyName: string = "";

    const handleNewOrder = () => {
        navigate("/merchant/createorder");
        modalState.actions.setModal(null, {});
    };

    const handleCancel = () => {
        navigate("/merchant/dashboard");
        modalState.actions.setModal(null, {});
    };
</script>

<div class="w-2xs p-8">
    <div class="w-full flex justify-between mb-5">
        <p id="order_created_model" class="text-xl font-semibold">
            {$_("order.orderCreated")}
        </p>
        <div class="cursor-pointer" on:click={handleCancel} on:keyup>
            <div>
                <Icon size={24} name="cross" fill="#222222" />
            </div>
        </div>
    </div>
  {#if !$account?.flags.enable_create_order_via_oc}
    <div class="mb-10 text-gray-500">
      {$_("order.emailNotification", { values: { fName, companyName } })}
    </div>
  {/if}
    <div class="flex w-full justify-end">
        <div class="w-52 mr-2 mt-5">
            <TwoUIButton
                on:click={handleNewOrder}
                size="md"
                variant="secondaryGray"
                content={$_("order.createAnotherOrder")}
                fullSize={true}
            />
        </div>
        <div class="w-24 ml-2 mt-5">
            <TwoUIButton
                on:click={handleCancel}
                size="md"
                variant="primary"
                content={$_("components.close")}
                fullSize={true}
            />
        </div>
    </div>
</div>
