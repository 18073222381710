import { writable } from "svelte/store";
import notificationState from "./notifications.store";
import { navigate } from "svelte-routing";
import { merchantData } from "./../static/b2bonboardingmerchantmock";
const ENABLE_ONBOARDING_EMAIL_VERIFICATION = import.meta.env.ENABLE_ONBOARDING_EMAIL_VERIFICATION;
import {
    GET_BUSINESS_CUSTOMER,
    UPDATE_BUSINESS_CUSTOMER,
    CREATE_CUSTOMER_USER,
    TRIGGER_VERIFY_BILLING_EMAIL,
    VERIFY_BILLING_EMAIL
} from "./../static/endPoints";
import API from "../services/apiService";
import { getBusinessCustomerData } from "../utils/auth";
import { NotificationType } from "@/static/types";

function createBusinessCustomer() {
    let customerData: any = writable({});
    let userData: any = writable({});
    let customerBillingEmailVerified: any = writable(false);
    let enableEmailVerification: any = writable(
        ENABLE_ONBOARDING_EMAIL_VERIFICATION == undefined ||
        ENABLE_ONBOARDING_EMAIL_VERIFICATION.toLowerCase() == "true"
    );
    let triggerSkipVerifyEmailConfirmation: any = writable(false);
    let tooManyVerificationAttempts: any = writable(false);
    let skipVerificationCode: any = writable(false);

    let userInfo = getBusinessCustomerData();
    let token: string = userInfo?.token;

    // let SECRET_API_KEY: string = "secret_test_vZclT9h-f-VC1h1XnM68UNtCquboKqP3p_G8q40VVSI";

    const merchantOnboardingData = writable(merchantData);

    let updateCustomerLoading = writable(false);

    let verifyEmailLoading = writable(false);

    const setCustomerUpdateLoading = (value: boolean): any => {
        updateCustomerLoading.set(value);
    };

    const setVerifyEmailLoading = (value: boolean): any => {
        verifyEmailLoading.set(value);
    };

    const setTooManyVerificationAttempts = (value: boolean): any => {
        tooManyVerificationAttempts.set(value);
    };

    const setTriggerSkipVerifyEmailConfirmation = (value: boolean): any => {
        triggerSkipVerifyEmailConfirmation.set(value);
    };

    const setSkipVerificationCode = (value: boolean): any => {
        skipVerificationCode.set(value);
    };

    return {
        updateCustomerLoading,
        verifyEmailLoading,
        tooManyVerificationAttempts,
        merchantOnboardingData,
        customerBillingEmailVerified,
        enableEmailVerification,
        triggerSkipVerifyEmailConfirmation,
        skipVerificationCode,
        customerData: {
            subscribe: customerData.subscribe,
            actions: {
                setTriggerSkipVerifyEmailConfirmation,
                getCustomer: (mid: string, cid: string) => {
                    let customerResponse: any = {};
                    API.get(GET_BUSINESS_CUSTOMER(mid, cid) + "?token=" + token)
                        .then((res: any) => {
                            customerResponse = res;
                            customerData.set(customerResponse);
                            merchantOnboardingData.set(merchantData);
                        })
                        .catch((err) => {
                            console.error(err.response.data);
                            let message: string =
                                err.response.data.error_message ||
                                "Something went wrong. Please try again later.";
                            if (err.response.status == 401) {
                                // check the status code for token
                                navigate("/buyer/onboarding/customer/token-expired");
                            }
                            if (err.response.status == 404) {
                                message = "Server not available";
                            }

                            notificationState.actions.create(NotificationType.ERROR, message);
                        });
                },
                updateCustomer: (mid: string, cid: string, data: any) => {
                    setCustomerUpdateLoading(true);
                    API.patch(UPDATE_BUSINESS_CUSTOMER(mid, cid) + "?token=" + token, data)
                        .then((res: any) => {
                            setCustomerUpdateLoading(false);
                            console.log(res);
                            // check if it's approved for credit then allow user creation
                            // if(res?.approved_for_credit) {
                            //   customerData.set(res); // update the customer profile in store
                            //   navigate("/buyer/onboarding/customer/create-user-success");
                            // } else {
                            //   navigate("/buyer/onboarding/customer/create-user-failed");
                            // }

                            try {
                                window.location.href = res?.onboarding_completed_url;
                            } catch (e) {
                                console.error(e);
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            setCustomerUpdateLoading(false);
                            // let message:string = "error";
                            // if(error.response.data) {
                            //   let _error = error.response.data;
                            //   message = _error.error_message;

                            //   if(_error.error_code == "MERCHANT_CUSTOMER_ALREADY_EXISTS") {
                            //     message = "This company has already completed Two onboarding. If you have any questions please contact Rekki support.";
                            //   }

                            // }
                            try {
                                let message: string =
                                    error.response?.data?.error_message || "Network error";
                                if (error.response?.status == 401) {
                                    // check the status code for token
                                    navigate("/buyer/onboarding/customer/token-expired");
                                }
                                if (error.response?.status == 404) {
                                    message = "Server not available";
                                }
                                notificationState.actions.create(NotificationType.ERROR, message);

                                if (error.response.data?.onboarding_failed_url) {
                                    window.location.href =
                                        error.response.data?.onboarding_failed_url;
                                }
                            } catch (e) {
                                console.error(e);
                            }
                        });
                },
                sendVerificationCode: (mid: string, cid: string, data: any) => {
                    API.post(TRIGGER_VERIFY_BILLING_EMAIL(mid, cid) + "?token=" + token, data)
                        .then((res: any) => {
                            setTooManyVerificationAttempts(false);
                        })
                        .catch((err) => {
                            console.error(err.response.data);
                            let message: string =
                                err.response.data.error_message ||
                                "Something went wrong. Please try again later.";
                            if (err.response.status == 401) {
                                // check the status code for token
                                navigate("/buyer/onboarding/customer/token-expired");
                            }
                            if (err.response.status == 404) {
                                message = "Server not available";
                            }
                            notificationState.actions.create(NotificationType.ERROR, message);
                        });
                },
                verifyEmailCode: (data: any) => {
                    setVerifyEmailLoading(true);
                    API.post(VERIFY_BILLING_EMAIL + "?token=" + token, data)
                        .then((res: any) => {
                            setVerifyEmailLoading(false);
                            customerBillingEmailVerified.set(true);
                        })
                        .catch((err) => {
                            setVerifyEmailLoading(false);
                            customerBillingEmailVerified.set(false);
                            if (err.response.status == 429) {
                                setTooManyVerificationAttempts(true);
                            }
                        });
                },
                skipEmailVerification: () => {
                    setTriggerSkipVerifyEmailConfirmation(false);
                    customerBillingEmailVerified.set(true);
                    setSkipVerificationCode(true);
                }
            }
        },
        userData: {
            subscribe: userData.subscribe,
            actions: {
                createUser: (mid: string, cid: string, data: any) => {
                    API.post(CREATE_CUSTOMER_USER(mid, cid) + "?token=" + token, data)
                        .then((res: any) => {
                            userData.set(res);
                            setTimeout(() => {
                                navigate("/buyer/onboarding/customer/create-user-success");
                            }, 2000);
                        })
                        .catch((err) => {
                            navigate("/buyer/onboarding/customer/create-user-failed");
                        });
                }
            }
        }
    };
}

const b2bonboardingState: any = createBusinessCustomer();
export default b2bonboardingState;
