<script lang="ts">
    export let fill: string = "#4CFAFF";
</script>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.2712 10.8427C-0.777827 5.66498 2.92222 0 8.35302 0H8.64601C12.7075 0 16 3.38957 16 7.57081C16 12.2261 12.3342 16 7.81221 16H0.683695C0.39359 16 0.135074 15.8115 0.0387901 15.5298C-0.0574938 15.2481 0.029422 14.9344 0.255615 14.7474L2.05271 13.2617C2.13129 13.1967 2.15909 13.0863 2.12106 12.9902L1.2712 10.8427ZM8.35302 1.40762C3.89327 1.40762 0.854805 6.05969 2.53745 10.3116L3.38732 12.4591C3.65349 13.1317 3.45892 13.9044 2.90887 14.3592L2.6268 14.5924H7.81221C11.5791 14.5924 14.6327 11.4487 14.6327 7.57081C14.6327 4.16698 11.9524 1.40762 8.64601 1.40762H8.35302Z"
        {fill}
    />
</svg>
