import { writable } from "svelte/store";
import { getToken } from "../../utils/auth";
// import { groupedStatements } from "../../static/groupedPaymentStatements";
import API from "../../services/apiService";
import { GET_GROUPED_ORDERS } from "../../static/endPoints";
import { groupedOrdersFiltering } from "../../utils/functions";

function createMerchantGroupedOrders() {
    let ordersList: any[] = [];
    const groupedOrders = writable(ordersList);
    const isGroupedOrders = writable(false);

    let filters = writable({});

    return {
        isGroupedOrders,
        filters,
        groupedOrders: {
            subscribe: groupedOrders.subscribe,
            update: groupedOrders.update,
            actions: {
                getGroupedOrders: () => {
                    // API endpoint here

                    API.get(GET_GROUPED_ORDERS)
                        .then((res: any) => {
                            if (res?.statements) {
                                ordersList = res?.statements;
                                groupedOrders.set(ordersList);
                                isGroupedOrders.set(ordersList.length > 0);
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                },

                // Search transactions to match search string
                setSearch: (value: string): void => {
                    let curOrders: any[] = [];
                    curOrders = ordersList.filter((order: any) => {
                        let comName = order.buyer_company_name
                            ? order.buyer_company_name.toUpperCase()
                            : "";
                        // let userName = order.userName ? order.userName.toUpperCase() : "";
                        let statementId = order.statement_number
                            ? order.statement_number.toString()
                            : "";
                        let searchString = value.toUpperCase();
                        if (
                            comName.indexOf(searchString) !== -1 ||
                            // userName.indexOf(searchString) !== -1 ||
                            statementId.indexOf(searchString) !== -1
                        ) {
                            return true;
                        }
                        return false;
                    });
                    groupedOrders.set(curOrders);
                },

                filtering: (filterItems: any) => {
                    let filteredOrders = groupedOrdersFiltering(ordersList, filterItems);
                    groupedOrders.set(filteredOrders);
                },

                getStatementID: (statement_number: string) => {
                    let statementID = ordersList.find((order: any) => {
                        return order.statement_number == statement_number;
                    });
                    return statementID?.statement_id;
                }
            }
        }
    };
}

const mGroupedOrdersState = createMerchantGroupedOrders();
export default mGroupedOrdersState;

//store for selected filters for grouped orders
export const groupedFilters: any = writable({});
