<script lang="ts">
    export let size = 20;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2669_744)">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.04167 9.44195C1.04167 8.31235 1.04167 7.74755 1.18533 7.22608C1.31259 6.76411 1.52183 6.32877 1.80307 5.9408C2.12052 5.50286 2.56156 5.15004 3.44362 4.44438L6.00196 2.39772C7.42883 1.25622 8.14227 0.685471 8.93348 0.466585C9.63139 0.27351 10.3686 0.27351 11.0665 0.466585C11.8577 0.685471 12.5712 1.25622 13.9981 2.39772L16.5564 4.44439C17.4385 5.15004 17.8795 5.50286 18.1969 5.9408C18.4782 6.32877 18.6874 6.76411 18.8147 7.22608C18.9583 7.74755 18.9583 8.31235 18.9583 9.44194V12.558C18.9583 14.7982 18.9583 15.9183 18.5224 16.774C18.1389 17.5266 17.5269 18.1385 16.7743 18.522C15.9187 18.958 14.7985 18.958 12.5583 18.958H7.44167C5.20146 18.958 4.08136 18.958 3.22571 18.522C2.47306 18.1385 1.86114 17.5266 1.47765 16.774C1.04167 15.9183 1.04167 14.7982 1.04167 12.558V9.44195ZM7.29167 12.7084C6.94649 12.7084 6.66667 12.9882 6.66667 13.3334C6.66667 13.6786 6.94649 13.9584 7.29167 13.9584H12.7083C13.0535 13.9584 13.3333 13.6786 13.3333 13.3334C13.3333 12.9882 13.0535 12.7084 12.7083 12.7084H7.29167Z"
            fill="#98A2B3"
        />
    </g>
    <defs>
        <clipPath id="clip0_2669_744">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>
