<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";
    import { Button as TwoUIButton } from "@two-ui/core";
    import { createEventDispatcher, onMount } from "svelte";

    import Icon from "../../components/icons/Icon.svelte";
    import QuestionIcon from "../../components/icons/question_comment.svelte";
    import ExternalLinkIcon from "../icons/external-link.svelte";
    import * as helpLinks from "../../services/helpCentreService";
    import { Tooltip } from "@two-ui/core";
    import { clickOutside } from "../../utilsV2/clickOutside";
    import { signupComplete } from "@/store/merchant/account.store";
    import { ACCOUNT_SETUP } from "@/static/modals";
    import modalState from "@/store/modals.store";
    import type { IBanner } from "../../static/types";
    import { NotificationTypeIcons } from "../../static/constant";
    import { DOCS_URL } from "../../static/endPoints";
    import environment from "@/services/environment";

    const dispatch = createEventDispatcher();

    export let title: string;
    export let description: string = "";
    export let descriptionHtml: string = "";
    export let showAccountAlert: boolean = false;
    export let backLink: string = "";
    export let newUser: boolean = true;
    export let pageBanner: IBanner = null;
    export let customClasses: string = "";

    let isBuyerPortal = true;
    let staticHelpLinks: Record<string, string> = {};
    let handyPageLinks: Record<string, string> = {};
    let hasSeenPageHelp = true;
    let newUserTooltipActive = false;
    let pageBannerColor = "";
    let pageBannerIcon;
    let isOnStatementPage = false;

    const merchantQuickLinks = {
        dashboard: {
            [$_("helpLinks.portalTour")]: helpLinks.PORTAL_TOUR,
            [$_("helpLinks.merchantFaq")]: helpLinks.MERCHANT_FAQ,
            [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        },
        orders: {
            [$_("helpLinks.orderCreateFaq")]: helpLinks.ORDER_CREATE_FAQ,
            [$_("helpLinks.orderStatusFaq")]: helpLinks.ORDER_STATUS_FAQ,
            [$_("helpLinks.creditRiskFaq")]: helpLinks.CREDIT_RISK_FAQ,
            [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink,
            [$_("helpLinks.customerRecourseFaq")]: helpLinks.CUSTOMER_RECOURSE_FAQ
        },
        customers: {
            [$_("helpLinks.customerTabFaq")]: helpLinks.CUSTOMER_TAB_FAQ,
            [$_("helpLinks.creditCheckFaq")]: helpLinks.CREDIT_CHECK_FAQ,
            [$_("helpLinks.paymentTermsFaq")]: helpLinks.PAYMENT_TERMS_FAQ,
            [$_("helpLinks.customerRecourseFaq")]: helpLinks.CUSTOMER_RECOURSE_FAQ,
            [$_("helpLinks.fraudCheckFaq")]: helpLinks.FRAUD_CHECK_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        },
        statements: {
            [$_("helpLinks.creditCheckFaq")]: helpLinks.CREDIT_CHECK_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink,
            [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
            [$_("helpLinks.dunningCollectionFaq")]: helpLinks.DUNNING_COLLECTION_FAQ,
            [$_("helpLinks.customerRecourseFaq")]: helpLinks.CUSTOMER_RECOURSE_FAQ
        },
        settlement: {
            [$_("helpLinks.settlementFaq")]: helpLinks.SETTLEMENT_FAQ,
            [$_("helpLinks.paymentFaq")]: helpLinks.PAYMENT_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        },
        account: {
            [$_("helpLinks.accountSettingFaq")]: helpLinks.ACCOUNT_SETTING_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        },
        createorder: {
            [$_("helpLinks.orderCreateFaq")]: helpLinks.ORDER_CREATE_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        },
        default: {
            [$_("helpLinks.portalTour")]: helpLinks.PORTAL_TOUR,
            [$_("helpLinks.merchantFaq")]: helpLinks.MERCHANT_FAQ,
            [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
                environment.branding.aboutLink
        }
    };
    const merchantHelpLinks = {
        [$_("helpLinks.buyerFaq")]: helpLinks.MERCHANT_FAQ,
        [$_("helpLinks.docsUrl")]: DOCS_URL,
        [$_("helpLinks.twoStatus")]: helpLinks.TWO_STATUS
    };

    const buyerHelpLinks = {
        [$_("helpLinks.buyerFaq")]: helpLinks.BUYER_FAQ,
        [$_("helpLinks.aboutTwo", { values: { brandName: environment.branding.displayName } })]:
            environment.branding.aboutLink
    };

    const buyerQuickLinks = {
        invoices: {
            [$_("helpLinks.buyerInvoiceFaq")]: helpLinks.BUYER_INVOICE_FAQ,
            [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
        },
        statements: {
            [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
        },
        default: {
            [$_("helpLinks.buyerRefundFaq")]: helpLinks.BUYER_REFUND_FAQ
        }
    };

    onMount(() => {
        hasSeenPageHelp = localStorage.getItem("acknowledged_help_button");
        isBuyerPortal = window.location.pathname.split("/").at(1) === "buyer";
        const currentPage = window.location.pathname.split("/").at(-1);
        staticHelpLinks = isBuyerPortal ? buyerHelpLinks : merchantHelpLinks;
        handyPageLinks = isBuyerPortal
            ? buyerQuickLinks[currentPage] || buyerQuickLinks.default
            : merchantQuickLinks[currentPage] || merchantQuickLinks.default;
        isOnStatementPage = currentPage === "statements";
    });

    $: newUserTooltipActive = newUser && !showSupportDropdown && !hasSeenPageHelp && !isBuyerPortal;

    $: if (pageBanner) {
        const theme = pageBanner.type.toLowerCase();
        pageBannerColor = `var(--${theme}-800)`;
        pageBannerIcon = NotificationTypeIcons[pageBanner.type];
    }
    let showSupportDropdown: boolean = false;

    const openHelpChat = () => {
        const iframe = document.getElementById("launcher") as HTMLIFrameElement;
        iframe.contentWindow.document.getElementsByTagName("button")[0].click();
        showSupportDropdown = false;
    };

    const acknowledgedHelpButton = () => {
        localStorage.setItem("acknowledged_help_button", "true");
        hasSeenPageHelp = true;
    };

    const handleClickOutsideHelpMenu = (event: CustomEvent) => {
        event.stopPropagation();
        if (showSupportDropdown) {
            showSupportDropdown = false;
        }
    };

    const toggleHelpMenu = (event: CustomEvent) => {
        event.stopPropagation();
        showSupportDropdown = !showSupportDropdown;
        acknowledgedHelpButton();
    };

    const openPage = (link: string) => window.open(link);
</script>

<header class:main_pb={$$slots.tabs}>
    <div class={`main ${customClasses}`}>
        {#if backLink}
            <button class="back-arrow" on:click={() => navigate(backLink)}>
                <Icon name="arrow-left" size={18} fill="#667085" />
            </button>
        {/if}
        <div class="title">
            <h1>{title}</h1>
            <div class="text-gray-700 description">
              {#if descriptionHtml}
                {@html descriptionHtml}
              {:else if description}
                {description}
              {/if}
            </div>
        </div>
        <slot name="cta" class="cta" />
        {#if isOnStatementPage}
            <TwoUIButton
                id="docs-btn"
                on:click={() => openPage("https://docs.two.inc/openapi/billing-account-api")}
                size="md"
                variant="secondaryGray"
                content={$_("pageHeader.apiDocument")}
            ></TwoUIButton>
        {/if}
        <Tooltip
            width="100%"
            placement="center"
            wrapLines
            label={$_("pageHeader.helpText")}
            position="bottom"
        >
            <TwoUIButton
                id="help-menu-btn"
                on:click={toggleHelpMenu}
                size="md"
                variant="secondaryGray"
                content={""}
                ><QuestionIcon size={20} slot="rightIcon" />
            </TwoUIButton>
        </Tooltip>
        {#if newUserTooltipActive}
            <div id="help-menu-tooltip">
                <h1 style="font-weight:700;font-size:16px;">
                    {$_("pageHeader.helpTooltipTitle")} 🛟
                </h1>
                <p class="small-text">{$_("pageHeader.helpTooltipDescription")}</p>
                <div style="display:flex;justify-content: flex-end;">
                    <button id="new-user-tooltip-btn" on:click={acknowledgedHelpButton}>
                        {$_("pageHeader.helpTooltipButton")}
                    </button>
                </div>
            </div>
        {/if}
        {#if showSupportDropdown}
            <div
                id="help-menu"
                use:clickOutside={{ enabled: true }}
                on:click_outside={handleClickOutsideHelpMenu}
            >
                <div class="drop-down-links">
                    {#each Object.entries(staticHelpLinks) as [label, link]}
                        <button on:click={() => openPage(link)}>
                            <p>{label}</p>
                            <ExternalLinkIcon fill="var(--primary-500)" />
                        </button>
                    {/each}
                </div>
                <div class="pill-button-links">
                    {#each Object.entries(handyPageLinks) as [label, link]}
                        <button on:click={() => openPage(link)}><p>{label}</p></button>
                    {/each}
                </div>
                {#if environment.branding.hasSupportChat}
                    <div class="help-form small-text">
                        <span class="help-text">
                            {$_("pageHeader.needSpecificHelp")}
                            <button on:click={openHelpChat}
                                ><p>{$_("pageHeader.theForm")}</p></button
                            >
                            {$_("pageHeader.below")}
                        </span>
                    </div>
                {/if}
            </div>
        {/if}
    </div>
    <slot name="summary-info" />
    <div class="tabs" class:tabs_mt={$$slots.tabs}>
        <slot name="tabs" class="tabs" />
    </div>
    {#if !$signupComplete}
        <div class="banner banner--important">
            <div style="display:flex;gap: 0.5rem;align-items: center;">
                <Icon size={16} name="errorinfo" fill="var(--warning-900)" />
                <p style="font-size:14px">
                    {@html $_("pageHeader.statementDemoBanner")}
                    <button
                        class="text-link"
                        on:click={() => modalState.actions.setModal(ACCOUNT_SETUP, {})}
                    >
                        <strong>{$_("pageHeader.statementSignup")}</strong>
                    </button>
                </p>
            </div>
        </div>
    {:else if showAccountAlert}
        <div class="banner banner--important">
            <div style="display:flex;gap: 0.5rem;align-items: center;">
                <Icon size={16} name="errorinfo" fill="var(--warning-900)" />
                <strong>{$_("pageHeader.accountDetailsMissing")}</strong>
                {$_("pageHeader.incompleteFields")}
                <button
                    class="text-link"
                    on:click={() => {
                        navigate("/merchant/account?tab=Team");
                        dispatch("alertClicked");
                    }}
                    ><strong class="underline cursor-pointer">
                        {$_("pageHeader.completeSignup")}</strong
                    >
                </button>
            </div>
        </div>
    {/if}
    {#if pageBanner && !pageBanner?.closed}
        <div class="banner banner--{pageBanner.type.toLowerCase()}">
            <div style="display:flex;gap: 0.5rem;">
                <svelte:component this={pageBannerIcon} {...{ size: 20, fill: pageBannerColor }} />
                <div>
                    <strong>{pageBanner.header || ""}</strong>
                    {pageBanner.body}
                    {#if pageBanner.textLink}
                        <button
                            class="text-link"
                            on:click={() => {
                                navigate(pageBanner.textLink);
                            }}
                            ><strong class="underline cursor-pointer">
                                {$_("pageHeader.completeSignup")}</strong
                            ></button
                        >
                    {/if}
                </div>
            </div>
            <div style="display:flex">
                <button on:click={() => (pageBanner.closed = true)}>
                    <Icon name="cross" fill={"var(--gray-400)"} size={20} />
                </button>
            </div>
        </div>
    {/if}
</header>

<style lang="scss">
    header {
        background: white;
        border-bottom: 1px solid #e4e7ec;
        position: sticky;
        top: 0;
        z-index: 109;
        /* overflow: visible; */
        padding: 32px;
    }
    .back-arrow {
        margin: 0 8px 0 -8px;
    }
    .title {
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-weight: 700;
        flex-grow: 1;
        height: 4rem; /* height and flexbox to vertically and centrally align with cta without title bouncing on navigation */
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: var(--ctnGeneralPrimary);
        h1 {
            margin-bottom: 2px;
        }
    }
    .description {
        font-size: 14px;
        font-weight: normal;
        padding: 0;
        margin: 0;
        line-height: 1.5;
    }
    .main {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
    }
    .main_pb {
        padding-bottom: 0;
    }
    .text-link strong {
        color: var(--warning-700);
        text-decoration: underline;
    }
    .text-link:hover strong {
        color: var(--warning-800);
    }
    :global(#help-menu-btn) {
        border: 1px solid var(--ctnInteractiveGeneralDefault);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
    }

    :global(#docs-btn) {
        border: 1px solid var(--gray-300);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    :global(#help-menu-btn.active) {
        box-shadow:
            0px 0px 0px 4px rgba(242, 244, 247, 1),
            0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    :global(#help-menu-btn:hover) {
        border: 1px solid var(--gray-400);
    }
    #help-menu-tooltip {
        z-index: 20;
        position: absolute;
        right: 1rem;
        top: 95px;
        width: 284px;
        border-radius: 8px;
        background-color: var(--gray-700);
        color: var(--gray-200);
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    #help-menu-tooltip::before {
        /* adds point to tooltip */
        position: absolute;
        content: " ";
        bottom: 100%;
        left: 83.5%;
        border-width: 10px;
        border-style: solid;
        border-color: transparent transparent var(--gray-700) transparent;
        margin-top: -2px; /* margin needs tooltip::before padding - margin, as negative value */
    }

    #help-menu {
        border: 1px solid var(--gray-200);
        border-radius: 8px;
        box-shadow: 0px 4px 16px 6px #4e5ba614;
        z-index: 300;
        position: absolute;
        right: 2rem;
        top: 90px;
        padding-bottom: 1rem;
        background-color: white;
        width: 295px;
        letter-spacing: 0;
    }
    #help-menu p {
        color: var(--primary-500);
    }
    .drop-down-links button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        padding-bottom: 10px;
        padding: 6px 16px 6px 16px;
    }
    .drop-down-links button:active {
        background-color: var(--primary-50);
    }
    .drop-down-links button:hover {
        background-color: var(--primary-25);
    }
    .drop-down-links {
        display: flex;
        flex-direction: column;
    }
    .drop-down-links > button:first-child {
        padding-top: 12px;
    }
    .drop-down-links :last-child {
        padding-bottom: 12px;
    }
    .pill-button-links {
        border-top: 1px solid var(--gray-200);
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        flex-wrap: wrap;
        padding: 10px 16px 10px 16px;
    }
    .pill-button-links button {
        padding: 2px 10px 2px 10px;
        border-radius: 16px;
        background-color: var(--primary-50);
        color: var(--primary-500);
    }
    .drop-down-links button:active p {
        color: var(--primary-600) !important;
    }
    .pill-button-links button:hover,
    .pill-button-links button:hover p {
        background-color: var(--bgSemanticHighlightPrimary);
        color: var(--ctnInteractiveGeneralInverse) !important;
    }
    .pill-button-links button:active,
    .pill-button-links button:active p {
        color: var(--primary-600) !important;
        background-color: var(--primary-100);
    }
    .pill-button-links button:active,
    .pill-button-links button:active p {
        background-color: var(--primary-100);
        color: var(--primary-600) !important;
    }
    .help-form {
        border-top: 1px solid var(--gray-200);
        padding: 10px 16px 0px 16px;
    }
    .help-form span {
        color: var(--ctnGeneralSecondary);
    }
    #help-menu-tooltip button {
        background-color: var(--gray-200);
        color: var(--gray-700);
        box-shadow: 0px 1px 2px 0px #1018280d;
        border-radius: 8px;
        padding: 8px 14px 8px 14px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
    }
    .small-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
    }

    #help-menu-tooltip button:hover {
        background-color: var(--basic);
    }

    .banner {
        line-height: 20px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 2rem 0.5rem 2rem;
        font-weight: 500;
        font-size: 14px;
        justify-content: space-between;

        &--important {
            color: var(--gray-800);
            background-color: var(--warning-200);
        }

        &--success {
            color: var(--success-800);
            background-color: var(--success-50);
        }
        &--warning {
            color: var(--warning-800);
            background-color: var(--warning-50);
        }
        &--error {
            color: var(--error-800);
            background-color: var(--error-50);
        }
    }
</style>
