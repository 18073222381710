<script lang="ts">
    export let fill = "#B4B4C9";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.2 13.3999V16.5999C18.2 17.0242 18.0314 17.4312 17.7314 17.7313C17.4313 18.0313 17.0243 18.1999 16.6 18.1999H5.39999C4.97564 18.1999 4.56868 18.0313 4.26862 17.7313C3.96856 17.4312 3.79999 17.0242 3.79999 16.5999V13.3999"
        stroke={fill || "#B4B4C9"}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M7.00012 9.40002L11.0001 13.4L15.0001 9.40002"
        stroke={fill || "#B4B4C9"}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M11 13.4V3.80005"
        stroke={fill || "#B4B4C9"}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
