export const paymentStatusTagColors = {
    Paid: "var(--success-50)",
    Unpaid: "var(--error-50)",
    "Partially paid": "var(--info-50)"
};

export const paymentStatusTextColors = {
    Paid: "var(--semantic-success)",
    Unpaid: "var(--error-600)",
    "Partially paid": "var(--info-600)"
};

export interface ITextLink {
    description: string;
    iconText: string;
    link: string;
    disabled?: boolean;
}

export interface IIconLink {
    description: string;
    iconSource: string;
    link: string;
}

export interface ITag {
    text: string;
    textColor: string;
    bgColor: string;
}

export function determinePaymentStatus(
    grossAmount: number,
    unreconciledAmount: number
): "Paid" | "Unpaid" | "Partially paid" {
    if (Number(unreconciledAmount) == 0.0) {
        return "Paid";
    } else if (Number(grossAmount) == Number(unreconciledAmount)) {
        return "Unpaid";
    } else {
        return "Partially paid";
    }
}

export function determinePaymentStatusNoPartialPayment(
    unreconciledAmount: number
): "Paid" | "Unpaid" {
    if (Number(unreconciledAmount) == 0.0) {
        return "Paid";
    }
    return "Unpaid";
}
