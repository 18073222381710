export const downloadJsonAsCsv = (data, filename) => {
    // Convert JSON to CSV
    const csv = convertJsonToCsv(data);

    // Create a Blob object with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
        // Set the href attribute of the anchor element to the Blob URL
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);

        // Append the anchor element to the document body
        document.body.appendChild(link);

        // Programmatically trigger the download
        link.click();

        // Clean up by removing the anchor element and revoking the Blob URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
};

const convertJsonToCsv = (json) => {
    const rows = [];

    // Extract the headers from the first object in the JSON array
    const headers = Object.keys(json[0]);

    // Add the headers to the CSV rows
    rows.push(headers.join(","));

    // Process each object in the JSON array
    json.forEach((obj) => {
        const values = [];

        // Extract the values for each header
        headers.forEach((header) => {
            const value = obj[header];

            // Escape double quotes and wrap the value in double quotes if necessary
            values.push(`"${String(value).replace(/"/g, '""')}"`);
        });

        // Add the values to the CSV rows
        rows.push(values.join(","));
    });

    // Combine the CSV rows into a single string
    return rows.join("\n");
};
