export function focusTrap(element: HTMLElement) {
    var tabbableElements = [
        "a[href]:not([disabled])",
        "area[href]:not([disabled])",
        "button:not([disabled])",
        "textarea:not([disabled])",
        "input:not([disabled])",
        "select:not([disabled])"
    ].join();
    var focusableEls = Array.from(element.querySelectorAll(tabbableElements)) as HTMLElement[];
    var firstFocusableEl = focusableEls[0];
    var lastFocusableEl = focusableEls[focusableEls.length - 1];
    // this is the element that is focussed before the element with the focus trap is mounted
    var lastFocusedEl = document.activeElement as HTMLElement;

    function trap(e: KeyboardEvent) {
        var isTabPressed = e.key === "Tab";
        var isEscapePressed = e.key === "Escape";

        if (!isTabPressed) {
            if (isEscapePressed) {
                element.dispatchEvent(new CustomEvent("escape"));
            }
            return;
        }

        // set first focus to an element inside the node the trap is applied to
        if (!focusableEls.includes(document.activeElement as HTMLElement)) {
            if (e.shiftKey) {
                lastFocusableEl.focus();
            } else {
                firstFocusableEl.focus();
            }
            e.preventDefault();
            return;
        }

        if (e.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                (lastFocusableEl as HTMLElement).focus();
                e.preventDefault();
            }
        } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
                (firstFocusableEl as HTMLElement).focus();
                e.preventDefault();
            }
        }
    }

    document.addEventListener("keydown", trap);

    return {
        destroy() {
            // restore last focus
            lastFocusedEl.focus();
            document.removeEventListener("keydown", trap);
        }
    };
}
