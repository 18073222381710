import environment from "@/services/environment";
import { COUNTRIES } from "./constant";
import countryName from "./countryName";
import type { IBuyerCountryConfiguration, ICountry } from "./types";

export const invoicePaymentTermsInfo =
    "A customer set to 30 days has 30 days to pay the invoice after you've fulfilled the order.";
export const guaranteedCheckoutLimitinfo = `A customer with guaranteed checkout can pay via ${environment.branding.displayName} when we don't approve them for credit. You take the credit risk on these orders. Click edit to change the limit you are willing to fund.`;

export function getBuyerCountries(buyerCountryConfigs: IBuyerCountryConfiguration[]) {
    let availableCountries = {};
    buyerCountryConfigs.forEach((countryConfig) => {
        availableCountries[countryConfig.country_code] = <ICountry>{
            name: countryName[countryConfig.country_code],
            country_code: countryConfig.country_code,
            currency: countryConfig.available_currencies.includes(
                COUNTRIES[countryConfig.country_code]?.currency ?? ""
            )
                ? COUNTRIES[countryConfig.country_code].currency
                : countryConfig.available_currencies[0],
            buyerCurrencies: countryConfig.available_currencies
        };
    });
    return availableCountries;
}
