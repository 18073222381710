<script lang="ts">
    export let stroke = "#1D2939";
    export let fill = "none";
    export let size = 24;
</script>

<svg width={size} height={size} viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M2 4V15.6C2 17.8402 2 18.9603 2.43597 19.816C2.81947 20.5686 3.43139 21.1805 4.18404 21.564C5.03968 22 6.15979 22 8.4 22H15.6C17.8402 22 18.9603 22 19.816 21.564C20.5686 21.1805 21.1805 20.5686 21.564 19.816C22 18.9603 22 17.8402 22 15.6V12.4C22 10.1598 22 9.03968 21.564 8.18404C21.1805 7.43139 20.5686 6.81947 19.816 6.43597C18.9603 6 17.8402 6 15.6 6H12M22 11H21.5C19.8431 11 18.5 12.3431 18.5 14V14C18.5 15.6569 19.8431 17 21.5 17H22M7 14H13M13 14L10.5 11.5M13 14L10.5 16.5M17 6L15.92 4.56C15.216 3.62134 14.864 3.15201 14.4179 2.8135C14.0228 2.5137 13.5754 2.28999 13.0985 2.15378C12.56 2 11.9733 2 10.8 2H4C2.89543 2 2 2.89543 2 4V4C2 5.10457 2.89543 6 4 6H17Z"
        {stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
