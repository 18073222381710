export const EMAIL_SENT = "EMAIL_SENT";
export const DELETE_USER = "DELETE_USER";
export const ADD_CUSTOMER_MODAL = "ADD_CUSTOMER_MODAL";
export const SEND_BUSINESS_REGISTRATION_INVITE_MODAL = "SEND_BUSINESS_REGISTRATION_INVITE_MODAL";
export const CONTINUE_VIPPS = "CONTINUE_VIPPS";
export const EDIT_INVOICE = "EDIT_INVOICE";
export const FULFILL_ORDER = "FULFILL_ORDER";
export const REFUND_ORDER = "REFUND_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_REMAINING_ORDER = "CANCEL_REMAINING_ORDER";
export const EDIT_CREDITLIMIT = "EDIT_CREDITLIMIT";
export const CONFIRM_EDIT_CREDIT = "CONFIRM_EDIT_CREDIT";
export const WELCOME_TILLIT = "WELCOME_TILLIT";
export const ACCOUNT_SETUP = "ACCOUNT_SETUP";
export const SANDBOX_API_KEY = "SANDBOX_API_KEY";
export const API_KEY = "API_KEY";
export const CREATE_UPDATE_USER_MERCHANT = "CREATE_UPDATE_USER_MERCHANT";
export const DELETE_USER_MERCHANT = "DELETE_USER_MERCHANT";
export const CREATE_USER_MERCHANT_SUCCESS = "CREATE_USER_MERCHANT_SUCCESS";
export const UPDATE_USER_MERCHANT_SUCCESS = "UPDATE_USER_MERCHANT_SUCCESS";
export const DELETE_USER_MERCHANT_SUCCESS = "DELETE_USER_MERCHANT_SUCCESS";
export const CONFIRM_LOGOUT = "CONFIRM_LOGOUT";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CROSS_BORDER_ORDER = "CROSS_BORDER_ORDER";
export const MI_BUYER_FALLBACK = "MI_BUYER_FALLBACK";
export const CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK =
    "CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK";
export const CUSTOM_SETTLEMENT_REPORT = "CUSTOM_SETTLEMENT_REPORT";
