<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import Input from "./Input.svelte";
    import type { InputType, ISelectorInputField } from "../../static/types";
    import ToolTip from "../tooltip/ToolTip.svelte";
    import Icon from "../icons/Icon.svelte";
    import Selector from "./Selector.svelte";
    import PhoneInput from "../../components/authentication/PhoneInput.svelte";

    const dispatch = createEventDispatcher();

    export let name: string = "";
    export let label: string = "";
    export let value: string | number = "";
    export let minLength: number = null;
    export let maxLength: number = null;
    export let placeholder: string = "";
    export let tooltipHint: string = "";
    export let type: InputType = "text";
    export let editable: boolean = false;
    export let required: boolean = true;
    export let selectorInput: ISelectorInputField = null;
    export let noEditIcons: boolean = false;
    export let editOngoing: boolean = false;

    let phoneNumber: string = "";
    let isEditing: boolean = required && [null, ""].includes(value) && editable;
    const existingValue: string | number = value;
    let invalid: boolean = false;

    function saveValue() {
        if (invalid) {
            return;
        }
        if ((type === "phone" && !phoneNumber) || (!required && !value)) {
            value = null;
        }
        const data = {
            newValue: value,
            name
        };
        isEditing = false;
        dispatch("inputChanged", data);
    }

    function handleEditOngoing(isEditing: boolean) {
        editOngoing = isEditing;
        dispatch("edit", { isEditing });
    }

    $: handleEditOngoing(isEditing);

    function onCancel() {
        isEditing = false;
        value = existingValue;
    }
</script>

<div class="field-container">
    {#if !isEditing && !noEditIcons}
        <div class:disable={editOngoing} class:truncate={!tooltipHint}>
            {#if tooltipHint}
                <ToolTip width="250px" position="top" content={tooltipHint}>
                    <Icon name="question" fill="var(--gray-400)" size={12} />
                </ToolTip>
            {/if}
            <p class="label">{label}</p>
            {#if type == "dropdown"}
                <p class="truncate">{selectorInput.options.get(value) || placeholder}</p>
            {:else}
                <p class="truncate">{value || placeholder}</p>
            {/if}
        </div>
        {#if editable}
            <button
                disabled={editOngoing}
                on:click={() => (isEditing = true)}
                class="button-lg button-tertiary"
            >
                {$_("components.edit")}
            </button>
        {/if}
    {:else}
        <div class="input-container">
            <p class="label" style="padding-bottom:4px;">{label}</p>
            {#if type === "dropdown"}
                <Selector
                    bind:selectedKey={value}
                    disable={selectorInput.options.size == 1 && selectorInput.placeholder}
                    options={selectorInput.options}
                    labelLines={selectorInput.labelLines}
                    placeholder={selectorInput.placeholder}
                />
            {:else if type === "phone"}
                <PhoneInput
                    useCustomInput={true}
                    bind:phoneNumberWithCode={value}
                    bind:phoneNumber
                    disable={false}
                    nullAllowed={!required}
                    {placeholder}
                    bind:numberIsInvalid={invalid}
                />
            {:else}
                <Input
                    inputType={type}
                    bind:value
                    {minLength}
                    {maxLength}
                    nullAllowed={!required}
                    {placeholder}
                    bind:showInvalidText={invalid}
                />
            {/if}
        </div>
        <button on:click={onCancel} class="button-lg button-tertiary">
            {$_("components.cancel")}
        </button>
        <button
            id="save-input-change"
            on:click={saveValue}
            disabled={invalid ||
                existingValue == value ||
                (type === "phone" && !phoneNumber && !existingValue)}
            class="button-lg button-primary"
        >
            {$_("components.saveChanges")}
        </button>
    {/if}
</div>

<style>
    .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .input-container,
    .truncate {
        grid-column: span 2;
    }
    #save-input-change {
        grid-column: 1;
    }
    .field-container {
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        display: grid;
        grid-template-columns: auto auto auto;
    }
    div.disable {
        color: var(--gray-400);
    }
</style>
