<script lang="ts">
    import { _ } from "svelte-i18n";
    import { DOCS_URL } from "../../../static/endPoints";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import Search from "../../../components/search/Search.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import helpers from "../../../utilsV2/helpers";
    import NoPlansInfo from "./NoPlansInfo.svelte";
    import { loggedInAsMerchant } from "../../../store/merchant/account.store";
    import {
        actions as planActions,
        plans as repaymentPlans,
        plansLoading
    } from "../../../store/merchant/plans.store";
    import PlansTable from "./PlansTable.svelte";

    let searchValue: string = "";
    let loading: boolean = true;

    let plans: any = [];
    let pageNum = 1;

    $: (searchValue = ""), getPlans();
    $: $loggedInAsMerchant, getPlans();

    $: loading = $plansLoading;
    $: plans = $repaymentPlans;

    function setSearch(input: InputEvent | string): void {
        if (input instanceof InputEvent) {
            const inputE = input.target as HTMLInputElement;
            searchValue = inputE.value;
        } else {
            searchValue = input;
        }
        handleSearchChanged();
    }

    const handleSearchChanged = helpers.debounce(() => {
        getPlans();
        pageNum = 1;
    }, 400);

    function pageUpdated(event) {
        getPlans(event.detail.pageReference);
        pageNum = event.detail.pageNum;
    }

    function getPlans(cursor: string = null): void {
        if ($loggedInAsMerchant) {
            planActions.getAllPlans(searchValue, {
                errorMessage: $_("plans.api.errorMessage"),
                errorDescription: $_("plans.api.errorDescription")
            });
            pageNum = 1;
        }
    }

    // TODO(hpatel): Need to update when table is implemented
    // function getCustomers(cursor: string = null):void {
    //   if ($loggedInAsMerchant) {
    //     customerActions.getCustomers(searchValue, selectedTab == recourseTabLabel, cursor);
    //     pageNum = 1;
    //   }
    // }
</script>

<MetaWrapper title={$_("plans.pageTitle")}>
    <div class="page-container">
        <PageHeader
            title={$_("plans.pageTitle")}
            description={$_("plans.pageDescription")}
            customClasses="plans"
        >
            <button
                slot="cta"
                class="button-secondary button-sm"
                style="width:100%;max-width:fit-content;"
                on:click={() => {
                    window.open(
                        `${DOCS_URL}/openapi/installment-api/#tag/Repayment-Plans`,
                        "_blank"
                    );
                }}
            >
                {$_("plans.button.viewApiDocs")}
            </button>
        </PageHeader>
        {#if !loading && plans.length === 0}
            <div>
                <NoPlansInfo />
            </div>
        {:else}
            <div class="content-container">
                <div class="rounded-lg bg-white customers-summary">
                    <div class="flex items-center justify-between mx-8 pt-4 pb-6">
                        <div class="flex w-1/3 max-w-7xl">
                            <p class="text-lg font-bold">
                                {$_("plans.allPlans")}
                            </p>
                        </div>
                        <div class="flex w-2/3">
                            <div class="flex w-full justify-end sm:gap-4">
                                <div class="flex w-2/3 items-end">
                                    <Search
                                        bind:searchValue
                                        {setSearch}
                                        placeholder={$_("plans.searchPlaceholder")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <PlansTable on:pagechanged={pageUpdated} />
                </div>
            </div>
        {/if}
    </div>
</MetaWrapper>

<style>

    .customers-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
        margin: 1.5rem 2rem;
        max-height: 80vh;
    }

    .content-container {
        grid-row-gap: 0;
    }
</style>
