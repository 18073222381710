<script lang="ts">
    import { _ } from "svelte-i18n";

    import { NotificationType } from "../../static/types";
    import Button from "../Elements/Button.svelte";
    import Input from "../Elements/InputBox.svelte";
    import DropDownSearch from "../Elements/DropDownSearch.svelte";
    import Icon from "../icons/Icon.svelte";
    import notificationState from "../../store/notifications.store";
    import { BASE_URL } from "../../static/endPoints";
    import countryName from "@/static/countryName";
    import { SORT_TYPE, listSort } from "@/utils/functions";
    import environment from "@/services/environment";

    export let closeModal: any;
    export const props: any = {};

    let loading = false;
    let country = "";
    let currency = "";
    let orderValue = null;
    let freeText = "";
    let countryError = false;
    let currencyError = false;
    let amountError = false;

    function invalidInputs() {
        countryError = !Object.entries(countryName).find(
            ([, countryName]) => countryName == country
        );
        currencyError = currency.length != 3;
        amountError = (orderValue ?? 0) < 1;
        return countryError || currencyError || amountError;
    }

    $: country, (countryError = false);
    $: currency, (currencyError = false);
    $: orderValue, (amountError = false);

    async function submitRequest() {
        if (invalidInputs()) return;
        loading = true;
        const request_body = {
            country_code: Object.entries(countryName).find(
                ([, countryName]) => countryName == country
            )[0],
            expected_order_value_currency: currency,
            expected_order_value_amount: orderValue,
            additional_information: freeText
        };
        const response = await fetch(`${BASE_URL}/v1/portal/merchant/expansion`, <RequestInit>{
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(request_body)
        });
        if (response.status !== 200) {
            notificationState.actions.create(
                NotificationType.WARNING,
                $_("errors.somethingWrong"),
                null,
                $_("errors.requestFailedRetry")
            );
            closeModal();
            return;
        }

        notificationState.actions.create(
            NotificationType.SUCCESS,
            $_("buyerCountry.requestSubmitted"),
            null,
            ""
        );
        closeModal();
    }
</script>

<div class="container">
    <div class="header">
        <div style="display: flex;justify-content: space-between;">
            <h1 class="header-text">
                {$_("buyerCountry.title", {
                    values: { brandName: environment.branding.displayName }
                })}
            </h1>
            <div class="close">
                <button on:click={closeModal}>
                    <Icon name="cross" fill="#2F384C" size={18} />
                </button>
            </div>
        </div>
        <h2 class="subtitle-text">{$_("buyerCountry.subtitle")}</h2>
        <p class="header-sub-text">{$_("buyerCountry.description")}</p>
    </div>
    <div>
        <DropDownSearch
            label={$_("buyerCountry.countryLabel")}
            placeholder={$_("buyerCountry.countryPlaceholder")}
            required={true}
            itemList={listSort(Object.values(countryName), true, SORT_TYPE.string)}
            bind:value={country}
        />
        {#if countryError}
            <p class="error-text">{$_("buyerCountry.selectFromDropdown")}</p>
        {/if}
        <p class="helper-text">{$_("buyerCountry.countryHelper")}</p>
    </div>

    <div>
        <div style="display:flex;gap:.5rem;">
            <div style="width:20%;">
                <Input
                    label={$_("buyerCountry.currencyLabel")}
                    required={true}
                    placeholder="EUR"
                    bind:value={currency}
                    type="text"
                    id="currency"
                />
                {#if currencyError}
                    <p class="error-text">{$_("buyerCountry.currencyError")}</p>
                {/if}
            </div>
            <div style="width:80%;">
                <Input
                    label={$_("buyerCountry.valueLabel")}
                    required={true}
                    placeholder="1,000,000.00"
                    bind:value={orderValue}
                    type="number"
                    id="order-value"
                />
                {#if amountError}
                    <p class="error-text">{$_("buyerCountry.amountError")}</p>
                {/if}
            </div>
        </div>
        <p class="helper-text">{$_("buyerCountry.valueHelper")}</p>
    </div>
    <div>
        <Input
            label={$_("buyerCountry.additionalInfoLabel")}
            bind:value={freeText}
            type="textarea"
            id="free-text"
        />
    </div>
    <div class="action-buttons">
        <Button
            label={$_("components.goBack")}
            on:click={closeModal}
            bgColor="white"
            color="var(--gray-800)"
            borderColor="var(--gray-300)"
            paddingX="16px"
            paddingY="10px"
            disable={loading}
        />
        <Button
            label={$_("buyerCountry.continueButton")}
            on:click={submitRequest}
            paddingX="16px"
            paddingY="10px"
            {loading}
        />
    </div>
</div>

<style lang="scss">
    .header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .header-text {
        font-weight: 700;
        line-height: 32px;
        font-size: 24px;
    }
    .subtitle-text {
        font-family: Aeonik;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
    .helper-text {
        font-family: Aeonik;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: var(--gray-400);
        padding-top: 4px;
    }
    .header-sub-text {
        color: var(--ctnGeneralSecondary);
    }

    .error-text {
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }
    .container {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1.5rem;
        // hack to accomodate the left date picker
        min-width: 630px;
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-top: 2rem;
    }
</style>
