import { createTheme, useSvelteUITheme } from "@two-ui/core";
import ABN_COLOURS from "./abn-colours";

export function getMerchantTheme(brandName: string) {
    let newTheme: any;

    if (brandName === "Achteraf betalen") {
        newTheme = {
            ...useSvelteUITheme(),
            ...createTheme("new-theme", {
                colors: ABN_COLOURS
            })
        };
    } else {
        newTheme = undefined;
    }

    return newTheme;
}
