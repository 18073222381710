/* eslint-disable */
import { writable } from "svelte/store";
import { GET_DASHBOARD, GET_DASHBOARD_MARKETS } from "../../static/endPoints";
import API from "../../services/apiService";
import { DateToFormat } from "../../utils/functions";
import type { CountryCode, CurrencyCode, IDashboardData } from "../../static/types";
import { dashboardData } from "../../static/signupDemoMockResponses";

export const dashboard = writable(<IDashboardData>{});
export const loading = writable(false);
export const dashboardMarkets = writable([]);

function createDashboard() {
    return {
        actions: {
            getDashboard: (
                start: Date,
                end: Date,
                countryCode?: CountryCode,
                currency?: CurrencyCode,
                signupComplete?: boolean
            ) => {
                loading.set(true);
                if (!signupComplete) {
                    let data = setDashboardData(dashboardData(start, end));
                    dashboard.set(data);
                    loading.set(false);
                } else {
                    let dasboard_path = `${GET_DASHBOARD}?start_date=${DateToFormat(
                        start
                    )}&end_date=${DateToFormat(end)}`;
                    if (countryCode != null && currency != null) {
                        dasboard_path += `&country_code=${countryCode}&currency=${currency}`;
                    }
                    API.get(dasboard_path)
                        .then((res: any) => {
                            dashboard.set(setDashboardData(res));
                            loading.set(false);
                        })
                        .catch(() => {
                            loading.set(false);
                        });
                }
            }
        }
    };
}

const setDashboardData = (res) => {
    let newDashboardData: IDashboardData = {};
    newDashboardData.average_order_values = res.average_order_values || "0";
    newDashboardData.capture_rate = res.capture_rate || "0";
    newDashboardData.credit_approval_rate = res.credit_approval_rate || "0";
    newDashboardData.end_date = res.end_date || DateToFormat(new Date());
    newDashboardData.incr_average_order_values = res.incr_average_order_values || 0;
    newDashboardData.incr_capture_rate = res.incr_capture_rate || 0;
    newDashboardData.incr_credit_approval_rate = res.incr_credit_approval_rate || 0;
    newDashboardData.incr_orders_placed = res.incr_orders_placed || 0;
    newDashboardData.incr_total_sales = res.incr_total_sales || 0;
    newDashboardData.last_order = res.last_order || new Date();
    newDashboardData.orders_placed = res.orders_placed || 0;
    newDashboardData.start_date = res.start_date || DateToFormat(new Date());
    newDashboardData.total_sales = res.total_sales || "0";
    newDashboardData.returning_buyers = res.returning_buyers || "0";
    newDashboardData.verification_rate = res.verification_rate || "0";
    newDashboardData.unique_buyers = res.unique_buyers || "0";
    newDashboardData.fulfilled_order_data = res.fulfilled_order_data;
    newDashboardData.refunded_order_data = res.refunded_order_data;
    return newDashboardData;
};

const dashboardState: any = createDashboard();
export default dashboardState;
