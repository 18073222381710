<script lang="ts">
    export let fill: string = "var(--primary-500)";
</script>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="check">
        <path
            id="Vector"
            d="M2.66663 8.66667L5.99996 12L13.3333 4"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </g>
</svg>
