<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { SummaryStatus } from "./types";

    export let status: SummaryStatus;

    function translateStatus(status: SummaryStatus): string {
        switch (status) {
            case "READY_TO_FULFIL":
                return $_("repaymentPlanStatus.readyToFulfill");
            default:
                return $_(`repaymentPlanStatus.${status.toLowerCase()}`);
        }
    }
</script>

<div class="status status--{status?.toLowerCase().replace(/_/g, '-')}">
    {translateStatus(status)}
</div>

<style lang="scss">
    .status {
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--fulfilled,
        &--part-fulfilled {
            color: var(--success-600);
            background-color: var(--success-50);
        }

        &--fulfilling,
        &--pending {
            color: var(--gray-500);
            background-color: var(--gray-100);
        }

        &--ready-to-fulfil {
            color: var(--ctnSemanticInfoPrimary);
            background-color: var(--bgSemanticInfoUltraLight);
        }

        &--cancelled,
        &--rejected {
            color: var(--error-700);
            background-color: var(--error-50);
        }
    }
</style>
