<script lang="ts">
    export let title = "Two Portal";
    export let hotjarId: string = "";
</script>

<svelte:head>
    <title>{title}</title>

    {#if hotjarId == "2786357"}
        <!-- Hotjar Tracking Code for Merchant signup flow -->
        <script>
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: 2786357, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        </script>
    {/if}

    {#if hotjarId == "2805260"}
        <!-- Hotjar Tracking Code for Merchant onboarding flow -->
        <script>
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: 2805260, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        </script>
    {/if}

    {#if hotjarId == "2809391"}
        <!-- Hotjar Tracking Code for Buyer authentication flow -->
        <script>
            (function (h, o, t, j, a, r) {
                h.hj =
                    h.hj ||
                    function () {
                        (h.hj.q = h.hj.q || []).push(arguments);
                    };
                h._hjSettings = { hjid: 2809391, hjsv: 6 };
                a = o.getElementsByTagName("head")[0];
                r = o.createElement("script");
                r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        </script>
    {/if}
</svelte:head>
