<script lang="ts">
    import { _ } from "svelte-i18n";
    import Icon from "../../../components/icons/Icon.svelte";

    export let setPage = (pageCursor: string) => {
        console.info(`pageCursor=${pageCursor}`);
    };
    export let rowCount: number = 0;
    export let nextPage: string = "";
    export let prevPage: string = "";
    export let loading: boolean = false;

    export let pageNum = 1;

    function changeToPrevPage() {
        if (prevPage) {
            setPage(prevPage);
            pageNum = pageNum > 1 ? pageNum - 1 : 1;
        }
    }
    function changeToNextPage() {
        if (nextPage) {
            setPage(nextPage);
            pageNum++;
        }
    }
</script>

{#if !loading && rowCount}
    <div class="py-4 pr-20 flex justify-end page-counter" style="gap:1rem">
        <button class="cursor-pointer" disabled={!prevPage} on:click={() => changeToPrevPage()}>
            <Icon name="arrow-left" size={16} fill={!prevPage ? "#D0D5DD" : "#667085"} />
        </button>
        <span>{$_("components.table.page")} {pageNum}</span>
        <button
            class="cursor-pointer flip-icon"
            disabled={!nextPage}
            on:click={() => changeToNextPage()}
        >
            <Icon name="arrow-left" size={16} fill={!nextPage ? "#D0D5DD" : "#667085"} />
        </button>
    </div>
{/if}

<style lang="scss">
    .flip-icon {
        transform: scaleX(-1);
    }

    .page-counter {
        position: sticky;
        bottom: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #344054;
        user-select: none;
        box-shadow: 0px -1px 5px #eee;
        background-color: white;
        z-index: 10;
    }
</style>
