<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.50001 1.04175C5.31388 1.04175 3.54168 2.81395 3.54168 5.00008C3.54168 7.18621 5.31388 8.95841 7.50001 8.95841C9.68614 8.95841 11.4583 7.18621 11.4583 5.00008C11.4583 2.81395 9.68614 1.04175 7.50001 1.04175ZM0.208344 15.7917C0.208344 13.1684 2.33499 11.0417 4.95835 11.0417H10.0417C12.665 11.0417 14.7917 13.1684 14.7917 15.7917C14.7917 17.5407 13.3739 18.9584 11.625 18.9584H3.37501C1.62611 18.9584 0.208344 17.5407 0.208344 15.7917ZM17.4167 5.83341C17.4167 5.4192 17.0809 5.08341 16.6667 5.08341C16.2525 5.08341 15.9167 5.4192 15.9167 5.83341V7.58341H14.1667C13.7525 7.58341 13.4167 7.9192 13.4167 8.33341C13.4167 8.74763 13.7525 9.08341 14.1667 9.08341H15.9167V10.8334C15.9167 11.2476 16.2525 11.5834 16.6667 11.5834C17.0809 11.5834 17.4167 11.2476 17.4167 10.8334V9.08341H19.1667C19.5809 9.08341 19.9167 8.74763 19.9167 8.33341C19.9167 7.9192 19.5809 7.58341 19.1667 7.58341H17.4167V5.83341Z"
        {fill}
    />
</svg>
