<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import Button from "../../Elements/Button.svelte";
    import { loading, account, signupComplete } from "../../../store/merchant/account.store";
    import type { IMNewUserConfig, IMUserRoles } from "../../../static/types";
    import SignupIncompleteModal from "../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import modalState from "../../../store/modals.store";

    let submitUser: CallableFunction = null;
    let userId: string;
    let firstName: string;
    let lastName: string;
    let fullName: string;
    let roleName: string;
    let phoneNumberWithCode: string;
    let selectedUserType: IMUserRoles;

    export let closeModal;
    export let props: any = {};

    function handleSubmit() {
        const data: IMNewUserConfig = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumberWithCode,
            role: selectedUserType?.toUpperCase()
        };
        if (!$signupComplete) {
            modalState.actions.setModal("", {}, SignupIncompleteModal);
            return;
        }
        submitUser(userId, data);
    }

    $: displayName = $account.accountName ?? "";

    onMount(() => {
        if (props) {
            if (props.id) {
                userId = props.id;
            }
            if (props.first_name) {
                firstName = props.first_name;
            }
            if (props.last_name) {
                lastName = props.last_name;
            }
            fullName = [firstName, lastName]
                .filter((el) => {
                    return el != null;
                })
                .join(" ");
            if (props.phone_number_with_code) {
                phoneNumberWithCode = props.phone_number_with_code;
            }
            if (props.role) {
                selectedUserType = props.role;
            }
            // Capitalize first letter
            roleName =
                selectedUserType.charAt(0).toUpperCase() + selectedUserType.slice(1).toLowerCase();
            if (props.submit_user) {
                submitUser = props.submit_user;
            }
        }
    });
</script>

<div class="flex flex-col h-full w-xsm py-7 px-6 sm:py-6 sm:px-8 text-sm">
    <h1 class="ctn-general-primary font-bold text-lg sm:text-2xl">{$_("components.removeUser")}</h1>
    <div class="h-full grow mt-4">
        {@html $_("components.disablePortal", {
            values: { displayName, name: fullName || roleName, phone: props.phone_number_with_code }
        })}
    </div>
    <div class="flex justify-end mt-4">
        <Button
            bgColor="#EEF4FF"
            hBg="#EBEAEF"
            hoverBg="#F8F8F9"
            hoverColor="#4F25E4"
            color="var(--primary-700)"
            label={$_("components.cancel")}
            paddingX="20px"
            on:click={closeModal}
        />
        <div class="w-full ml-2 sm:w-auto">
            <Button
                bgColor="var(--primary-700)"
                label={$_("components.submit")}
                paddingX="20px"
                on:click={handleSubmit}
                loading={$loading}
            />
        </div>
    </div>
</div>
