<script lang="ts">
    export let fill: string = "#938DA9";
    export let size: number = 10;
    export let makeSquare: boolean = false;
</script>

<svg
    width={size}
    height={makeSquare ? size : (size * 10) / 7}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.53032 0.96967C9.82321 1.26256 9.82321 1.73744 9.53032 2.03033L5.53032 6.03033C5.23743 6.32322 4.76256 6.32322 4.46966 6.03033L0.46967 2.03033C0.176777 1.73744 0.176777 1.26256 0.46967 0.96967C0.762563 0.676777 1.23744 0.676777 1.53033 0.96967L4.99999 4.43934L8.46966 0.96967C8.76255 0.676777 9.23743 0.676777 9.53032 0.96967Z"
        {fill}
    />
</svg>
