<script lang="ts">
    import { _ } from "svelte-i18n";
    import { navigate } from "svelte-routing";

    import { getBaseUrl } from "../../utils/functions";
    import { CANCEL_ORDER, CANCEL_MI_ORDER } from "../../static/endPoints";
    import API from "../../services/apiService";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import { Button as TwoUIButton } from "@two-ui/core";
    import environment from "@/services/environment";
    import { NotificationType } from "../../static/types";

    export let orderID: string = "";
    export let merchantOrderID: string = "";
    export let isLatent: boolean = false;

    let isCancelProcessing: boolean = false;

    const handleCancel = () => {
        const baseUrl = getBaseUrl();
        const url = isLatent
            ? `${baseUrl}${CANCEL_MI_ORDER(orderID)}`
            : `${baseUrl}${CANCEL_ORDER(orderID)}`;
        isCancelProcessing = true;
        API.post(url, orderID)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("order.cancel.successMessage")
                );
                modalState.actions.setModal(null, { isCancelled: true });
                // currently the page is not updated with the Cancelled status
                navigate("/merchant/orders");
            })
            .catch(() => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    $_("order.cancel.errorMessage")
                );
                modalState.actions.setModal(null, { isCancelled: false });
            });
    };

    const handleCancelModal = () => {
        modalState.actions.setModal(null, {});
    };
</script>

<div class="container">
    <div class="flex title">
        {#if merchantOrderID}
            <div class="ctn-general-primary">
                {$_("order.cancel.titleWithID", { values: { merchantOrderID } })}
            </div>
        {:else}
            <div class="ctn-general-primary">{$_("order.cancel.titleWithoutID")}</div>
        {/if}
    </div>
    <div class="body-container">
        <div class="body-text">
            {$_("order.cancel.reminder", {
                values: { brandName: environment.branding.displayName }
            })}
        </div>
    </div>
    <div class="button-area">
        <div class="right-align">
            <TwoUIButton
                on:click={handleCancelModal}
                size="md"
                variant="secondaryGray"
                content={$_("components.goBack")}
                fullSize={true}
            />
        </div>
        <div>
            <TwoUIButton
                on:click={handleCancel}
                size="md"
                variant="destructivePrimary"
                content={$_("components.cancel")}
                fullSize={true}
                disabled={isCancelProcessing}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        display: flex;
        flex-direction: column;
        padding: 24px 32px 24px 32px;
        max-height: 450px;
        width: 544px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .body-container {
        overflow: auto;
        margin-bottom: 1rem;
        flex-grow: 1;
    }

    .body-text {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }

    .button-area {
        display: flex;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }
</style>
