<script lang="ts">
    import { account } from "../../../store/merchant/account.store";
    import SettlementV1 from "./SettlementV1.svelte";
    import Payouts from "../payouts/Payouts.svelte";

    let settlementComponent;
    $: $account?.flags?.legacy_settlement_ui_portal
        ? (settlementComponent = SettlementV1)
        : (settlementComponent = Payouts);
</script>

<svelte:component this={settlementComponent} />
