import { titleCase } from "../../../utilsV2/helpers";
import { BASE_URL } from "../../../static/endPoints";
import type {
    CurrencyCodeEnum,
    IBillingAccountBuyerStatementResponse,
    IPeriodStatement
} from "../../../static/types";
import utils from "../../../utilsV2/currency";
import {
    IIconLink,
    ITextLink,
    determinePaymentStatus,
    paymentStatusTagColors,
    paymentStatusTextColors
} from "../../../utilsV2/tableHelpers";
import { get_statement_type_from_creation_reason } from "../../../static/billing";
import type { BuyerData } from "../../../store/buyer/account.store";

export interface IStatementObject {
    "Merchant details": {
        tableItemBold: string;
        subItem: string;
    };
    Sites: {
        tableItem: string;
    };
    "Statement №": {
        tableItem: string;
        subItem: string;
        itemStatusTagTextColor: string;
        itemBgColor: string;
    };
    "Issue date": {
        tableItem: Date;
    };
    "Statement total": {
        tableItemBold: string;
    };
    "Remaining to pay": {
        tableItemBold: string;
        subItem: string;
        subItemTextColor: string;
    };
    "View and pay": {
        icons: (IIconLink | ITextLink)[];
    };
    billingAccountId: string;
    periodId: string;
}

const isStatementOverDue = (dueDate: string | number | Date) => {
    return new Date(dueDate) < new Date();
};

export function buildAllStatementsTable(
    statements: IBillingAccountBuyerStatementResponse[]
): IStatementObject[] {
    const tableRows: IStatementObject[] = [];
    for (const statement of statements || []) {
        const paymentStatus = determinePaymentStatus(
            statement.gross_amount,
            statement.unreconciled_amount
        );
        const rowItem: IStatementObject = {
            "Merchant details": {
                tableItemBold: statement.merchant_company_name,
                subItem: statement.merchant_website || ""
            },
            Sites: {
                tableItem: titleCase(statement.trade_name_list).join(", ")
            },
            "Statement №": {
                tableItem: statement.billing_statement_number,
                subItem: paymentStatus,
                itemStatusTagTextColor: paymentStatusTextColors[paymentStatus],
                itemBgColor: paymentStatusTagColors[paymentStatus]
            },
            "Issue date": {
                tableItem: statement.statement_date
            },
            "Statement total": {
                tableItemBold: `${utils.formatNumber(statement.gross_amount)} ${statement.currency}`
            },
            "Remaining to pay": {
                tableItemBold: `${utils.formatNumber(statement.unreconciled_amount)} ${
                    statement.currency
                }`,
                subItem: statement.unreconciled_amount > 0 ? `Due ${statement.due_date}` : "",
                subItemTextColor: isStatementOverDue(statement.due_date) ? "var(--error-600)" : ""
            },
            "View and pay": {
                icons: [
                    {
                        description: "downloadStatement",
                        iconSource: "/pictures/download-statement.svg",
                        link: `${BASE_URL}/v1/portal/buyer/statements/download/${
                            statement.billing_statement_id
                        }${statement.billing_statement_type === "STATEMENT" ? "" : "/preview"}`
                    },
                    {
                        description: "payStatement",
                        iconText: "Pay",
                        link: statement.statement_pis_link,
                        disabled: statement.unreconciled_amount == 0.0
                    }
                ]
            },
            billingAccountId: statement.billing_account_id,
            periodId: statement.billing_period_id
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}

export function filterPaidStatementsTable(allRows: IStatementObject[]): IStatementObject[] {
    return allRows.filter((row) => row["Statement №"].subItem == "Paid");
}

export function filterUnpaidStatementsTable(allRows: IStatementObject[]): IStatementObject[] {
    return allRows.filter((row) =>
        ["Unpaid", "Partially paid"].includes(row["Statement №"].subItem)
    );
}

function getDistinctSites(statements: IStatementObject[]): Set<string> {
    const distinctSites = new Set<string>();
    for (const statement of statements) {
        const sites = statement.Sites.tableItem.split(", ");
        for (const site of sites) {
            if (site) {
                distinctSites.add(site);
            }
        }
    }
    return distinctSites;
}

export function createExplanationText(
    statements: IStatementObject[],
    buyerData: BuyerData,
    selectedCompany: string
) {
    let text = "";
    const siteCount = getDistinctSites(statements).size;
    if (siteCount == 0 || buyerData?.billing_account_companies == undefined) {
        return "";
    }

    if (selectedCompany != "All Companies") {
        text += `Below is a view of statements for ${selectedCompany}`;
    } else {
        const companies = Array.from(buyerData.billing_account_companies.keys());
        if (companies.length == 1) {
            text += `Below is a view of statements for ${companies[0]}.`;
        } else {
            text += `Below is a view of statements for ${companies.length} companies.`;
        }
    }

    if (siteCount > 0) {
        text += `\nThis covers the invoices for ${siteCount} sites that we can associate with ${buyerData.account_email}`;
    }

    return text;
}

export function buildStatementSummaryRows(
    statements: IPeriodStatement[],
    currency: CurrencyCodeEnum
) {
    const tableRows = [];
    for (const statement of statements) {
        const rowItem = {
            Number: {
                tableItem: statement.statement_reference
            },
            "Date issued": {
                tableItem: new Date(statement.issue_date).toLocaleDateString()
            },
            Type: {
                tableItem: get_statement_type_from_creation_reason(
                    statement.statement_creation_reason
                )
            },
            "Statement total": {
                tableItem: `${utils.formatCurrency(currency)(statement.total_amount)}`
            },
            Download: {
                textLink: {
                    text: "Download",
                    link: `${BASE_URL}/v1/portal/buyer/statements/download/${
                        statement.statement_id
                    }${statement.statement_creation_reason === "PREVIEW" ? "/preview" : ""}`
                }
            }
        };
        tableRows.push(rowItem);
    }
    return tableRows;
}
