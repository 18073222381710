<script lang="ts">
    export let width: string = "100%";
    export let content: string = "";
    export let position: string = "top";
    export let changeDirection = false;
    export let isActive = true;

    const left: String = Number(width.split("px")[0]) / 2 + 22 + "px";
</script>

{#if changeDirection}
    <!--  need to change this -->
    <div style="--width: {width}; --left {left}">
        <button
            data-tooltip={content}
            class={position}
            class:not-active={!isActive}
            class:is-active={isActive}
        >
            <slot />
        </button>
    </div>
{:else}
    <div style="--width: {width}; --left: {left}">
        <button
            data-tooltip={content}
            class={position}
            class:not-active={!isActive}
            class:is-active={isActive}
        >
            <slot />
        </button>
    </div>
{/if}

<svelte:head>
    <style>
        /*This would all go into the global.css file*/
        [data-tooltip] {
            position: relative;
            display: block;
            text-align: left;
            white-space: pre-line;
        }

        [data-tooltip]:before,
        [data-tooltip]:after {
            /* visibility: hidden; */
            opacity: 0;
            pointer-events: none;
            transition: 0.2s ease-out;
            transform: translate(-50%, 5px);
            z-index: 2;
        }

        [data-tooltip].is-active:before,
        [data-tooltip].is-active:after {
            /* visibility: hidden; */
            opacity: 1;
        }

        [data-tooltip].not-active:before,
        [data-tooltip].not-active:after {
            /* visibility: hidden; */
            opacity: 0;
        }

        .top[data-tooltip]:before {
            position: absolute;
            bottom: calc(100% - 4px);
            left: 50%;
            margin-bottom: 7px;
            padding: 10px;
            width: var(--width);
            min-width: 70px;
            /* max-width: 250px; */
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 4px;
            background-color: var(--gray-700);
            color: #fff;
            content: attr(data-tooltip);
            font-size: 14px;
            line-height: 20px;
            transition: 0.2s ease-out;
        }

        .right[data-tooltip]:before {
            position: absolute;
            left: var(--left);
            padding: 10px;
            width: var(--width);
            top: -10px;
            min-width: 70px;
            /* max-width: 250px; */
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 4px;
            background-color: var(--gray-700);
            color: #fff;
            content: attr(data-tooltip);
            font-size: 14px;
            line-height: 20px;
            transition: 0.2s ease-out;
        }

        .left-down[data-tooltip]:before {
            position: absolute;
            left: calc(var(--width) / -2.5);
            padding: 10px;
            width: var(--width);
            top: 25px;
            min-width: 70px;
            /* max-width: 250px; */
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 4px;
            background-color: var(--gray-700);
            color: #fff;
            content: attr(data-tooltip);
            font-size: 14px;
            line-height: 20px;
            transition: 0.2s ease-out;
        }
    </style>
</svelte:head>
