<script lang="ts">
    export let text: string;
</script>

<div class="root">{text}</div>

<style>
    .root {
        display: inline-block;
        padding: 4px 8px;
        background-color: var(--info-200);
        border-radius: 4px;
        font-size: 0.875rem;
        line-height: 1.2;
        font-weight: 400;
    }
</style>
