<script lang="ts">
    import intlTelInput from "intl-tel-input";
    import "intl-tel-input/build/css/intlTelInput.css";
    import { onMount, createEventDispatcher, onDestroy } from "svelte";

    import Input from "../../components/account/Input.svelte";
    import { COUNTRIES } from "../../static/constant";

    export let id: string = Math.random().toString();
    export let useCustomInput: boolean = false;
    export let phoneNumberWithCode: string = "";
    export let phoneNumber: string = "";
    export let countryCode: string = "";
    export let disable: boolean = true;
    export let isfocus: boolean = false;
    export let invalid: string = "";
    export let fontSize: string = "14px";
    export let nullAllowed: boolean = true;
    export let placeholder: string = "";
    export let numberIsInvalid: boolean = false;
    export let showInvalidError: boolean = false;

    let newPhoneNumber: string = phoneNumber;
    const PHONE_TO_COUNTRY = {};
    Object.keys(COUNTRIES).forEach((countryCode) => {
        const phoneCountryCode = COUNTRIES[countryCode].phoneCountryCode;
        PHONE_TO_COUNTRY[phoneCountryCode] = countryCode;
    });

    const dispatch = createEventDispatcher();

    function handleKeyPress(event): any {
        if (!event.key.match(/^[0-9]$/g)) {
            event.preventDefault();
        }
    }

    let iti: any;
    $: if (phoneNumberWithCode) {
        iti?.setNumber(phoneNumberWithCode);
    }

    $: if (countryCode && countryCode in PHONE_TO_COUNTRY) {
        iti?.setCountry(PHONE_TO_COUNTRY[countryCode]);
    }

    export function setCountry(country: string) {
        if (country) {
            iti?.setCountry(country);
        }
    }

    onMount(() => {
        const input = document.getElementById(id);
        iti = intlTelInput(input, {
            separateDialCode: true,
            preferredCountries: ["gb", "no", "se", "nl"]
        });
        if (phoneNumberWithCode) {
            iti?.setNumber(phoneNumberWithCode);
        }
        onCountryChange(); // to resolve and propagate phoneNumber and countryCode
        input.addEventListener("countrychange", onCountryChange);
    });

    onDestroy(() => {
        const input = document.getElementById(id);
        input?.removeEventListener("countrychange", onCountryChange);
    });

    function onCountryChange() {
        newPhoneNumber = phoneNumber = iti?.telInput.value;
        const country = iti?.getSelectedCountryData();
        countryCode = "+" + country.dialCode;
        phoneNumberWithCode = countryCode + phoneNumber;
    }

    function onClose() {
        dispatch("close");
    }

    $: if ((phoneNumber && newPhoneNumber != phoneNumber) || (!phoneNumber && newPhoneNumber)) {
        phoneNumberWithCode = countryCode + newPhoneNumber;
        if (phoneNumberWithCode) {
            invalid = "";
            iti?.setNumber(phoneNumberWithCode);
            phoneNumber = newPhoneNumber;
        }
    }

    $: if (invalid && showInvalidError) {
        document.querySelector("div.iti__selected-flag")?.classList.add("invalid");
    } else {
        document.querySelector("div.iti__selected-flag")?.classList.remove("invalid");
    }
</script>

{#if useCustomInput}
    <Input
        {id}
        data-testid="country_code_input"
        inputType="tel"
        bind:value={newPhoneNumber}
        diallingCountryCode={countryCode}
        {nullAllowed}
        on:close={onClose}
        {placeholder}
        bind:showInvalidText={numberIsInvalid}
    />
{:else}
    <div class="phone-input" class:edit-phone={disable}>
        <input
            type="tel"
            {id}
            data-testid="phone_number_input"
            placeholder="Phone number"
            class="phone"
            class:invalid
            class:edit-phone={disable}
            style="font-size: {fontSize};"
            disabled={disable}
            bind:value={newPhoneNumber}
            on:focus={() => (isfocus = true)}
            on:focusout={() => (isfocus = false)}
            on:input={(e) => {
                invalid = "";
                phoneNumber = e.target.value.replace("+", "");
                phoneNumberWithCode = countryCode + phoneNumber;
                dispatch("input");
            }}
            on:keypress={handleKeyPress}
        />
    </div>
    {#if showInvalidError && invalid}
        <p class="error">
            {invalid}
        </p>
    {/if}
{/if}

<style lang="scss">
    .phone {
        color: var(--ctnGeneralPrimary);
        outline: none;
        margin-left: 8px !important;
        padding-left: 16px !important;
        height: 42px;
        border-radius: 8px;
        width: 100%;
        position: static;
        border: 1px solid var(--gray-300);
        background: var(--basic);
    }

    @media screen and (max-width: 350px) {
        input {
            width: 150px;
        }
    }
    .edit-phone {
        border: none;
    }

    p.error {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: var(--ctnSemanticErrorPrimary);
    }
    .phone:focus-within,
    .phone:focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    .invalid {
        border: 1px solid var(--borderSemanticErrorSecondary) !important;
        background-color: var(--bgInteractiveSemanticErrorHoverSecondary) !important;
    }

    .invalid:focus,
    .invalid:focus-within {
        border: 2px solid var(--borderSemanticErrorSecondary);
        box-shadow: 0px 1px 2px 0px var(--gray-900);
    }

    .error {
        padding-top: 4px;
    }

    * :global(.iti__selected-flag):focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
</style>
