<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import StatementOfAccountModal from "../../../components/modals/StatementOfAccountModal.svelte";
    import TradeAccounts from "./TradeAccounts.svelte";
    import BillingAccounts from "./BillingAccounts.svelte";
    import API from "../../../services/apiService";
    import { adaptTradeAccountsToUI, type TradeAccountUI } from "./helpers";
    import { BASE_URL, GET_CUSTOMER_BETA } from "@/static/endPoints";
    import modalState from "@/store/modals.store";
    import { singleCustomer } from "@/store/merchant/customers.store";
    import type { TradeAccount, ICustomersResponse } from "@/static/types";

    export let url;
    export let id;

    const [buyerOrgNumber, buyerCountryCode] = id.split("**");

    const TRADE_ACCOUNT_TAB_NAME = $_("customer.tradeAccount");
    const BILLING_ACCOUNT_TAB_NAME = $_("customer.billingAccount");
    const tabs = [TRADE_ACCOUNT_TAB_NAME, BILLING_ACCOUNT_TAB_NAME];
    let selectedTab = tabs[0];

    let loadingTradeAccounts: boolean = false;
    let tradeAccounts: Array<TradeAccountUI> = [];

    function setTab(tabName) {
        selectedTab = tabName;
    }

    async function fetchSingleCustomerDetails() {
        API.get(
            GET_CUSTOMER_BETA +
                `?country_code=${buyerCountryCode}&organization_number=${buyerOrgNumber}`
        )
            .then((res: ICustomersResponse) => {
                if (res.customers && res.customers[0]) {
                    singleCustomer.set(res.customers[0]);
                }
            })
            .catch((e) => {
                console.error(`Failed to fetchSingleCustomerDetails because ${e}`);
            });
    }

    async function fetchTradeAccounts() {
        loadingTradeAccounts = true;
        API.get(
            `${BASE_URL}/v1/portal/merchant/trade_accounts?country_code=${buyerCountryCode}&organization_number=${buyerOrgNumber}`
        )
            .then((response: { data: Array<TradeAccount> }) => {
                tradeAccounts = adaptTradeAccountsToUI(response.data).sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
            })
            .catch((error) => {
                console.error(`fetchTradeAccounts failed with ${error}`);
            })
            .finally(() => {
                loadingTradeAccounts = false;
            });
    }

    function showInvoiceStatementModal() {
        const props = {
            exportUrl: `${BASE_URL}/v1/portal/merchant/invoice/statement`,
            isBuyerPortal: false,
            headerSubText: $_("customer.downloadSpreadsheet"),
            showAllText: $_("customer.showAllInvoices"),
            additionalFilters: {
                buyer_companies: [
                    {
                        national_identification_number: buyerOrgNumber,
                        country_code: buyerCountryCode
                    }
                ]
            }
        };
        modalState.actions.setModal("", props, StatementOfAccountModal);
    }

    onMount(async () => {
        if (!$singleCustomer) {
            await fetchSingleCustomerDetails();
        }
        await fetchTradeAccounts();
    });
</script>

<MetaWrapper title={$_("customer.detailsPageTitle")}>
    <div class={`page-container ${url}`}>
        <PageHeader
            title={$singleCustomer?.buyer_company_name || $_("components.loading")}
            description={$singleCustomer?.buyer_organization_number || $_("components.loading")}
            backLink="/merchant/customers"
            customClasses="customer-details"
        >
            <div slot="tabs" class="tab-headers">
                {#each tabs as tab}
                    <button
                        class="px-6 mr-2 h-10 tab-header"
                        on:click={() => setTab(tab)}
                        class:selected={selectedTab === tab}
                    >
                        {tab}
                    </button>
                {/each}
            </div>
            <button
                slot="cta"
                class="button-secondary button-sm"
                style="width:100%;max-width:fit-content;"
                on:click={showInvoiceStatementModal}
            >
                {$_("customer.downloadStatement")}
            </button>
        </PageHeader>

        <div class="content">
            {#if selectedTab === TRADE_ACCOUNT_TAB_NAME}
                <TradeAccounts {tradeAccounts} {loadingTradeAccounts} {fetchTradeAccounts} />
            {:else}
                <BillingAccounts {id} />
            {/if}
        </div>
    </div>
</MetaWrapper>

<style>
</style>
