<script lang="ts">
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.99984 10.8334C10.4601 10.8334 10.8332 10.4603 10.8332 10C10.8332 9.5398 10.4601 9.16671 9.99984 9.16671C9.5396 9.16671 9.1665 9.5398 9.1665 10C9.1665 10.4603 9.5396 10.8334 9.99984 10.8334Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M9.99984 5.00004C10.4601 5.00004 10.8332 4.62694 10.8332 4.16671C10.8332 3.70647 10.4601 3.33337 9.99984 3.33337C9.5396 3.33337 9.1665 3.70647 9.1665 4.16671C9.1665 4.62694 9.5396 5.00004 9.99984 5.00004Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        d="M9.99984 16.6667C10.4601 16.6667 10.8332 16.2936 10.8332 15.8334C10.8332 15.3731 10.4601 15 9.99984 15C9.5396 15 9.1665 15.3731 9.1665 15.8334C9.1665 16.2936 9.5396 16.6667 9.99984 16.6667Z"
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
