/* 
The plan is to slowly migrate functions over from functions.ts
once they are covered by tests.
Please make sure each function here is covered by a unit test before
merging into staging.
*/
import { getLocaleFromNavigator, getNumberFormatter } from "svelte-i18n";
import type { CurrencyCode } from "../static/types";

// TODO add better types and change to provider for other formatting needs
const getFormatter = (locale_?: string, formatOptions?: Intl.NumberFormatOptions) => {
    // getNumberFormatter gets locale from init out of the box but this
    // allows a user override as well as easier testing :)
    const locale = locale_ || getLocaleFromNavigator();
    return getNumberFormatter({ locale, ...formatOptions });
};

interface NumberFormatOptions {
    decimalPlaces?: number;
    // TODO figure out better type for all options that use locale
    locale?: string;
}

// can't use parseFloat directly as we need to call it
// conditionally with type guard
const coerceToNumber = (amount: number | string): number => {
    return typeof amount === "string" ? parseFloat(amount) : amount;
};

const formatNumber = (amount: number | string, options?: NumberFormatOptions): string => {
    const formatOptions = {
        minimumFractionDigits: options?.decimalPlaces ?? 2,
        maximumFractionDigits: options?.decimalPlaces ?? 2,
        style: "decimal"
    };
    const amountValue: number = coerceToNumber(amount);
    return getFormatter(options?.locale, formatOptions).format(amountValue);
};

type CurrencyDisplay = "symbol" | "narrowSymbol" | "code" | "name";

export interface CurrencyFormatOptions {
    locale?: string;
    decimalPlaces?: number;
    currencyDisplay?: CurrencyDisplay;
}

const formatCurrency =
    (currency: CurrencyCode) =>
    (amount: number | string, options?: CurrencyFormatOptions): string => {
        if (currency === undefined) return "";
        else {
            const formatOptions = {
                minimumFractionDigits: options?.decimalPlaces ?? 2,
                maximumFractionDigits: options?.decimalPlaces ?? 2,
                currencyDisplay: options?.currencyDisplay ?? "narrowSymbol",
                style: "currency",
                currency: currency
            };
            const amountValue: number = coerceToNumber(amount);
            return getFormatter(options?.locale, formatOptions).format(amountValue);
        }
    };

const formatCurrencyToParts =
    (currency: CurrencyCode) =>
    (
        amount: number | string,
        options?: CurrencyFormatOptions
    ): { currency: string; amount: string } => {
        if (currency === undefined) return;
        const baseOptions = {
            minimumFractionDigits: options?.decimalPlaces ?? 2,
            maximumFractionDigits: options?.decimalPlaces ?? 2
        };
        const currencyFormatOptions = {
            ...baseOptions,
            currencyDisplay: options?.currencyDisplay ?? "symbol",
            style: "currency",
            currency: currency
        };
        const currencyFormatter = getFormatter(options?.locale, currencyFormatOptions);
        const numberFormatOptions = {
            ...baseOptions,
            style: "decimal"
        };
        const numberFormatter = getFormatter(options?.locale, numberFormatOptions);
        const amountValue: number = coerceToNumber(amount);
        const currencyPart = currencyFormatter
            .formatToParts(amountValue)
            .find((p) => p.type === "currency").value;
        const amountPart = numberFormatter.format(amountValue);
        return { currency: currencyPart, amount: amountPart };
    };

interface CurrencySymbolOptions {
    locale?: string;
    // as we always start with currency code, e.g. GBP we
    // probably only want symbol, narrowSymbol or name
    currencyDisplay?: CurrencyDisplay;
}
const getCurrencySymbol = (currency: CurrencyCode, options?: CurrencySymbolOptions): string => {
    // need to provide amount value to satisfy function constraint
    const { currency: symbol, amount: _ } = formatCurrencyToParts(currency)(0, { ...options });
    return symbol;
};

export default {
    formatNumber,
    formatCurrency,
    formatCurrencyToParts,
    getCurrencySymbol
};
