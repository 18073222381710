import { getEnvironmentName, IS_RUNNING_IN_BROWSER } from "./environment";

// Keep this module in sync with its namesake in admin-portal and checkout-page.
// When portals is upgraded to sveltekit, move this module into a shared library and import into all three projects.

const IS_DEV = getEnvironmentName() === "dev";
const FINGERPRINT_ENABLED = IS_RUNNING_IN_BROWSER;
const PROXY_FETCH_ENABLED = true;
// Enable local storage for faster initial load times, but this will reduce the usefulness of the fingerprint.com API since it will no longer be called on every load.
const MAX_CACHE_AGE_DAYS = 14;

const BASE64_ENCODED = !IS_DEV;
const FINGERPRINT_SCRIPT_SOURCE = "https://metrics.two.inc/web/v3/JS0kEm7zeYUvDf93cEua";
const FINGERPRINT_HEADER_KEY = "X-Two-FX";
const FINGERPRINT_STORAGE_KEY = "X-Two-FX";
const INITIALIZATION_TIMEOUT_MS = 5000;
const INITIALIZATION_RETRY_DELAY_MS = 50;

type Fingerprint = {
    requestId: string;
    visitorFound: boolean;
    visitorId: string;
    confidence: { revision: string; score: number };
    meta: { version: string };
};

type FingerprintScriptParameters = { endpoint: string[] };

type FingerprintScript = {
    load: (parameters: FingerprintScriptParameters) => Promise<{ get: () => Promise<Fingerprint> }>;
    defaultEndpoint: string;
};

let fingerprintPromise: Promise<{ [key: string]: string }> | null = null;

async function loadFingerprint(): Promise<Fingerprint | null> {
    try {
        // Disable eslint rule for dynamic import since it is required to load the fingerprint script.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const FingerprintJS: FingerprintScript = await import(
            FINGERPRINT_SCRIPT_SOURCE /* @vite-ignore */
        );
        const fp = await FingerprintJS.load({
            endpoint: ["https://metrics.two.inc", FingerprintJS.defaultEndpoint]
        });

        return await fp.get();
    } catch (err) {
        // Slightly obscure error message, deliberately, so that the word "fingerprint" doesn't apear in the obfuscated code that we serve.
        console.warn("Failed to fetch metrics");
        console.warn(err);
        return Promise.resolve(null) as Promise<null>;
    }
}

function validateFingerprint(fingerprintEncoded: string): boolean {
    if (!fingerprintEncoded) {
        return false;
    }

    try {
        const fingerprintJson = BASE64_ENCODED ? atob(fingerprintEncoded) : fingerprintEncoded;
        const fingerprint = JSON.parse(fingerprintJson) as {
            visitorId: string;
            requestId: string;
            confidence: { score: number };
        };
        return Boolean(fingerprint.visitorId && fingerprint.requestId);
    } catch {
        console.warn("Invalid stored metrics");
        return false;
    }
}

function encodeFingerprint(fingerprint: Fingerprint): string {
    const fingerprintJson = JSON.stringify(fingerprint);
    return BASE64_ENCODED ? btoa(fingerprintJson) : fingerprintJson;
}

function getCachedFingerprint(): string | null {
    if (MAX_CACHE_AGE_DAYS > 0) {
        const fingerprintCached = document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${FINGERPRINT_STORAGE_KEY}=`))
            ?.split("=")[1];

        if (validateFingerprint(fingerprintCached)) {
            return fingerprintCached;
        }
    }

    return null;
}

function setCachedFingerprint(fingerprint: string): void {
    if (MAX_CACHE_AGE_DAYS > 0) {
        document.cookie = `${FINGERPRINT_STORAGE_KEY}=${fingerprint}; max-age=${
            MAX_CACHE_AGE_DAYS * 86400
        }; ${IS_DEV ? "" : "domain=.two.inc; path=/; "}`;
    }
}

async function getOrLoadFingerprint(): Promise<{ [key: string]: string }> {
    let fingerprintEncoded: string | null = getCachedFingerprint();

    if (!fingerprintEncoded) {
        const fingerprint = await loadFingerprint();

        if (fingerprint != null) {
            fingerprintEncoded = encodeFingerprint(fingerprint);
            setCachedFingerprint(fingerprintEncoded);
        }
    }

    return { [FINGERPRINT_HEADER_KEY]: fingerprintEncoded ?? "" };
}

// Disable linting for the fetch proxy: we know this is correct but it would be very verbose to convince the linter of that.
/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */
async function fetchWithFingerprint(target, thisArg, args) {
    const fingerprintHeaders = await getFingerprintHeaders();
    const resource = args[0];
    // prettier-ignore
    const originalOptions = (args.length > 1 && args[1] != null) ? args[1] : {};
    const originalHeaders = originalOptions.headers ?? {};
    const modifiedHeaders = new Headers(originalHeaders);

    for (const [key, value] of Object.entries(fingerprintHeaders)) {
        modifiedHeaders.set(key, value);
    }

    const modified_options = { ...originalOptions, headers: modifiedHeaders };

    return Reflect.apply(target, thisArg, [resource, modified_options]);
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access */

function isOurApi(resource: Request | string): boolean {
    const url = resource instanceof Request ? resource.url : resource.toString();
    return RegExp(/(api\.([a-z]+\.)?two.inc|localhost)/).exec(url) != null;
}

// Marked async to ensure that callers do not wait. This speeds up startup on admin portal and checkout-page markedly.
//eslint-disable-next-line @typescript-eslint/require-await
async function initFingerprint() {
    if (FINGERPRINT_ENABLED) {
        fingerprintPromise = getOrLoadFingerprint();

        if (PROXY_FETCH_ENABLED) {
            window.fetch = new Proxy(window.fetch, {
                apply(target, thisArg, args) {
                    if (isOurApi(args[0] as Request | string)) {
                        return fetchWithFingerprint(target, thisArg, args);
                    } else {
                        // Disable linting for the invocation of the original fetch function: we know this is correct
                        // but it would be very verbose to convince the linter of that.
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        return Reflect.apply(target, thisArg, args);
                    }
                }
            });
        }
    }
}

async function getFingerprintHeaders(
    timeoutMs: number = INITIALIZATION_TIMEOUT_MS
): Promise<{ [key: string]: string }> {
    if (fingerprintPromise == null) {
        // initFingerprint should already have been called from a suitable site within application startup. Wait until it has completed.
        if (FINGERPRINT_ENABLED) {
            for (
                let i = 0;
                // prettier-ignore
                (i < (timeoutMs / INITIALIZATION_RETRY_DELAY_MS)) && (fingerprintPromise == null);
                i++
            ) {
                await new Promise((resolve) => setTimeout(resolve, INITIALIZATION_RETRY_DELAY_MS));
            }
        }

        if (fingerprintPromise == null) {
            // Timed out. Leave fingerprintPromise null to force a retry on the next call.
            return Promise.resolve({});
        }
    }

    return fingerprintPromise;
}

export { initFingerprint, getFingerprintHeaders };
