import Account from "../pages/buyer/account/Account.svelte";
import Users from "../pages/buyer/users/Users.svelte";
import Statements from "../pages/buyer/billingPeriods/Statements.svelte";
import PeriodDetail from "../pages/buyer/billingPeriods/periodDetail/PeriodDetail.svelte";
import Invoices from "../pages/buyer/invoices/Invoices.svelte";
import Home from "../pages/buyer/homepage/Home.svelte";

const routes = [
    { path: "", component: Home },
    { path: "statements", component: Statements },
    { path: "billing/:billingaccountid/period/:periodid", component: PeriodDetail },
    { path: "invoices", component: Invoices },
    { path: "users", component: Users },
    { path: "account", component: Account }
];

export default routes;
