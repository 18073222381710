<script lang="ts">
    export let size = 20;
    export let fill: string = "#1D2939";
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="trash-2">
        <path
            id="Vector"
            d="M3.33317 4.16675L3.82396 12.5102C3.9375 14.4403 3.99427 15.4054 4.37553 16.1522C4.79523 16.9743 5.48635 17.626 6.3317 17.9967C7.09962 18.3334 8.06636 18.3334 9.99984 18.3334V18.3334C11.9333 18.3334 12.9001 18.3334 13.668 17.9967C14.5133 17.626 15.2044 16.9743 15.6241 16.1522C16.0054 15.4054 16.0622 14.4403 16.1757 12.5102L16.6665 4.16675M3.33317 4.16675H1.6665M3.33317 4.16675H16.6665M16.6665 4.16675H18.3332M13.3332 4.16675L13.0469 3.3078C12.8502 2.71769 12.7518 2.42263 12.5694 2.20448C12.4083 2.01185 12.2014 1.86274 11.9677 1.77083C11.7031 1.66675 11.3921 1.66675 10.77 1.66675H9.22966C8.60762 1.66675 8.29661 1.66675 8.03197 1.77083C7.79828 1.86274 7.5914 2.01185 7.4303 2.20448C7.24788 2.42263 7.14952 2.71769 6.95282 3.3078L6.6665 4.16675"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </g>
</svg>
