<script lang="ts">
    import { onMount } from "svelte";

    let currentIndex: number = 0;

    export let imageSources: any[] = [];
    export let rotationInterval: number = 3000;

    function rotate() {
        currentIndex = (currentIndex + 1) % imageSources.length;
    }

    onMount(() => {
        setInterval(rotate, rotationInterval);
    });
</script>

<div class="carousel-main">
    <img
        src={imageSources[currentIndex].src}
        alt={`Image ${imageSources[currentIndex].src}`}
        class="display-image"
    />
    <div class="gradient"></div>
    <div class="testimonial-container">
        <div class="testimonial-main">
            {imageSources[currentIndex].testimonial}
        </div>
        <div class="testimonial-provider">
            {imageSources[currentIndex].providerName}
        </div>
        <div class="testimonial-role-company">
            {imageSources[currentIndex].roleCompany}
        </div>
    </div>
</div>

<style lang="scss">
    .carousel-main {
        display: flex;
        overflow: hidden;
        height: 100vh;
        padding: 1rem;
        position: relative;
        flex: 1;
    }

    .display-image {
        width: 100%;
        border-radius: 1rem;
        object-fit: cover;
        transition: transform 0.5s ease-in-out;
        position: relative;
    }

    .gradient {
        height: calc(100% - 2rem);
        width: calc(100% - 2rem);
        position: absolute;
        background: linear-gradient(180deg, rgba(39, 44, 111, 0) 0%, #0e113a 100%);
        border-radius: 1rem;
    }

    .testimonial-container {
        left: 4rem;
        color: white;
        bottom: 4rem;
        right: 4rem;
        position: absolute;

        .testimonial-main {
            color: white;
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
        }

        .testimonial-provider {
            margin-top: 2rem;
            color: white;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
        }

        .testimonial-role-company {
            color: var(--gray-300);
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
</style>
