<script lang="ts">
    export let fill: string = "var(--ctnGeneralSecondary)";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5001 0.916748L12.475 0.916748H12.475C11.6425 0.916745 10.9827 0.916742 10.4472 0.95026C9.90036 0.984488 9.43316 1.05574 8.99356 1.22119C7.71336 1.70303 6.70303 2.71336 6.2212 3.99356C6.05574 4.43316 5.98449 4.90036 5.95026 5.44722C5.91674 5.98272 5.91675 6.64252 5.91675 7.47501V7.47503L5.91675 7.50008V12.5001L5.91675 12.5251V12.5252C5.91675 13.3576 5.91674 14.0174 5.95026 14.553C5.98449 15.0998 6.05574 15.567 6.2212 16.0066C6.70303 17.2868 7.71336 18.2971 8.99356 18.779C9.43316 18.9444 9.90036 19.0157 10.4472 19.0499C10.9827 19.0834 11.6425 19.0834 12.475 19.0834H12.5001H12.5251C13.3576 19.0834 14.0174 19.0834 14.5529 19.0499C15.0998 19.0157 15.567 18.9444 16.0066 18.779C17.2868 18.2971 18.2971 17.2868 18.779 16.0066C18.9444 15.567 19.0157 15.0998 19.0499 14.5529C19.0834 14.0174 19.0834 13.3576 19.0834 12.5251V12.5001V7.50008V7.47504C19.0834 6.64254 19.0834 5.98272 19.0499 5.44721C19.0157 4.90036 18.9444 4.43316 18.779 3.99356C18.2971 2.71336 17.2868 1.70303 16.0066 1.22119C15.567 1.05574 15.0998 0.984488 14.5529 0.95026C14.0174 0.916742 13.3576 0.916745 12.5251 0.916748H12.5251L12.5001 0.916748ZM10.3031 6.13645C10.596 5.84356 11.0709 5.84356 11.3637 6.13645L14.6971 9.46978C14.99 9.76268 14.99 10.2376 14.6971 10.5304L11.3637 13.8638C11.0709 14.1567 10.596 14.1567 10.3031 13.8638C10.0102 13.5709 10.0102 13.096 10.3031 12.8031L12.3561 10.7501L1.66675 10.7501C1.25253 10.7501 0.916748 10.4143 0.916748 10.0001C0.916748 9.5859 1.25253 9.25012 1.66675 9.25012L12.3561 9.25012L10.3031 7.19711C10.0102 6.90422 10.0102 6.42935 10.3031 6.13645Z"
        {fill}
    />
</svg>
