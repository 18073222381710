<script lang="ts">
    import { _ } from "svelte-i18n";

    import {
        getExposureChangeOverTimeChartData,
        getLosesPerMonthChartData,
        getLostPercentageVolumeChartData,
        getApprovalRateChartData,
        getCustomersRejectedChartData
    } from "../common/charts";
    import Card from "../../../../components/card/Card.svelte";
    import RiskChart from "../common/RiskChart.svelte";
    import environment from "@/services/environment";
</script>

<section id="cards" class="mx-8 mt-6 grid grid-cols-2 gap-4">
    <Card
        id="current-exposure"
        text={$_("riskManagement.exposure.total")}
        value="kr 93,201"
        textClasses="ctn-general-primary font-bold text-base"
    >
        {$_("riskManagement.exposure.invoicesAwaitingPayment")}
    </Card>
    <Card
        id="bespoke-exposure"
        text={$_("riskManagement.exposure.bespokeExposure")}
        value="kr 1,220"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        {$_("riskManagement.exposure.invoicesOverdueAwaitingPayment")}
    </Card>
    <Card
        id="credit-approval-rate"
        text={$_("riskManagement.exposure.creditApprovalRate")}
        value="87%"
        valueClasses="ctn-semantic-success-primary"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        {$_("riskManagement.exposure.creditApprovalRateDescription")}
    </Card>
    <Card
        id="credit-limit-change"
        text={$_("riskManagement.exposure.creditLimitChange")}
        value="+12%"
        valueClasses="ctn-semantic-success-primary"
        textClasses="ctn-general-primary font-semibold text-base"
    >
        {$_("riskManagement.exposure.creditLimitChangeDescription")}
    </Card>
</section>

<section id="exposure-chart" class="mx-8 mt-4 grid grid-cols-1 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.exposure.title")}
        titleTooltip={$_("riskManagement.exposure.overviewExposureTooltip")}
        chartData={getExposureChangeOverTimeChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
    />
</section>

<section id="credit-loses-charts" class="mx-8 mt-4 grid grid-cols-2 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.exposure.creditLoses")}
        subtitle={$_("riskManagement.exposure.losesPerMonth")}
        subtitleTooltip={$_("riskManagement.fraudInsights.lossesPerMonthTooltip")}
        chartData={getLosesPerMonthChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
        showCustomLegend={false}
    />

    <RiskChart
        title="&nbsp;"
        subtitle={$_("riskManagement.fraudInsights.percentageLostText")}
        subtitleTooltip={$_("riskManagement.fraudInsights.percentageLostTooltip")}
        chartData={getLostPercentageVolumeChartData(
            $_("riskManagement.exposure.customersWithOverride"),
            $_("riskManagement.exposure.organicExposure", {
                values: { brandName: environment.branding.displayName }
            })
        )}
    />
</section>

<section id="credit-approval" class="mx-8 my-4 grid grid-cols-2 bg-white rounded-lg">
    <RiskChart
        title={$_("riskManagement.exposure.creditApproval")}
        subtitle={$_("riskManagement.exposure.approvalRate")}
        subtitleTooltip={$_("riskManagement.exposure.approvalRateTooltip")}
        chartData={getApprovalRateChartData()}
        showCustomLegend={false}
    />

    <RiskChart
        title="&nbsp;"
        subtitle={$_("riskManagement.exposure.countRejectedCustomers")}
        subtitleTooltip={$_("riskManagement.exposure.countRejectedCustomersTooltip")}
        chartData={getCustomersRejectedChartData()}
        showCustomLegend={false}
    />
</section>
