<script lang="ts">
    export let fill: string = "#2F384C";
    export let size: number = 24;
</script>

<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="cross">
        <path
            id="Vector"
            d="M6 18L18 6M6 6L18 18"
            stroke={fill}
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </g>
</svg>
