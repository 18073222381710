<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Tooltip } from "@two-ui/core";

    import Input from "../../../components/Elements/InputBox.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import { OrderState, OrderType, InvoiceType } from "../../../static/types";
    import environment from "@/services/environment";

    export let order;
    export let editEmail;
    export let onEdit;
    export let invalidInvoiceEmail;
    export let updateInvoiceEmail;
    export let formatAmount;
    export let state;
    export let fulfilledAmount;
    export let withCancelledRemaining;

    let emailChanged: boolean = false;

    function getRiskHolder() {
        return order.invoice_type === InvoiceType.FUNDED
            ? environment.branding.displayName
            : order.merchantShortName;
    }

    function getDeclineReasonText(declineReason: string, merchantName: string) {
        const supportEmail = environment.branding.supportEmail;
        const displayName = environment.branding.displayName;

        switch (declineReason) {
            case "TOO_HIGH_RISK":
                return $_("orderDeclineReason.TOO_HIGH_RISK", { values: { displayName } });

            case "BUYER_NOT_FOUND":
                return $_("orderDeclineReason.BUYER_NOT_FOUND", { values: { displayName } });

            case "BUYER_ADDRESS_DEVIATION":
                return $_("orderDeclineReason.BUYER_ADDRESS_DEVIATION", {
                    values: { displayName }
                });

            case "BUYER_INFO_INCONSISTENT":
                return $_("orderDeclineReason.BUYER_INFO_INCONSISTENT", {
                    values: { displayName }
                });

            case "BUYER_AUTHENTICATION_FAILED":
                return $_("orderDeclineReason.BUYER_AUTHENTICATION_FAILED", {
                    values: { displayName }
                });

            case "FRAUD_CHECK_NOT_PASSED":
                return $_("orderDeclineReason.FRAUD_CHECK_NOT_PASSED", { values: { displayName } });

            case "DECLINED_IN_VERIFICATION":
                return $_("orderDeclineReason.DECLINED_IN_VERIFICATION", {
                    values: { displayName }
                });

            case "UNDEFINED":
                return $_("orderDeclineReason.UNDEFINED", { values: { displayName } });

            case "INVALID_LEGAL_REP":
                return $_("orderDeclineReason.INVALID_LEGAL_REP", { values: { displayName } });

            case "CREDIT_CHECK_FAILED":
                return $_("orderDeclineReason.CREDIT_CHECK_FAILED", { values: { displayName } });

            case "EXCEPTION":
                return $_("orderDeclineReason.EXCEPTION", { values: { displayName } });

            case "SERVICE_UNAVAILABLE":
                return $_("orderDeclineReason.SERVICE_UNAVAILABLE", { values: { displayName } });

            case "BUYER_IN_ARREARS":
                return $_("orderDeclineReason.BUYER_IN_ARREARS", { values: { displayName } });

            case "ORDER_EXCEEDS_MAX_INVOICE_AMOUNT":
                return $_("orderDeclineReason.ORDER_EXCEEDS_MAX_INVOICE_AMOUNT", {
                    values: { displayName }
                });

            case "ORDER_EXCEEDS_MIN_INVOICE_AMOUNT":
                return $_("orderDeclineReason.ORDER_EXCEEDS_MIN_INVOICE_AMOUNT", {
                    values: { displayName }
                });

            case "ORDER_BELOW_MIN_INVOICE_AMOUNT":
                return $_("orderDeclineReason.ORDER_BELOW_MIN_INVOICE_AMOUNT", {
                    values: { displayName }
                });

            case "MERCHANT_LIMIT_EXCEEDED":
                return $_("orderDeclineReason.MERCHANT_LIMIT_EXCEEDED", {
                    values: { displayName, merchantName, supportEmail }
                });

            case "BUYER_LIMIT_EXCEEDED":
                return $_("orderDeclineReason.BUYER_LIMIT_EXCEEDED");

            case "INVALID_ORDER_DATA":
                return $_("orderDeclineReason.INVALID_ORDER_DATA");

            case "INVALID_ORDER_DATA_TOTAL_AMOUNT_MISMATCH":
                return $_("orderDeclineReason.INVALID_ORDER_DATA_TOTAL_AMOUNT_MISMATCH");

            case "INVALID_ORDER_LINE_ITEM_DATA_AMOUNT_MISMATCH":
                return $_("orderDeclineReason.INVALID_ORDER_LINE_ITEM_DATA_AMOUNT_MISMATCH");

            default:
                return $_("orderDeclineReason.DEFAULT");
        }
    }
</script>

<section class="details">
    <div class="card relative">
        <span class="card-title">{$_("order.details.customer_details")}</span>
        <div class="card-heading-items">
            <div>
                <div class="card-heading">{$_("order.details.business_name")}</div>
                <div class="card-main-detail">
                    <strong>{order.companyName}</strong>, {order.country}
                </div>
            </div>
        </div>
        <div class="card-columns">
            <div class="two-col">
                <div class="card-heading">{$_("order.details.org_number")}</div>
                <div class="card-item">{order.companyID}</div>
                <div class="card-heading">{$_("order.details.billing_address")}</div>
                <div class="card-item">{order.billingAddress}</div>
                {#if order.shippingAddress}
                    <div class="card-heading">{$_("order.details.shipping_address")}</div>
                    <div class="card-item">{order.shippingAddress}</div>
                {/if}
                <div class="editable-cell">
                    <div class="card-heading">{$_("order.details.invoice_email")}</div>
                    <div class="flex-col">
                        {#if !editEmail}
                            <div class="flex justify-between">
                                <div class="relative">
                                    <Tooltip
                                        label={order.invoiceEmails || order.email}
                                        position="top"
                                    >
                                        <div class="card-item invoice-email">
                                            {order.invoiceEmails || order.email}
                                        </div>
                                    </Tooltip>
                                </div>
                                {#if order.state === "CONFIRMED" || order.state === "VERIFIED"}
                                    <div class="text-white relative">
                                        <Tooltip label={$_("components.edit")} position="top">
                                            <button class="p-1" on:click={onEdit}>
                                                <Icon
                                                    name="edit"
                                                    size={22}
                                                    fill="var(--royal-blue)"
                                                />
                                            </button>
                                        </Tooltip>
                                    </div>
                                {:else}
                                    <div class="text-white relative">
                                        <Tooltip
                                            label={$_("order.details.edit_unavailable")}
                                            position="top"
                                        >
                                            <Icon
                                                name="noedit"
                                                size={22}
                                                fill="var(--royal-blue)"
                                            />
                                        </Tooltip>
                                    </div>
                                {/if}
                            </div>
                        {:else}
                            <Input
                                type="text"
                                bind:value={order.invoiceEmails}
                                placeholder={!order.invoiceEmails ? order.email : ""}
                                on:change={() => {
                                    emailChanged = true;
                                }}
                            />
                            {#if invalidInvoiceEmail}
                                <div class="text-sm error-message">
                                    {$_("order.details.email_format_invalid")}
                                </div>
                            {/if}
                        {/if}
                    </div>
                </div>
            </div>
            <div class="two-col">
                <div class="card-heading">{$_("order.details.representative")}</div>
                <div class="card-main-detail"><strong>{order.buyerName}</strong></div>
                <div class="card-heading">{$_("order.details.phone_number")}</div>
                <div class="card-item">{order.phone}</div>
                <div class="card-heading">{$_("order.details.representative_email")}</div>
                <div class="card-item">{order.email}</div>
                {#if order.buyerDepartment}
                    <div class="card-heading">{$_("order.details.department")}</div>
                    <div class="card-item">{order.buyerDepartment}</div>
                {/if}
            </div>
        </div>
        {#if editEmail}
            <div class="relative edit-options">
                <Button
                    size="small"
                    label={$_("components.cancel")}
                    color="var(--gray-700)"
                    bgColor="#FFF"
                    borderColor="#D0D5DD"
                    on:click={() => {
                        editEmail = false;
                    }}
                    paddingX="14px"
                    borderRadius="8px"
                />
                <Button
                    size="small"
                    label={$_("components.saveChanges")}
                    bgColor={"var(--primary-600)"}
                    on:click={updateInvoiceEmail}
                    paddingX="14px"
                    borderRadius="8px"
                    disable={!emailChanged}
                />
            </div>
        {/if}
    </div>
    {#if order.invoiceNumber && order.invoiceNumber !== ""}
        <div class="card">
            <div class="flex">
                <span class="card-title">{$_("order.details.payment_details")}</span>
            </div>
            <div class="card-heading-items">
                <div>
                    <div class="card-heading">{$_("order.details.order_value")}</div>
                    <div class="card-main-detail">
                        <strong>{formatAmount(order.grossAmount)}</strong>
                    </div>
                </div>
            </div>
            <div class="card-columns">
                <div class="two-col">
                    <div class="card-heading">{$_("order.details.account_name")}</div>
                    <div class="card-main-detail">
                        {order.invoice_details?.payee?.organization_name || "-"}
                    </div>
                    {#if order.invoice_details?.payee?.country_code === "GB"}
                        <div class="card-heading">{$_("order.details.account_number")}</div>
                        <div class="card-item">
                            {order.invoice_details?.payee?.local_account_number || "-"}
                        </div>
                        <div class="card-heading">{$_("order.details.sort_code")}</div>
                        <div class="card-item">
                            {order.invoice_details?.payee?.branch_sort_code || "-"}
                        </div>
                    {:else}
                        <div class="card-heading">{$_("order.details.account_number")}</div>
                        <div class="card-item">{order.invoice_details?.payee?.bban || "-"}</div>
                    {/if}
                </div>
                <div class="two-col">
                    <div class="card-heading">{$_("order.details.payment_reference")}</div>
                    <div class="card-item">{order.invoice_details?.payment_reference || "-"}</div>
                    {#if order.invoice_details?.invoice_addess}
                        <div class="card-heading">{$_("order.details.invoice_address")}</div>
                        <div class="card-item">{order.invoice_details?.invoice_addess}</div>
                    {/if}
                    {#if state !== OrderState.FULFILLED && state !== OrderState.REFUNDED}
                        <div class="copy-details">{$_("order.details.copy_details")}</div>
                    {/if}
                </div>
            </div>
        </div>
    {:else if state === OrderState.UNVERIFIED || state === OrderState.CANCELLED}
        <div class="card no-invoice">
            <div class="card-title">
                <Icon name="cross-circle" size={22} fill="#d92d20" />
                {#if order.status === "REJECTED" && order.declineReason}
                    <span>{$_("order.details.reject")}</span>
                {:else}
                    <span>{$_("order.details.no_invoice_issued")}</span>
                {/if}
            </div>
            {#if state === OrderState.UNVERIFIED}
                <span>
                    {$_("order.details.order_not_verified")}
                </span>
            {/if}
            {#if state === OrderState.CANCELLED}
                {#if order.status === "REJECTED"}
                    {#if order.declineReason}
                        <span>
                            {getDeclineReasonText(order.declineReason, order.companyName)}
                        </span>
                    {:else}
                        <span>
                            {$_("order.details.unableProvideCredit")}
                        </span>
                    {/if}
                {:else}
                    <span> {$_("order.details.order_cancelled")} </span>
                {/if}
            {/if}
        </div>
    {:else if state === OrderState.PENDING || state === $_("order.list.tabs.readyToFulfill")}
        <div class="card no-invoice">
            <div class="card-title">
                <Icon name="time" size={24} fill="var(--warning-400)" />
                <span>{$_("order.details.invoice_not_issued_yet")}</span>
            </div>
            {#if order.type === OrderType.BUYER_INITIATED}
                <span class="no-invoice-message">
                    {$_("order.details.details_displayed_here")}
                </span>
                <span class="no-invoice-disclaimer">
                    {$_("order.details.credit_risk_held", {
                        values: { riskHolder: getRiskHolder() }
                    })}
                </span>
            {:else if order.type === OrderType.MERCHANT_INITIATED && order.status === "QUOTED"}
                <span class="no-invoice-message">
                    {$_("order.details.buyer_must_confirm", {
                        values: { buyerName: order.buyerName, email: order.email }
                    })}
                </span>
            {:else if order.type === OrderType.MERCHANT_INITIATED && order.status === "APPROVED"}
                <span class="no-invoice-message">
                    {$_("order.details.invoice_message_merchant_fulfill")}
                </span>
            {/if}
        </div>
    {:else if order.status === "PARTIAL"}
        <div class="card">
            <div class="card-title">{$_("order.details.payment_details")}</div>
            <div class="card-heading-items">
                <div>
                    <div class="card-heading">{$_("order.details.order_value")}</div>
                    <div class="card-main-detail">
                        <strong>{formatAmount(order.grossAmount)}</strong>
                    </div>
                </div>
                <div>
                    <div class="card-heading">
                        {withCancelledRemaining
                            ? $_("status.cancelled")
                            : $_("order.details.payment_details_left_to_fulfill")}
                    </div>
                    <div class="card-main-detail">
                        {formatAmount(Number(order.grossAmount) - fulfilledAmount)}
                    </div>
                </div>
            </div>

            <div class="card-main-detail pt-4">
                {$_("order.details.multiple_invoices")}
            </div>
        </div>
    {/if}
</section>

<style>
    .details {
        display: flex;
        gap: 16px;
    }

    .card {
        flex-basis: 100%;
        border-radius: 0.5rem;
        background-color: var(--basic);
        padding: 1.5rem;
        position: relative;
        --tw-bg-opacity: 1;

        border: 1px solid #e4e7ec;
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
    }

    .card-heading-items {
        position: relative;
        padding-bottom: 1rem;
        display: flex;
        gap: 3rem;
    }
    .card-heading-items::after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: -24px;
        right: -22px;
        width: calc(100% + 48px);
        height: 1px;
        background-color: var(--gray-200);
    }

    .card-heading {
        color: var(--ctnGeneralTertiary);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 16px;
    }

    .card-main-detail {
        font-size: 0.875rem;
        line-height: 20px;
        margin-top: 4px;
    }

    .card-columns {
        display: flex;
        gap: 30px;
    }

    .two-col {
        width: 50%;
    }

    .card-item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 4px;
    }

    .invoice-email {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
        color: #2d2a3c;
    }

    .error-message {
        color: var(--old-brick);
    }

    .edit-options {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        display: flex;
        justify-content: end;
        gap: 0.5rem;
    }

    .edit-options::before {
        content: "";
        position: absolute;
        top: 1px;
        left: -24px;
        right: -22px;
        width: calc(100% + 48px);
        height: 1px;
        background-color: var(--gray-200);
    }

    .copy-details {
        color: var(--primary-700);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 18px;
    }

    .no-invoice {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .no-invoice-message {
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-500);
    }

    .no-invoice-disclaimer {
        color: var(--gray-400);
        margin-top: auto;
    }
</style>
