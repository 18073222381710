/* eslint-disable
@typescript-eslint/no-unsafe-member-access,
@typescript-eslint/no-unsafe-argument,
@typescript-eslint/no-unsafe-assignment,
@typescript-eslint/no-floating-promises */
import { writable } from "svelte/store";
import API from "../../services/apiService";
import { GET_ALL_REPAYMENT_PLANS } from "../../static/endPoints";

import {
    type ICursorPage,
    NotificationType,
    type IRepaymentPlansResponse,
    type IAccount
} from "../../static/types";
import { account } from "../merchant/account.store";
import notificationState from "../notifications.store";
import { repaymentPlansMockData } from "./../../static/data";

function createRepaymentPlans() {
    const plans = writable([]);
    const cursorPage = writable<ICursorPage>();
    const cursorLimit = writable(10);
    const plansLoading = writable(true);
    let merchantAccount: IAccount;

    account.subscribe((value: IAccount) => (merchantAccount = value));

    const setPlansLoading = (value: boolean): any => {
        plansLoading.set(value);
    };

    return {
        plansLoading,
        cursorPage,
        cursorLimit,
        plans,
        actions: {
            getAllPlans: (searchFilterQuery: string = null, responseMessage: any = undefined) => {
                setPlansLoading(true);
                let limit: number | null = null;
                cursorLimit.subscribe((value) => (limit = value));
                const searchParams = new URLSearchParams();
                if (searchFilterQuery) {
                    searchParams.append("search_filter", searchFilterQuery);
                }

                API.get(GET_ALL_REPAYMENT_PLANS)
                    .then((res: IRepaymentPlansResponse) => {
                        plans.set(res.plans);
                        if (res?.cursor_page_metadata) {
                            cursorPage.set(res?.cursor_page_metadata);
                        }
                    })
                    .catch((err) => {
                        if (err.response.status >= 500) {
                            notificationState.actions.create(
                                NotificationType.ERROR,
                                responseMessage.errorMessage,
                                null,
                                responseMessage.errorDescription
                            );
                        }
                    })
                    .finally(() => {
                        setPlansLoading(false);
                    });
            }
        }
    };
}

export const { plans, plansLoading, cursorPage, cursorLimit, actions } = createRepaymentPlans();
