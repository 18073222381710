export async function downloadBlob(responseData, fileType, fileName) {
    const file_blob = await responseData.blob();
    const link = document.createElement("a");
    // Set the href attribute of the anchor element to the Blob URL
    const url = URL.createObjectURL(file_blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${fileName}.${fileType}`);
    // Append the anchor element to the document body
    document.body.appendChild(link);
    // Programmatically trigger the download
    link.click();
    // Clean up by removing the anchor element and revoking the Blob URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
}
