<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 24 24" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill="white"
    />
    <path
        d="M10.8242 5.2002H13.2742V7.6502H10.8242V5.2002ZM10.8242 9.2252H13.2742V18.8502H10.8242V9.2252Z"
        fill="#150E0B"
    />
</svg>
