<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { InstalmentStatus } from "./types";

    export let status: InstalmentStatus;

    function localeInstalmentStatus(status: InstalmentStatus): string {
        var result = $_(`planDetails.instalmentStatus.${status}`);
        if (!result) {
            // fallback in case BE introduces new status before FE can handle it
            result = "-";
        }
        return result;
    }
</script>

<div class="status status--{status?.toLowerCase().replace(/_/g, '-')}">
    {localeInstalmentStatus(status)}
</div>

<style lang="scss">
    .status {
        border-radius: 4px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 20px;
        padding: 0.25rem 8px;
        min-width: 100px;
        display: flex; 
        align-items: center;
        justify-content: center;
        
        &--invoiced {
            color: var(--success-600);
            background-color: var(--success-50);
        }

        &--pending {
            color: var(--gray-500);
            background-color: var(--gray-100);
        }

        &--cancelled {
            color: var(--error-700);
            background-color: var(--error-50);
        }
    }
</style>
