<script lang="ts">
    import modalState from "../../../store/modals.store";
    import Icon from "../../icons/Icon.svelte";
    import SetOnboardingData from "./SetOnboardingData.svelte";

    export const props: any = {};

    function cancelOnboarding() {
        return modalState.actions.setModal(null, {});
    }
</script>

<div class="relative w-full py-10 px-8 text-sm overflow-x-hidden overflow-y-auto max-w-lg">
    <div class="close-button">
        <button on:click={cancelOnboarding}>
            <Icon name="cross" fill={"var(--gray-500)"} size={20} />
        </button>
    </div>
    <SetOnboardingData />
</div>

<style>
    .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
</style>
