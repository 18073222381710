<script lang="ts">
    import type { INotification } from "../../static/types";
    import notificationState from "../../store/notifications.store";
    import NotificationItem from "./NotificationItem.svelte";
    let notifications: INotification[] = [];

    notificationState.notification.subscribe((values) => (notifications = values));
</script>

<div class="notification-wrapper">
    {#each notifications as notification (notification.id)}
        {#if !notification.displayWithItem}
            <NotificationItem {notification} />
        {/if}
    {/each}
</div>

<style>
    .notification-wrapper {
        display: flex;
        flex-direction: column;
        gap: 14px;
        position: fixed;
        top: 24px;
        right: 24px;
        z-index: 99999;
        width: 400px;
    }
    @media screen and (max-width: 400px) {
        .notification-wrapper {
            width: 300px;
        }
    }
</style>
