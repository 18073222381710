<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton, TextInput } from "@two-ui/core";

    import { getBaseUrl } from "../../utils/functions";
    import { FULFILL_ORDER, PARTIALLY_FULFILL_ORDER } from "../../static/endPoints";
    import { NotificationType, type IOrderItem } from "../../static/types";
    import API from "../../services/apiService";
    import modalState from "../../store/modals.store";
    import notificationState from "../../store/notifications.store";
    import Icon from "../icons/Icon.svelte";
    import { account } from "@/store/merchant/account.store";

    export let orderID: string = "";
    export let merchantOrderID: string = "";
    export let buyerName: string = "";
    export let lang: string = "";
    export let isPartial: boolean = false;
    export let lineItems: IOrderItem[] = [];

    let isFulfillProcessing: boolean = false;
    let invoiceNumberValue: string = "";
    let invoiceNumberError: string = "";

    const preparePartialFulfillmentData = () => {
        let gross_amount = 0;
        let net_amount = 0;
        let discount_amount = 0;
        let tax_amount = 0;
        lineItems.forEach((lineItem) => {
            gross_amount += lineItem.gross_amount;
            net_amount += lineItem.net_amount;
            discount_amount += lineItem.discount_amount;
            tax_amount += lineItem.tax_amount;
        });

        gross_amount = Number(gross_amount.toFixed(2));
        net_amount = Number(net_amount.toFixed(2));
        discount_amount = Number(discount_amount.toFixed(2));
        tax_amount = Number(tax_amount.toFixed(2));

        const data: any = {
            invoice_issue_date: getInvoiceDate(),
            partial: {
                gross_amount,
                net_amount,
                discount_amount,
                tax_amount,
                line_items: lineItems
            }
        };
        if (invoiceNumberValue) data.invoice_number = invoiceNumberValue;

        return data;
    };

    const getInvoiceDate = () => {
        const date = new Date();
        return date.toISOString().slice(0, 10);
    };

    const handleFulfill = () => {
        const isInvoiceNumberRequired = $account?.flags.provides_own_invoice_numbers;
        // check if invoice number filled in
        if (isInvoiceNumberRequired && !invoiceNumberValue) {
            invoiceNumberError = $_("components.input.required", {
                values: { type: $_("order.edit.invoice_number") }
            });
            return;
        }
        invoiceNumberError = "";

        const baseUrl = getBaseUrl();
        isFulfillProcessing = true;

        const data = isPartial
            ? preparePartialFulfillmentData()
            : isInvoiceNumberRequired
              ? { invoice_number: invoiceNumberValue }
              : undefined;

        const url = `${baseUrl}${isPartial ? PARTIALLY_FULFILL_ORDER(orderID) : FULFILL_ORDER(orderID, lang)}`;

        API.post(url, data)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("fulfillOrder.fulfilledSuccess")
                );
                modalState.actions.setModal(null, { isFulfilled: true });
                setTimeout(() => window.location.reload(), 2000); // to refresh UI
            })
            .catch((err) => {
                notificationState.actions.create(
                    NotificationType.ERROR,
                    err?.response?.data?.error_message ||
                        (isPartial
                            ? $_("fulfillOrder.partialFulfilledFailed")
                            : $_("fulfillOrder.fulfilledFailed"))
                );
                modalState.actions.setModal(null, { isFulfilled: false });
            })
            .finally(() => (isFulfillProcessing = false));
    };

    const handleCancel = () => {
        modalState.actions.setModal(null, {});
    };
    $: if (invoiceNumberValue) {
        invoiceNumberError = "";
    }
</script>

<div class="container">
    <div class="flex title">
        <div class="ctn-general-primary">
            {$_("fulfillOrder.title", { values: { merchantOrderID } })}
        </div>
        <div class="close">
            <button on:click={handleCancel}>
                <Icon name="cross" size={26} />
            </button>
        </div>
    </div>
    <div class="body-text">
        {#if $account?.flags.provides_own_invoice_numbers}
            <p>{@html $_("fulfillOrder.addInvoiceNumber", { values: { buyerName } })}</p>
            <div class="w-full mt-4 mb-2">
                <TextInput
                    label={$_("order.edit.invoice_number")}
                    id="invoice-number"
                    type="text"
                    bind:value={invoiceNumberValue}
                    required={true}
                    placeholder="1234567890"
                    error={invoiceNumberError}
                />
            </div>
            <p class="text-sm text-gray-100">{$_("fulfillOrder.addInvoiceNumberDescription")}</p>
        {:else}
            <span>{$_("fulfillOrder.bodyText", { values: { buyerName } })}</span>
        {/if}
    </div>
    <div class="button-area">
        <div class="right-align button">
            <TwoUIButton
                on:click={handleCancel}
                size="md"
                variant="secondaryGray"
                content={$_("components.goBack")}
                fullSize={true}
            />
        </div>
        <div class="button">
            <TwoUIButton
                on:click={handleFulfill}
                size="md"
                variant="primary"
                content={$_("components.confirm")}
                fullSize={true}
                loading={isFulfillProcessing}
                disabled={isFulfillProcessing}
            />
        </div>
    </div>
</div>

<style lang="scss">
    .container {
        padding: 24px 32px 24px 32px;
        width: 544px;
    }

    .title {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        padding-bottom: 16px;
    }

    .close {
        margin-left: auto;
    }

    .body-text {
        color: var(--ctnGeneralSecondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 24px;
    }

    .button-area {
        display: flex;
        right: 32px;
        left: 32px;
        bottom: 24px;
    }

    .right-align {
        margin-left: auto;
        margin-right: 8px;
    }
</style>
