<script lang="ts">
    import { _ } from "svelte-i18n";

    import { account } from "../../../../store/merchant/account.store";
    import { slide } from "svelte/transition";
    import { tweened } from "svelte/motion";
    import { cubicOut } from "svelte/easing";
    import Icon from "../../../../components/icons/Icon.svelte";
    import Input from "../../../../components/Elements/InputBox.svelte";
    import Selector from "../../../../components/Elements/Selector.svelte";
    import type { CurrencyCode, IMerchantOrderInvalidLineItems } from "../../../../static/types";

    export let item: any;
    export let index: number;
    export let currency: CurrencyCode;
    export let lineItemsError: string;
    export let invalidLineItems: IMerchantOrderInvalidLineItems[];
    export let taxRateOptions: string[];
    export let line_items: any[];

    export let calculateTotals: any;
    export let removeItem: any;

    const codeTypes = ["GTIN", "EAN", "UPC"];
    const rotate = tweened(0, { duration: 200, easing: cubicOut });

    let enableMoreDetails: boolean = false;
    let displayMoreDetails: boolean = false;

    $: enableMoreDetails = $account?.flags?.show_line_item_more_details_on_order_creator;

    function handleChange(): void {
        lineItemsError = "";

        item.net_amount = item.unit_price * item.quantity - item.discount_amount;
        item.tax_amount = Number(
            (!item.tax_rate ? 0 : item.net_amount * (item.tax_rate / 100)).toFixed(2)
        );
        item.gross_amount = Number((item.net_amount + item.tax_amount).toFixed(2));

        calculateTotals();
    }

    function toggleMoreDetails(): void {
        setMoreDetailsVisibility(enableMoreDetails && !displayMoreDetails);
    }

    function setMoreDetailsVisibility(show: boolean): void {
        displayMoreDetails = show;
        $rotate = show ? 180 : 0;
    }
</script>

<div class="w-full mt-2 name-field first-column">
    <Input
        id={`item_name_${index}`}
        type="text"
        bind:value={item.name}
        placeholder={$_("lineItem.namePlaceholder")}
        on:change={function clearLineItemsError() {
            lineItemsError = "";
        }}
        invalid={invalidLineItems[index]?.itemName}
        showInvalidError={false}
    />
</div>
<div class="w-full mt-2 relative with-type">
    <Input
        type="number"
        bind:value={item.unit_price}
        id={`unit_price_${index}`}
        placeholder="1,000.00"
        on:change={handleChange}
        invalid={invalidLineItems[index]?.unitPrice}
        showInvalidError={false}
    />
    <span class="item-type">{currency}</span>
</div>
<div class="w-full mt-2 relative with-type">
    <Input
        type="number"
        bind:value={item.quantity}
        id={`quantity_${index}`}
        placeholder="10"
        on:change={handleChange}
        invalid={invalidLineItems[index]?.quantity}
        showInvalidError={false}
    />
    <span class="item-type">Pc</span>
</div>
<div class="w-full mt-2 relative with-type">
    <Input
        type="number"
        bind:value={item.discount_amount}
        id={`discount_amount_${index}`}
        placeholder="0"
        on:change={handleChange}
        invalid={invalidLineItems[index]?.discount}
        showInvalidError={false}
    />
    <span class="item-type">{currency}</span>
</div>
<div class="w-full mt-2 tax-rate-selector">
    <Selector
        required
        options={taxRateOptions}
        bind:value={item.tax_rate}
        id={`tax_rate_${index}`}
        showArrow={true}
        placeholder="-"
        on:change={handleChange}
        size="44px"
        backgroundColor="white"
        invalid={invalidLineItems[index]?.taxRate}
        showInvalidError={false}
    />
</div>
<div class="w-full mt-2 relative with-type">
    <Input
        type="text"
        bind:value={item.gross_amount}
        id={`gross_amount_${index}`}
        disabled={true}
        placeholder="1,000.00"
    />
    <span class="item-type">{currency}</span>
</div>
{#if enableMoreDetails}
    <div
        class="pt-3 m-auto cursor-pointer more-details-button"
        style="transform: rotate({$rotate}deg); transform-origin: bottom;"
        id={String(index)}
        on:click={toggleMoreDetails}
        on:keypress={toggleMoreDetails}
        title={$_("lineItem.showMoreDetailsTitle")}
    >
        <div>
            <Icon size={24} name="drop-arrow-down" fill="#222222" />
        </div>
    </div>
{/if}
<div
    class="pt-3 m-auto cursor-pointer remove-items"
    id={String(index)}
    on:click={removeItem}
    on:keypress={removeItem}
    class:disable_item={line_items.length <= 1}
    title={$_("lineItem.removeItemTitle")}
>
    <div>
        <Icon size={24} name="cross" fill="#222222" />
    </div>
</div>

{#if displayMoreDetails}
    <div class="w-full mt-2 relative with-type first-column more-details-caption" transition:slide>
        <label for="description">{$_("lineItem.descriptionLabel")}</label>
    </div>
    <div class="w-full mt-2 relative with-type line-item-description" transition:slide>
        <Input
            id="description"
            type="text"
            bind:value={item.description}
            placeholder="Enter item description"
        />
    </div>

    <div class="w-full mt-2 relative with-type more-details-caption" transition:slide>
        <label for="code-selector">{$_("lineItem.codeLabel")}</label>
    </div>
    <div class="w-full mt-4 relative with-type" transition:slide>
        <Selector
            id="code-selector"
            options={codeTypes}
            bind:value={item.barcodeType}
            on:change={handleChange}
            placeholder={$_("lineItem.selectType")}
            size={"44px"}
            backgroundColor="white"
            invalid={invalidLineItems[index]?.barcode}
            showInvalidError={false}
        />
    </div>
    <div class="w-full mt-2 relative with-type" transition:slide>
        <Input
            type="text"
            bind:value={item.barcodeValue}
            on:change={handleChange}
            placeholder={$_("lineItem.codePlaceholder")}
            invalid={invalidLineItems[index]?.barcode}
            showInvalidError={false}
        />
    </div>
{/if}

<style>
    .more-details-button div {
        pointer-events: none;
    }

    .first-column {
        grid-column: 1;
    }

    .more-details-caption {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .line-item-description {
        grid-column: 2 / span 2;
    }

    .remove-items div {
        pointer-events: none;
    }

    .disable_item {
        pointer-events: none;
        opacity: 0.25;
    }

    .tax-rate-selector :global(.dropdown-item::after),
    .tax-rate-selector :global(.selector-value::after) {
        content: "%";
    }

    .more-details-caption label {
        margin-top: 3px;
    }
</style>
