<script lang="ts">
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import Icon from "../icons/Icon.svelte";
    import { clickOutside } from "../../utils/clickOutside";

    export let options: Map<string | number, string>;
    export let value: string = "";
    export let labelLines: string[] = [];
    export let placeholder: string = "";
    export let disable: boolean = false;
    export let selectedKey: string | number;

    export let visibleText: string = "";
    let isFocus: boolean = false;
    let dropDown: HTMLElement;
    let isOpen: boolean = false;
    let selectWrapper: HTMLDivElement;
    let dropDownList: HTMLElement;

    $: if (dropDownList) dropUp();

    function dropUp() {
        if (dropDownList.getBoundingClientRect().bottom > window.innerHeight) {
            dropDownList.classList.add("drop-up");
        }
    }

    function topLabel(): any {
        isOpen = !isOpen;
        isFocus = true;
    }

    function closeDown(event): any {
        if (!selectWrapper.contains(event.target) && !dropDown.contains(event.target)) {
            isOpen = false;
        }
        if (!isOpen) isFocus = false;
    }

    const dispatch = createEventDispatcher();

    function selectOption(key: string | number, text: string) {
        isOpen = false;
        visibleText = text;
        dispatch("change", key);
        selectedKey = key;
    }

    function onCancel() {
        dispatch("close");
    }

    onMount(() => {
        window.addEventListener("click", closeDown);
        visibleText = options.get(value);
    });

    onDestroy(() => {
        window.removeEventListener("click", closeDown);
    });
</script>

<div
    class="selector-container relative h-12 self-center items-center flex w-full"
    use:clickOutside
    on:click_outside={onCancel}
>
    {#if labelLines.length > 0}
        <div class="w-1/2">
            <div class="selector-label">
                {#each labelLines as label}
                    <label class="text-gray-700 text-sm" for="user-selector">
                        {@html label}
                    </label>
                {/each}
            </div>
        </div>
    {/if}
    <div class="text-sm relative {labelLines.length > 0 ? 'w-1/2' : 'w-full'} overflow">
        <button
            class="selector {disable ? 'disabled' : ''} h-12 flex items-center justify-between"
            on:click={topLabel}
            bind:this={dropDown}
            class:border_focus={isFocus}
        >
            <p class="text-ls pl-4" class:text-gray-100={visibleText === ""}>
                {@html visibleText || placeholder}
            </p>
            <div class="mr-4">
                {#if !disable}
                    <div class={`${isOpen && "rotate"} dropdown_icon`}>
                        <Icon name="drop-arrow-down" fill={"#81809A"} />
                    </div>
                {/if}
            </div>
        </button>
        <div class="relative w-full" bind:this={selectWrapper}>
            {#if isOpen}
                <div class="dropdown-list items" bind:this={dropDownList}>
                    {#each [...options] as [key, text], i}
                        <button
                            class="dropdown-item item flex items-center pl-4"
                            class:border-none={options.size === i + 1}
                            on:click={() => selectOption(key, text)}
                        >
                            {@html text}
                        </button>
                    {/each}
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    .selector {
        background-color: white;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        border: 1px solid var(--gray-300);
        gap: 0.5rem;
    }
    .focus {
        color: var(--ctnGeneralPrimary);
    }
    .items {
        position: absolute;
        min-width: 100%;
        background-color: white;
        z-index: 20;
        border: 1px solid var(--gray-300);
        border-radius: 8px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 200px;
        box-shadow: 0px 5px 30px rgba(64, 58, 85, 0.1);
    }
    .item {
        height: 48px;
        border-bottom: 1px solid #ebeaef;
        width: 100%;
    }
    .border_focus {
        border: 2px solid var(--borderSemanticHighlightSecondary);
        background: white;
    }
    .rotate {
        transform: rotate(180deg);
    }
    .dropdown_icon {
        transition: 0.3s;
    }
    .border-none {
        border-bottom: none !important;
    }
    .selector-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .overflow {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .drop-up {
        bottom: 48px;
    }
    .disabled {
        pointer-events: none;
        cursor: not-allowed;
    }
</style>
