<script lang="ts">
    import StatementsTable from "./StatementsTable.svelte";
    import { type IStatementObject, buildStatementsRows } from "./statementUtils";
    import { billingStatements } from "../../../../../store/merchant/billingStatement.store";
    import PageHeader from "../../../../../components/pageHeader/PageHeader.svelte";
    import { showAccountAlert, account } from "../../../../../store/merchant/account.store";

    let rows: IStatementObject[] = [];

    $: rows = buildStatementsRows($billingStatements);
</script>

<div class="page-container">
    <PageHeader
        title="Statements"
        showAccountAlert={$showAccountAlert}
        newUser={!$account?.flags?.has_made_order}
    />
    <div class="content-container">
        <div class="main-container">
            <StatementsTable {rows} />
        </div>
    </div>
</div>

<style>
    .main-container {
        background: #ffffff;
        border: 1px solid #f2f4f7;
        box-shadow: 0px 2px 6px rgba(208, 213, 221, 0.25);
        border-radius: 8px;
        margin: 1.5rem 2rem 1.5rem 2rem;
        display: flex;
        overflow: auto;
        flex-direction: column;
        height: 80vh;
    }
</style>
