<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";

    import CompanySelector from "../../../components/companySelector/CompanySelectorDark.svelte";
    import Button from "../../../components/Elements/Button.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import { GB } from "../../../static/constant";

    let loading = false;
    let step: number = 1;
    let company: string = "";
    let companyID: string = "";
    let address: any = {};

    let country: any = {
        flag: "/pictures/flag/uk.svg",
        name: "UK",
        country_code: GB
    };
    let error: any = {
        company: "",
        address: "",
        companyID: "",
        code: ""
    };

    const handleSubmit = () => {
        if (
            <HTMLInputElement>document.getElementById("code1") &&
            (<HTMLInputElement>document.getElementById("code1")).value === "6"
        ) {
            return (error.code = $_("b2bSignup.invalidCode"));
        }
        if (!company) return (error.company = $_("b2bSignup.inputCompanyName"));

        if (!address.streetAddress) return (error.address = $_("b2bSignup.inputAddress"));

        if (!companyID) return (error.companyID = $_("b2bSignup.inputCompanyID"));

        if (step == 1) {
            step = 2;
            return;
        }

        window.location.href = "https://demo.onboarding.two.inc/account.html";
    };

    onMount(() => {
        document.getElementById("company").focus();
    });

    function onSubmit(e) {
        e.preventDefault();
    }
</script>

<div class="flex justify-center flex-wrap container m-auto">
    {#if step == 1}
        <form on:submit={onSubmit} class="px-4 card">
            <p class="text-black text-2xl text-center mb-4 m-auto font-bold">
                {$_("b2bSignup.companyDetailsTitle")}
            </p>
            <p class="text-user-gray text-center">
                {$_("b2bSignup.companyDetailsDescription")}
            </p>

            <CompanySelector
                bind:address
                {country}
                bind:company
                bind:companyID
                required={false}
                invalid={error.company}
            />

            {#if company && address.streetAddress}
                <div class="flex justify-between text-sm mt-4">
                    <div class="mt-5">
                        <div class="font-bold">{company}</div>
                        <div>{companyID}</div>
                    </div>
                    <div class="mt-5 text-right">
                        <div class="font-bold">{$_("b2bSignup.registeredAddress")}:</div>
                        <div>{address.streetAddress ? address.streetAddress : ""}</div>
                        <div>{address.city ? address.city : ""}</div>
                        <div>{address.postalCode ? address.postalCode : ""}</div>
                    </div>
                </div>
            {/if}
        </form>
    {/if}

    {#if step == 2}
        <div class="p-4 card">
            <p class="text-black text-xl text-center font-bold mb-4">
                {$_("b2bSignup.verifyAccountTitle")}
            </p>
            <p class="text-user-gray text-center">
                {$_("b2bSignup.verifyAccountDescription")}
            </p>

            <div class="mt-5 flex justify-center gap-2">
                <input id="code1" class="code-input" type="text" maxlength="1" />
                <input id="code2" class="code-input" type="text" maxlength="1" />
                <input id="code3" class="code-input" type="text" maxlength="1" />
                <input id="code4" class="code-input" type="text" maxlength="1" />
            </div>
        </div>
    {/if}

    {#if error.code}
        <div class="error-text">{error.code}</div>
    {/if}

    <div class="mt-10 px-4 flex w-full flex-wrap justify-evenly">
        <div class="w-full">
            <Button
                size="large"
                label={$_("components.continue")}
                bgColor="black"
                hoverBg="#095ac2"
                on:click={handleSubmit}
                {loading}
            />
        </div>
    </div>

    <style>
        #hubspot-messages-iframe-container {
            display: none !important;
        }
        #hs-eu-cookie-confirmation {
            display: none !important;
        }
    </style>
</div>

{#if loading}
    <div class="flex flex-col justify-center flex-wrap items-center absolute mainContainer">
        <p class="font-normal text-white mb-6">{$_("onboarding.connecting")}</p>
        <Icon name="arrow-right-circle" size={300} fill={"#fff"} />
    </div>
{/if}

<style>
    * :global(#company) {
        font-size: 1rem;
        line-height: 1rem;
        height: 3rem;
        background-color: white;
        box-shadow:
            0px 1px 2px rgb(16 24 40 / 5%),
            0px 0px 0px 4px #eef4ff;
    }

    * :global(.cursor-pointer) {
        top: 2rem;
        right: 1rem;
    }

    * :global(.dropdown-item) {
        background: white;
    }

    * :global(.reset-selection svg) {
        bottom: 8px !important;
    }

    .mainContainer {
        background: #140e09;
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .container {
        width: 550px;
        padding: 75px 50px;
        margin-top: 45px;
        border-radius: 16px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .code-input {
        width: 50px;
        line-height: 50px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid lightgrey;
        font-size: xx-large;
        text-align: center;
    }

    .error-text {
        color: red;
    }
</style>
