<script lang="ts">
    export let id;
    // group is the selected option
    // group needs to be bound in each instance (deliberately done to allow
    // layout changes per instance)
    export let group;
    export let option;

    // select handler necessary as we are hiding the input component
    // TODO improve a11y
    function handleSelect(option) {
        if (group == option) return;
        group = option;
    }
</script>

<div class="radio-container">
    <input {id} type="radio" value={option} bind:group />
    <div class="radio" class:selected={group == option} on:click={() => handleSelect(option)} on:keyup />
    <label for={id}>
        {option.label}
    </label>
</div>

<style>
    :root {
        --primary: var(--primary-600);
        --secondary: #eef4ff;
        --size: 16px;
    }

    input {
        display: none;
    }

    label {
        margin-left: 1.4rem;
    }

    .radio-container {
        display: flex;
        align-items: center;
    }

    .radio {
        cursor: pointer;
        height: var(--size);
        width: var(--size);
        border-radius: 50%;
        border: 1px solid var(--primary);
        position: absolute;
    }

    .radio:after {
        content: "";
        height: calc(50%);
        width: calc(50%);
        background: var(--primary);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: 300ms ease-in-out 0s;
    }

    .selected:after {
        transform: translate(-50%, -50%) scale(1);
    }

    .selected {
        background: var(--primary-50);
    }
</style>
