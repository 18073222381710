<script lang="ts">
    import { _ } from "svelte-i18n";

    import Switch from "../../../../components/Elements/Switch.svelte";
    import { TextInput } from "@two-ui/core";
    import { orderReplica } from "@/store/merchant/allOrders.store";

    export let billingAddress;
    export let shippingAddress;
    export let invalid;
    export let showShippingAddressFields: boolean;

    // check if shipping address is different and apply it
    if ($orderReplica) {
        const {
            billingAddress,
            shippingAddress: shippingAddressAsArray,
            originalShippingAddress
        } = $orderReplica;
        if (billingAddress && shippingAddressAsArray && originalShippingAddress) {
            const addressesAreEqual = billingAddress.every(
                (value, index) => value === shippingAddressAsArray[index]
            );
            if (!addressesAreEqual) {
                showShippingAddressFields = true;
                shippingAddress.streetAddress = originalShippingAddress.street_address;
                shippingAddress.postalCode = originalShippingAddress.postal_code;
                shippingAddress.city = originalShippingAddress.city;
            }
        }
    }
</script>

<p>{$_("addresses.billingDetails")}</p>
<div class="w-full mt-5">
    <TextInput
        label={$_("addresses.streetAddress")}
        id="billing_address_street_address"
        size="md"
        placeholder={$_("addresses.streetAddressPlaceholder")}
        bind:value={billingAddress.streetAddress}
        bind:error={invalid.billingAddressStreetName}
        required={true}
    />
</div>
<div class="flex flex-container gap-4">
    <div class="w-full mt-5">
        <TextInput
            label={$_("addresses.postcode")}
            id="billing_address_postcode"
            size="md"
            placeholder=""
            bind:value={billingAddress.postalCode}
            bind:error={invalid.billingAddressPostcode}
            required={true}
        />
    </div>
    <div class="w-full mt-5">
        <TextInput
            label={$_("addresses.city")}
            id="billing_address_city"
            size="md"
            placeholder=""
            bind:value={billingAddress.city}
            bind:error={invalid.billingAddressCity}
            required={true}
        />
    </div>
</div>
<div class="mt-8 flex items-center">
    <Switch bind:checked={showShippingAddressFields} />
    <span class="ml-2">{$_("addresses.shipToDifferentAddress")}</span>
</div>

{#if showShippingAddressFields}
    <p class="mt-10">{$_("addresses.shippingDetails")}</p>
    <div class="w-full mt-5">
        <TextInput
            label={$_("addresses.streetAddress")}
            size="md"
            placeholder={$_("addresses.streetAddressPlaceholder")}
            bind:value={shippingAddress.streetAddress}
            bind:error={invalid.shippingAddressStreetName}
            required={true}
        />
    </div>
    <div class="flex flex-container gap-4">
        <div class="w-full mt-5">
            <TextInput
                label={$_("addresses.postcode")}
                size="md"
                placeholder=""
                bind:value={shippingAddress.postalCode}
                bind:error={invalid.shippingAddressPostcode}
                required={true}
            />
        </div>
        <div class="w-full mt-5">
            <TextInput
                label={$_("addresses.city")}
                size="md"
                placeholder=""
                bind:value={shippingAddress.city}
                bind:error={invalid.shippingAddressCity}
                required={true}
            />
        </div>
    </div>
{/if}
