<script lang="ts">
    import { _ } from "svelte-i18n";

    import authState from "../../store/auth.store";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";
    import environment from "@/services/environment";
    import { Button as TwoUIButton } from "@two-ui/core";

    export let closeModal;
    export let props: any = {};
</script>

<div class="min-w-500 flex flex-col py-10 px-10 pb-5">
    <p class="text-black-dark font-semibold text-xl">
        {$_("modals.logoutTitle")}
    </p>
    <p class="mt-4">
        {$_("modals.logoutMessage", { values: { brandName: environment.branding.displayName } })}
    </p>
    <div class="w-full mt-8 text-right">
        <div class="inline-block w-44 my-2 mr-2">
            <TwoUIButton
                on:click={() => {
                    modalState.actions.setModal(null, {});
                    authState.action.logout(props.type.toLowerCase());
                }}
                size="md"
                variant="secondaryGray"
                content={$_("modals.logoutButton")}
                fullSize={true}
            />
        </div>
        <div class="inline-block w-44 my-2">
            <TwoUIButton
                on:click={closeModal}
                size="md"
                variant="primary"
                content={$_("components.cancel")}
                fullSize={true}
            />
        </div>
    </div>
</div>
