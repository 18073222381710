<script lang="ts">
    import Fa from "svelte-fa";
    import { _ } from "svelte-i18n";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { createEventDispatcher } from "svelte";
    import { navigate } from "svelte-routing";

    import type { CurrencyCode, ICustomer } from "../../../static/types";
    import {
        customersLoading,
        singleCustomer,
        cursorPage,
        showCustomerSettings as canShowCustomerSettings,
        actions as customerActions,
        customers as customerStateCustomers
    } from "../../../store/merchant/customers.store";
    import modalState from "../../../store/modals.store";
    import {
        account as merchantAccount,
        recourseEnabled
    } from "../../../store/merchant/account.store";
    import TableCell from "../../../components/table/TableCell.svelte";
    import Switch from "../../../components/Elements/Switch.svelte";
    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import Icon from "../../../components/icons/Icon.svelte";
    import CombinedCustomersSetting from "./CombinedCustomersSetting.svelte";
    import utils, { type CurrencyFormatOptions } from "../../../utilsV2/currency";
    import { titleCase } from "../../../utilsV2/helpers";
    import { getCurrency } from "../../../utils/functions";
    import CursorPagination from "./CombinedCustomersPagination.svelte";
    import environment from "@/services/environment";

    const dispatch = createEventDispatcher();

    export let selectedTab: string = "All";
    export let currentSort = "";
    export let recourseRelatedData: any;

    export let numAllRecords = 0;
    export let pageNum = 1;

    let isValidateCustomerRecourseLimit: boolean = false;

    let sortDown = true;

    let tableHeaders = [];
    let tableRows = [];

    let prevPage = "";
    let nextPage = "";

    let customers: ICustomer[] = [];
    let initCustomersData: ICustomer[] = [];
    let selectedCustomer: ICustomer;
    let autoScrollSettings: boolean = false;
    // TODO: implement column in merchant table that specifies what type of merchant it is, so we don't have to hardcode.

    const TAB_ITEMS = {
        "All": {
            tableHeaders: [
                {
                    title: "Customer",
                    translation: $_("customers.combined.customer"),
                    clickable: false,
                    type: "text"
                },
                {
                    title: "Total spend",
                    translation: $_("customers.combined.totalSpend"),
                    clickable: false,
                    type: "number",
                    tooltip: $_("customers.combined.totalSpendTooltip")
                },
                ...(environment.branding.hideCreditLimit
                    ? []
                    : [
                          {
                              title: `${environment.branding.displayName}'s limit`,
                              translation: $_("customers.combined.twoLimit", {
                                  values: { brandName: environment.branding.displayName }
                              }),
                              clickable: false,
                              type: "number"
                          },
                          {
                              title: "Remaining credit limit",
                              translation: $_("customers.combined.remainingCreditLimit"),
                              clickable: false,
                              type: "number"
                          }
                      ]),
                ...(environment.branding.hideOnRecourse
                    ? []
                    : [
                          {
                              title: "On recourse",
                              translation: $_("customers.combined.onRecourse"),
                              clickable: false,
                              type: "switch",
                              tooltip: $_("customers.combined.onRecourseTooltip")
                          }
                      ])
            ]
        },
        "On recourse": {
            tableHeaders: [
                {
                    title: "Customer",
                    translation: $_("customers.combined.customer"),
                    clickable: false,
                    type: "text"
                },
                {
                    title: "Total spend",
                    translation: $_("customers.combined.totalSpend"),
                    clickable: false,
                    type: "number"
                },
                {
                    title: `${environment.branding.displayName}'s limit`,
                    translation: $_("customers.combined.twoLimit", {
                        values: { brandName: environment.branding.displayName }
                    }),
                    clickable: false,
                    type: "number"
                },
                {
                    title: "Remaining credit limit",
                    translation: $_("customers.combined.remainingCreditLimit"),
                    clickable: false,
                    type: "number"
                },
                {
                    title: "Recourse limit",
                    translation: $_("customers.combined.recourseLimit"),
                    clickable: false,
                    type: "number"
                },
                {
                    title: "Current recourse exposure",
                    translation: $_("customers.combined.currentRecourseExposure"),
                    clickable: false,
                    type: "number",
                    tooltip: $_("customers.combined.currentRecourseExposureTooltip")
                }
            ]
        }
    };

    $: if ($cursorPage) {
        prevPage = $cursorPage?.previous_page_cursor;
        nextPage = $cursorPage?.next_page_cursor;
    }

    $: if ($merchantAccount && Object.keys($merchantAccount).length) {
        // only if the account state is loaded
        const value = $merchantAccount;
        isValidateCustomerRecourseLimit = value.flags.enable_fe_validate_customer_recourse_limit;
    }

    $: merchantRecourseFallbackEnabled = $recourseEnabled;

    $: initCustomersData = $customerStateCustomers;

    $: if ($canShowCustomerSettings && merchantRecourseFallbackEnabled) {
        // merchant recourse fallback enabled only
        customerActions.getCustomerRecourseLimit(
            selectedCustomer?.buyer_organization_number,
            selectedCustomer?.buyer_country_code
        );

        // feature flag enable_fe_validate_customer_recourse_limit check
        if (isValidateCustomerRecourseLimit) {
            customerActions.getCustomerMaxAllowedRecourseLimit(
                selectedCustomer?.buyer_organization_number,
                selectedCustomer?.buyer_country_code
            );
        }
    }

    const formatMoney = (
        money: string | number,
        currency?: CurrencyCode,
        options?: CurrencyFormatOptions
    ) => utils.formatCurrency(currency)(money, options);

    function pageChanged(pageReference: string | null = null) {
        dispatch("pagechanged", {
            pageNum,
            pageReference
        });
    }

    function buildCustomersRows() {
        const tableRows = [];
        for (const customer of customers) {
            const currency: CurrencyCode = getCurrency(customer.buyer_country_code);
            const rowItem = {
                "Customer": {
                    tableItemBold: titleCase([customer.buyer_company_name])[0],
                    subItem: customer.buyer_organization_number
                },
                "Total spend": {
                    tableItem: formatMoney(customer.total_gross_amount, currency)
                },
                "Two's limit": {
                    tableItem: formatMoney(customer.credit_limit, currency)
                },
                "Remaining credit limit": {
                    tableItem: formatMoney(customer.credit_limit_balance, currency)
                },
                "On recourse": customer.on_recourse,
                "Recourse limit": {
                    tableItem: formatMoney(customer.recourse_max_amount, currency)
                },
                "Current recourse exposure": {
                    tableItem: formatMoney(customer.current_recourse_exposure, currency)
                },
                "onRowClick": () => {
                    singleCustomer.set(customer);
                    navigate(
                        `/merchant/customers/${customer.buyer_organization_number}**${customer.buyer_country_code}`
                    );
                }
            };
            tableRows.push(rowItem);
        }
        return tableRows;
    }

    $: {
        customers = initCustomersData;

        tableHeaders = TAB_ITEMS[selectedTab].tableHeaders;

        tableRows = buildCustomersRows();
        numAllRecords = tableRows.length;
    }

    function showCustomerSettings(customer: ICustomer, isScroll: boolean) {
        selectedCustomer = customer;
        autoScrollSettings = isScroll; // sets if we should autoscroll settings to recourse amount input field
        customerActions.toggleSettings(true);
    }

    function sortData(dataColumn) {
        dispatch("sorting", {
            sortItem: dataColumn
        });
        if (currentSort !== dataColumn) {
            currentSort = dataColumn;
            sortDown = true;
        } else {
            sortDown = !sortDown;
        }
    }
</script>

<div data-testid="customer-table" class="customer-table">
    <table class="table text-sm w-full table-auto text-left">
        <thead class="table-head">
            {#if tableHeaders && tableHeaders.length}
                {#each tableHeaders as tableHeader}
                    {#if (tableHeader.title == "On recourse" && merchantRecourseFallbackEnabled) || tableHeader.title !== "On recourse"}
                        <th
                            class="m-auto"
                            class:clickable={tableHeader.clickable}
                            class:date={tableHeader.type === "date"}
                            class:number={tableHeader.type === "number"}
                            class:status={tableHeader.type === "status"}
                            class:switch={tableHeader.type === "switch"}
                            on:click={() => sortData(tableHeader.title)}
                        >
                            <div class="flex my-auto items-center">
                                {#if !tableHeader.notext}<div>
                                        {tableHeader.translation || tableHeader.title}
                                    </div>{/if}
                                {#if tableHeader.clickable}
                                    <div
                                        class="my-auto px-2"
                                        class:icon-rotate={currentSort === tableHeader.title &&
                                            sortDown}
                                    >
                                        <Icon
                                            name="arrow-up"
                                            size={20}
                                            fill={currentSort === tableHeader.title
                                                ? "var(--ctnInteractiveSemanticHighlightDefault)"
                                                : "grey"}
                                        />
                                    </div>
                                {:else if tableHeader.tooltip}
                                    <div class="tooltip-container" style="margin-top: 2px">
                                        <ToolTip
                                            width="250px"
                                            position="left-down"
                                            content={tableHeader.tooltip}
                                        >
                                            <Fa
                                                size="md"
                                                icon={faCircleQuestion}
                                                color="var(--primary-500)"
                                            />
                                        </ToolTip>
                                    </div>
                                {/if}
                            </div>
                        </th>
                    {/if}
                {/each}
            {/if}
            <th />
        </thead>
        {#if $customersLoading}
            <div class="relative w-full h-28 table-row">
                <LoadingWrapper background={"white"} loading={$customersLoading} />
            </div>
        {/if}
        <tbody class:hidden={$customersLoading}>
            {#each tableRows as tableRow, key}
                <tr on:click={() => tableRow.onRowClick()}>
                    {#each tableHeaders as tableHeader}
                        {#if (tableHeader.title == "On recourse" && merchantRecourseFallbackEnabled) || tableHeader.title !== "On recourse"}
                            <td>
                                {#if tableHeader.type == "switch"}
                                    <span on:click|stopPropagation on:keyup>
                                        <Switch
                                            bind:checked={tableRow[tableHeader.title]}
                                            on:change={() => {
                                                if (tableRow[tableHeader.title]) {
                                                    const data = {
                                                        buyer_organization_number:
                                                            customers[key]
                                                                ?.buyer_organization_number,
                                                        buyer_country_code:
                                                            customers[key]?.buyer_country_code,
                                                        max_amount: "0",
                                                        currency: getCurrency(
                                                            customers[key]?.buyer_country_code
                                                        )
                                                    };

                                                    modalState.actions.setModal(
                                                        "CONFIRM_ENABLE_CUSTOMER_RECOURSE_FALLBACK",
                                                        {
                                                            data,
                                                            customer_name:
                                                                customers[key]?.buyer_company_name,
                                                            is_disable: true
                                                        }
                                                    );
                                                } else {
                                                    showCustomerSettings(customers[key], true);
                                                }
                                            }}
                                        />
                                    </span>
                                {:else}
                                    <TableCell
                                        cellItem={tableRow[tableHeader.title]}
                                        type={tableHeader.type}
                                    />
                                {/if}
                            </td>
                        {/if}
                    {/each}
                    {#if !environment.branding.hideCustomerConfig}
                        <td>
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                class="cog"
                                on:click|stopPropagation={() =>
                                    showCustomerSettings(customers[key], false)}
                            >
                                <Icon name="gear" size={20} fill="#22272F" />
                            </div>
                        </td>
                    {/if}
                </tr>
            {/each}

            {#if customers.length === 0 && !$customersLoading}
                <tr>
                    <td colspan="10">
                        <div class="w-full flex flex-col items-center pt-14">
                            <img src="/pictures/searchstate.svg" alt="mailbox" />
                            <p class="my-4 font-semibold text-black-dark text-center">
                                {$_("customers.combined.noCustomersMessage")}
                            </p>
                            <p class="text-center mb-5">
                                {$_("customers.combined.noCustomersDescription", {
                                    values: { brandName: environment.branding.displayName }
                                })}
                            </p>
                        </div>
                    </td>
                </tr>
            {/if}
        </tbody>
    </table>

    {#if customers.length > 0 && !$customersLoading}
        <CursorPagination
            loading={$customersLoading}
            setPage={pageChanged}
            rowCount={tableRows.length}
            {nextPage}
            {prevPage}
            bind:pageNum
        />
    {/if}

    {#if $canShowCustomerSettings}
        <CombinedCustomersSetting {selectedCustomer} {autoScrollSettings} {recourseRelatedData} />
    {/if}
</div>

<style>
    .cog {
        display: inline-block;
        margin-right: 10px;
    }
    .customer-table {
        background-color: rgb(243, 244, 247);
        overflow: auto;
    }
    .customer-table .table {
        border-spacing: 0 2px;
    }

    td:first-child,
    th:first-child {
        padding-left: 2rem;
    }

    th {
        /* position: sticky; */
        top: 0;
        background-color: var(--bgGeneralQuarterary);
        height: 3rem;
        cursor: default;
        z-index: 1;
    }

    .clickable {
        cursor: pointer;
    }

    tr {
        background: white;
        height: 4.5rem;
        cursor: var(--cursor, pointer);
    }

    tr:not(.loading-tr):hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
    }

    .date {
        width: 13rem;
    }

    .number {
        width: 12rem;
    }

    .status {
        width: 12rem;
        z-index: 15;
    }

    .tooltip-container {
        margin: auto 0.5rem;
    }

    @media only screen and (max-width: 1300px) {
        .date {
            width: 10rem;
        }

        .number {
            width: 8rem;
        }

        .status {
            width: 8rem;
        }
    }

    table {
        overflow: auto;
    }
</style>
