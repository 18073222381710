import { writable } from "svelte/store";
import { type ICreditInsights, type ICreditAlert, NotificationType } from "../../static/types";
import notificationState from "../notifications.store";

import { GET_CREDIT_INSIGHTS, GET_CREDIT_ALERT_DATA, SEND_CREDIT_ALERT } from "../../static/endPoints";
import API from "../../services/apiService";

function createInsight() {
    const insight = writable<ICreditInsights[]>([]);
    const alertDetail = writable<ICreditAlert>(null);
    const insightsLoading = writable(false);
    const alertLoading = writable(false);
    const showInsightsSettings = writable(false);
    const showSendAlertModal = writable(false);

    const setInsightsLoading = (value: boolean): void => {
        insightsLoading.set(value);
    };

    const setAlertLoading = (value: boolean): void => {
        alertLoading.set(value);
    };

    return {
        insight,
        alertDetail,
        insightsLoading,
        alertLoading,
        showInsightsSettings,
        showSendAlertModal,
        actions: {
            getInsights: (searchFilterQuery: string = null, filterQueryString:string = null): void => {
                setInsightsLoading(true);
                const searchParams = new URLSearchParams();
                if (searchFilterQuery) {
                    searchParams.append("search_input", searchFilterQuery);
                  }
                API.get(GET_CREDIT_INSIGHTS + "?" + searchParams.toString() + filterQueryString)
                    .then((data: { customer_credit_insights: ICreditInsights[] }) => {
                        insight.set(data?.customer_credit_insights);
                        setInsightsLoading(false);
                    })
                    .catch(() => {
                        setInsightsLoading(false);
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Credit insights get failed."
                        );
                    });
            },
            getAlertDetails: (companyId: string): void => {
                setAlertLoading(true);

                API.get(GET_CREDIT_ALERT_DATA(companyId))
                    .then((data) => {
                        alertDetail.set(data as ICreditAlert);
                        setAlertLoading(false);
                    })
                    .catch(() => {
                        setAlertLoading(false);
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Alert details get failed."
                        );
                    });
            },
            sendAlert: (companyId: string): void => {
                API.post(SEND_CREDIT_ALERT(companyId))
                    .then(() => {
                        notificationState.actions.create(
                            NotificationType.SUCCESS,
                            "Alert sent"
                        );
                    })
                    .catch(() => {
                        notificationState.actions.create(
                            NotificationType.ERROR,
                            "Alert failed to send",
                            `We weren't able to send an alert to ${companyId}.`
                        );
                    });
            },
            toggleSettings(value: boolean) {
                showInsightsSettings.set(value);
            },
            displaySendAlertModal(value: boolean) {
                showSendAlertModal.set(value);
            }
        },
    };
}

export const {
    insight,
    alertDetail,
    insightsLoading,
    alertLoading,
    actions,
    showInsightsSettings,
    showSendAlertModal
} = createInsight();

