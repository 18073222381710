<script lang="ts">
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import { Button as TwoUIButton } from "@two-ui/core";

    const dispatch = createEventDispatcher();
    function handleApply() {
        dispatch("apply");
    }
    function handleCancel() {
        dispatch("cancel");
    }
</script>

<div class="flex items-center w-full p-2 pt-3">
    <div class="w-full mr-1">
        <TwoUIButton
            on:click={handleCancel}
            size="sm"
            variant="secondaryGray"
            content={$_("components.cancel")}
            fullSize={true}
        />
    </div>
    <div class="w-full ml-1">
        <TwoUIButton
            on:click={handleApply}
            size="sm"
            variant="primary"
            content={$_("components.apply")}
            fullSize={true}
        />
    </div>
</div>
