<script lang="ts">
    import { _ } from "svelte-i18n";
    import { Button as TwoUIButton } from "@two-ui/core";

    import {
        NotificationType,
        type IInvoiceStatementRequest,
        type ISelectorInputField
    } from "../../static/types";
    import DatePicker from "../Elements/DatePicker.svelte";
    import ComplexSelector from "../account/Selector.svelte";
    import Selector from "../Elements/Selector.svelte";
    import Icon from "../icons/Icon.svelte";
    import notificationState from "../../store/notifications.store";
    import { downloadBlob } from "../../utils/blobHelpers";
    import environment from "@/services/environment";

    // making this flexible for use in merchant portal
    export let closeModal: any;
    export let props: any;
    // if in buyer portal, the user may have filtered which company they want to see invoices/statements for
    const additionalFilters: object = props.additionalFilters;
    const isBuyerPortal = props.isBuyerPortal;
    const headerSubText = props.headerSubText || $_("statements.headerSubText");
    const showAllText =
        props.showAllText ||
        $_("statements.showAllText", { values: { brand: environment.branding.displayName } });
    const exportUrl = props.exportUrl;
    const companyDropDownChoices: Map<string | number, string> = props.dropDownChoices || new Map();
    const placeholder = isBuyerPortal
        ? $_("statements.allMerchants")
        : $_("statements.allCustomers");
    const selectorInput: ISelectorInputField = {
        options: companyDropDownChoices.set(placeholder, placeholder),
        placeholder
    };
    let dropDownCompanyChoice = selectorInput.placeholder;
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let startDate = firstDayOfMonth;
    let endDate = today;
    let fileType = "xlsx";
    let enableDatePicking = true;
    let errorMsg = "";
    let loading = false;
    function handleStartDateChange(d: CustomEvent) {
        startDate = d.detail;
        validateDates();
    }
    function handleEndDateChange(d: CustomEvent) {
        endDate = d.detail;
        validateDates();
    }

    async function downloadStatement() {
        loading = true;
        let request_body: IInvoiceStatementRequest = {
            file_type: fileType,
            start_date: enableDatePicking ? startDate : null,
            end_date: enableDatePicking ? endDate : null
        };
        if (isBuyerPortal && dropDownCompanyChoice && !dropDownCompanyChoice.startsWith("All")) {
            request_body.merchant_id = dropDownCompanyChoice;
        }
        if (additionalFilters) {
            request_body = { ...request_body, ...additionalFilters };
        }

        const response = await fetch(exportUrl, <RequestInit>{
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(request_body)
        });
        if (response.status !== 200) {
            notificationState.actions.create(
                NotificationType.WARNING,
                $_("errors.somethingWrong"),
                null,
                $_("errors.requestFailedRetry"),
                "INVOICE_STATEMENT_MODAL",
                props
            );
            closeModal();
            return;
        }
        await downloadBlob(response, fileType, $_("statements.statementOfAccountInvoices"));

        notificationState.actions.create(
            NotificationType.SUCCESS,
            $_("statements.downloadProcessed"),
            null,
            $_("statements.checkDevice")
        );
        closeModal();
    }

    function validateDates() {
        if (startDate > endDate) {
            errorMsg = $_("statements.errorMessageStartDate");
        } else if (startDate > today || endDate > today) {
            errorMsg = $_("statements.errorMessageFutureDate");
        } else {
            errorMsg = "";
        }
    }
</script>

<div class="container">
    <div class="header">
        <div style="display: flex;justify-content: space-between;">
            <h1 class="header-text">{$_("statements.header")}</h1>
            <div class="close">
                <button on:click={closeModal}>
                    <Icon name="cross" fill="#2F384C" size={18} />
                </button>
            </div>
        </div>
        <p class="header-sub-text">{headerSubText}</p>
    </div>
    <div class="date-input-wrapper">
        <div class="date-inputs">
            <DatePicker
                label={$_("components.from")}
                on:datechange={handleStartDateChange}
                selectedDate={startDate}
                disable={!enableDatePicking}
                topInPx={66}
            />
            <DatePicker
                label={$_("components.to")}
                on:datechange={handleEndDateChange}
                selectedDate={endDate}
                disable={!enableDatePicking}
                topInPx={66}
            />
        </div>
        <TwoUIButton
            on:click={() => (enableDatePicking = !enableDatePicking)}
            size="sm"
            variant="tertiaryColor"
            content={enableDatePicking ? showAllText : $_("statements.selectInvoiceDates")}
        />
        {#if errorMsg}
            <div class="error-text">{errorMsg}</div>
        {/if}
    </div>
    {#if selectorInput.options.size > 2 && isBuyerPortal}
        <!-- hide option if only one option aside from ALL default -->
        <div>
            <p style="margin-bottom: 4px;">{$_("statements.merchants")}</p>
            <ComplexSelector
                options={selectorInput.options}
                bind:selectedKey={dropDownCompanyChoice}
                placeholder={selectorInput.placeholder}
            />
        </div>
    {/if}
    <div>
        <p>{$_("statements.fileFormat")}</p>
        <Selector
            showArrow={true}
            backgroundColor="white"
            options={["xlsx", "csv", "json"]}
            bind:value={fileType}
        />
    </div>

    <div class="action-buttons">
        <TwoUIButton
            on:click={closeModal}
            size="md"
            variant="secondaryGray"
            disabled={loading}
            content={$_("components.close")}
        />

        <TwoUIButton
            on:click={downloadStatement}
            size="md"
            variant="primary"
            disabled={loading}
            content={$_("statements.downloadStatement")}
        />
    </div>
</div>

<style lang="scss">
    .header-text {
        font-weight: 700;
        line-height: 32px;
        font-size: 24px;
        padding-bottom: 0.5rem;
    }
    .header-sub-text {
        color: var(--ctnGeneralSecondary);
    }
    .date-input-wrapper {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
    }
    .date-inputs {
        display: grid;
        grid-column: 1fr 1fr;
        gap: 0.5rem;
        grid-template-columns: 0.5fr 0.5fr;
    }
    .error-text {
        color: var(--error-600);
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .container {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 1.5rem;
        // hack to accomodate the left date picker
        //min-width: 630px;
        max-width: 696px;
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-top: 2rem;
    }
</style>
