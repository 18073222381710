<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { INavItem } from "../../static/types";
    import modalState from "../../store/modals.store";
    import { CONFIRM_LOGOUT } from "../../static/modals";
    import NavList from "./NavList.svelte";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import NavItem from "./NavItem.svelte";
    import { statements, invoices } from "../../store/buyer/account.store";
    import environment from "@/services/environment";
    import { account, signupComplete, userMerchants } from "../../store/merchant/account.store";
    import { navigate } from "svelte-routing";
    
    export let hidden: boolean = false;
    export let show: boolean = true;
    export let itemList: INavItem[] = [];

    export let type: "Merchant" | "Buyer" = "Merchant";

    $: if ($invoices.length > 0) itemList[0].show = true;
    $: if ($statements.length > 0) itemList[1].show = true;

    const accountItem: INavItem = {
        icon: "account",
        name: $_("nav.account"),
        url: "/merchant/account",
        margin: "4",
        show: true
    };

    const integrationItem: INavItem = {
        icon: "tool",
        name: $_("nav.integration"),
        url: "/merchant/integration",
        margin: "4",
        show: true
    };

    const logOut: INavItem = {
        icon: "logout",
        name: $_("nav.logout"),
        url: "",
        margin: "4",
        show: true
    };

    function translateMenuItem(name) {
        switch (name) {
            case "Dashboard":
                return $_("nav.dashboard");
            case "Invoices":
                return $_("nav.invoices");
            case "Statements":
                return $_("nav.statements");
            case "Orders":
                return $_("nav.orders");
            case "Plans":
                return $_("nav.plans");
            case "Customers":
                return $_("nav.customers");
            case "Settlement reports":
                return $_("nav.settlement reports");
            default:
                return name;
        }
    }

    $: {
        // inject translations
        itemList = itemList.map((item) => ({ ...item, name: translateMenuItem(item.name) }));
    }

    const dispatch = createEventDispatcher();
    const closeMenu = (): void => {
        dispatch("close");
    };
    let slideMenu: HTMLDivElement;
    const closeDown = (event) => {
        const pageHeader: HTMLElement = document.getElementById("page-header");

        if (!slideMenu.contains(event.target) && pageHeader && !pageHeader.contains(event.target)) {
            dispatch("close");
        }
    };

    onMount(() => {
        document.addEventListener("click", closeDown);
    });

    onDestroy(() => {
        document.removeEventListener("click", closeDown);
    });
</script>

<div
    class="transition-right -right-72 duration-500 side-nav"
    class:right-0={show}
    class:-right-72={hidden}
    bind:this={slideMenu}
>
    <div class="logo-container">
        <img id="theme-logo" src={environment.branding.logo} class="logo" alt="logo" />
    </div>

    <div class="side-nav-menu-items">
        <div>
            <button
                class:cursor-not-allowed={$userMerchants?.length <= 1}
                style="margin:0px 8px 8px 8px;"
                on:click={() => navigate("/auth/merchant/user/select")}
                disabled={$userMerchants?.length <= 1}
                class="button-tertiary-gray button-sm">{$account?.accountName ?? ""}</button
            >
            <NavList {itemList} on:click={closeMenu} />
        </div>

        <div>
            {#if type === "Merchant"}
                <NavItem item={accountItem} on:click={closeMenu} />
                {#if $signupComplete}
                    <NavItem item={integrationItem} on:click={closeMenu} />
                {/if}
            {/if}

            <!-- Log out button -->
            <NavItem
                item={logOut}
                on:click={() => {
                    modalState.actions.setModal(CONFIRM_LOGOUT, { type });
                }}
            />
        </div>
    </div>
</div>

<style>
    .logo-container {
        height: 136px;
        padding: 2rem 2rem 2rem 1.5rem;
    }
    .logo {
        max-height: 50px;
    }

    .side-nav {
        background-color: var(--sidenav-bg);
        box-shadow: 0 0 6px rgb(208 213 221 / 25%);
        top: 4rem;
        width: 14rem;
        padding-bottom: 2.5rem;
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        position: fixed;
        z-index: 110;
        gap: 0.25rem;
    }

    @media (min-width: 768px) {
        .side-nav {
            top: 0;
            left: 0;
            position: sticky;
            height: 100vh;
        }
    }

    .side-nav-menu-items {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }
</style>
