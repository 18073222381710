<script lang="ts">
    export let fill: string = "#4F25E4";
</script>

<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.8294 6.8578C15.0569 6.63033 15.0569 6.25122 14.8294 6.03541L13.4646 4.6706C13.2488 4.44313 12.8697 4.44313 12.6422 4.6706L11.569 5.73795L13.7562 7.92515L14.8294 6.8578ZM4.79257 12.52C4.60523 12.7075 4.5 12.9617 4.5 13.2268V14C4.5 14.5523 4.94772 15 5.5 15H6.27272C6.53809 15 6.79258 14.8945 6.98014 14.7068L13.138 8.5434L10.9508 6.3562L4.79257 12.52Z"
        {fill}
    />
</svg>
