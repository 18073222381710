<script lang="ts">
    import { _ } from "svelte-i18n";
    import { ItemWithLabel, Tooltip, Button as TwoUIButton } from "@two-ui/core";
    import { faClockRotateLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

    import API from "../../../services/apiService";
    import { type TradeAccountUI, formatDate, adaptTradingAccountToBackend } from "./helpers";
    import { TRADE_ACCOUNT_CONFIG, LABEL_TO_VALUE_MAP } from "./variables";
    import Input from "../../../components/Elements/InputBox.svelte";
    import { getBaseUrl } from "../../../utils/functions";
    import notificationState from "../../../store/notifications.store";
    import { NotificationType } from "../../../static/types";

    export let accountDetails: TradeAccountUI | null = null;
    export let fetchTradeAccounts;

    const accountDetailsEditable = { ...accountDetails };
    const accountDetailsInitialValues = { ...accountDetails };

    const VIEW_ONLY_ITEMS = [
        {
            label: LABEL_TO_VALUE_MAP($_).verification,
            value: accountDetails?.verificationType,
            icon: faCircleCheck,
            iconColor: accountDetails.verificationTime
                ? "var(--ctnSemanticSuccessPrimary)"
                : "var(--ctnInteractiveGeneralDisabled)",
            secondary: accountDetails.verificationTime
                ? formatDate(accountDetails.verificationTime)
                : null,
            secondaryIcon: faClockRotateLeft
        }
    ];

    const onFocusHandler = (config) => {
        const elem = document.getElementById(
            config.dataKey.toLowerCase().replace(" ", "-") + "-editable"
        );

        if (elem) {
            // not working without async
            setTimeout(() => {
                elem.focus();
            }, 100);
        }
    };

    const onBlurHandler = (config) => {
        accountDetails[config.dataKey] = accountDetailsEditable[config.dataKey];
        isFormValid();
    };

    const invalidObj = Object.fromEntries(
        Object.keys(accountDetailsEditable).map((dataKey) => [dataKey, ""])
    );

    const isFormValid = () => {
        let isValid = true;

        // check required fields
        Object.keys(accountDetailsEditable).forEach((elem) => {
            const currentConfig = TRADE_ACCOUNT_CONFIG.find((item) => item.dataKey === elem);
            const isEditable = currentConfig && !currentConfig.disabled;

            if (isEditable && !accountDetailsEditable[elem]) {
                invalidObj[elem] = $_("errors.fieldRequired");
                isValid = false;
            } else {
                invalidObj[elem] = "";
            }
        });

        return isValid;
    };

    const getUpdatedElements = () => {
        const updatedElements: Partial<TradeAccountUI> = {};
        Object.entries(accountDetailsEditable).forEach(([key, value]) => {
            if (accountDetailsInitialValues[key] !== value) {
                updatedElements[key] = value;
            }
        });

        return updatedElements;
    };

    const onSave = () => {
        if (isFormValid()) {
            const updatedElements = getUpdatedElements();
            if (Object.keys(updatedElements).length > 0) {
                updateAccount(updatedElements);
            }
        }
    };

    const updateAccount = (updatedElements) => {
        const url = `${getBaseUrl()}/v2/merchant/${accountDetails.merchantId}/customer/${
            accountDetails.id
        }`;
        const data = adaptTradingAccountToBackend(updatedElements);

        API.patch(url, data)
            .then(() => {
                notificationState.actions.create(
                    NotificationType.SUCCESS,
                    $_("customer.accountUpdated")
                );
                fetchTradeAccounts();
            })
            .catch((err) => {
                const errorMessage = err.response?.data?.error_message
                    ? err.response?.data?.error_message
                    : $_("customer.accountFailedToBeUpdated");
                notificationState.actions.create(NotificationType.ERROR, errorMessage);
            });
    };
</script>

<section class="flex flex-wrap items">
    {#each TRADE_ACCOUNT_CONFIG as config}
        <div class="w-1/2 mb-4 item-wrap">
            <div class="w-2/3">
                {#if config.key}
                    <!-- isFocused -->
                    <div style={`display: ${config.isFocused ? "block" : "none"}`}>
                        <Input
                            label={LABEL_TO_VALUE_MAP($_)[config.key]}
                            id={config.dataKey.toLowerCase().replace(" ", "-") + "-editable"}
                            type={config.inputType}
                            placeholder={$_("components.enterYour", {
                                values: { field: LABEL_TO_VALUE_MAP($_)[config.key].toLowerCase() }
                            })}
                            bind:value={accountDetailsEditable[config.dataKey]}
                            required={config.required}
                            disabled={config.disabled}
                            onBlur={() => {
                                onBlurHandler(config);
                                config.isFocused = false;
                            }}
                        />
                    </div>
                    <!-- not isFocused -->
                    <div
                        style={`display: ${!config.isFocused ? "block" : "none"}`}
                        class:mb-4={invalidObj[config.dataKey]}
                    >
                        <Tooltip
                            style="width: 100%"
                            wrapLines
                            width={276}
                            position="bottom"
                            placement="start"
                            {...config.tooltipContent ? {} : { opened: false }}
                            label={config.tooltipContent ? config.tooltipContent($_) : ""}
                        >
                            <Input
                                label={LABEL_TO_VALUE_MAP($_)[config.key]}
                                id={config.dataKey.toLowerCase().replace(" ", "-")}
                                type="text"
                                placeholder={$_("components.enterYour", {
                                    values: {
                                        field: LABEL_TO_VALUE_MAP($_)[config.key].toLowerCase()
                                    }
                                })}
                                value={accountDetails[config.dataKey]}
                                required={config.required}
                                disabled={config.disabled}
                                invalid={invalidObj[config.dataKey]}
                                onFocus={() => {
                                    onFocusHandler(config);
                                    config.isFocused = true;
                                }}
                            />
                        </Tooltip>
                    </div>
                {/if}
            </div>
        </div>
    {/each}

    {#each VIEW_ONLY_ITEMS as item}
        <div class="w-1/2 mb-4 item-wrap">
            <ItemWithLabel
                label={item.label}
                value={item.value}
                icon={item.icon}
                iconColor={item.iconColor}
                secondary={item.secondary}
                secondaryIcon={item.secondaryIcon}
            />
        </div>
    {/each}

    <div class="mt-4 mb-8 w-full">
        <TwoUIButton
            on:click={onSave}
            size="md"
            variant="primary"
            content={$_("components.saveChanges")}
        />
    </div>
</section>

<style>
    .items .item-wrap:nth-child(odd) {
        padding-right: 1rem;
    }
    .items .item-wrap:nth-child(even) {
        padding-left: 1rem;
    }
</style>
