<script lang="ts">
    export let fill: string = "#111822";
    export let size: number = 20;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M6.66669 18.3333H14.5834C15.2803 18.3333 15.6288 18.3333 15.9186 18.2756C17.1087 18.0389 18.039 17.1086 18.2757 15.9186C18.3334 15.6288 18.3334 15.2803 18.3334 14.5833V14.1666C18.3334 13.8571 18.3334 13.7023 18.3219 13.5718C18.1951 12.1215 17.0452 10.9716 15.5948 10.8447C15.4643 10.8333 15.3096 10.8333 15 10.8333V10.8333M10 5.833L10.9323 4.90062C11.1917 4.64121 11.3214 4.5115 11.442 4.41363C12.5438 3.51891 14.122 3.51885 15.2239 4.41347C15.3444 4.51133 15.4742 4.64103 15.7336 4.90042V4.90042C15.9717 5.1385 16.0907 5.25754 16.1834 5.3702C17.0265 6.39532 17.0958 7.85261 16.3537 8.95308C16.2722 9.07402 16.1649 9.20382 15.9505 9.46342L10 16.6666M6.25002 14.1666C6.25002 14.3967 6.06347 14.5833 5.83335 14.5833C5.60324 14.5833 5.41669 14.3967 5.41669 14.1666M6.25002 14.1666C6.25002 13.9365 6.06347 13.75 5.83335 13.75C5.60324 13.75 5.41669 13.9365 5.41669 14.1666M6.25002 14.1666H5.41669M10 18.3333V5.83329C10 4.74769 10 4.20488 9.86117 3.76451C9.56684 2.83102 8.83563 2.0998 7.90214 1.80547C7.46176 1.66663 6.91896 1.66663 5.83335 1.66663V1.66663C4.74775 1.66663 4.20494 1.66663 3.76457 1.80547C2.83108 2.0998 2.09986 2.83102 1.80554 3.76451C1.66669 4.20488 1.66669 4.74769 1.66669 5.83329V13.5333C1.66669 15.2135 1.66669 16.0535 1.99367 16.6953C2.28129 17.2598 2.74023 17.7187 3.30472 18.0063C3.94645 18.3333 4.78653 18.3333 6.46669 18.3333H10Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
