<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 24;
</script>

<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.12" cx="12" cy="12" r="10" {fill} />
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12ZM12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM9.7019 8.64124C9.40901 8.34835 8.93414 8.34835 8.64124 8.64124C8.34835 8.93414 8.34835 9.40901 8.64124 9.7019L10.9393 12L8.64124 14.2981C8.34835 14.591 8.34835 15.0659 8.64124 15.3588C8.93414 15.6517 9.40901 15.6517 9.7019 15.3588L12 13.0607L14.2981 15.3588C14.591 15.6517 15.0659 15.6517 15.3588 15.3588C15.6517 15.0659 15.6517 14.591 15.3588 14.2981L13.0607 12L15.3588 9.7019C15.6517 9.40901 15.6517 8.93414 15.3588 8.64124C15.0659 8.34835 14.591 8.34835 14.2981 8.64124L12 10.9393L9.7019 8.64124Z"
        fill="#B42318"
    />
</svg>
