export const merchantOrderIDInfo =
    "This is the number you use for the order in your own systems. This is mandatory so we can include it on the invoice.";
export const merchantReferenceInfo =
    "This is any other internal reference that you may use for processing this order. This is optional and will not be included on the invoice.";
export const purchaseOrderInfo =
    "Add this if your customer wants a purchase order reference associated with the order. This will appear on the invoice your customer receives.";
export const projectInfo =
    "Add this if your customer wants to show the project this order relates to on the invoice.";
export const departmentInfo =
    "Add this if your customer wants to show the department this order relates to on the invoice.";
