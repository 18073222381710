<script lang="ts">
    export let fill: string = "#4CFAFF";
</script>

<svg width="30" height="20" viewBox="0 0 30 20" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10 5.5L10 14.5M10 14.5L13.5 11M10 14.5L6.5 11"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path d="M17 6H26" stroke={fill} stroke-width="1.5" stroke-linecap="round" />
    <path d="M17 10H23" stroke={fill} stroke-width="1.5" stroke-linecap="round" />
    <path d="M17 14H20" stroke={fill} stroke-width="1.5" stroke-linecap="round" />
</svg>
