<script lang="ts">
    import type { INavItem } from "../../static/types";
    import NavItem from "./NavItem.svelte";
    export let itemList: INavItem[];
</script>

<ul>
    {#each itemList as item}
        {#if item.show}
            <NavItem {item} on:click />

            {#if item?.subItems}
                {#each item.subItems as item}
                    {#if item.show}
                        <NavItem {item} on:click />
                    {/if}
                {/each}
            {/if}
        {/if}
    {/each}
</ul>
