<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 34 34" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17Z"
        fill="#8C8C8C"
    />
    <path
        d="M24.3469 11.35L18.6969 17L24.3469 22.65L22.6469 24.35L16.9969 18.7L11.3469 24.35L9.64688 22.65L15.2969 17L9.64688 11.35L11.3469 9.65L16.9969 15.3L22.6469 9.65L24.3469 11.35Z"
        fill="white"
    />
</svg>
