<script lang="ts">
    import { _ } from "svelte-i18n";

    import MetaWrapper from "../../../../components/metadata/MetaWrapper.svelte";
    import VerifyCode from "../../../../components/authentication/VerifyCode.svelte";
    import Button from "../../../../components/Elements/Button.svelte";
    import authenticationService from "../../../../services/authenticationService";
    import { getOrganizationId } from "../../../../utils/auth";
    import environment from "../../../../services/environment";

    const userEmail: string = localStorage.getItem("user_email");

    const codeLength: number = 6;
    let verifyCode: string[] = new Array(codeLength).fill("");
    let isInvalid: boolean[] = new Array(codeLength).fill(false);
    let displayLoginError: string = "";
    let isSubmitting: boolean = false;

    function submitEmail() {
        const formValue = {
            verification_type: "EMAIL",
            verification_context: "MERCHANT_ONBOARDING",
            company_id: getOrganizationId(),
            email: localStorage.getItem("user_email")
        };
        authenticationService
            .sendEmailCode(formValue)
            .then((res: any) => {
                localStorage.setItem("verification_id", res.verification_id);
                localStorage.setItem("redirect_url", res.redirect_url);
            })
            .catch(() => {
                displayLoginError = $_("login.errorSendingCode");
                console.error();
            });
    }

    function submitCode() {
        const formValue = {
            verification_type: "EMAIL",
            code: verifyCode.join(""),
            verification_id: localStorage.getItem("verification_id")
        };
        isSubmitting = true;
        displayLoginError = "";
        authenticationService
            .completeVerification(formValue)
            .then((res: any) => {
                window.location.href = res.complete_url;
            })
            .catch((err: any) => {
                if (err.response.data.error_code === "VERIFICATION_NOT_FOUND") {
                    displayLoginError = $_("login.codeMismatch");
                } else if (err.response.data.error_code === "ACCESS_DENIED") {
                    displayLoginError = $_("login.accessDenied");
                } else {
                    displayLoginError = $_("login.loginError");
                    console.error(err);
                }
                isSubmitting = false;
            });
    }
</script>

<div style="height:100vh;">
    <header>
        <img id="theme-logo" src={environment.branding.logo} alt="logo" />
    </header>
    <MetaWrapper>
        <div class="flex justify-center">
            <div class="flex-col justify-center code-container">
                <p class="text-xl font-bold text-center">{$_("login.verifyEmail")}</p>

                <p class="mt-6 text-center text-md">{$_("login.enterCode")}</p>
                <div class="flex email-pill">
                    <div class="m-auto">{userEmail}</div>
                </div>
                <div class="mt-8">
                    <VerifyCode
                        bind:code={verifyCode}
                        bind:invalid={isInvalid}
                        codeError={displayLoginError !== ""}
                    />
                    {#if displayLoginError !== ""}
                        <div class="text-center error-message text-sm">{displayLoginError}</div>
                    {/if}
                    <p class="mt-8 text-center text-sm text-dark">
                        {$_("login.resendCode")}
                        <button class="text-main resend-btn" on:click={submitEmail}>
                            {$_("login.resendButton")}
                        </button>
                    </p>
                </div>
                <div class="relative mt-6">
                    <Button
                        loading={isSubmitting}
                        size="xlarge"
                        label={$_("login.submitCode")}
                        on:click={submitCode}
                    />
                </div>
            </div>
        </div>
    </MetaWrapper>
</div>

<style lang="scss">
    header {
        display: flex;
        justify-content: center;
        font-size: 0.875rem;
        padding: 12px 0 12px 32px;
        z-index: 10;
        background-color: white;
        box-shadow: 0px 3px 6px rgba(208, 213, 221, 0.25);
        position: fixed;
        top: 0px;
        width: 100%;
    }

    .code-container {
        max-width: 500px;
        background: white;
        margin-top: 5rem;
        padding: 4rem;
        border-radius: 1rem;

        border-radius: 16px;
        border: 1px solid var(--Gray-100, #f2f4f7);
        background: var(--Basic-White, #fff);

        box-shadow: 0px 0px 6px 0px rgba(208, 213, 221, 0.25);
    }

    .email-pill {
        border-radius: 32px;
        border: 1px solid #d0d5dd;
        background: #f9fafb;
        padding: 8px 14px;
        width: fit-content;
        margin: 24px auto;
    }

    .error-message {
        color: #d92d20;
    }

    .resend-btn {
        color: var(--ctnInteractiveSemanticHighlightDefault);
    }
</style>
