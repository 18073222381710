<script>
    import { _ } from "svelte-i18n";

    import LoadingWrapper from "../../../components/Elements/LoadingWrapper.svelte";
    import Accordion from "../../../components/Elements/Accordion.svelte";
    import SingleTradeAccountView from "./SingleTradeAccountView.svelte";
    import SingleTradeAccountEdit from "./SingleTradeAccountEdit.svelte";

    export let tradeAccounts = [];
    export let loadingTradeAccounts = false;
    export let fetchTradeAccounts;
    let openedTradeAccountsIds = [];
    let tradeAccountsCopy = [];

    function onToggleAccordionAccount(id) {
        if (openedTradeAccountsIds.includes(id)) {
            openedTradeAccountsIds = openedTradeAccountsIds.filter((item) => item !== id);
        } else {
            openedTradeAccountsIds = [...openedTradeAccountsIds, id];
        }
    }

    function onCancelEditing(id) {
        tradeAccounts = tradeAccounts.map((account, index) => {
            if (account.id === id) return tradeAccountsCopy[index];
            return account;
        });
    }

    $: if (!tradeAccountsCopy.length && tradeAccounts.length) {
        tradeAccountsCopy = JSON.parse(JSON.stringify(tradeAccounts));
    }
</script>

<div class="gcard">
    <h1 class="page-title">{$_("customer.tradeAccount")}</h1>
    <p class="description mb-6">
        {$_("customer.tradeAccountDescription")}
    </p>
    {#if loadingTradeAccounts}
        <div><LoadingWrapper loading={true} /></div>
    {:else}
        {#each tradeAccounts as tradeAccount}
            <Accordion
                title={tradeAccount.name || tradeAccount.legalName}
                alwaysOpened={tradeAccounts.length === 1}
                isActive={openedTradeAccountsIds.includes(tradeAccount.id)}
                onToggle={() => onToggleAccordionAccount(tradeAccount.id)}
                onCancel={() => onCancelEditing(tradeAccount.id)}
            >
                <SingleTradeAccountView slot="view" accountDetails={tradeAccount} />
                <SingleTradeAccountEdit
                    slot="edit"
                    accountDetails={tradeAccount}
                    {fetchTradeAccounts}
                />
            </Accordion>
        {/each}

        {#if !tradeAccounts.length}
            <p>{$_("customer.noTradeAccounts")}</p>
        {/if}
    {/if}
</div>

<style>
    .gcard {
        padding: 1.5rem;
    }
    .description {
        font-size: 14px;
        color: var(--gray-700);
    }
</style>
