<script lang="ts">
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.66669 15H4.16669M15.8334 4.99996H18.3334M13.3334 15H18.3334M1.66669 4.99996H6.66669M7.36669 18.3333H7.63335C8.75346 18.3333 9.31351 18.3333 9.74134 18.1153C10.1177 17.9236 10.4236 17.6176 10.6154 17.2413C10.8334 16.8135 10.8334 16.2534 10.8334 15.1333V14.8666C10.8334 13.7465 10.8334 13.1865 10.6154 12.7586C10.4236 12.3823 10.1177 12.0764 9.74134 11.8846C9.31351 11.6666 8.75346 11.6666 7.63336 11.6666H7.36669C6.24658 11.6666 5.68653 11.6666 5.25871 11.8846C4.88238 12.0764 4.57642 12.3823 4.38467 12.7586C4.16669 13.1865 4.16669 13.7465 4.16669 14.8666V15.1333C4.16669 16.2534 4.16669 16.8135 4.38467 17.2413C4.57642 17.6176 4.88238 17.9236 5.25871 18.1153C5.68653 18.3333 6.24658 18.3333 7.36669 18.3333ZM12.3667 8.33329H12.6334C13.7535 8.33329 14.3135 8.33329 14.7413 8.11531C15.1177 7.92356 15.4236 7.6176 15.6154 7.24127C15.8334 6.81345 15.8334 6.2534 15.8334 5.13329V4.86663C15.8334 3.74652 15.8334 3.18647 15.6154 2.75864C15.4236 2.38232 15.1177 2.07636 14.7413 1.88461C14.3135 1.66663 13.7535 1.66663 12.6334 1.66663H12.3667C11.2466 1.66663 10.6865 1.66663 10.2587 1.88461C9.88238 2.07636 9.57642 2.38232 9.38467 2.75864C9.16669 3.18647 9.16669 3.74652 9.16669 4.86662V5.13329C9.16669 6.2534 9.16669 6.81345 9.38467 7.24127C9.57642 7.6176 9.88238 7.92356 10.2587 8.11531C10.6865 8.33329 11.2466 8.33329 12.3667 8.33329Z"
        stroke={"var(--ctnInteractiveGeneralDefault)"}
        stroke-width="1.5"
        stroke-linecap="round"
    />
</svg>
