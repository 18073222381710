<script lang="ts">
    import { _ } from "svelte-i18n";

    import Modal from "../Elements/Modal.svelte";
    import Selector from "../Elements/Selector.svelte";
    import Button from "../Elements/Button.svelte";
    import { GET_PENDING_PAYOUTS_REPORT } from "../../static/endPoints";
    import { getBaseUrl } from "../../utils/functions";
    import environment from "@/services/environment";

    export let show: boolean;
    export let balances = [];
    export let defaultCurrency: string;

    const ALL_CURRENCIES = $_("payout.allCurrencies");
    const ALL_FUNDING_PROVIDERS = $_("payout.allFundingProviders");
    const fileFormats: string[] = ["csv", "xlsx"];
    let fileFormatSelected: string = fileFormats[0];
    const fundingProvidersSet = new Set([ALL_FUNDING_PROVIDERS]);
    let fundingProviders = [...fundingProvidersSet];
    let fundingProviderSelected = fundingProviders[0];
    const currencySet = new Set([]);
    let currencies = [];

    $: currencySelected = defaultCurrency;
    $: getFundingProviders(balances);

    function getFundingProviders(balances) {
        balances.forEach((balance) => {
            if (balance.funding_provider) {
                fundingProvidersSet.add(balance.funding_provider);
            }
            currencySet.add(balance.currency);
        });
        fundingProviders = [...fundingProvidersSet];
        currencies = [...currencySet, ALL_CURRENCIES];
    }

    function generateStatement() {
        let query = "?";
        const params = new URLSearchParams({
            file_type: fileFormatSelected
        });
        if (fundingProviderSelected != ALL_FUNDING_PROVIDERS) {
            params.append("funding_provider", fundingProviderSelected);
        }
        if (currencySelected != ALL_CURRENCIES) {
            params.append("currency", currencySelected);
        }
        query += params.toString();
        window.location.href = `${getBaseUrl()}${GET_PENDING_PAYOUTS_REPORT}${query}`;
    }

    function handleDownload() {
        generateStatement();
        closeModal();
    }

    function closeModal() {
        show = false;
        resetInputs();
    }

    function resetInputs() {
        fileFormatSelected = fileFormats[0];
        fundingProviderSelected = fundingProviders[0];
        currencySelected = defaultCurrency;
    }
</script>

<Modal title={$_("payout.balanceReport")} bind:show on:close={resetInputs}>
    <p class="description">
        {$_("payout.description", { values: { brand: environment.branding.displayName } })}
    </p>
    <div class="selector-wrapper">
        <Selector
            label={$_("payout.fundingProvider")}
            backgroundColor="white"
            options={fundingProviders}
            showArrow={true}
            bind:value={fundingProviderSelected}
        />
        <Selector
            label={$_("payout.currency")}
            backgroundColor="white"
            options={currencies}
            showArrow={true}
            bind:value={currencySelected}
        />
        <Selector
            label={$_("payout.fileFormat")}
            backgroundColor="white"
            options={fileFormats}
            showArrow={true}
            bind:value={fileFormatSelected}
        />
    </div>
    <div class="buttons" slot="footer">
        <button class="button-secondary-gray button-sm cancel" on:click={closeModal}>
            {$_("components.close")}
        </button>
        <Button
            label={$_("components.download")}
            size="medium"
            paddingX="16px"
            on:click={handleDownload}
        />
    </div>
</Modal>

<style lang="scss">
    * {
        box-sizing: border-box;
    }

    .description {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        color: var(--gray-500);
        margin-block-end: 1.7rem;
    }

    .selector-wrapper {
        margin-block-end: 3rem;

        & > :global(div) {
            margin-block-end: 1.5rem;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;

        .cancel {
            margin-inline-end: 8px;
        }
    }
</style>
