<script lang="ts">
    import { _ } from "svelte-i18n";
    import { actions } from "../../store/merchant/customers.store";
    import { titleCase } from "../../utilsV2/helpers";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";
    import environment from "@/services/environment";
    export let closeModal;
    export let props: any = {};
</script>

<div class="min-w-500 flex flex-col py-10 px-10 pb-5">
    <p class="text-black-dark font-semibold text-xl">
        {#if props?.is_disable}
            {$_("resource.disableTitle", {
                values: { customerName: titleCase([props?.customer_name])[0] }
            })}
        {:else}
            {$_("resource.enableTitle", {
                values: { customerName: titleCase([props?.customer_name])[0] }
            })}
        {/if}
    </p>
    <p class="mt-4">
        {#if props?.is_disable}
            {$_("resource.disableMessage", {
                values: { brandName: environment.branding.displayName }
            })}
        {:else}
            {$_("resource.enableMessage", {
                values: {
                    customerName: titleCase([props?.customer_name])[0],
                    brandName: environment.branding.displayName
                }
            })}
        {/if}
    </p>
    <div class="w-full mt-8 text-right">
        <div class="inline-block w-44 my-2 mr-2">
            <Button
                bgColor="white"
                hoverBg="#F9FAFB"
                hoverColor="#1D2939"
                color="#344054"
                borderColor="#D0D5DD"
                label={$_("components.cancel")}
                on:click={closeModal}
            />
        </div>
        <div class="inline-block w-44 my-2">
            <Button
                label={props?.is_disable ? $_("components.disable") : $_("components.confirm")}
                on:click={() => {
                    modalState.actions.setModal(null, {});
                    actions.updateCustomerRecouseLimit(
                        props?.data,
                        titleCase([props?.customer_name])[0],
                        props?.is_disable ? true : false
                    );
                }}
                bgColor="var(--primary-600)"
                hoverBg="var(--primary-700)"
                color="#ffffff"
            />
        </div>
    </div>
</div>
