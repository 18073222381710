const CUSTOMER_DETAILS_CLASS = "customer-details";

const tableHeadersCreditInsights = [
    { title: "Customer", clickable: false, type: CUSTOMER_DETAILS_CLASS },
    { title: "Credit used", clickable: false, type: "number" },
    { title: "Credit available", clickable: false, type: "number" },
    { title: "Credit limit", clickable: false, type: "number" },
    { title: "Total spend last 30 days", clickable: false, type: "number" },
    { title: "Actions", clickable: false, type: "number" }
];

export const TAB_ITEMS = {
    "Credit insights": {
        tableHeaders: tableHeadersCreditInsights,
        title: "Credit insights"
    }
};
