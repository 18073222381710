<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 28" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M16.6667 0.666016H3.33333C1.4924 0.666016 0 2.15841 0 3.99935V23.9993C0 25.8404 1.4924 27.3327 3.33333 27.3327H16.6667C18.5077 27.3327 20 25.8404 20 23.9993V3.99935C20 2.15841 18.5077 0.666016 16.6667 0.666016ZM18.3333 23.9993C18.3333 24.9184 17.5857 25.666 16.6667 25.666H3.33333C2.41432 25.666 1.66667 24.9184 1.66667 23.9993V3.99935C1.66667 3.08034 2.41432 2.33268 3.33333 2.33268H16.6667C17.5857 2.33268 18.3333 3.08034 18.3333 3.99935V23.9993ZM10 15.666C11.8409 15.666 13.3333 14.1736 13.3333 12.3327C13.3333 10.4917 11.8409 8.99935 10 8.99935C8.15906 8.99935 6.66667 10.4917 6.66667 12.3327C6.66667 14.1736 8.15906 15.666 10 15.666ZM10 10.666C10.919 10.666 11.6667 11.4137 11.6667 12.3327C11.6667 13.2517 10.919 13.9993 10 13.9993C9.08099 13.9993 8.33333 13.2517 8.33333 12.3327C8.33333 11.4137 9.08099 10.666 10 10.666ZM11.6667 17.3327H8.33333C6.03219 17.3327 4.16667 19.1981 4.16667 21.4993C4.16667 21.9596 4.53979 22.3327 5 22.3327C5.46031 22.3327 5.83333 21.9596 5.83333 21.4993C5.83333 20.1186 6.9526 18.9993 8.33333 18.9993H11.6667C13.0474 18.9993 14.1667 20.1186 14.1667 21.4993C14.1667 21.9596 14.5398 22.3327 15 22.3327C15.4603 22.3327 15.8333 21.9596 15.8333 21.4993C15.8333 19.1981 13.9679 17.3327 11.6667 17.3327ZM7.5 5.66602H12.5C12.9603 5.66602 13.3333 5.29289 13.3333 4.83268C13.3333 4.37247 12.9603 3.99935 12.5 3.99935H7.5C7.03979 3.99935 6.66667 4.37247 6.66667 4.83268C6.66667 5.29289 7.03979 5.66602 7.5 5.66602Z"
        {fill}
    />
</svg>
