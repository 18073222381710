<script lang="ts">
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

    import ToolTip from "../tooltip/ToolTip.svelte";

    export let text = "";
    export let textClasses = "ctn-general-tertiary";
    export let tooltipText = "";
    export let value = "";
    export let valueClasses = "";
    export let id = "";
</script>

<div class="card" {id}>
    <div class="flex items-center mb-2">
        <p class={`font-normal ${textClasses} ${id}-text`}>
            {text}
        </p>
        {#if tooltipText}
            <span class="ml-2 z-10 hide-tooltip">
                <ToolTip width="250px" position="right" placement="start" content={tooltipText}>
                    <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                </ToolTip>
            </span>
        {/if}
    </div>
    <div class="flex items-center mb-1">
        <p class={`text-3xl main-values font-bold ${id}-value ${valueClasses}`}>
            {value}
        </p>
    </div>
    <p class={`ctn-general-tertiary font-medium text-sm ${id}-description`}>
        <slot></slot>
    </p>
</div>

<style>
    .card {
        padding: 24px;
        border-radius: 12px;
        background-color: #fff;
        width: 100%;
        font-weight: 600;
        border: 1px solid var(--gray-100);
        align-items: center;
        align-content: space-between;
    }
</style>
