<script lang="ts">
    import { _ } from "svelte-i18n";
    import environment from "@/services/environment";
    import Button from "../../Elements/Button.svelte";
    export let closeModal;
    export const props = null;
</script>

<div class="px-8 py-10 max-w-xl w-full">
    <div>
        <img class="m-auto" src="/pictures/peace-hand.svg" alt="icon" />
        <p class="mt-6 text-center text-2xl font-bold">{$_("onboarding.completed")}</p>
        <p class="text-base ctn-general-secondary text-center mt-5">
            {$_("onboarding.fullAccess", {
                values: { brandName: environment.branding.displayName }
            })}
        </p>
        <div class="m-auto mt-12 max-w-sm w-full">
            <Button
                size="xlarge"
                bgColor="var(--primary-dark)"
                label={$_("onboarding.letsGo")}
                on:click={closeModal}
            />
        </div>
    </div>
</div>
