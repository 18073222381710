<script lang="ts">
    import { _ } from "svelte-i18n";

    import { NotificationType, type ICustomer } from "../../static/types";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";
    import { EDIT_CREDITLIMIT } from "../../static/modals";
    import notificationState from "../../store/notifications.store";
    interface Props extends ICustomer {
        newValue: string;
    }
    export let props: Props;

    function goEditCreditLimit() {
        modalState.actions.setModal(EDIT_CREDITLIMIT, props);
    }

    function updateCreditLimit() {
        modalState.actions.setModal(null, {});
        setTimeout(() => {
            notificationState.actions.create(
                NotificationType.SUCCESS,
                $_("editCredit.successMessage", {
                    values: { buyerCompanyName: props?.buyer_company_name }
                })
            );
        }, 300);
    }
</script>

<div class="p-10 w-full sm:w-2xs">
    <p class="text-2xl font-bold">
        {$_("editCredit.confirmationTitle", {
            values: { buyerCompanyName: props?.buyer_company_name }
        })}
    </p>
    <div class="flex mt-12 w-full">
        <div class="w-full mr-4">
            <Button
                size="large"
                label={$_("components.cancel")}
                borderColor="var(--primary-dark)"
                color="var(--primary-dark)"
                hoverColor="var(--primary-dark)"
                hoverBg="var(--gray-50)"
                bgColor="white"
                on:click={goEditCreditLimit}
            />
        </div>
        <div class="w-full ml-4">
            <Button
                size="large"
                label={$_("components.yes")}
                bgColor="var(--primary-dark)"
                hoverBg="var(--primary)"
                on:click={updateCreditLimit}
            />
        </div>
    </div>
</div>
