<script lang="ts">
    import { _ } from "svelte-i18n";

    import environment from "@/services/environment";
    import modalState from "../../../store/modals.store";
    import Button from "../../Elements/Button.svelte";
</script>

<div class="min-w-500 flex flex-col py-10 px-10 pb-5">
    <p class="text-black-dark font-semibold text-xl">
        {$_("crossBorder.title")}
    </p>
    <p class="mt-4">
        {$_("crossBorder.description", { values: { brandName: environment.branding.displayName } })}
    </p>
    <div class="w-full mt-8 text-right">
        <div class="inline-block w-44 my-2">
            <Button
                label={$_("components.ok")}
                on:click={() => {
                    modalState.actions.setModal(null, {});
                }}
            />
        </div>
    </div>
</div>
