<script lang="ts">
    export let fill = "#344054";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5332 0.861296C10.53 0.583751 9.47021 0.583751 8.46697 0.861296C7.86137 1.02883 7.3135 1.32485 6.70827 1.74028C6.11602 2.1468 5.42932 2.69616 4.55692 3.39409L4.55691 3.39409L4.53402 3.41241L4.15548 3.71524L4.0692 3.78425L4.0692 3.78426C3.0934 4.56469 2.49289 5.04497 2.04796 5.64502C1.61284 6.23184 1.29353 6.89621 1.10711 7.60257C0.916501 8.32485 0.916599 9.09379 0.916757 10.3433L0.916766 10.4538V14.1667C0.916766 16.8821 3.11803 19.0833 5.83343 19.0833C7.16812 19.0833 8.2501 18.0014 8.2501 16.6667V13.3333C8.2501 12.3668 9.0336 11.5833 10.0001 11.5833C10.9666 11.5833 11.7501 12.3668 11.7501 13.3333V16.6667C11.7501 18.0014 12.8321 19.0833 14.1668 19.0833C16.8822 19.0833 19.0834 16.8821 19.0834 14.1667V10.4538L19.0834 10.3433C19.0836 9.09379 19.0837 8.32484 18.8931 7.60257C18.7067 6.89621 18.3874 6.23184 17.9522 5.64502C17.5073 5.04497 16.9068 4.56469 15.931 3.78425L15.8447 3.71524L15.4662 3.41241L15.4432 3.39406L15.4432 3.39406C14.5709 2.69615 13.8842 2.14679 13.2919 1.74028C12.6867 1.32485 12.1388 1.02883 11.5332 0.861296Z"
        {fill}
    />
</svg>
