<script lang="ts">
    export let fill = "#1D2939";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00008 17.9583C6.24354 17.9583 4.86527 17.9583 3.77592 17.5141C2.28144 16.9046 1.09554 15.7187 0.48603 14.2242C0.041748 13.1348 0.041748 11.7565 0.041748 9C0.041748 6.24347 0.041748 4.8652 0.48603 3.77584C1.09554 2.28136 2.28144 1.09546 3.77592 0.485954C4.86527 0.0416718 6.24354 0.0416718 9.00008 0.0416718C11.7566 0.0416718 13.1349 0.0416718 14.2242 0.485954C15.7187 1.09546 16.9046 2.28136 17.5141 3.77584C17.9584 4.8652 17.9584 6.24347 17.9584 9C17.9584 11.7565 17.9584 13.1348 17.5141 14.2242C16.9046 15.7187 15.7187 16.9046 14.2242 17.5141C13.1349 17.9583 11.7566 17.9583 9.00008 17.9583ZM11.5001 5.66667C11.5001 7.04738 10.3808 8.16667 9.00008 8.16667C7.61937 8.16667 6.50008 7.04738 6.50008 5.66667C6.50008 4.28596 7.61937 3.16667 9.00008 3.16667C10.3808 3.16667 11.5001 4.28596 11.5001 5.66667ZM10.389 9.83334C11.9231 9.83334 13.1667 11.077 13.1667 12.6111C13.1667 13.3782 12.5449 14 11.7779 14H6.2223C5.45524 14 4.83341 13.3782 4.83341 12.6111C4.83341 11.077 6.07707 9.83334 7.61119 9.83334H10.389Z"
        {fill}
    />
</svg>
