<script lang="ts">
    import { _ } from "svelte-i18n";

    export let show: boolean;
    import Modal from "../Elements/Modal.svelte";
    import Loading from "../Elements/Loading.svelte";
    export let videoUrl: string;
    export let videoTitle: string;
    let isLoading: boolean = true;

    function closeModal() {
        show = false;
    }

    function handleLoad() {
        isLoading = false;
    }

    $: if (show) {
        isLoading = true;
    }
</script>

<Modal title={videoTitle} bind:show>
    {#if isLoading}
        <Loading color="#8098F9" />
    {/if}
    <div
        id="video-content"
        style="position: relative; padding-bottom: 64.86486486486486%;"
        class="container"
    >
        <iframe
            title="vid"
            src={videoUrl}
            frameborder="0"
            allowfullscreen
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
            on:load={handleLoad}
        ></iframe>
    </div>

    <div slot="footer">
        <button class="close-button" on:click={closeModal}>{$_("components.close")}</button>
    </div>
</Modal>

<style>
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
    }

    .close-button {
        display: flex;
        width: fit-content;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Primary-600, var(--primary-600));
        background: var(--Primary-600, var(--primary-600));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--White, #fff);
        font-family: Aeonik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
    }

    @media (max-width: 768px) {
        .close-button {
            margin-top: 1rem;
        }
    }
</style>
