<script lang="ts">
    export let size: number = 20;
    export let fill: string = "#FEF3F2";
</script>

<svg
    width={size}
    height={size}
    viewBox="0 0 {size} {size}"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M7.50008 12.5L10.0001 9.99996M10.0001 9.99996L12.5001 7.49996M10.0001 9.99996L7.50008 7.49996M10.0001 9.99996L12.5001 12.5M18.3334 9.99996C18.3334 14.6023 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99996C1.66675 5.39759 5.39771 1.66663 10.0001 1.66663C14.6025 1.66663 18.3334 5.39759 18.3334 9.99996Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
