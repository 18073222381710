<script lang="ts">
    export let fill: string = "#4CFAFF";
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 26 26" {fill} xmlns="http://www.w3.org/2000/svg">
    <path
        d="M12.9999 14.584C16.9351 14.584 20.1249 11.3939 20.1249 7.45898C20.1249 3.52405 16.9351 0.333984 12.9999 0.333984C9.06519 0.333984 5.87492 3.52405 5.87492 7.45898C5.87492 11.3939 9.06519 14.584 12.9999 14.584ZM12.9999 1.91732C16.0557 1.91732 18.5416 4.40325 18.5416 7.45898C18.5416 10.5146 16.0557 13.0007 12.9999 13.0007C9.94418 13.0007 7.45825 10.5146 7.45825 7.45898C7.45825 4.40325 9.94418 1.91732 12.9999 1.91732ZM17.7498 16.1673H8.25002C3.87789 16.1673 0.333252 19.7117 0.333252 24.084C0.333252 24.9584 1.04229 25.6673 1.91649 25.6673H24.0834C24.9575 25.6673 25.6666 24.9584 25.6666 24.084C25.6666 19.7117 22.1222 16.1673 17.7498 16.1673ZM1.91659 24.084C1.91659 20.5917 4.75778 17.7507 8.25002 17.7507H17.7498C21.2421 17.7507 24.0833 20.5917 24.0834 24.084H1.91659Z"
        {fill}
    />
</svg>
