<script lang="ts">
    export let fill = "#344054";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M1.23193 2.84467L4.1151 5.84858L8.75155 1.01953"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
