<div class="flex flex-col flex-1 justify-center w-full user-pt-3">
    <slot />
</div>

<style>
    @media only screen and (max-width: 400px) {
        .user-pt-3 {
            padding-top: 12px;
        }
    }
</style>
