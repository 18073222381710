<script lang="ts">
    export let fill = "var(--primary-700)";
    export let size = 16;
</script>

<svg width={size} height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.66671 2.08329V3.46665C9.66671 4.58676 9.66671 5.14681 9.88469 5.57463C10.0764 5.95096 10.3824 6.25692 10.7587 6.44866C11.1865 6.66665 11.7466 6.66665 12.8667 6.66665H14.25M8.00004 9.16663V14.1666M8.00004 14.1666L10.0834 12.0833M8.00004 14.1666L5.91671 12.0833M14.6667 8.77957V11.6666C14.6667 13.2166 14.6667 13.9916 14.4963 14.6274C14.034 16.3528 12.6863 17.7006 10.9608 18.1629C10.325 18.3333 9.54999 18.3333 8.00004 18.3333V18.3333C6.45009 18.3333 5.67511 18.3333 5.03928 18.1629C3.31382 17.7006 1.96608 16.3528 1.50375 14.6274C1.33337 13.9916 1.33337 13.2166 1.33337 11.6666V8.14844C1.33337 6.77087 1.33337 6.08208 1.46821 5.51329C1.90428 3.6738 3.34054 2.23753 5.18004 1.80146C5.74883 1.66663 6.43761 1.66663 7.81519 1.66663V1.66663C8.41857 1.66663 8.72025 1.66663 9.00957 1.70045C9.93026 1.80809 10.8029 2.16955 11.53 2.74447C11.7585 2.92512 11.9719 3.13845 12.3985 3.5651L12.5834 3.74996C13.192 4.35856 13.4963 4.66286 13.7377 5.00143C14.1784 5.61948 14.4726 6.32976 14.598 7.07841C14.6667 7.48853 14.6667 7.91888 14.6667 8.77957Z"
        stroke={fill}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
