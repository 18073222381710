<script lang="ts">
    import { _ } from "svelte-i18n";
    import utils from "../../../../utilsV2/currency";

    export let title = "";
    export let columns = [];
    export let items = [];
    export let locale = "";
    export let currency: any;
    export let creditNoteDownloadLink = "";
</script>

<div class="scrollbar">
    <div class="main-bar">
        <span class="card-title">{title}</span>
        <div class="button-container">
            <a class="download-invoice" href={creditNoteDownloadLink} target="_blank">
                {$_("components.download")}
            </a>
        </div>
    </div>

    <div class="product-table-wrap">
        <table class="table">
            <thead class="table-head">
                {#each columns as column}
                    <th
                        class="table-header table-header--{column.alignment}"
                        style="width: {column.width}"
                    >
                        {@html column.label}
                    </th>
                {/each}
            </thead>
            {#each items as item}
                <tr class="table-row">
                    <td class="table-cell">{item.refund_no}</td>
                    <td class="table-cell">{item.refund_date}</td>
                    <td class="table-cell"
                        >{utils.formatCurrency(currency)(item.amount, { locale })}</td
                    >
                    <td class="table-cell"> </td>
                </tr>
            {/each}
        </table>
    </div>
</div>

<style lang="scss">
    .card {
        flex-basis: 100%;
        border-radius: 8px;
        background-color: var(--basic);
        padding: 24px;
        --tw-bg-opacity: 1;

        border: 1px solid var(--gray-100);
        box-shadow: 0 0 6px rgba(208, 213, 221, 0.25);

        &--table {
            padding: 0;
        }
    }

    .card-title {
        display: flex;
        gap: 10px;
        font-weight: 700;
        line-height: 24px;
    }

    .card-title {
        padding: 24px;
    }

    .card-heading {
        color: var(--ctnGeneralTertiary);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 16px;
    }

    .download-invoice {
        border-radius: 8px;
        border: 1px solid var(--gray-300, #d0d5dd);
        background: var(--basic-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
        padding: 8px 14px;
    }

    .product-table-wrap {
        width: 100%;
    }

    .main-bar {
        display: flex;
        justify-content: space-between;
    }

    .button-container {
        margin: auto 1rem;
        display: flex;
        gap: 1rem;
    }

    .checkbox-label {
        line-height: 20px;
        margin: auto;
        height: 20px;
        display: flex;
    }

    .item-checkbox {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
    }

    .table {
        width: 100%;
        min-width: 930px;
        table-layout: fixed;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-700);
        margin-bottom: 24px;

        // global resets
        border-collapse: collapse;
        border-spacing: unset;
    }

    th,
    td {
        cursor: default;
    }

    .table-head {
        background-color: var(--bgGeneralQuarterary);
        color: var(--ctnGeneralSecondary);
    }

    .table-header {
        width: 100%;
        padding: 0 8px;
        height: 48px;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }
    }

    .table-cell {
        padding: 0 8px;
        font-weight: 400;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }
    }

    .download-button {
        color: var(--primary-600);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
</style>
