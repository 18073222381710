import type { IFrame, IMorderDetail, INavItem } from "./types";
import { DOCS_URL } from "./endPoints";
const buyerItemList: INavItem[] = [
    {
        icon: "order",
        name: "Invoices",
        url: "/buyer/invoices",
        margin: "4",
        show: false
    },
    {
        icon: "groupedOrder",
        name: "Statements",
        url: "/buyer/statements",
        margin: "4",
        show: false
    }
];

const merchantItemList: INavItem[] = [
    {
        icon: "home",
        name: "Dashboard",
        url: "/merchant/dashboard",
        margin: "4",
        show: true
    },
    {
        icon: "order",
        name: "Orders",
        url: "/merchant/orders",
        margin: "4",
        show: true
    },
    {
        icon: "groupedOrder",
        name: "Statements",
        url: "/merchant/customers/billing/statements",
        margin: "4",
        show: true
    },
    {
        icon: "plans",
        name: "Plans",
        url: "/merchant/plans",
        margin: "4",
        show: true
    },
    {
        icon: "users",
        name: "Customers",
        url: "/merchant/customers",
        margin: "4",
        show: true
    },
    {
        icon: "statement",
        name: "Settlement reports",
        url: "/merchant/settlement",
        margin: "4",
        show: true
    }
];

interface MerchantItemListContext {
    countryCode?: string;
    shortName?: string;
    partnerShortName?: string;
}

const LEGACY_SETTLEMENT_MERCHANTS = ["elektroimportøren"];

function getMerchantItemList(context: MerchantItemListContext = {}): INavItem[] {
    let navList = merchantItemList;
    const { countryCode, shortName, partnerShortName } = context;
    if (countryCode && shortName) {
        if (!(LEGACY_SETTLEMENT_MERCHANTS.includes(shortName) && countryCode == "NO")) {
            const index = navList.findIndex(function findUrl(ni) {
                return ni.url == "/merchant/settlement";
            });
            if (index > -1) {
                navList[index].url = "/merchant/payouts";
            }
        }

        // Removing the billing account statements page for non GB countries
        if (countryCode !== "GB") {
            navList = navList.filter((item) => {
                return item.name !== "Statements";
            });
        }

        // Removing the plans page if country is NL and partner shortname is abn
        if (partnerShortName == "abn" || partnerShortName == "abnamrobanknv") {
            navList = navList.filter((item) => {
                return item.name !== "Plans";
            });
        }
    }
    return navList;
}

const summaryList: IMorderDetail[] = [
    {
        width: 1,
        imgUrl: "./pictures/shopping.svg",
        title: "ORDER"
    },
    {
        width: 2,
        imgUrl: "./pictures/buyer.svg",
        title: "CUSTOMER INFORMATION"
    },
    {
        width: 1,
        imgUrl: "./pictures/delivery.svg",
        title: "SHIPPING"
    },
    {
        width: 2,
        imgUrl: "./pictures/invoice_purple.svg",
        title: "CUSTOMER PAYMENT INFORMATION"
    }
];

const frameList: IFrame[] = [
    {
        imgLink: "/pictures/Woocommerce.svg",
        alt: "woocommerce",
        hour: "1 h",
        pluginLink: "https://wordpress.org/plugins/tillit-payment-gateway/",
        guideLink: `${DOCS_URL}/developer-portal/plugins/woocommerce`
    },
    {
        imgLink: "/pictures/Magento.svg",
        alt: "magento",
        hour: "3-5 h",
        pluginLink: "https://github.com/two-inc/magento2",
        guideLink: `${DOCS_URL}/developer-portal/plugins/magento`
    }
];

export { buyerItemList, summaryList, frameList, getMerchantItemList };
