<script lang="ts">
    import { _ } from "svelte-i18n";

    import type { ICountry } from "../../static/types";
    import { account } from "../../store/merchant/account.store";
    import {
        customersLoading,
        actions as customerActions
    } from "../../store/merchant/customers.store";
    import modalState from "../../store/modals.store";
    import CompanySelector from "../companySelector/CompanySelector.svelte";
    import Button from "../Elements/Button.svelte";
    import Selector from "../Elements/Selector.svelte";
    import CountrySelector from "../countrySelector/CountrySelector.svelte";
    import { COUNTRIES } from "../../static/constant";
    import { getBuyerCountries } from "../../static/customers";

    let company: string = "";
    let companyID: string = "";
    let paymentTerm: string = "";
    let countryCode: string = "";
    let selectedCountry = {};
    let isCrossBorderEnabled: boolean = false;
    let country: ICountry = COUNTRIES[countryCode] || null;
    let buyerCountries = {};

    $: if (selectedCountry) {
        resetCompany(selectedCountry);
    }

    $: if ($account?.countryCode) {
        isCrossBorderEnabled =
            $account.flags.cross_border_enabled && $account.buyer_country_configurations.length > 1;
        buyerCountries = getBuyerCountries($account.buyer_country_configurations || []);
        country = COUNTRIES[$account.countryCode] || null;
        countryCode = $account.countryCode;
    }

    function resetCompany(newCountry: ICountry) {
        company = "";
        companyID = "";
        countryCode = newCountry.country_code;
        country = selectedCountry;
    }

    const invalid = {
        selectCompany: "",
        paymentTerm: ""
    };
    const options = ["14 days", "21 days", "30 days", "45 days", "60 days", "90 days"];
    const handleClose = () => {
        modalState.actions.setModal(null, {});
    };

    function addCustomer() {
        if (!company) invalid.selectCompany = $_("customer.selectCompanyError");
        if (!paymentTerm) invalid.paymentTerm = $_("customer.selectPaymentTermError");
        if (!invalid.selectCompany && !invalid.paymentTerm) {
            customerActions.addPaymentTerms({
                buyer_organization_number: companyID,
                due_in_days: Number(paymentTerm.split(" ")[0]),
                buyer_company_name: company,
                buyer_country_code: countryCode
            });
        }
    }
</script>

<div class="relative w-full p-8 sm:w-xxs">
    <div class="w-full text-2xl font-bold flex justify-between text-center items-center">
        <p class="w-full">{$_("customer.configureTitle")}</p>
        <div
            class="rounded-full hover:bg-gray-200 cursor-pointer flex justify-center items-center w-7 h-7 flex-shrink-0"
        >
            <img
                src="/pictures/close.svg"
                alt="close"
                on:keydown={handleClose}
                on:click={handleClose}
            />
        </div>
    </div>
    {#if isCrossBorderEnabled}
        <div class="mt-8">
            <CountrySelector
                countries={buyerCountries}
                bind:country={selectedCountry}
                initialCountryCode={countryCode}
            />
        </div>
    {/if}
    <div class="mt-9">
        <CompanySelector
            {countryCode}
            showId={false}
            bind:company
            bind:companyID
            invalid={invalid.selectCompany}
            placeholderText={$_("customer.selectCompanyPlaceholder")}
        />
    </div>
    {#if companyID}
        <p class="ml-4 text-sm mt-2">
            {$_("customer.companyIdLabel")}
            <span class="font-bold">{companyID}</span>
        </p>
    {/if}
    <div class="mt-8">
        <Selector
            label={$_("customer.paymentTermsLabel")}
            description={$_("customer.paymentTermsDescription")}
            invalid={invalid.paymentTerm}
            bind:value={paymentTerm}
            {options}
        />
    </div>
    <div class="mt-12 b">
        <Button
            label={$_("components.confirm")}
            on:click={addCustomer}
            loading={$customersLoading}
        />
    </div>
</div>
