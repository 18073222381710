import { OrderState } from "../static/types";

export function getTooltipDetailsForInvoiceEdit(order, $_) {
    let tooltipContent = "";
    let tooltipWidth = "";

    if (
        order?.invoice_type === "FUNDED_INVOICE" &&
        order?.state === OrderState.FULFILLED &&
        order?.invoice_details?.payee?.organization_name?.toUpperCase().includes("KREDINOR")
    ) {
        tooltipContent = $_("tooltips.invoiceEdit.nokFundedInvoice");
        tooltipWidth = "505px";
    } else if (order?.status === OrderState.CANCELLED || order?.state === OrderState.CANCELLED) {
        tooltipContent = $_("tooltips.invoiceEdit.cancelledOrder");
        tooltipWidth = "340px";
    } else if (order?.state === OrderState.REFUNDED) {
        tooltipContent = $_("tooltips.invoiceEdit.refundedInvoice");
        tooltipWidth = "400px";
    } else if (order?.payable_amount < order?.gross_amount) {
        tooltipContent = $_("tooltips.invoiceEdit.partialPayment");
        tooltipWidth = "350px";
    } else if (order?.status === "PARTIAL") {
        tooltipContent = $_("tooltips.invoiceEdit.partialFulfillment");
        tooltipWidth = "530px";
    } else {
        tooltipContent = $_("tooltips.invoiceEdit.editDetails");
        tooltipWidth = "90px";
    }

    return { tooltipContent, tooltipWidth };
}

export function getTooltipDetailsForOrderEdit(order, $_) {
    let tooltipContent = "";
    let tooltipWidth = "";

    if (
        order?.invoice_type === "FUNDED_INVOICE" &&
        order?.state === OrderState.FULFILLED &&
        order?.invoice_details?.payee?.organization_name?.toUpperCase().includes("KREDINOR")
    ) {
        tooltipContent = $_("tooltips.orderEdit.nokFundedInvoiceFulfilled");
        tooltipWidth = "505px";
    } else if (order?.status === OrderState.CANCELLED || order?.state === OrderState.CANCELLED) {
        tooltipContent = $_("tooltips.orderEdit.cancelledOrder");
        tooltipWidth = "340px";
    } else if (order?.state === OrderState.REFUNDED) {
        tooltipContent = $_("tooltips.orderEdit.refundedOrder");
        tooltipWidth = "425px";
    } else if (
        order?.state === OrderState.FULFILLED ||
        order?.status === "PARTIAL" ||
        order?.status === "QUOTED" ||
        order?.state === OrderState.CONFIRMED ||
        order?.state === OrderState.VERIFIED
    ) {
        tooltipContent = $_("tooltips.orderEdit.editProducts");
        tooltipWidth = "150px";
    } else {
        tooltipContent = "";
        tooltipWidth = "0px";
    }

    return { tooltipContent, tooltipWidth };
}
