<script lang="ts">
    export let size = 20;
    export let stroke = "#1D2939";
</script>

<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.4165 13.3332C10.4165 13.5633 10.23 13.7498 9.99984 13.7498C9.76972 13.7498 9.58317 13.5633 9.58317 13.3332M10.4165 13.3332C10.4165 13.1031 10.23 12.9165 9.99984 12.9165C9.76972 12.9165 9.58317 13.1031 9.58317 13.3332M10.4165 13.3332H9.58317M8.33317 8.74984V8.33317C8.33317 7.4127 9.07936 6.6665 9.99984 6.6665C10.9203 6.6665 11.6665 7.4127 11.6665 8.33317V8.43427C11.6665 8.90311 11.4803 9.35275 11.1487 9.68427L9.99984 10.8332M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C9.05037 18.3332 8.23612 18.1979 7.48006 17.9275C6.7655 17.6719 6.4082 17.5441 6.2711 17.5118C5.00617 17.2144 4.48142 18.0813 3.39121 18.2629C2.85574 18.3521 2.37929 17.9137 2.42372 17.3727C2.46258 16.8997 2.78974 16.4522 2.92029 15.998C3.19169 15.0535 2.82342 14.3375 2.43433 13.4976C1.94163 12.434 1.6665 11.249 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
        {stroke}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>
