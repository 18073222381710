<script lang="ts">
    export let size: number = 16;
</script>

<svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="var(--bgSemanticHighlightTertiary)" />
    <path
        d="M20.3001 12.2999C17.4999 12.2999 16.0998 12.2999 15.0302 12.8449C14.0894 13.3243 13.3245 14.0892 12.8451 15.03C12.3001 16.0995 12.3001 17.4997 12.3001 20.2999V25.7999C12.3001 26.64 12.3001 27.06 12.4636 27.3809C12.6074 27.6632 12.8369 27.8926 13.1192 28.0364C13.44 28.1999 13.8601 28.1999 14.7001 28.1999H33.3001C34.1402 28.1999 34.5603 28.1999 34.8811 28.0364C35.1634 27.8926 35.3928 27.6632 35.5367 27.3809C35.7001 27.06 35.7001 26.64 35.7001 25.7999V20.2999C35.7001 17.4997 35.7001 16.0995 35.1552 15.03C34.6758 14.0892 33.9109 13.3243 32.9701 12.8449C31.9005 12.2999 30.5004 12.2999 27.7002 12.2999H20.3001Z"
        fill="var(--bgSemanticHighlightSecondary)"
    />
    <path
        d="M11.4547 29.9999C11.404 29.9999 11.3787 29.9999 11.3573 30.0005C10.5625 30.0232 9.92346 30.6623 9.90076 31.4571C9.90015 31.4785 9.90015 31.5038 9.90015 31.5545C9.90015 31.6895 9.90015 31.757 9.90178 31.8141C9.96233 33.9337 11.6664 35.6377 13.7859 35.6983C13.843 35.6999 13.9105 35.6999 14.0456 35.6999H33.9547C34.0897 35.6999 34.1573 35.6999 34.2144 35.6983C36.3339 35.6377 38.038 33.9337 38.0985 31.8141C38.1002 31.757 38.1002 31.6895 38.1002 31.5545C38.1002 31.5038 38.1002 31.4785 38.0995 31.4571C38.0768 30.6623 37.4378 30.0232 36.643 30.0005C36.6216 29.9999 36.5963 29.9999 36.5456 29.9999H11.4547Z"
        fill="var(--bgSemanticHighlightSecondary)"
    />
</svg>
