<script lang="ts">
    import { _ } from "svelte-i18n";
    import Fa from "svelte-fa";
    import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";

    import mAllOrdersState from "../../../store/merchant/allOrders.store";
    import OrdersList from "./OrdersList.svelte";
    import { Button as TwoUIButton } from "@two-ui/core";
    import {
        account,
        showAccountAlert,
        signupComplete
    } from "../../../store/merchant/account.store";
    import { OrderType } from "../../../static/types";
    import Search from "../../../components/search/Search.svelte";
    import { TAB_ITEMS } from "./tableConstants";
    import CombinedOrdersFilter from "../../../components/invoices/filters/CombinedOrdersFilter.svelte";
    import ToolTip from "../../../components/tooltip/ToolTip.svelte";
    import helpers from "../../../utilsV2/helpers";
    import PageHeader from "../../../components/pageHeader/PageHeader.svelte";
    import MetaWrapper from "../../../components/metadata/MetaWrapper.svelte";
    import modalState from "../../../store/modals.store";
    import SignupIncompleteModal from "../../../components/modals/AccountSetup/SignupIncompleteModal.svelte";
    import environment from "@/services/environment";

    const TABS = [
        { key: "All", label: $_("order.list.tabs.all") },
        { key: "Pending", label: $_("order.list.tabs.pending") },
        { key: "Ready to fulfill", label: $_("order.list.tabs.readyToFulfill") },
        { key: "Fulfilled", label: $_("order.list.tabs.fulfilled") },
        { key: "Refunded", label: $_("order.list.tabs.refunded") },
        { key: "Cancelled", label: $_("order.list.tabs.cancelled") }
    ];

    function translateTitle(title) {
        switch (title) {
            case "all_orders":
                return $_("order.table.tabs.all_orders.title");
            case "pending_orders":
                return $_("order.table.tabs.pending_orders.title");
            case "ready_to_fulfill":
                return $_("order.table.tabs.ready_to_fulfil.title");
            case "fulfilled_orders":
                return $_("order.table.tabs.fulfilled_orders.title");
            case "refunded_orders":
                return $_("order.table.tabs.refunded_orders.title");
            case "cancelled_orders":
                return $_("order.table.tabs.cancelled_orders.title");
            default:
                return title;
        }
    }

    function translateSummary(title) {
        switch (title) {
            case "all_orders":
                return $_("order.table.tabs.all_orders.summary", {
                    values: { productName: environment.branding.productName }
                });
            case "pending_orders":
                return $_("order.table.tabs.pending_orders.summary");
            case "ready_to_fulfill":
                return $_("order.table.tabs.ready_to_fulfil.summary");
            case "fulfilled_orders":
                return $_("order.table.tabs.fulfilled_orders.summary");
            case "refunded_orders":
                return $_("order.table.tabs.refunded_orders.summary");
            case "cancelled_orders":
                return $_("order.table.tabs.cancelled_orders.summary");
            default:
                return title;
        }
    }

    let merchantInitiatedEnabled: boolean = false;
    let searchValue: string = "";
    let loading: boolean = false;
    let selectedTab: string = "All";
    let selectedStatus: string[] = ["ALL"];
    let selectedAction: string = "Any";
    let orderSource: OrderType[] = [OrderType.MERCHANT_INITIATED, OrderType.BUYER_INITIATED];

    let combinedOrdersFilterComponent;

    $: searchValue, handleSearchChanged();

    function setTab(tabItem) {
        mAllOrdersState.actions.setOrderTab(tabItem);
        mAllOrdersState.actions.getAllOrders();
        combinedOrdersFilterComponent.updateFilterCount();
    }

    function setSearch(e) {
        mAllOrdersState.actions.setSearchText(e.target.value);
        mAllOrdersState.actions.setOrderPage(1);
    }

    const handleSearchChanged = helpers.debounce(() => {
        mAllOrdersState.actions.setSearchText(searchValue);
        mAllOrdersState.actions.getAllOrders();
    }, 400);

    onMount(() => {
        mAllOrdersState.loading((value: boolean) => (loading = value));
        mAllOrdersState.selectedTab((value: string) => (selectedTab = value));
        mAllOrdersState.searchValue((value: string) => (searchValue = value));
    });

    $: {
        merchantInitiatedEnabled = $account?.flags?.merchant_initiated_enabled;
    }
</script>

<MetaWrapper title={$_("order.merchantOrders")} hotjarId="2805260">
    <div class="page-container">
        <PageHeader
            title={$_("order.orders")}
            showAccountAlert={$showAccountAlert}
            newUser={!$account?.flags?.has_made_order}
        >
            <div slot="cta" class="flex">
                <div class="w-42 ml-4">
                    {#if merchantInitiatedEnabled && !environment.branding.hideOrderCreationBtn}
                        <TwoUIButton
                            on:click={() =>
                                $signupComplete
                                    ? navigate("/merchant/createorder/")
                                    : modalState.actions.setModal("", {}, SignupIncompleteModal)}
                            size="md"
                            variant="primary"
                            content={$_("order.list.createNewOrder")}
                            fullSize={true}
                        />
                    {/if}
                </div>
            </div>
            <div slot="tabs" class="tab-headers">
                {#each TABS as tab}
                    <button
                        class="px-6 mr-2 h-10 tab-header"
                        on:click={() => setTab(tab.key)}
                        class:selected={selectedTab === tab.key}
                    >
                        {tab.label}
                    </button>
                {/each}
            </div>
        </PageHeader>
        <div class="content-container">
            <div class="rounded-lg bg-white mx-8 mt-6 mb-8 orders-summary">
                <div class="table-header-bar">
                    <div class="table-title">
                        <p class="text-lg font-bold">
                            {translateTitle(
                                TAB_ITEMS[selectedTab].title.toLowerCase().replace(/\s+/g, "_")
                            )}
                        </p>
                        <span class="tooltip-container" style="margin-top: 2px;">
                            <ToolTip
                                width="250px"
                                position="right"
                                content={translateSummary(
                                    TAB_ITEMS[selectedTab].title.toLowerCase().replace(/\s+/g, "_")
                                )}
                            >
                                <Fa size="sm" icon={faCircleQuestion} color="var(--primary-500)" />
                            </ToolTip>
                        </span>
                    </div>
                    <div class="search">
                        <Search
                            bind:searchValue
                            {setSearch}
                            placeholder={$_("order.list.searchPlaceholder")}
                        />
                        <CombinedOrdersFilter
                            bind:searchValue
                            bind:selectedTab
                            bind:selectedStatus
                            bind:orderSource
                            bind:selectedAction
                            bind:this={combinedOrdersFilterComponent}
                        />
                    </div>
                </div>
                <OrdersList tableType={selectedTab} {loading} bind:orderSource />
            </div>
        </div>
    </div>
</MetaWrapper>

<style>

    .tooltip-container {
        margin: auto 0.5rem;
    }

    .orders-summary {
        display: flex;
        overflow: auto;
        flex-direction: column;
    }

    .tab-headers {
        height: 2.5rem;
        display: flex;
        box-sizing: border-box;
    }

    .tab-header:hover {
        background-color: var(--bgInteractiveSemanticHighlightHoverSecondary);
        border-radius: 8px 8px 0px 0px;
    }

    .tab-header {
        line-height: 2.5rem;
        font-size: 14px;
        white-space: nowrap;
    }

    .table-header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: stretch;
        align-content: center;
    }
    .table-title {
        font-family: "Aeonik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        color: var(--ctnGeneralPrimary);
    }
    .table-header-bar div {
        margin: 1.25rem 2rem 1.25rem 2rem;
    }
    .search {
        width: 70%;
        display: flex;
        gap: 0.5rem;
    }
</style>
