<script lang="ts">
    import { _ } from "svelte-i18n";

    import CardData from "../../../../components/Elements/CardData.svelte";
    import type { PaymentMeansType, RepaymentPlanDetails } from "./types";

    export let planDetails: RepaymentPlanDetails;
    export let formatCurrency = (input: string) => input;

    function getAccountNumberLabel(type: PaymentMeansType): string {
        var label = $_(`planDetails.paymentDetails.accountNumber.${type}`);
        if (!label) {
            // fallback in case BE introduces new type before FE has translations for it
            var label = $_("planDetails.paymentDetails.accountNumber.GB_CREDIT_TRANSFER");
        }
        return label;
    }

    function getBranchCodeLabel(type: PaymentMeansType): string {
        var label = $_(`planDetails.paymentDetails.branchCode.${type}`);
        if (!label) {
            // fallback in case BE introduces new type before FE has translations for it
            label = $_("planDetails.paymentDetails.branchCode.GB_CREDIT_TRANSFER");
        }
        return label;
    }

    // TODO display account name once we worked out how to reliably retrieve this
    // TODO format sort code with hyphens
</script>

<div class="card-header">
    <p class="card-title">{$_("planDetails.paymentDetails.title")}</p>
    <div class="header-data">
        <CardData label={$_("planDetails.paymentDetails.planValue")}>
            <div class="emphasise">
                {formatCurrency(planDetails.total_repayment_amount)}
            </div>
        </CardData>
        <CardData label={$_("planDetails.paymentDetails.invoiced")}>
            <div class="emphasise-secondary">
                {formatCurrency(planDetails.invoiced_amount)}
            </div>
        </CardData>
        <CardData label={$_("planDetails.paymentDetails.remaining")}>
            <div class="emphasise-secondary">
                {formatCurrency(planDetails.uninvoiced_amount)}
            </div>
        </CardData>
    </div>
</div>
<div class="card-content">
    {#if planDetails.summary_status == "READY_TO_FULFIL"}
        <CardData label={$_("planDetails.paymentDetails.thisPlan")}>
            {$_("planDetails.paymentDetails.readyToFulfil")}
        </CardData>
    {:else if planDetails.summary_status == "CANCELLED" && planDetails.instalments.length == 0}
        <CardData label={$_("planDetails.paymentDetails.thisPlan")}>
            {$_("planDetails.paymentDetails.cancelled")}
        </CardData>
        <!-- hidden until designs for multiple payment means available-->
    {:else if false}
        <div class="card-columns">
            {#each planDetails.payment_means as means}
                <div>
                    <CardData label={getAccountNumberLabel(means.type)}>
                        {means.account_number || "-"}
                    </CardData>
                    <CardData label={getBranchCodeLabel(means.type)}>
                        {means.branch_code || "-"}
                    </CardData>
                </div>
            {/each}
            <div>
                <CardData label={$_("planDetails.paymentDetails.planFee")}
                    >{formatCurrency(planDetails.total_plan_fee)}</CardData
                >
            </div>
        </div>
    {/if}
</div>

<style lang="scss">
    .card-header {
        border-bottom: 1px solid var(--gray-200);
        padding: 1.5rem 1.5rem 1rem;
        margin-bottom: 1rem;
    }

    .card-title {
        font-weight: 700;
        margin-bottom: 1rem;
    }

    .card-content {
        padding: 0 1.5rem 1.5rem;
    }

    .card-columns {
        display: flex;
        gap: 30px;

        > div {
            flex-grow: 1;
        }
    }
    .header-data {
        display: flex;
        gap: 30px;
        font-size: 1rem;
    }
    .emphasise {
        font-size: 1rem;
        font-weight: 700;
    }

    .emphasise-secondary {
        font-size: 1rem;
    }
</style>
