<script lang="ts">
    import { _ } from "svelte-i18n";

    import environment from "@/services/environment";
    import modalState from "../../store/modals.store";
    import Button from "../Elements/Button.svelte";

    export let buyerUserName: string = "Buyer_User_Name";
    export let merchantName: string = "Nordmann AS";

    $: {
        modalState.modals((values) => {
            buyerUserName = values.props?.buyerUserName;
            merchantName = values.props?.merchantName;
        });
    }

    const deleteUser = () => {
        modalState.actions.setModal(null, null);
    };

    const cancel = () => {
        modalState.actions.setModal(null, null);
    };
</script>

<div class="pt-4 pb-8 px-10 sm:w-xsm max-w-lg w-full">
    <img class="m-auto" src="./pictures/user_delete.svg" alt="logo" />
    <p class="text-center py-4 text-2xl font-bold">{$_("modals.deleteUser")}</p>
    <p class="text-center text-sm">
        {$_("modals.deleteUserMessage", {
            values: { buyerUserName, merchantName, brandName: environment.branding.displayName }
        })}
    </p>
    <div class="mt-10">
        <div class="w-full">
            <Button label={$_("modals.deleteUser")} on:click={() => deleteUser()} />
        </div>
        <div class="w-full mt-4">
            <Button
                label={$_("components.cancel")}
                bgColor="white"
                color="#4F25E4"
                hBg="#FAFAFA"
                on:click={() => cancel()}
            />
        </div>
    </div>
</div>
