<script lang="ts">
    import { _ } from "svelte-i18n";

    import Icon from "../../../../components/icons/Icon.svelte";
    import Button from "../../../../components/Elements/Button.svelte";
    import type {
        MerchantB2bOnboardingData,
        MerchantB2bOnboardingFailure
    } from "../../../../static/types";
    import b2bonboardingState from "../../../../store/b2bonboarding.store";

    let details: MerchantB2bOnboardingFailure = {};

    $: b2bonboardingState.merchantOnboardingData.subscribe(
        (data: MerchantB2bOnboardingData) => (details = data?.failure)
    );
</script>

<div class="flex flex-col justify-center flex-wrap items-center px-10 absolute mainContainer">
    <Icon name="error" size={50} fill="#ccc" />
    <h2 class="text-black mb-6 font-bold text-center">
        {$_("b2bOnboarding.failure.heading")}
    </h2>
    <p class="font-normal text-base text-gray mb-6 text-center">
        {$_("b2bOnboarding.failure.details")}
    </p>

    <div class="w-full">
        <Button
            size="large"
            label={$_("components.continue")}
            borderRadius="20px"
            on:click={() => {
                window.close();
            }}
        />
    </div>
    <style>
        #hs-eu-cookie-confirmation {
            display: none !important;
        }
    </style>
</div>

<style>
    .mainContainer {
        z-index: 999;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>
