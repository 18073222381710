import environment from "@/services/environment";
import { monthNames } from "@/static/monthName";

// This can be deleted when receive real data from backend
const generateDateArray = (): string[] => {
    const today = new Date();
    const dateArray: string[] = [];
    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short" };

    for (let i = 0; i < 12; i++) {
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() - i * 7); // Subtract 7 days for each previous date
        dateArray.unshift(currentDate.toLocaleDateString("en-US", options)); // Format as "DD MMM"
    }

    return dateArray;
};

export type ChartSettings = {
    id: string;
    type?: string; // "bar" is by default
    lineData: Array<unknown>;
    label: Array<unknown>;
    hoverStrings?: [string, string] | [string];
    yAxisTitle: string;
    colours: [string, string] | [string];
    yTickModifier?: (value: number) => string;
};

export const getExposureChangeOverTimeChartData = (
    firstLabel: string,
    secondLabel: string
): ChartSettings => ({
    id: "exposure-change-over-time",
    lineData: [
        [100, 200, 400, 200, 400, 500, 200, 500, 600, 600, 300, 200],
        [200, 300, 700, 300, 600, 200, 100, 800, 1000, 4400, 2300, 3400]
    ],
    label: [...generateDateArray()],
    hoverStrings: [firstLabel, secondLabel],
    yAxisTitle: "",
    colours: ["#DC6803", environment.branding.chartMainColor]
});

export const getLosesPerMonthChartData = (
    firstLabel: string,
    secondLabel: string
): ChartSettings => ({
    id: "loses-per-month",
    lineData: [
        [45, 67, 75, 34, 31, 43],
        [12, 34, 21, 12, 9, 13]
    ],
    label: [...monthNames.slice(0, 6)],
    hoverStrings: [firstLabel, secondLabel],
    yAxisTitle: "",
    colours: ["#DC6803", environment.branding.chartMainColor]
});

export const getLostPercentageVolumeChartData = (
    firstLabel: string,
    secondLabel: string
): ChartSettings => ({
    id: "lost-percentage-volume",
    type: "line",
    lineData: [
        [0.8, 0.67, 0.75, 1.23, 0.54, 0.34],
        [0.12, 0.34, 0.21, 0.12, 0.9, 1.2]
    ],
    label: [...monthNames.slice(0, 6)],
    hoverStrings: [firstLabel, secondLabel],
    yAxisTitle: "",
    colours: ["#DC6803", environment.branding.chartMainColor]
});

export const getFraudLossesPerMonthChartData = (
    firstLabel: string,
    secondLabel: string
): ChartSettings => ({
    id: "fraud-losses-per-month",
    lineData: [
        [12, 5, 7, 17, 2, 7],
        [1, 3, 2, 3, 1, 2]
    ],
    label: [...monthNames.slice(0, 6)],
    hoverStrings: [firstLabel, secondLabel],
    yAxisTitle: "",
    colours: ["#DC6803", environment.branding.chartMainColor]
});

export const getFraudLostPercentageVolumeChartData = (
    firstLabel: string,
    secondLabel: string
): ChartSettings => ({
    id: "fraud-lost-percentage-volume",
    type: "line",
    lineData: [
        [0.2, 0.17, 0.15, 0.23, 0.54, 0.66],
        [0.05, 0.08, 0.11, 0.12, 0.23, 0.3]
    ],
    label: [...monthNames.slice(0, 6)],
    hoverStrings: [firstLabel, secondLabel],
    yAxisTitle: "",
    colours: ["#DC6803", environment.branding.chartMainColor]
});

export const getApprovalRateChartData = (): ChartSettings => ({
    id: "approval-rate",
    type: "line",
    lineData: [[94, 88, 92, 95, 97, 96]],
    label: [...monthNames.slice(0, 6)],
    yAxisTitle: "",
    yTickModifier: (value) => `${value}%`,
    colours: [environment.branding.chartMainColor]
});

export const getCustomersRejectedChartData = (): ChartSettings => ({
    id: "customers-rejected",
    lineData: [[4, 7, 3, 5, 2, 1]],
    label: [...monthNames.slice(0, 6)],
    yAxisTitle: "",
    colours: [environment.branding.chartMainColor]
});
